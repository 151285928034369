<div class="content-wrapper">
  <div>

  </div>
  <div class="d-flex">
    <div class="w-50 pe-4" style="border-right: 1px solid #BFBFBF">
      <form [formGroup]="attachmentForm" (submit)="onSubmit()">
        <div>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>
              Attachment
            </mat-label>
            <mat-select formControlName="attachment" [(ngModel)]="type" (selectionChange)="handleSelect($event)">
              <mat-option *ngFor="let option of attachmentOptions" [value]="option.value">
                {{ option.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="w-100 border-red ">
            <mat-label>{{ selectedAttachment === 'URL' ? ' URL Type' : 'Document Label' }}</mat-label>
            <input #attachmentAutoCompleter
                   [formControl]="docControl"
                   [matAutocomplete]="auto1"
                   [(ngModel)]="urlType"
                   type="text" matInput placeholder="e.g. Pan Card, Aadhar Card "
                   aria-describedby="title" required>
            <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"
                              [displayWith]="displayDocFn">
              <mat-option *ngFor=" let type of filteredDocs| async"
                          (onSelectionChange)="chooseDocType($event)" [value]="type">
                {{ type.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div *ngIf="(filteredDocs|async)?.length == 0" class=" dropdown-skills position-relative ">
            <ul>
              <li class="li_disabled">
                No match Found
              </li>
              <hr class="m-0 p-0">
              <li class="font-weight-bold">
                <div class="d-flex justify-content-between align-items-center"
                     (click)="addDocType($event,'doc', attachmentAutoCompleter.value)">
                  <span>Add this Document type:</span>
                  <button type="button" class="p-0 bg-transparent border-0 fs-20"> +</button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="selectedAttachment === 'URL'">
          <mat-form-field appearance="outline" class="w-100 border-red ">
            <mat-label>Paste URL</mat-label>
            <input formControlName="url"
                   type="text" matInput
                   aria-describedby="title" required>
          </mat-form-field>
        </div>
        <div *ngIf="selectedAttachment !== 'URL'">
          <div class="d-flex">
            <div class="me-2 ">
              <img class="thumbnail-image" *ngIf="imageSrc" [src]="imageSrc" alt="Consultant Image"/>
            </div>
            <div class="w-100" *ngIf="!imageSrc && !this.file">
              <div class="" (click)="uploadFileTrigger()">
                <input #fileInput style="display: none" type="file" class="form-control-file"
                       (change)="onSelectLogo($event)"/>
                <div class="d-flex align-items-center justify-content-center pt-3">
                  <div class="drag_drop_wrapper text-center w-75" (dragover)="handleDragOver($event)"
                       (drop)="onSelectLogo($event,true)">
                    <div class="d-flex justify-content-center"><img src="assets/img_1.png" style="height: 40px"/></div>
                    <div class="d-flex justify-content-center">
                      <div>
                        <span><b>Drag & Drop or choose file to upload</b></span><br/>
                        <span> Select Jpeg,PNG</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex row align-items-center justify-content-center" *ngIf="!imageSrc && this.file">
            <div class="drag_drop_wrapper justify-content-center w-75 d-flex align-items-center justify-content-center">
              <p class="px-2 d-flex text-center">{{ this.file.name }}</p>
              <span class="d-flex justify-content-center" (click)="delete()">
              <i class="fas fa-times-circle pb-3"></i></span>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-end pt-3">
          <button *ngIf="!showloader" mat-raised-button class="theme-bg-color text-white" type="submit">Save
            Attachment
          </button>
          <div *ngIf="showloader" class="d-flex align-items-center justify-content-end">
            <button mat-raised-button class="theme-bg-color text-white">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only"></span>
              </div>
              Save Attachment
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="w-50 ps-4">
      <div class="d-flex align-items-center justify-content-between">
        <div class="pb-2">
          Attachment History:
        </div>
        <div>
<!--          <div class=" ps-5 pt-3 d-flex align-items-center justify-content-start">-->
<!--            <mat-slide-toggle-->
<!--              class="example-margin"-->
<!--              color="primary"-->
<!--              [disabled]=false-->
<!--              [checked]="showArchived"-->
<!--              (change)="toggleShowArchivedAttachment($event)">-->
<!--              <div class="ActiveButton">Show Archived</div>-->
<!--            </mat-slide-toggle>-->
<!--          </div>-->
        </div>
      </div>
      <div>
        <table class="table" style="cursor:pointer;">
          <thead class="theme-bg-color">
          <tr>
            <th>Attachment</th>
            <th>Uploaded By</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let docData of documentData">
            <td>{{ docData?.type }}</td>
            <td>{{docData?.createdBy?.firstName}} {{docData?.createdBy?.lastName}}</td>
            <td>
             <div class="d-flex">
               <mat-slide-toggle
                 class="example-margin"
                 color="primary"
                 [disabled]=false
                 [checked]="docData?.publish"
                 (change)="togglePublishAttachment($event,docData.id)">
                 <div class="ActiveButton">Publish</div>
               </mat-slide-toggle>
<!--               <div class="ps-3" (click)="handleArchiveDocument(docData.id)">-->
<!--                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="18" height="18">-->
<!--                   <path-->
<!--                     d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z"/>-->
<!--                 </svg>-->
<!--               </div>-->
<!--               <div>-->
<!--                 <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="18" height="18">-->
<!--                   <g id="SVGRepo_bgCarrier" stroke-width="0"></g>-->
<!--                   <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>-->
<!--                   <g id="SVGRepo_iconCarrier"> <path d="M4.52185 7H7C7.55229 7 8 7.44772 8 8C8 8.55229 7.55228 9 7 9H3C1.89543 9 1 8.10457 1 7V3C1 2.44772 1.44772 2 2 2C2.55228 2 3 2.44772 3 3V5.6754C4.26953 3.8688 6.06062 2.47676 8.14852 1.69631C10.6633 0.756291 13.435 0.768419 15.9415 1.73041C18.448 2.69239 20.5161 4.53782 21.7562 6.91897C22.9963 9.30013 23.3228 12.0526 22.6741 14.6578C22.0254 17.263 20.4464 19.541 18.2345 21.0626C16.0226 22.5842 13.3306 23.2444 10.6657 22.9188C8.00083 22.5931 5.54702 21.3041 3.76664 19.2946C2.20818 17.5356 1.25993 15.3309 1.04625 13.0078C0.995657 12.4579 1.45216 12.0088 2.00445 12.0084C2.55673 12.0079 3.00351 12.4566 3.06526 13.0055C3.27138 14.8374 4.03712 16.5706 5.27027 17.9625C6.7255 19.605 8.73118 20.6586 10.9094 20.9247C13.0876 21.1909 15.288 20.6513 17.0959 19.4075C18.9039 18.1638 20.1945 16.3018 20.7247 14.1724C21.2549 12.043 20.9881 9.79319 19.9745 7.8469C18.9608 5.90061 17.2704 4.3922 15.2217 3.6059C13.173 2.8196 10.9074 2.80968 8.8519 3.57803C7.11008 4.22911 5.62099 5.40094 4.57993 6.92229C4.56156 6.94914 4.54217 6.97505 4.52185 7Z" fill="#0F0F0F"></path> </g></svg>-->
<!--               </div>-->
             </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <mat-paginator [length]="totalElements"
                     [pageSizeOptions]="[5, 10, 20, 50]"
                     [hidden]="totalElements <= 5"
                     aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
</div>
