import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {DataService} from "../../../services/data.service";

// @ts-ignore
import {saveAs} from 'file-saver';
import {DateRangesComponent} from "../../../util/date-ranges/date-ranges.component";
import {MatSelect} from "@angular/material/select";
import { DateRange } from '@angular/material/datepicker';
import * as moment from 'moment';
import {MatPaginator} from "@angular/material/paginator";
import {debounceTime, distinctUntilChanged, filter, tap} from "rxjs/operators";
import {fromEvent} from "rxjs";

@Component({
  selector: 'app-audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.scss']
})
export class AuditReportComponent implements OnInit ,AfterViewInit{
  userId: any;
  @ViewChild('select') select: MatSelect;
  @ViewChild('action') action: MatSelect;
  @ViewChild('dateFilter') dateFilter: DateRangesComponent;
  audit: any[] = [];
  members: any[] = [];
  pageSize = 12;
  totalAudits=0
  totalPages = 0;
  allRecords = 0;
  currentPage = 0;
  selectedFilters: any[] = [];
  visible = false;
  selectedUsers: any[] = [];
  selectedActions: any[] = [];
  selectedUsersName: any[] = [];
  role: any;
  allSelected = false;
  id = 0;
  startDate: Date | null | any = null;
  endDate: Date | null | any = null;
  actionFilter:any[]=[];
  showLoader:boolean = true;
  selectedRole:any[]=[];
  dateRange = new DateRange<Date>(null, null);
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input',{static:true}) input:ElementRef | any;
  constructor(
    private service: ApiService,
    private dataService: DataService,
  ) {
    this.actionFilter=['Creation','Deletion','LogIn','Deactivation','Change','Activation','Rename','Archive','Restore'];
  }

  ngOnInit(): void {
    this.loadMembers(0);
    this.loadHistory(this.currentPage,this.pageSize);
  }
  ngAfterViewInit() {
    this.paginator.page.pipe(
        tap(() => {
          this.loadHistory(this.paginator.pageIndex, this.paginator.pageSize);
        })
    ).subscribe();
    if (this.input) {
      fromEvent(this.input?.nativeElement, 'keyup')
          .pipe(
              filter(Boolean),
              debounceTime(500),
              distinctUntilChanged(),
              tap(() => this.loadHistory(this.paginator.pageIndex, this.paginator.pageSize )),
          )
          .subscribe();
    }
  }
  removeFilter(index: number, filterchip: any) {
    if (filterchip.name == 'Users') {
      this.select.options.forEach((d) => d.deselect())
    } else if (filterchip.name == 'Actions') {
      this.action.options.forEach((d) => d.deselect())
    } else if(filterchip.name == 'Date') {
      if(this.startDate == undefined){
        this.endDate = undefined;
      } else if(this.endDate == undefined) {
        this.startDate = undefined
      } else {
        this.startDate = undefined;
        this.endDate = undefined
      }
    }
    this.loadHistory(this.currentPage,this.pageSize);
    this.selectedFilters.splice(index, 1);
  }

  getInitials(firstName: string, lastName: string): string {
    if(!firstName && !lastName) {
      return '';
    }
    let firstNames = firstName.trim().replace(/_/g, '').split(' '),
      initials = firstNames[0].substring(0, 1).toUpperCase();
    let lastNames = lastName.trim().replace(/_/g, '').split(' '),
      initialsLast = lastNames[0].substring(0, 1).toUpperCase();
    if (firstNames.length > 1) {
      initials += firstNames[firstNames.length - 1].substring(0, 1).toUpperCase();
    }
    if (lastNames.length > 1) {
      initialsLast += lastNames[lastNames.length - 1].substring(0, 1).toUpperCase();
    }
    return (initials + initialsLast);
  }

  onResetFilters(event: any, page: number) {
    event.stopPropagation();
    this.select.options.forEach((d) => d.deselect())
    this.action.options.forEach((e) => e.deselect())
    this.selectedFilters = [];
    this.selectedActions = [];
    this.startDate = undefined;
    this.endDate = undefined
    this.loadHistory(this.currentPage,this.pageSize);
  }

  onLoadHistory(event: any, page: number) {
    event.stopPropagation();
    this.selectedFilters = [];
    this.startDate = this.startDate ? this.formatDateToYYYYMMDD(this.startDate) : undefined;
    this.endDate = this.endDate ? this.formatDateToYYYYMMDD(this.endDate) : undefined;
    if (this.startDate != undefined && this.endDate != undefined) {
      this.selectedFilters.push({
        "name": 'Date',
        "value": this.startDate + " - " + this.endDate
      })
    }
    else if(this.endDate !== undefined) {
      this.selectedFilters.push({
        "name": 'Date',
        "value": this.endDate
      })
    } else if(this.startDate !== undefined) {
      this.selectedFilters.push({
        "name": 'Date',
        "value": this.startDate
      })
    }
    if (this.selectedUsersName.length > 0) {
      this.selectedFilters.push({
        "name": 'Users',
        "value": this.selectedUsersName
      })
    }
     if (this.selectedActions.length > 0) {
      this.selectedFilters.push({
        "name": 'Actions',
        "value": this.selectedActions
      })
    }
    this.loadHistory(this.currentPage,this.pageSize);
  }

  formatDateToYYYYMMDD(date: Date): string {
    if (!date) return '';
    return moment(date).format('YYYY-MM-DD');
  }

  loadMembers(page: number): void {
    this.dataService.isLoading.next(true);
    this.service.getMembers(page,['ADMIN','TA','MANAGER','BSE','BSM','SALES_HEAD'],true).then(res => {
      this.dataService.isLoading.next(false);
      this.members = res.content;
      // this.totalPages = res['totalPages'];
      this.currentPage = page;
    });
  }

  loadHistory(page: number,size:number): void {
    this.dataService.isLoading.next(true);
    const payload = {
      fromDate: this.startDate,
      toDate: this.endDate,
      users: this.selectedUsers,
      actions: this.selectedActions,
      vendorId: "",
    }
    this.service.getAuditHostory(payload, page,size).then(resp => {
      this.dataService.isLoading.next(false);
      this.audit = resp.content;
      this.totalPages = resp.page.totalPages;
      this.allRecords = resp.page.totalElements
      this.currentPage = page;
      this.showLoader = false
      this.paginator.pageIndex=resp.page.number;
      this.paginator.pageSize=resp.page.size;
      this.pageSize=resp.page.size;
      this.totalAudits =resp.page.totalElements;
    });
  }

  downloadExcel() {
    const payload = {
      fromDate: this.startDate,
      toDate: this.endDate,
      users: this.selectedUsers,
      vendorId: "",
    }
    this.service.downloadExcel(payload).subscribe(blob => saveAs(blob));
  }

  optionClick(member: any) {
    this.selectedUsers = [];
    this.selectedUsersName = [];
    member.value.forEach((u: any) => {
      this.selectedUsers.push(u['userId']);
      this.selectedUsersName.push(u['firstName'] + " " + u['lastName']);
    });
    let newStatus = true;
    this.allSelected = newStatus;
  }

  optionAction(member: any) {
    this.selectedActions = member.value;
    let newStatus = true;
    this.allSelected = newStatus;
  }

}
