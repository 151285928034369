import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeLoaderService {
  private themeSubject = new BehaviorSubject<{ primaryColor: string, secondaryColor?: string } | null>(null);
  public theme$ = this.themeSubject.asObservable();

  constructor() {
    this.loadTheme();
  }

  private loadTheme() {
    const theme = localStorage.getItem('selectedTheme');
    if (theme) {
      try {
        const parsedTheme = JSON.parse(theme);
        this.themeSubject.next(parsedTheme);
        this.applyTheme(parsedTheme);
      } catch (error) {
        console.error('Failed to parse theme from localStorage', error);
      }
    }
  }

  public getTheme(): { primaryColor: string, secondaryColor?: string } | null {
    return this.themeSubject.getValue();
  }

  public setTheme(primaryColor: string, secondaryColor?: string) {
    const theme = { primaryColor, secondaryColor };
    this.themeSubject.next(theme);
    this.applyTheme(theme);
    localStorage.setItem('selectedTheme', JSON.stringify(theme)); // Save theme to localStorage
  }

  private applyTheme(theme: { primaryColor: string, secondaryColor?: string }) {
    const rootVariable = document.documentElement;
    rootVariable.style.setProperty('--primaryColor', theme.primaryColor);
    if (theme.secondaryColor) {
      rootVariable.style.setProperty('--secondaryColor', theme.secondaryColor);
    } else {
      rootVariable.style.removeProperty('--secondaryColor');
    }
  }
}
