<div class="content-wrapper">
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h5 class="page-title">Portal Settings</h5>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="content-wrapper-main-inner-content">
      <mat-tab-group mat-align-tabs="start" animationDuration="10ms" class="remove-border-bottom" color="transparent">
        <mat-tab label="Document Types" class="remove-border-bottom text-white" *ngIf="role !== 'TA'">
          <div class="content-wrapper">
            <div class="d-flex justify-content-between mat-head">
              <h5>Document Types</h5>
              <div class="download_link">
                <a type="link" [href]="getEnvironment().url + 'sample-document-type.csv'">Download Sample File</a>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-4">
                <div class="vendor_wrapper mt-0 ml-0 mr-0">
                  <div class="upload_doc text-center">
                    <div (click)="uploadFileTrigger('DOC')" (dragover)="handleDragOver($event)" (drop)="onSelectDocument($event,'DOC',true)">
                      <input #fileInput1 style="display: none" type="file" class="form-control-file"
                             (change)="onSelectDocument($event,'DOC',false)"
                             accept=".csv,.png,.doc,.docx,application/msword,application/vnd,application/pdf">
                      <h5>Upload Files</h5>
                      <p class="image-uploader_max-file-size mb-3">Max file size: 10MB (pdf, png, jpeg, xlx, doc,
                        docx)</p>
                      <div class="drag_drop_wrapper">
                        <div><img src="assets/img_1.png" style="height: 40px" class=""/></div>
                        <div *ngIf="!document" class="mt-3">
                          <div><span><b>Choose a file</b> or drag it here</span></div>
                        </div>
                        <div class="mt-3" *ngIf="document"><b> {{doc.name}} <br></b>
                        </div>
                        <div *ngIf="this.type=='DOC' && errorMessage" class="alert-danger bg-white">
                          <p><i class="fas fa-file-pdf mx-2"></i>{{errorMessage}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="form-group btns save-form">
                    <button *ngIf="!showloader" (click)="removeSelectedFile('DOC')" class="action-btn action-btn-cancel"
                            type="submit">Cancel
                    </button>
                    <button *ngIf="!showloader" (click)="onDocumentUpload('DOC')"
                            class="action-btn float-right theme-btn-color"
                            type="submit">Upload
                    </button>
                    <div *ngIf="showloader" disabled>
                      <button class="btn btn-saveform" disabled>
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div *ngIf="docFiles.length >0">
                  <div class="justify-content-center">
                    <form [formGroup]="editDocumentForm">
                      <table class="table table-striped" style="cursor:pointer;">
                        <thead class="tableview theme-bg-color">
                        <tr>
                          <th>File Name</th>
                          <th *ngIf="role!=='MANAGER'">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container formArrayName="docRows" *ngFor="let group of getDocFormControls.controls;  index as i;">
                          <tr *ngIf="checkIfDocTypeSelected(group.get('id')?.value)" [formGroupName]="i">

                            <td>
                              <mat-form-field>
                                <input matInput type="text" formControlName="name" id="inputDocumentName1"
                                       placeholder="Name">
                              </mat-form-field>
                            </td>
                            <td>
                              <mat-icon class="done m-2" (click)="doneRow(group,'DOC')"><span
                                class="badge bg-success text-white">Save</span>
                              </mat-icon>
                            </td>
                          </tr>
                          <tr *ngIf="!checkIfDocTypeSelected(group.get('id')?.value)">

                            <td>
                              <a>{{group.get('name')?.value}}</a>
                            </td>
                            <td *ngIf="role!=='MANAGER'">
                              <a type="button" (click)="editDocumentTypeRow(group)">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V12H19V10H5V20H12V22H5ZM22.125 17L20 14.875L20.725 14.15C20.9083 13.9667 21.1417 13.875 21.425 13.875C21.7083 13.875 21.9417 13.9667 22.125 14.15L22.85 14.875C23.0333 15.0583 23.125 15.2917 23.125 15.575C23.125 15.8583 23.0333 16.0917 22.85 16.275L22.125 17ZM14 23V20.875L19.3 15.575L21.425 17.7L16.125 23H14Z"
                                    fill="#545454"/>
                                </svg>
                              </a>

                              <mat-slide-toggle
                                class="example-margin mx-3"
                                color="primary"
                                [disabled]="false"
                                [checked]="group.get('active')?.value"
                                (change)="toggleDocType($event, group)">
                                <div class="ActiveButton">{{ group.get('active')?.value ? 'Enabled' : 'Disabled' }}</div>
                              </mat-slide-toggle>
                            </td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <mat-paginator [length]="totalDocuments"
                         [pageSize]="pageSize"
                         [pageSizeOptions]="[12,25,50,100]"
                         [hidden]="totalDocuments==0"
                         aria-label="Select page">
          </mat-paginator>
        </mat-tab>
        <mat-tab label="Designation" class="remove-border-bottom text-white" *ngIf="role !== 'TA'">
          <div class="content-wrapper">
            <div class="d-flex justify-content-between mat-head">
              <h5>Designation</h5>
              <div class="download_link">
                <a type="link" [href]="getEnvironment().url + 'sample-designation.csv'">Download Sample file</a>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-4">
                <div class="vendor_wrapper mt-0 ml-0 mr-0">
                  <div class="upload_doc text-center">
                    <div (click)="uploadFileTrigger('DES')" (dragover)="handleDragOver($event)" (drop)="onSelectDocument($event,'DES',true)">
                      <input #fileInput2 style="display: none" type="file" class="form-control-file"
                             (change)="onSelectDocument($event,'DES',false)"
                             accept=".csv,.png,.doc,.docx,application/msword,application/vnd,application/pdf">
                      <h5 *ngIf="!designation">Upload File</h5>
                      <p class="image-uploader_max-file-size mb-3">Max file size: 10MB (pdf, png, jpeg, xlx, doc,
                        docx)</p>
                      <div class="drag_drop_wrapper">
                        <div><img src="assets/img_1.png" style="height: 40px" class=""/></div>
                        <div *ngIf="!designation" class="mt-3">
                          <div><span><b>Choose a file</b> or drag it here</span></div>
                        </div>
                        <div class="mt-3" *ngIf="designation"><b> {{doc.name}} <br></b>
                        </div>
                        <div *ngIf="this.type == 'DES' && errorMessage" class="alert-danger bg-white">
                          <p><i class="fas fa-file-pdf mx-2"></i>{{errorMessage}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="form-group btns save-form">
                    <button *ngIf="!showloader" (click)="removeSelectedFile('DES')" class="action-btn action-btn-cancel">
                      Cancel
                    </button>
                    <button *ngIf="!showloader" (click)="onDocumentUpload('DES')"
                            class="action-btn float-right theme-btn-color"
                            type="submit">Upload
                    </button>
                    <div *ngIf="showloader" disabled>
                      <button class="btn btn-saveform" disabled>
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div *ngIf="designationFiles.length >0">
                  <div class="justify-content-center">
                    <form [formGroup]="editDesignationForm">
                      <table class="table table-striped" style="cursor:pointer;">
                        <thead class="tableview theme-bg-color">
                        <tr>
                          <th>File Name</th>
                          <th *ngIf="role!=='MANAGER'">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container formArrayName="desRows" *ngFor="let group of getDesFormControls.controls; let i=index">

                          <tr *ngIf="checkIfDesSelected(group.get('id')?.value)" [formGroupName]="i">
                            <td>
                              <mat-form-field>
                                <input matInput type="text" formControlName="name" id="inputDesignationName1"
                                       placeholder="Name">
                              </mat-form-field>
                            </td>
                            <td>
                              <mat-icon class="done m-2" (click)="doneRow(group,'DES')"><span
                                class="badge bg-success text-white">Save</span>
                              </mat-icon>
                            </td>
                          </tr>
                          <tr *ngIf="!checkIfDocTypeSelected(group.get('id')?.value)" >
                            <td>
                              <a>{{group.get('name')?.value}}</a>
                            </td>
                            <td *ngIf="role!=='MANAGER'">
                              <a type="button" (click)="editDesignationRow(group)">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V12H19V10H5V20H12V22H5ZM22.125 17L20 14.875L20.725 14.15C20.9083 13.9667 21.1417 13.875 21.425 13.875C21.7083 13.875 21.9417 13.9667 22.125 14.15L22.85 14.875C23.0333 15.0583 23.125 15.2917 23.125 15.575C23.125 15.8583 23.0333 16.0917 22.85 16.275L22.125 17ZM14 23V20.875L19.3 15.575L21.425 17.7L16.125 23H14Z"
                                    fill="#545454"/>
                                </svg>
                              </a>
                              <mat-slide-toggle
                                class="example-margin mx-3"
                                color="primary"
                                [disabled]="false"
                                [checked]="group.get('active')?.value"
                                (change)="toggleDesType($event,group)">
                                <div class="ActiveButton">{{ group.get('active')?.value ? 'Enabled' : 'Disabled' }}</div>
                              </mat-slide-toggle>

                              <!--                            <mat-slide-toggle-->
                              <!--                              class="example-margin mx-3"-->
                              <!--                              color="primary"-->
                              <!--                              [disabled]="false"-->
                              <!--                              [checked]="group.get('active')?.value"-->
                              <!--                              (change)="toggleDocType($event, group)">-->
                              <!--                              <div class="ActiveButton">{{ group.get('active')?.value ? 'Enabled' : 'Disabled' }}</div>-->
                              <!--                            </mat-slide-toggle>-->
                            </td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <mat-paginator [length]="totalDesignation"
                         [pageSizeOptions]="[12,25,50,100]"
                         (page)="handlePageDesignation($event)"
                         [hidden]="totalDesignation==0"
                         aria-label="Select page">
          </mat-paginator>
        </mat-tab>
        <mat-tab label="Skills" class="remove-border-bottom text-white" *ngIf="role !== 'TA'">
          <div class="content-wrapper">
            <div class="d-flex justify-content-between mat-head">
              <h5>Skills</h5>
              <div class="download_link">
                <a type="link" [href]="getEnvironment().url + 'sample-skill.csv'">Download Sample file</a>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-4">
                <div class="vendor_wrapper mt-0 ml-0 mr-0">
                  <div class="upload_doc text-center">
                    <div (click)="uploadFileTrigger('SKL')" (dragover)="handleDragOver($event)" (drop)="onSelectDocument($event,'SKL',true)">
                      <input #fileInput3 style="display: none" type="file" class="form-control-file"
                             (change)="onSelectDocument(($event), 'SKL',false)"
                             accept=".csv,.png,.doc,.docx,application/msword,application/vnd,application/pdf">
                      <h5 *ngIf="!skill">Upload File</h5>
                      <p class="image-uploader_max-file-size mb-3">Max file size: 10MB (pdf, png, jpeg, xlx, doc,
                        docx)</p>
                      <div class="drag_drop_wrapper">
                        <div><img src="assets/img_1.png" style="height: 40px" class=""/></div>
                        <div *ngIf="!skill" class="mt-3">
                          <div><span><b>Choose a file</b> or drag it here</span></div>
                        </div>
                        <div class="mt-3" *ngIf="skill"><b> {{doc.name}} <br></b>
                        </div>
                        <div *ngIf="this.type=='SKL' && errorMessage" class="alert-danger bg-white">
                          <p><i class="fas fa-file-pdf mx-2"></i>{{errorMessage}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-3">
                  <div class="form-group btns save-form">
                    <button *ngIf="!showloader" (click)="removeSelectedFile('SKL')" class="action-btn action-btn-cancel">
                      Cancel
                    </button>
                    <button *ngIf="!showloader" (click)="onDocumentUpload('SKL')"
                            class="action-btn float-right theme-btn-color"
                            type="submit">Upload
                    </button>
                    <div *ngIf="showloader" disabled>
                      <button class="btn btn-saveform" disabled>
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div *ngIf="skillFiles.length > 0">
                  <div class="justify-content-center">
                    <form [formGroup]="editSkillForm">
                      <table class="table table-striped" style="cursor:pointer;">
                        <thead class="tableview theme-bg-color">
                        <tr>
                          <th>File Name</th>
                          <th *ngIf="role!=='MANAGER'">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container formArrayName="skillRows" *ngFor="let group of getSkillFormControls.controls;  index as i;">
                          <tr *ngIf="checkIfSkillSelected(group.get('id')?.value)" [formGroupName]="i">
                            <td>
                              <mat-form-field>
                                <input matInput type="text" formControlName="title" id="inputSkillName1"
                                       placeholder="Name">
                              </mat-form-field>
                            </td>
                            <td>
                              <mat-icon class="done m-2" (click)="doneRow(group,'SKL')"><span
                                class="badge bg-success text-white">Save</span>
                              </mat-icon>
                            </td>
                          </tr>
                          <tr *ngIf="!checkIfSkillSelected(group.get('id')?.value)">
                            <td>
                              <a>{{group.get('title')?.value}}</a>
                            </td>
                            <td *ngIf="role!=='MANAGER'">
                              <a type="button" (click)="editSkillRow(group)">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V12H19V10H5V20H12V22H5ZM22.125 17L20 14.875L20.725 14.15C20.9083 13.9667 21.1417 13.875 21.425 13.875C21.7083 13.875 21.9417 13.9667 22.125 14.15L22.85 14.875C23.0333 15.0583 23.125 15.2917 23.125 15.575C23.125 15.8583 23.0333 16.0917 22.85 16.275L22.125 17ZM14 23V20.875L19.3 15.575L21.425 17.7L16.125 23H14Z"
                                    fill="#545454"/>
                                </svg>
                              </a>
                              <mat-slide-toggle
                                class="example-margin mx-3"
                                color="primary"
                                [disabled]=false
                                [checked]="group.get('active')?.value"
                                (change)="toggleSkillType($event,group)">
                                <div class="ActiveButton">{{group.get('active')?.value ? 'Enabled' : 'Disabled'}}</div>
                              </mat-slide-toggle>
                            </td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <mat-paginator [length]="totalSkills"
                         [pageSizeOptions]="[12,25,50,100]"
                         (page)="handlePageSkills($event)"
                         [hidden]="totalSkills==0"
                         aria-label="Select page">
          </mat-paginator>
        </mat-tab>

        <mat-tab label="Verticals" class="remove-border-bottom text-white" *ngIf="role !== 'TA'">
          <div class="content-wrapper">
            <div class="d-flex justify-content-between mat-head">
              <h5>Verticals</h5>
              <div class="download_link">
                <a type="link" [href]="getEnvironment().url + 'sample-verticals.csv'">Download Sample file</a>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-4">
                <div class="vendor_wrapper mt-0 ml-0 mr-0">
                  <div class="upload_doc text-center">
                    <div (click)="uploadFileTrigger('VERTICAL')" (dragover)="handleDragOver($event)" (drop)="onSelectDocument($event,'VERTICAL',true)">
                      <input #fileInput5 style="display: none" type="file" class="form-control-file"
                             (change)="onSelectDocument(($event), 'VERTICAL',false)"
                             accept=".csv,.png,.doc,.docx,application/msword,application/vnd,application/pdf">
                      <h5 *ngIf="!vertical">Upload File</h5>
                      <p class="image-uploader_max-file-size mb-3">Max file size: 10MB (pdf, png, jpeg, xlx, doc,
                        docx)</p>
                      <div class="drag_drop_wrapper">
                        <div><img src="assets/img_1.png" style="height: 40px" class=""/></div>
                        <div *ngIf="!vertical" class="mt-3">
                          <div><span><b>Choose a file</b> or drag it here</span></div>
                        </div>
                        <div class="mt-3" *ngIf="vertical"><b> {{doc.name}} <br></b>
                        </div>
                        <div *ngIf="this.type=='VERTICAL' && errorMessage" class="alert-danger bg-white">
                          <p><i class="fas fa-file-pdf mx-2"></i>{{errorMessage}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-3">
                  <div class="form-group btns save-form">
                    <button *ngIf="!showloader" (click)="removeSelectedFile('VERTICAL')" class="action-btn action-btn-cancel">
                      Cancel
                    </button>
                    <button *ngIf="!showloader" (click)="onDocumentUpload('VERTICAL')"
                            class="action-btn float-right theme-btn-color"
                            type="submit">Upload
                    </button>
                    <div *ngIf="showloader" disabled>
                      <button class="btn btn-saveform" disabled>
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div *ngIf="verticalFiles.length > 0">
                  <div class="justify-content-center">
                    <form [formGroup]="editVerticalForm">
                      <table class="table table-striped" style="cursor:pointer;">
                        <thead class="tableview theme-bg-color">
                        <tr>
                          <th>File Name</th>
                          <th *ngIf="role!=='MANAGER'">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container formArrayName="verticalRows" *ngFor="let group of getVerticalFormControls.controls;  index as i;">
                          <tr *ngIf="checkIfVerticalSelected(group.get('id')?.value)" [formGroupName]="i">
                            <td>
                              <mat-form-field>
                                <input matInput type="text" formControlName="name" id="inputVerticalName1"
                                       placeholder="Name">
                              </mat-form-field>
                            </td>
                            <td>
                              <mat-icon class="done m-2" (click)="doneRow(group,'VERTICAL')"><span
                                class="badge bg-success text-white">Save</span>
                              </mat-icon>
                            </td>
                          </tr>
                          <tr *ngIf="!checkIfVerticalSelected(group.get('id')?.value)">
                            <td>
                              <a>{{group.get('name')?.value}}</a>
                            </td>
                            <td *ngIf="role!=='MANAGER'">
                              <a type="button" (click)="editRow(group)">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V12H19V10H5V20H12V22H5ZM22.125 17L20 14.875L20.725 14.15C20.9083 13.9667 21.1417 13.875 21.425 13.875C21.7083 13.875 21.9417 13.9667 22.125 14.15L22.85 14.875C23.0333 15.0583 23.125 15.2917 23.125 15.575C23.125 15.8583 23.0333 16.0917 22.85 16.275L22.125 17ZM14 23V20.875L19.3 15.575L21.425 17.7L16.125 23H14Z"
                                    fill="#545454"/>
                                </svg>
                              </a>
                              <mat-slide-toggle
                                class="example-margin mx-3"
                                color="primary"
                                [disabled]="false"
                                [checked]="group.get('active')?.value"
                                (change)="toggleVerticalStatus($event,group)">
                                <div class="ActiveButton">{{group.get('active')?.value ? 'Enabled' : 'Disabled'}}</div>
                              </mat-slide-toggle>
                            </td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <mat-paginator [length]="totalVerticals"
                         [pageSizeOptions]="[12,25,50,100]"
                         (page)="handlePageVerticals($event)"
                         [hidden]="totalVerticals==0"
                         aria-label="Select page">
          </mat-paginator>
        </mat-tab>

        <mat-tab label="Education" class="remove-border-bottom text-white" *ngIf="role !== 'TA'">
          <div class="content-wrapper">
            <div class="d-flex justify-content-between mat-head">
              <h5>Education</h5>
              <div class="download_link">
                <a type="link" [href]="getEnvironment().url + 'sample-education.csv'">Download Sample file</a>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-4">
                <div class="settings_wrapper mt-0 ml-0 mr-0">
                  <div class="upload_doc text-center">
                    <div (click)="uploadFileTrigger('EDUCATION')" (dragover)="handleDragOver($event)" (drop)="onSelectDocument($event,'EDUCATION',true)">
                      <input #fileInput6 style="display: none" type="file" class="form-control-file"
                             (change)="onSelectDocument(($event), 'EDUCATION',false)"
                             accept=".csv,.png,.doc,.docx,application/msword,application/vnd,application/pdf">
                      <h5 *ngIf="!education">Upload File</h5>
                      <p class="image-uploader_max-file-size mb-3">Max file size: 10MB (pdf, png, jpeg, xlx, doc,
                        docx)</p>
                      <div class="drag_drop_wrapper">
                        <div><img src="assets/img_1.png" style="height: 40px" class=""/></div>
                        <div *ngIf="!education" class="mt-3">
                          <div><span><b>Choose a file</b> or drag it here</span></div>
                        </div>
                        <div class="mt-3" *ngIf="education"><b> {{doc.name}} <br></b>
                        </div>
                        <div *ngIf="this.type=='EDUCATION' && errorMessage" class="alert-danger bg-white">
                          <p><i class="fas fa-file-pdf mx-2"></i>{{errorMessage}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-3">
                  <div class="form-group btns save-form">
                    <button *ngIf="!showloader" (click)="removeSelectedFile('EDUCATION')" class="action-btn action-btn-cancel">
                      Cancel
                    </button>
                    <button *ngIf="!showloader" (click)="onDocumentUpload('EDUCATION')"
                            class="action-btn float-right theme-btn-color"
                            type="submit">Upload
                    </button>
                    <div *ngIf="showloader" disabled>
                      <button class="btn btn-saveform" disabled>
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div *ngIf="educationFiles.length > 0">
                  <div class="justify-content-center">
                    <form [formGroup]="editEducationForm">
                      <table class="table table-striped" style="cursor:pointer;">
                        <thead class="tableview theme-bg-color">
                        <tr>
                          <th>File Name</th>
                          <th *ngIf="role!=='MANAGER'">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container formArrayName="educationRows" *ngFor="let group of getEducationFormControls.controls;  index as i;">
                          <tr *ngIf="checkIfEducationSelected(group.get('id')?.value)" [formGroupName]="i">
                            <td>
                              <mat-form-field>
                                <input matInput type="text" formControlName="name" id="inputEducationName1"
                                       placeholder="Name">
                              </mat-form-field>
                            </td>
                            <td>
                              <mat-icon class="done m-2" (click)="doneRow(group,'EDUCATION')"><span
                                class="badge bg-success text-white">Save</span>
                              </mat-icon>
                            </td>
                          </tr>
                          <tr *ngIf="!checkIfEducationSelected(group.get('id')?.value)">
                            <td>
                              <a>{{group.get('name')?.value}}</a>
                            </td>
                            <td *ngIf="role!=='MANAGER'">
                              <a type="button" (click)="editEducationRow(group)">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V12H19V10H5V20H12V22H5ZM22.125 17L20 14.875L20.725 14.15C20.9083 13.9667 21.1417 13.875 21.425 13.875C21.7083 13.875 21.9417 13.9667 22.125 14.15L22.85 14.875C23.0333 15.0583 23.125 15.2917 23.125 15.575C23.125 15.8583 23.0333 16.0917 22.85 16.275L22.125 17ZM14 23V20.875L19.3 15.575L21.425 17.7L16.125 23H14Z"
                                    fill="#545454"/>
                                </svg>
                              </a>
                              <mat-slide-toggle
                                class="example-margin mx-3"
                                color="primary"
                                [disabled]=false
                                [checked]="group.get('active')?.value"
                                (change)="toggleEducationStatus($event,group)">
                                <div class="ActiveButton">{{group.get('active')?.value ? 'Enabled' : 'Disabled'}}</div>
                              </mat-slide-toggle>
                            </td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <mat-paginator [length]="totalEducation"
                         [pageSizeOptions]="[12,25,50,100]"
                         (page)="handlePageEducation($event)"
                         [hidden]="totalEducation==0"
                         aria-label="Select page">
          </mat-paginator>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
