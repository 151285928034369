<div class="vendor_wrapper" *ngIf=" role=='TA' || openDialogType!='READONLY'">
  <h2 class="mb-4 font-weight-bold">Add Notes</h2>
  <form [formGroup]="notesForm" ngNativeValidate (submit)="addNotes()">
    <mat-form-field appearance="outline" class="w-100 border-red">
      <mat-label>Add Notes</mat-label>
      <textarea matInput class="form-control" formControlName="description" placeholder="Notes"
                style="height: 120px;" required></textarea>
    </mat-form-field>
    <div class="form-group btns d-flex justify-content-end save-form mt-5">
      <button *ngIf="!showloader" class="btn btn-save theme-btn-color" type="submit" (click)="addNotes()">Save
        Details
      </button>
      <div *ngIf="showloader" disabled>
        <button class="btn btn-save theme-btn-color" disabled>
          <div class="spinner-border spinner-border-sm me-2" role="status">
            <span class="sr-only"></span>
          </div>
          Save Details
        </button>
      </div>
    </div>
  </form>
</div>

<div *ngIf="notes.length>0" class="document-wrapper mb-5 ">
  <div class="col-md-12 justify-content-center mx-height-scroll mt-2">
    <form [formGroup]="editNotesForm">
      <table class="table table-striped">
        <thead class="tableview theme-bg-color">
        <tr>
          <th class="w-auto">Notes</th>
          <th>Activity</th>
          <th >Action</th>
        </tr>
        </thead>
        <tbody>
        <ng-container formArrayName="noteRows" *ngFor="let note of getNotesFormControls.controls, let i=index">
          <tr *ngIf="note.get('isEditable')?.value" [formGroupName]="i">
            <td>
              <mat-form-field class="w-100">
                        <textarea matInput formControlName="description" class="form-control" placeholder="Notes"
                                  style="height: 120px;"
                                  id="textareaNotes"></textarea>
              </mat-form-field>
            </td>
            <td><a
              style="text-transform:capitalize;font-size: 12px">{{note.get('createdAt')?.value | date:
              ' hh:mm a, dd MMM yyyy '}}</a><br>
              <a style="text-transform:capitalize;font-size: 12px">uploaded
                by {{note.get('createdBy')?.value}}</a>
            </td>
            <td>
              <a (click)="updateNotes(note)"
                 style="margin-left: 8px;margin-right: 45px;text-transform:capitalize;font-size: 18px; cursor: pointer"><span
                class="badge bg-success text-white">Done</span></a>
            </td>
          </tr>
          <tr *ngIf="!note.get('isEditable')?.value">
            <td style="position: relative; padding: 20px">
              <div [attr.id]="'divOfContent'+i" class="lessContent">
                        <span [innerHTML]="note.get('description')?.value" class=""
                              style="white-space: pre-line;"></span>
                <a *ngIf="note.get('description')?.value.split('\n').length>3"
                   style="font-size: 12px;color: #1c4e80;position: absolute;bottom: 0;margin-bottom: 4px;font-weight: bold;right: 0;cursor:pointer;"
                   (click)="toggleShow($event)">Show more</a>
              </div>
            </td>
            <td><a
              style="text-transform:capitalize;font-size: 12px">{{note.get('createdAt')?.value | date:'h:mm a, d MMM y'}}</a><br>
              <a style="text-transform:capitalize;font-size: 12px">uploaded
                by {{note.get('createdBy')?.value}}</a>
            </td>
            <td class="">
              <div  class="d-flex">
                <a *ngIf="hideWidget(note.get('createdAt')?.value)" type="button"
                   (click)="editNotes(note)" class="me-3 action_icon">

                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V12H19V10H5V20H12V22H5ZM22.125 17L20 14.875L20.725 14.15C20.9083 13.9667 21.1417 13.875 21.425 13.875C21.7083 13.875 21.9417 13.9667 22.125 14.15L22.85 14.875C23.0333 15.0583 23.125 15.2917 23.125 15.575C23.125 15.8583 23.0333 16.0917 22.85 16.275L22.125 17ZM14 23V20.875L19.3 15.575L21.425 17.7L16.125 23H14Z"
                              fill="#545454"/>
                            </svg>
                       </a>
                <a  *ngIf="hideWidget(note.get('createdAt')?.value) && (role!='TA' && openDialogType!='READONLY')" type="button"
                    (click)="deleteNotes(note.get('id')?.value )"
                    class="me-3 action_icon">

                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20.37 8.91003L19.37 10.64L7.24 3.64003L8.24 1.91003L11.28 3.66003L12.64 3.29003L16.97 5.79003L17.34 7.16003L20.37 8.91003ZM6 19V7.00003H11.07L18 11V19C18 19.5305 17.7893 20.0392 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H8C7.46957 21 6.96086 20.7893 6.58579 20.4142C6.21071 20.0392 6 19.5305 6 19Z"
                                fill="#ED0F0F"/>
                            </svg>

                </a>
              </div>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </form>
  </div>
</div>
<mat-paginator [length]="totalNotes"
               [pageSizeOptions]="[5,12,25,50,100]"
               (page)="handlePageNotes($event)"
               [hidden]="totalNotes==0"
               aria-label="Select page">
</mat-paginator>
<div *ngIf="notes.length == 0" class=" mt-5 empty_table_wrapper theme-border-color">
  <span>Currently no active notes available</span>
</div>
