<div (click)="showTextarea($event)">
  <mat-form-field *ngIf="!isTextareaVisible" appearance="outline" class="w-100">
    <mat-label>{{ label }}</mat-label>
    <input matInput [formControl]="formControl" [placeholder]="placeholder" hidden="true">
    <span style="padding: 6px">{{ stripHtmlTags(formControl?.value) }}</span>
  </mat-form-field>
</div>

<div class="pb-3" *ngIf="isTextareaVisible">
  <quill-editor class="custom-quill-editor"
    [formControl]="formControl"
    (blur)="hideTextarea()" [placeholder]="placeholder"
    [modules]="quillConfig" [disabled]="false"
    (onEditorCreated)="onEditorCreated($event)"
  >
  </quill-editor>
</div>
