<td *ngFor="let data of searchFields" style="font-size: 12px">
  <input
    *ngIf="data!== 'skills' && data!== 'dateSearchCriteria' && data !=='emailSentStatus' && data !== ''
     && data !== 'status' && data !=='assignee' && data !=='spocIds' && data !== 'postingDate'
      &&  data !== 'ClientStatus' &&  data !== 'ExperienceYear' &&  data !== 'techStack' && data !== 'SolutionType'
       && data !== 'industryVertical' && data !== 'InformationTag' && data !== 'techStacks' && data !== 'solutionTypes'
       && data !== 'demoReady'
"
    class="w-100 customInput"
    type="search"
    placeholder="Search"
    [(ngModel)]="filters[data]"
    (ngModelChange)="onChangeSearch($event, data)"
  >

  <!--  for skills-->
  <div *ngIf="data === 'skills' || data === 'techStacks'" class="dropdowns" id="dropdownSkills" #dropdownSkills>
    <button class="experienceButton dropdown-toggle" type="button"
            style="font-size: 12px; color: rgba(0,0,0,0.5)"
            (click)="toggleDropdown('dropdownSkills')">
      Skills({{ selectedSkill.length }})
    </button>

    <div class="dropdowns-menu">
      <mat-form-field appearance="outline" class="customMultiSelect">
        <mat-chip-list #chipList aria-label="skill selection">
          <mat-chip
            class="matChip"
            *ngFor="let skill of selectedSkill"
            (removed)="removeSkill(skill)">
            {{ skill.title }}
            <button class="removeButton" matChipRemove><span>&#x2716;</span></button>
          </mat-chip>
          <input #skillsAutoCompleter
                 placeholder="skills..."
                 #chipGrid
                 [formControl]="skillControl"
                 [matAutocomplete]="auto"
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 #autoTrigger='matAutocompleteTrigger'
                 (click)="openAutocomplete(autoTrigger)"
          >
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectSkills($event)">
          <mat-option *ngFor="let skill of filteredSkills | async" [value]="skill"
                      [disabled]="disableSkill(skill)">
            {{ skill.title }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>


  <!--  for tech stack-->
  <div *ngIf="data === 'techStack'" class="dropdowns" id="dropdownTechStack" #dropdownTechStack>
    <button class="experienceButton dropdown-toggle" type="button"
            style="font-size: 12px; color: rgba(0,0,0,0.5)"
            (click)="toggleDropdown('dropdownTechStack')">

      Tech({{ selectedSkill.length }})
    </button>

    <div class="dropdowns-menu">
      <mat-form-field appearance="outline" class="customMultiSelect">
        <mat-chip-list #chipList aria-label="skill selection">
          <mat-chip
            class="matChip"
            *ngFor="let skill of selectedSkill"
            (removed)="removeSkill(skill)">
            {{ skill.title }}
            <button class="removeButton" matChipRemove><span>&#x2716;</span></button>
          </mat-chip>
          <input #skillsAutoCompleter
                 placeholder="Tech..."
                 #chipGrid
                 [formControl]="skillControl"
                 [matAutocomplete]="auto"
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 #autoTrigger='matAutocompleteTrigger'
                 (click)="openAutocomplete(autoTrigger)"
          >
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectSkills($event)">
          <mat-option *ngFor="let skill of filteredSkills | async" [value]="skill"
                      [disabled]="disableSkill(skill)">
            {{ skill.title }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>

  <!--  for date from and to -->
  <div *ngIf="data === 'dateSearchCriteria'" class="dropdowns" id="dropdownDate" #dropdownDate>
    <button class="experienceButton dropdown-toggle" type="button"
            style="font-size: 12px; color: rgba(0,0,0,0.5);padding:0 20px;"
            (click)="toggleDropdown('dropdownDate')">
      <svg class="me-1" fill="#000000" width="15px" height="15px" viewBox="0 0 256 256" id="Flat"
           xmlns="http://www.w3.org/2000/svg"
           stroke="#000000" stroke-width="0.00256">
        <g id="SVGRepo_bgCarrier" stroke-width="0">
        </g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path
            d="M112.001,229.05469A12.00583,12.00583,0,0,1,100,217.05176v-77.959a3.98926,3.98926,0,0,0-1.03955-2.68945l-65.75537-72.331A12,12,0,0,1,42.08447,44H213.91553a12,12,0,0,1,8.87939,20.07227l-65.75439,72.33007A3.98975,3.98975,0,0,0,156,139.09277v56.626a11.97406,11.97406,0,0,1-5.34424,9.98437l-31.99951,21.333A11.98627,11.98627,0,0,1,112.001,229.05469ZM42.08447,52A4.00015,4.00015,0,0,0,39.125,58.69141l65.75439,72.33007A11.971,11.971,0,0,1,108,139.09277v77.959a3.99924,3.99924,0,0,0,6.21826,3.32812l32.00049-21.333A3.99,3.99,0,0,0,148,195.71875v-56.626a11.97153,11.97153,0,0,1,3.12158-8.07226L216.875,58.69141A4.00015,4.00015,0,0,0,213.91553,52Z">
          </path>
        </g>
      </svg>
      Duration
    </button>
    <div class="dropdowns-menu">
      <div class="form-check form-switch" style="left:27px">
        <input class="form-check-input" type="checkbox" role="switch"
               id="flexSwitchCheckDefault" (change)="handleDateToggleChange($event)">
        <label class="form-check-label" for="flexSwitchCheckDefault">
          <div
            style="font-size: 13px">{{ filters?.dateSearchCriteria?.filter || filters?.dateSearchCriteria?.filterType }}
          </div>
        </label>
      </div>
      <div class="input-group m-2" style="width: 85%">
        <input
          class="w-100 customInput"
          [matDatepicker]="picker"
          style="position: relative; height: 40px"
          placeholder="From"
          [(ngModel)]="selectedFromDate"
          (dateChange)="onFromDateChange($event)"
          (ngModelChange)="updateEndDateMinDate()"
        >
        <mat-datepicker #picker></mat-datepicker>
        <div style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); background-color: white">
          <span #picker (click)="picker.open()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="20px" height="20px"
                 viewBox="-2.4 -2.4 28.80 28.80" stroke="#000000" stroke-width="0.00024000000000000003"
                 transform="rotate(0)"><g id="SVGRepo_bgCarrier" stroke-width="0">
            </g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC"
                 stroke-width="0.096"></g><g id="SVGRepo_iconCarrier">
                <path
                  d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z">
                </path>
              </g>
            </svg>
          </span>
        </div>
      </div>

      <div class="input-group m-2" style="width: 85%">
        <input
          class="w-100 customInput"
          [matDatepicker]="picker1"
          style="position: relative; height: 40px"
          placeholder="To"
          [(ngModel)]="selectedToDate"
          (dateChange)="onToDateChange($event)"
          [disabled]="!selectedFromDate"
          [min]="minEndDate"
        >
        <mat-datepicker #picker1></mat-datepicker>
        <div style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%);  background-color: white">
          <span (click)="picker1.open()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="20px" height="20px"
                 viewBox="-2.4 -2.4 28.80 28.80" stroke="#000000" stroke-width="0.00024000000000000003"
                 transform="rotate(0)"><g id="SVGRepo_bgCarrier" stroke-width="0">
            </g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC"
                 stroke-width="0.096"></g><g id="SVGRepo_iconCarrier">
                <path
                  d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z">
                </path>
              </g>
            </svg>
          </span>
        </div>
      </div>
    </div>
  </div>

  <!--  for location preference-->
  <div *ngIf="data === 'locationsPref'" class="dropdowns" id="dropdownLocationPreference" #dropdownLocationPreference>
    <button class="experienceButton dropdown-toggle" type="button"
            (click)="toggleDropdown('dropdownLocationPreference')"
            style="font-size: 12px; color: rgba(0,0,0,0.5)">
      Location({{ selectedItemsLocationPreference.length }})
    </button>
    <div class="dropdowns-menu">
      <div
        *ngFor="let option of locationPreferenceOptions"
        class="dropdowns-item"
        [class.selected]="isSelected('locationsPref', option, selectedItemsLocationPreference)"
        (click)="handleSelection('locationsPref', option, selectedItemsLocationPreference, 'locationsPref', false)">
        {{ option }}
      </div>
    </div>
  </div>

  <!--for email Sent Status-->
  <div *ngIf="data === 'emailSentStatus'" class="dropdowns" id="dropdownEmailSent" #dropdownEmailSent>
    <button class="experienceButton dropdown-toggle" type="button"
            style="font-size: 12px; color: rgba(0,0,0,0.5)"
            (click)="toggleDropdown('dropdownEmailSent')">
      EmailSent({{ selectedEmailOption.length }})
    </button>
    <div class="dropdowns-menu" style="left:-20px">
      <div
        *ngFor="let option of emailSentOptions"
        class="dropdowns-item"
        [class.selected]="isSelected('emailSentStatus', option, selectedEmailOption)"
        (click)="handleSelection('emailSentStatus', option, selectedEmailOption, 'emailSentStatus', true)">
        {{ option }}
      </div>
    </div>
  </div>

  <!--  for myTask status-->
  <div *ngIf="data === 'status'">
    <div class="dropdowns" id="dropdownConsultantStatus" #dropdownConsultantStatus>
      <button class="experienceButton dropdown-toggle" type="button"
              (click)="toggleDropdown('dropdownConsultantStatus')"
              style="font-size: 12px; color: rgba(0,0,0,0.5)">
        Status({{ selectedItemsConsultantStatus.length }})
      </button>
      <div class="dropdowns-menu" style="left: -30px">
        <div
          *ngFor="let option of consultantStatusOptions"
          class="dropdowns-item"
          [class.selected]="isSelected('status',option, selectedItemsConsultantStatus)"
          (click)="handleSelection('status', option, selectedItemsConsultantStatus, 'status', false)">
          {{ option }}
        </div>
      </div>
    </div>
  </div>

  <!--    for Assigned spoc-->
  <div *ngIf="data === 'assignee' || data ==='spocIds'">
    <div class="dropdowns" id="dropdownAssignedSPOC" #dropdownAssignedSPOC>
      <button class="experienceButton dropdown-toggle" type="button"
              style="font-size: 12px; color: rgba(0,0,0,0.5)"
              (click)="toggleDropdown('dropdownAssignedSPOC')">
        Assigned SPOC({{ selectedAssignedSpocIds.length }})
      </button>
      <div class="dropdowns-menu">
        <mat-form-field appearance="outline" class="customMultiSelect">
          <mat-select class="customInputSelect" [formControl]="assignedSpocControl" placeholder="Search"
                      [multiple]="true" #multiSelect
                      (selectionChange)="onSelectionChange($event)">
            <mat-option class="customInputSelect">
              <ngx-mat-select-search class="customInputSelect" [placeholderLabel]="'search'"
                                     [formControl]="assignedSpocControlMultiSelect"
                                     [searching]="searchState" [noEntriesFoundLabel]="'No Result Found'"
                                     (ngModelChange)="onSearchInputChange($event)">
                <mat-icon ngxMatSelectSearchClear>
                  <div class="d-flex align-items-center justify-content-center mt-sm-1">
                    <a>
                      <svg width="14" height="14" viewBox="0 0 19 19" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2"
                              stroke-linecap="round"/>
                        <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2"
                              stroke-linecap="round"/>
                      </svg>
                    </a>
                  </div>
                </mat-icon>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let spoc of filteredAssignSpocMulti | async" [value]="spoc?.userId">
              {{ spoc?.firstName ?? '' }}  {{ spoc?.lastName ?? '' }} | {{ spoc?.role ?? '' }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <!--  for single date-->
  <div *ngIf="data === 'postingDate'" class="dropdowns" id="DropdownPostingDate" #DropdownPostingDate>
    <button class="experienceButton dropdown-toggle" type="button"
            style="font-size: 12px; color: rgba(0,0,0,0.5);padding:0 20px;"
            (click)="toggleDropdown('DropdownPostingDate')">
      <svg class="me-1" fill="#000000" width="15px" height="15px" viewBox="0 0 256 256" id="Flat"
           xmlns="http://www.w3.org/2000/svg"
           stroke="#000000" stroke-width="0.00256">
        <g id="SVGRepo_bgCarrier" stroke-width="0">
        </g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path
            d="M112.001,229.05469A12.00583,12.00583,0,0,1,100,217.05176v-77.959a3.98926,3.98926,0,0,0-1.03955-2.68945l-65.75537-72.331A12,12,0,0,1,42.08447,44H213.91553a12,12,0,0,1,8.87939,20.07227l-65.75439,72.33007A3.98975,3.98975,0,0,0,156,139.09277v56.626a11.97406,11.97406,0,0,1-5.34424,9.98437l-31.99951,21.333A11.98627,11.98627,0,0,1,112.001,229.05469ZM42.08447,52A4.00015,4.00015,0,0,0,39.125,58.69141l65.75439,72.33007A11.971,11.971,0,0,1,108,139.09277v77.959a3.99924,3.99924,0,0,0,6.21826,3.32812l32.00049-21.333A3.99,3.99,0,0,0,148,195.71875v-56.626a11.97153,11.97153,0,0,1,3.12158-8.07226L216.875,58.69141A4.00015,4.00015,0,0,0,213.91553,52Z">
          </path>
        </g>
      </svg>
      Duration
    </button>
    <div class="dropdowns-menu">
      <div class="input-group m-2" style="width: 85%">
        <input
          class="w-100 customInput"
          [matDatepicker]="picker"
          style="position: relative; height: 40px"
          placeholder="From"
          [(ngModel)]="selectedFromDate"
          (dateChange)="onFromDateChangeSingle($event)"
          (ngModelChange)="updateEndDateMinDate()"
        >
        <mat-datepicker #picker></mat-datepicker>
        <div style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); background-color: white">
          <span #picker (click)="picker.open()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="20px" height="20px"
                 viewBox="-2.4 -2.4 28.80 28.80" stroke="#000000" stroke-width="0.00024000000000000003"
                 transform="rotate(0)"><g id="SVGRepo_bgCarrier" stroke-width="0">
            </g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC"
                 stroke-width="0.096"></g><g id="SVGRepo_iconCarrier">
                <path
                  d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z">
                </path>
              </g>
            </svg>
          </span>
        </div>
      </div>

      <div class="input-group m-2" style="width: 85%">
        <input
          class="w-100 customInput"
          [matDatepicker]="picker1"
          style="position: relative; height: 40px"
          placeholder="To"
          [(ngModel)]="selectedToDate"
          (dateChange)="onToDateChangeSingle($event)"
          [disabled]="!selectedFromDate"
          [min]="minEndDate"
        >
        <mat-datepicker #picker1></mat-datepicker>
        <div style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%);  background-color: white">
          <span (click)="picker1.open()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="20px" height="20px"
                 viewBox="-2.4 -2.4 28.80 28.80" stroke="#000000" stroke-width="0.00024000000000000003"
                 transform="rotate(0)"><g id="SVGRepo_bgCarrier" stroke-width="0">
            </g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC"
                 stroke-width="0.096"></g><g id="SVGRepo_iconCarrier">
                <path
                  d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z">
                </path>
              </g>
            </svg>
          </span>
        </div>
      </div>
    </div>
  </div>

  <!--  for client job status-->
  <div *ngIf="data === 'ClientStatus'">
    <div class="dropdowns" id="dropdownClientJobStatus" #dropdownClientJobStatus>
      <button class="experienceButton dropdown-toggle" type="button"
              style="font-size: 12px; color: rgba(0,0,0,0.5)"
              (click)="toggleDropdown('dropdownClientJobStatus')">
        Status ({{ selectedItemsClientJobStatus.length }})
      </button>
      <div class="dropdowns-menu" style="left: -30px">
        <div
          *ngFor="let option of clientJobStatusOptions"
          class="dropdowns-item"
          [class.selected]="isSelected('status',option, selectedItemsClientJobStatus)"
          (click)="handleSelection('status', option, selectedItemsClientJobStatus, 'status', false)">
          {{ option }}
        </div>
      </div>
    </div>

  </div>
  <!--    for experience range-->
  <div *ngIf="data === 'ExperienceYear'">
    <div class="dropdowns" id="dropdownExperienceYear" #dropdownExperienceYear>
      <button class="experienceButton dropdown-toggle" type="button"
              (click)="toggleDropdown('dropdownExperienceYear')"
              style="font-size: 12px; color: rgba(0,0,0,0.5)">
        Experience({{ selectedItemsExperience.length }})
      </button>
      <div class="dropdowns-menu">
        <div
          *ngFor="let option of experienceOptions"
          class="dropdowns-item"
          [class.selected]="isSelectedExperience(option, selectedItemsExperience)"
          (click)="toggleSelectionExperience(option, selectedItemsExperience)">
          {{ option.label }}
        </div>
      </div>
    </div>
  </div>

  <!--    for solution type-->
  <div *ngIf="data === 'SolutionType'">
    <div class="dropdowns" id="dropdownSolutionType" #dropdownSolutionType>
      <button class="experienceButton dropdown-toggle" type="button"
              (click)="toggleDropdown('dropdownSolutionType')"
              style="font-size: 12px; color: rgba(0,0,0,0.5)">
        Type({{ selectedSolutionType.length }})
      </button>
      <div class="dropdowns-menu">
        <div
          *ngFor="let option of solutionTypeOptions"
          class="dropdowns-item"
          [class.selected]="isSelected('dropdownSolutionType', option.label, selectedSolutionType)"
          (click)="handleSelection('dropdownSolutionType', option.label, selectedItemsExperience, 'experienceYears', false)">
          {{ option.label }}
        </div>
      </div>
    </div>
  </div>

  <!--    for solution type-->
  <div *ngIf="data === 'industryVertical'" class="dropdowns" id="dropdownIndustryVertical" #dropdownIndustryVertical>
    <button class="experienceButton dropdown-toggle" type="button"
            style="font-size: 12px; color: rgba(0,0,0,0.5)"
            (click)="toggleDropdown('dropdownIndustryVertical')"
            aria-expanded="false"
            aria-controls="dropdownIndustryVerticalMenu">
      Vertical ({{ selectedIndustryVertical.length }})
    </button>

    <div class="dropdowns-menu" id="dropdownIndustryVerticalMenu">
      <mat-form-field appearance="outline" class="customMultiSelect">
        <mat-chip-list #chipList aria-label="Vertical selection">
          <mat-chip
            class="matChip"
            *ngFor="let vertical of selectedIndustryVertical"
            (removed)="removeVertical(vertical)">
            {{ vertical.name }}
            <button class="removeButton" matChipRemove><span>&#x2716;</span></button>
          </mat-chip>
          <input #skillsAutoCompleter
                 placeholder="Vertical..."
                 #chipGrid
                 [formControl]="verticalControl"
                 [matAutocomplete]="auto"
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 #autoTrigger='matAutocompleteTrigger'
                 (click)="openAutocomplete(autoTrigger)"
          >
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectVertical($event)">
          <mat-option *ngFor="let vertical of filteredVerticals | async" [value]="vertical"
                      [disabled]="disableVertical(vertical)">
            {{ vertical.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="data === 'demoReady'">
    <div class="dropdowns" id="demoReady" #demoReady>
      <button class="experienceButton dropdown-toggle" type="button"
              (click)="toggleDropdown('demoReady')"
              style="font-size: 12px; color: rgba(0,0,0,0.5)">
        Types({{ selecteddemoReady.length }})
      </button>
      <div class="dropdowns-menu">
        <div
          *ngFor="let option of demoReadyOptions"
          class="dropdowns-item"
          [class.selected]="isSelected('demoReady', option, selecteddemoReady)"
          (click)="handleSelection('demoReady', option, selecteddemoReady, 'demoReady', true)">
          {{ option }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="data === 'solutionTypes'">
    <div class="dropdowns" id="solutionTypes" #solutionTypes>
      <button class="experienceButton dropdown-toggle" type="button"
              (click)="toggleDropdown('solutionTypes')"
              style="font-size: 12px; color: rgba(0,0,0,0.5)">
        Types({{ selectedSolutionTypes.length }})
      </button>
      <div class="dropdowns-menu">
        <div
          *ngFor="let option of selectedSolutionOptions"
          class="dropdowns-item"
          [class.selected]="isSelected('solutionTypes', option.value, selectedSolutionTypes)"
          (click)="handleSelection('solutionTypes', option.value, selectedSolutionTypes, 'solutionTypes',false)">
          {{ option.label }}
        </div>
      </div>
    </div>
  </div>

</td>


