<div class="px-3 py-2">
  <div class="row">
    <div class="col">
      <h1 mat-dialog-title>Change Assignee</h1>
    </div>
      <div class="col-md-8 d-flex justify-content-end">
        <a style="cursor: pointer" class="cursor-pointer" (click)="onNoClick()">
          <svg width="22" height="22" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
            <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </a>
      </div>
  </div>
<div>
  <div mat-dialog-content>
    <p>Select the Assignee from below</p>
    <div class="col-md-8">
      <mat-form-field appearance="outline" class="w-100 border-red">
        <mat-label>Select Assignee</mat-label>
        <mat-select (selectionChange)="onChangeAssignee($event)">
          <mat-option *ngFor="let assignee of assigneeList" [value]="assignee">
            {{assignee.firstName +' '+ assignee.lastName +' | '+ assignee.role}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions class="pt-5 mt-2 justify-content-end d-flex">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button class="confirm-btn-style"  (click)="submitAssignee()">Change Confirm</button>
  </div>
</div>

</div>

