import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {myTaskDTO} from "../../../../shared/interfaces";
import {NgDialogAnimationService} from "ng-dialog-animation";

@Component({
  selector: 'app-view-my-task',
  templateUrl: './view-my-task.component.html',
  styleUrls: ['./view-my-task.component.css']
})

export class ViewMyTaskComponent implements OnInit {
  myTaskData: myTaskDTO;
  role: string
  hasChanged:boolean=false
  constructor(private dialogRef: MatDialogRef<ViewMyTaskComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              @Inject(MAT_DIALOG_DATA) public selectedMyTask: any,
              public dialog: NgDialogAnimationService,
  ) {
  }

  ngOnInit(): void {
    if (this.selectedMyTask.id != null) {
      this.myTaskData = this.selectedMyTask.myTask;
    }
  }

  handleHasChangedEvent(hasChanged: boolean) {
    this.hasChanged = hasChanged
  }

  close() {
    this.dialogRef.close(this.hasChanged);
  }
}

