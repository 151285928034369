import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ApiService } from "../../../services/api.service";
import {
  ConsultantsDeployModalComponent
} from "../manage-consultants/consultants-deploy-modal/consultants-deploy-modal.component";

@Component({
  selector: 'app-customer-activity-modal',
  templateUrl: './customer-activity-modal.component.html',
  styleUrls: ['./customer-activity-modal.component.css']
})
export class CustomerActivityModalComponent implements OnInit {
  searchStringsUsed: string[] = [];
  consultantIdViewed: any[] = [];
  solutionsIdAccessed: any[] = [];
  customerContactId: number;
  sessionId: string;
  customerContact: string;
  customerContactEmail: string;
  customerContactPhone: number;

  constructor(
    public dialogRef: MatDialogRef<ConsultantsDeployModalComponent>,
    public service: ApiService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.customerContactId = data.customerContactId;
    this.sessionId = data.sessionId;
    this.customerContact = data.customerContact;
    this.customerContactEmail = data.customerContactEmail;
    this.customerContactPhone = data.customerContactPhone;
  }

  ngOnInit(): void {
    if (this.customerContactId && this.sessionId) {
      this.getCustomerActivity();
    }
  }

  close() {
    this.dialogRef.close();
  }

  getCustomerActivity() {
    this.service.getAllReportsOfCustomerAnalytics(this.customerContactId, this.sessionId).subscribe((res) => {
      this.searchStringsUsed = res.filter((storageKey: any) => storageKey.storageKey === "SearchString")
        .map((s: any) => s.value)
        .filter((value: string) => value && value.trim() !== '');
      this.consultantIdViewed = res.filter((storageKey: any) => storageKey.storageKey === "ConsultantId")
        .map((s: any) => s.value)
        .filter((value: string) => value && value.trim() !== '');
    }, error => {
      console.log('error', error);
    });
  }
}
