import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DefaultFilters, Skill, PlaceDto } from 'src/app/shared/interfaces';
import {debounce} from 'lodash';
import {MatSelect} from "@angular/material/select";
import {Router} from "@angular/router";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {DataService} from "../../../services/data.service";
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {
  @ViewChild('select') select: MatSelect;
  locationQuery: any = '';
  skillQuery: any = '';
  skill:any[] = [];
  vendors: any[] = [];
  message: string | null;
  addButtomEnable: boolean = true;
  defaultFilters: DefaultFilters;
  skillsResForSearch: Skill[] = [];
  locationsResForSearch: PlaceDto[] = [];
  addNewLocations: boolean = false;
  countOfSkills = 0;
  countOfLocations = 0;
  currentPage = 0;
  totalPages = 0;
  length: any;
  search = '';
  sort = '';
  showSkill=false;
  showLocation=false;
  showFilter=false;
  selectedFilterSkills: any[] = [];
  @Output() selectedFilters = new EventEmitter<DefaultFilters>();
  private payload: DefaultFilters;
  selectedFilterLocation: any[] = [];

  constructor(
    protected service: ApiService,
    protected router: Router,
    protected dataService: DataService,

    public dialog: NgDialogAnimationService,) { }

  ngOnInit(): void {
    this.searchSkills = debounce<any>(this.searchSkills, 600);
    this.searchStates = debounce<any>(this.searchStates, 600);
    this.service.defaultFilters().then(res => {
      this.defaultFilters = res;
    });
  }

  removeFilter(index: number, filterchip: any,type?: string) {
    if (type == 'skills') {
    this.defaultFilters.skills.forEach((e:any)=> {
     if(e['id']==filterchip.id) {
       e.isSelected=false;
       this.selectedFilterSkills.splice(filterchip.id, 1);
     }
    })
      this.selectedFilterSkills.splice(index, 1);
    } else if (type == 'locations') {
      this.defaultFilters.locations.forEach((e:any)=> {
        if(e['id']==filterchip.id) {
          e.isSelected=false;
          this.selectedFilterLocation.splice(filterchip.id, 1);
        }
      })
      this.selectedFilterLocation.splice(index, 1);
    }
  }

  skillsMethod(skill: any): void {
    if (skill) {
      this.addButtomEnable=true;
      console.log('data==========',skill)
      this.updateFilters(skill,'skills')
      // this.addToFilters(skill, 'skills');
    }
  }

  selectedLocation(location: PlaceDto): void {
    this.addToFilters(location, 'locations');
  }
  updateFilters( e: any, type: string): void {
      console.log(e+"=====event")
      switch (type) {
        case 'skills': {
          console.log(this.selectedFilterSkills,'====34')
          if(this.selectedFilterSkills.length===0){
            this.selectedFilterSkills.push(e);
            this.showFilter=true;
            console.log(this.selectedFilterSkills,'====14')
          } else{
            const listofSkills: any[]=[];
            this.selectedFilterSkills.forEach(skill => {
              listofSkills.push(skill.id);
            });
            if(listofSkills.includes(e.id)){
              console.log('skill is already added')
            } else {
              this.selectedFilterSkills.push(e);
            }
            }
          break;
        }
      }
      this.countOfSkills = this.defaultFilters.skills.filter(x => x.isSelected).length;
      this.countOfLocations = this.defaultFilters.locations.filter(x => x.isSelected).length;

  }
  filterVendorsBySkill(page: number): void {
    this.showSkill=false
    this.showLocation=false
    console.log(this.skill+"line 139",this.selectedFilterSkills,this.selectedFilterLocation);
    const _locations: any = [];
    this.selectedFilterLocation.map(res => {
      _locations.push(res.id);
    })
    this.payload={skills:  this.selectedFilterSkills,locations:_locations};
    console.log("#156", this.payload.skills);
    this.selectedFilters.emit(this.payload);
    this.skill=[];
    this.selectedFilterSkills?.map((skill: any)=>{
      this.skill.push(skill['id'])
    })
    console.log(this.skill+"line 139")

  }

  reset(): void {
    this.showSkill=false;
    this.showLocation=false
    this.countOfSkills = 0;
    this.countOfLocations = 0;

    this.defaultFilters.locations.forEach(x => x.isSelected = false);
    this.defaultFilters.skills.forEach(x => x.isSelected = false);
    this.selectedFilterLocation = [];
    this.selectedFilterSkills = [];

    this.payload={skills:this.selectedFilterSkills,locations:this.selectedFilterLocation};
    console.log(this.defaultFilters.skills+'line 134')
    this.selectedFilters.emit(this.payload);
  }

  searchSkills() {
    if (!this.skillQuery) {
      this.skillsResForSearch = [];
    }
    this.service.searchSkills(this.skillQuery).then(res => {
      this.skillsResForSearch = res;
      console.log(this.skillsResForSearch+"skills search")
      this.skillsResForSearch = this.skillsResForSearch.slice(0, 5);
    });
  }
  // searchCities() {
  //   if (!this.locationQuery) {
  //     this.locationsResForSearch = [];
  //   }
  //   this.service.citiesSearch(this.locationQuery).then(res => {
  //     this.locationsResForSearch = res;
  //     // console.log(this.filteredSkillData+"skills search")
  //     this.locationsResForSearch = this.locationsResForSearch.slice(0, 3);
  //   });
  // }

  searchStates() {
    if (!this.locationQuery) {
      this.locationsResForSearch = [];
    }
    this.service.stateSearch(this.locationQuery).then(res => {
      this.locationsResForSearch = res;
    });
  }

  addToFilters(item: any, type: string): void {
    let isContaining: boolean = false;
    if (item) {
      switch (type) {
        case 'locations': {
          this.defaultFilters.locations.forEach(z => {
            console.log('Item', z.id,item.id);
            if (z.id === item.id) {
              isContaining = true;
              this.selectedFilterLocation.push(z);
            }
            console.log('selected',this.selectedFilterLocation,z.id,item.id);
          })
          if (!isContaining) {
            item.isSelected = true;
            // this.defaultFilters.locations.splice(0, 0, item);
            this.selectedFilterLocation.push(item);
          }
          this.addNewLocations = false;
          break;
        }
      }
      this.countOfSkills = this.defaultFilters.skills.filter(x => x.isSelected).length;
      this.countOfLocations = this.defaultFilters.locations.filter(x => x.isSelected).length;
    }
    this.locationQuery = '';
    this.skillQuery = '';
  }
  showSkillButton(){
    this.showSkill=!this.showSkill;
  }
  showLocationButton(){
    this.showLocation=!this.showLocation;
  }
}
