<div class="content-wrapper">
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h5 class="page-title">Customer Contacts</h5>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="content-wrapper-main-inner-content">
      <div class="row mb-2">
        <div class="col-md-4">
          <div id="data-table_filter" class="d-flex justify-content-end dataTables_filter">
            <label class="w-100 search-input">
              <input class="form-control" placeholder="Search for job" type="search"
                     [(ngModel)]="search" (ngModelChange)="updateSearch()" aria-controls="data-table">
              <button class="search-button" type='button'>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.7479 18.5593L14.0096 12.8169C15.1409 11.4602 15.8213 9.72641 15.8213 7.83277C15.8213 3.51266 12.2758 0 7.91475 0C3.55364 0 0 3.51675 0 7.83687C0 12.157 3.54544 15.6696 7.90655 15.6696C9.7592 15.6696 11.4643 15.0343 12.8169 13.9727L18.5757 19.7315C18.9118 20.0676 19.4118 20.0676 19.7479 19.7315C20.084 19.3954 20.084 18.8954 19.7479 18.5593ZM1.6805 7.83687C1.6805 4.44308 4.47587 1.6846 7.90655 1.6846C11.3372 1.6846 14.1326 4.44308 14.1326 7.83687C14.1326 11.2307 11.3372 13.9891 7.90655 13.9891C4.47587 13.9891 1.6805 11.2266 1.6805 7.83687Z"
                    fill="#808080"/>
                </svg>
              </button>
            </label>
          </div>
        </div>
        <div class="col-md-8 d-flex justify-content-end">
          <mat-slide-toggle
            class="example-margin m-1 p-1"
            color="primary"
            [disabled]=false
            [checked]="showArchived"
            (change)="toggleActiveCustomers($event)"
          >
            <div>Show In-Active</div>
          </mat-slide-toggle>
          <div class="ms-3 p-0">
            <button class="btn btn-create theme-btn-color" (click)="clearFilter()">
              <i class="fas fa-redo me-2"></i>
              Reset Filters
            </button>
          </div>
          <div class="col-md-2 d-flex justify-content-end">
            <div class="button-style" style="position: relative">
              <button id="more-btn" class="more-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="more-dot"></span>
                <span class="more-dot"></span>
                <span class="more-dot"></span>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" (click)="openDialogBox()" style="cursor: pointer;">Change Assigned Person</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <table class="table table-striped">
          <thead class="tableview theme-bg-color">
          <tr>
            <th>
              <mat-checkbox [(ngModel)]="selectAll" (change)="toggleSelectAll()"></mat-checkbox>
            </th>
            <th>Contact Name</th>
            <th>Email</th>
            <th>Customer Name</th>
            <th>Assigned SPOC</th>
            <th>Email Sent</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr search-filter [searchFields]="searchFieldName" [filters]="filters" (filtersChanged)="onFiltersChanged($event)" (resetFilters)="clearFilter()"></tr>
          <ng-container *ngFor="let contact of customerContacts">
            <tr>
              <td>
                <mat-checkbox [checked]="selectedTasks.has(contact.id)" (change)="selectTasks($event, contact)"></mat-checkbox>
              </td>
              <td>{{ contact?.firstName ?? '--' }} {{ contact?.lastName ?? '' }}</td>
              <td>{{ contact?.email }}</td>
              <td>{{ contact?.customer?.companyName }}</td>
              <td>{{ contact?.spoc?.firstName }} {{ contact?.spoc?.lastName }}</td>
              <td *ngIf="contact?.sentEmailStatus">
                <label class="switch" style="color: #14804A; font-weight: 500;background-color: #E1FCEF">Yes</label>
              </td>
              <td style="margin-right: 45px;font-size: 14px" *ngIf="!contact?.sentEmailStatus">
                <button (click)="confirmationSendEmail(contact.id)" class="btn btn-save theme-bg-color" type="submit">Send Email</button>
              </td>
              <td></td>
            </tr>
          </ng-container>
          </tbody>
        </table>

      </div>

      <div *ngIf=" !showLoader && customerContacts?.length == 0" class="d-flex justify-content-center mt-8">
        <div>
          <img src="assets/images.png"><br>
          <h4> No Result Found</h4>
        </div>
      </div>
    </div>

    <mat-paginator [length]="totalContacts"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[10,12,25,50,100]"
                   [hidden]="totalContacts==0"
                   aria-label="Select page">
    </mat-paginator>

  </div>
</div>
