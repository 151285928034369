import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MatSelectChange} from "@angular/material/select";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CommentUpdateDialogBoxComponent} from "../comment-update-dialog-box/comment-update-dialog-box.component";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {myTaskDTO, userDT} from "../../../../shared/interfaces";
import {ApiService} from "../../../../services/api.service";
import {ToastrService} from "ngx-toastr";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";

@Component({
  selector: 'app-my-task-settings',
  templateUrl: './my-task-settings.component.html',
  styleUrls: ['./my-task-settings.component.css']
})
export class MyTaskSettingsComponent implements OnInit {
  status: string;
  myTaskData: myTaskDTO;
  confirmButtonEnable: boolean;
  managerAdd: boolean = false;
  assigne: string;
  assigneId: any;
  memebersList: userDT[];
  statusList = ["IN_PROGRESS", "ON_HOLD", "COMPLETED"];
  errorState: string;
  onHoldCommentText: string = '';
  assigneeConfirmButtonEnable: boolean;
  onHoldComment: boolean;
  assigneComment: boolean;
  role: string
  hasChanged:boolean=false;
  @Output() hasChangedEvent = new EventEmitter<boolean>();
  constructor(
    public dialog: NgDialogAnimationService,
    @Inject(MAT_DIALOG_DATA) public selectedMyTask: any,
    private service: ApiService,
    private toastr: ToastrService,
    private isMyTaskDataUpdated:IsDataUpdatedService,
  ) {
  }

  ngOnInit(): void {
    this.role = localStorage.getItem('role') ?? '';
    if (this.selectedMyTask.id != null) {
      this.status = this.selectedMyTask.myTask.status;
      this.getTaskAgain();
    }
  }

  handleStatusDialogClose() {
    this.service.onTaskStatusChange(this.selectedMyTask.id, 'ON_HOLD').then((response) => {
      this.isMyTaskDataUpdated.setUpdated(true);
      this.hasChangedEvent.emit(true)
      this.toastr.success('ID status changed to ON HOLD', 'Success');
      this.getTaskAgain();
    })
  }

  openDialogBox(type: String, title: string, subtitle: string) {
    if (type === 'status') {
      let dialogRefCommentStatus: MatDialogRef<CommentUpdateDialogBoxComponent>;
      dialogRefCommentStatus = this.dialog.open(CommentUpdateDialogBoxComponent, {
        width: "50%",
        height: "50%",
        data: {
          selectedTasks: this.myTaskData,
          title: title,
          subTitle: subtitle
        }
      });
      dialogRefCommentStatus.afterClosed().subscribe(result => {
        if (result) {
          this.handleStatusDialogClose();
        } else {
          this.status = this.myTaskData.status;
          this.confirmButtonEnable = false;
        }
      });
    } else if (type === 'assignee') {
      let dialogRefCommentAssignee: MatDialogRef<CommentUpdateDialogBoxComponent>;
      dialogRefCommentAssignee = this.dialog.open(CommentUpdateDialogBoxComponent, {
        width: "50%",
        height: "50%",
        data: {
          selectedTasks: this.myTaskData,
          title: title,
          subTitle: subtitle
        }
      });
      dialogRefCommentAssignee.afterClosed().subscribe(result => {
        if (result) {
          this.handleAssigneeDialogClose();
        } else {
          this.managerAdd = false;
          this.assigne = ''
        }
      });
    }
  }

  onAssigneeChange($event: MatSelectChange) {
    this.assigneId = $event.value.userId;
    this.onHoldComment = false;
    this.assigneComment = true;
    this.openDialogBox('assignee', "ASSIGNEE", "Why you want to change the assignee?");
    this.assigneeConfirmButtonEnable = true;
  }

  handleAssigneeDialogClose() {
    this.service.onChangeMangerOfTask(this.selectedMyTask.id, this.assigneId).then((response) => {
      this.isMyTaskDataUpdated.setUpdated(true);
      this.hasChangedEvent.emit(true);
      this.toastr.success('Assignee Changes Successfully ', 'Success');
      this.assigneeConfirmButtonEnable = false;
      this.managerAdd = false;
      this.getTaskAgain();

    });
  }

  getTaskAgain() {
    this.service.getTask(this.selectedMyTask.id).then((response) => {
      this.myTaskData = response;
    })
    this.assigne = this.selectedMyTask.myTask.assignee != null ? (this.selectedMyTask.myTask.assignee.firstName + ' ' + this.selectedMyTask.myTask.assignee.lastName) : '';
    this.getMembers();
  }

  getMembers() {
    this.service.getSpocList().subscribe((res: any) => {
      this.memebersList = res;
    })
  }

  onChangeManager() {
    this.managerAdd = true;
  }

  onStatusChange($event: MatSelectChange) {
    if ($event.value == 'ON_HOLD') {
      this.openDialogBox('status', "ON_HOLD", "Why you want to hold this request?"); // No title and subtitle for status
      this.onHoldComment = true;
      this.assigneComment = false;
    } else {
      this.onHoldComment = false;
    }
    this.confirmButtonEnable = true;
  }

  confirmButton() {
    if (this.onHoldComment == true) {
      if (this.onHoldCommentText != '') {
        this.service.onTaskStatusChange(this.selectedMyTask.id, this.status).then((response) => {
          this.isMyTaskDataUpdated.setUpdated(true);
          this.hasChangedEvent.emit(true)
          this.toastr.success('ID status changed to ' + this.status, 'Success');
          this.confirmButtonEnable = false;
        })
      } else {
        this.getTaskAgain()
        this.errorState = 'Comment required';
      }
    } else if (this.onHoldComment == false) {
      this.service.onTaskStatusChange(this.selectedMyTask.id, this.status).then((response) => {
        this.isMyTaskDataUpdated.setUpdated(true);
        this.hasChangedEvent.emit(true)
        this.toastr.success('ID status changed to ' + this.status, 'Success');
        this.confirmButtonEnable = false;
      })
    } else {
      this.service.onChangeMangerOfTask(this.selectedMyTask.id, this.assigneId).then((response) => {
        this.isMyTaskDataUpdated.setUpdated(true);
        this.toastr.success('Assignee Changes Successfully ', 'Success');
        this.hasChangedEvent.emit(true)
        this.assigneeConfirmButtonEnable = false;
        this.managerAdd = false;
        this.getTaskAgain();
      })
    }
  }

  cancel() {
    this.managerAdd = false;
    this.assigneeConfirmButtonEnable = false
  }
}
