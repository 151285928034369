import {ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../../services/api.service";
import {ToastrService} from "ngx-toastr";
import {Consultant, jobIdDt, PreferredLocations, Skill, TimeZone} from "../../shared/interfaces";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs/internal/Observable";
import {map, startWith} from "rxjs/operators";
import {PopUpComponent} from "../../util/pop-up/pop-up.component";
import {IsDataUpdatedClientService} from "../../services/isDataUpdatedClient.service";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";

@Component({
  selector: 'app-consultant-view-profile',
  templateUrl: './featured-consultant-view-profile-component.html',
  styleUrls: ['./featured-consultant-view-profile-component.css'],
  encapsulation:ViewEncapsulation.None

})

export class FeaturedConsultantViewProfileComponent implements OnInit {
  @ViewChild('jobFormElement') jobFormElement!: ElementRef;
  videoUrl: string = '';
  consultantDT: Consultant;
  isJobIdField: boolean = false
  jobForm: FormGroup;
  jobIdsList: jobIdDt[];
  isJobFormDisplay: boolean = false;
  workTimeZone: TimeZone[] = [];
  selectedJobId: any;
  durations: any[] = [];
  skills: any[] = [];
  skillCtrl = new FormControl();
  filteredSkills: Observable<any[]>;
  selectedJobIdControl = new FormControl();
  tentativeDateDropdown: any[] = [];
  isResume: boolean = false;

  @ViewChild("chipGrid") chipGrid: ElementRef;
  allSkillsRes: any = [];

  constructor(
    private dialogRef: MatDialogRef<FeaturedConsultantViewProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public consultant: any,
    private service: ApiService,
    private toastr: ToastrService,
    public dialog: MatDialog,
  ) {

  }

  ngOnInit(): void {
    console.log('consultant',this.consultant)
    this.getConsultantById();
  }

  getConsultantById() {
    this.service.getConsultantClientLogin(this.consultant.id).subscribe({
        next: res => {
          this.consultantDT = res;
          this.videoUrl = res.video
        },
        error: err => {
          this.toastr.error('API failed ' + err);
        },
        complete: () => {
        }
      }
    )
  }


  downloadResume() {
    window.open(this.consultantDT?.resume?.fileUrl);
  }

  close() {
    this.dialogRef.close();
  }


  getJobTypeString(jobList: any) {
    let jobString = '';
    jobList.forEach((e: PreferredLocations, index: number) => {
      jobString = jobString.concat(e.name);
      if (index < jobList.length - 1) {
        jobString = jobString.concat(', ');
      }
    });
    return jobString;
  }











  openSummaryDialog(): void {
    const dialogRef = this.dialog.open(PopUpComponent, {
      width: "50%",
      height: "auto",
      data: {
        type: 'consultant-summary',
        title: 'Technical Summary',
        summary: this.consultantDT?.summary,
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }



  getSummaryText(): string {
    if (!this.consultantDT?.summary) {
      return '';
    }
    const lines = this.consultantDT.summary.split('\n');
    if (lines.length <= 3) {
      return this.consultantDT.summary;
    } else {
      return lines.slice(0, 3).join('\n') + '...';
    }
  }
}
