<div class="row vendor_wrapper">
  <div *ngIf="openDialogType!='READONLY'" class="col-md-4 d-flex flex-column">
    <div class="upload_wrapper flex-fill">
      <div class="upload_doc text-center mb-5" *ngIf="!vendorLogoVisible">
        <div *ngIf="vendorImage == null && !logoLoader && role !='TA' ">
          <input #fileInput style="display: none" type="file" class="form-control-file"
                 (change)="onSelectLogo($event)"
                 accept=".png,.doc,.docx,application/msword,application/vnd,application/pdf">
          <h2 class="mb-4 font-weight-bold">Upload company logo</h2>
          <p class="image-uploader_max-file-size mb-3">Max file size: 3MB (png, jpeg)</p>
          <div class="drag_drop_wrapper" (click)="uploadFileTrigger()" (dragover)="handleDragOver($event)"
               (drop)="onSelectLogo($event,true)">
            <div><img src="assets/img_1.png" style="height: 40px"/></div>
            <div><span><b>Choose a file</b> or drag it here</span></div>
          </div>
        </div>

        <div *ngIf="vendorImage != null && !logoLoader" class="abc">
          <h2 *ngIf=" role !='TA'" class="mb-4 font-weight-bold">Upload company logo</h2>

          <p *ngIf=" role !='TA'" class="image-uploader_max-file-size mb-3">Max file size: 3MB (png, jpeg)</p>
          <div class="drag_drop_wrapper_upload">
            <img [src]="vendorImage" class="uploaded_img">
            <span *ngIf="role !='TA'" (click)="delete()"><i class="fas fa-times-circle"></i></span>
          </div>
        </div>
        <div *ngIf="logoLoader" disabled>
          <button id="logoLoader" class="btn btn-saveform" disabled>
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
      </div>
      <div class="drag_drop_wrapper_upload mb-5" *ngIf="vendorLogoVisible">
        <img [src]="vendorLogo" class="uploaded_img">
        <span *ngIf="role !='TA'" (click)="delete()"><i class="fas fa-times-circle"></i></span>
      </div>
      <hr/>
      <div *ngIf="role=='ADMIN' || role=='SALES_HEAD' || role=='MANAGER'">

        <div class="form-group btns save-form text-center mt-5">
          <h3>Activate/Deactivate vendor</h3>
          <mat-slide-toggle
            class="example-margin"
            color="primary"
            [disabled]=false
            [checked]="vendorData.status"
            (change)="change($event)">
            <div class="ActiveButton">ACTIVE</div>
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-8 mt-2">
    <table class="table audit_table table-striped">
      <thead class="tableview theme-bg-color">
      <tr>
        <th>Audit History</th>
        <th>Created Date</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let audits of audit">
        <tr>
          <td>
            <div class="d-flex align-items-baseline">
              <div class="circle me-2">
                <span
                  class="initials theme-bg-color"> {{ getInitials(audits.user?.firstName, audits?.user.lastName) }} </span>
              </div>
              <p> {{ audits.user?.firstName }} {{ audits?.user.lastName }} {{ audits?.actionDetails }}</p>
            </div>
          </td>
          <td>
            <div style="font-weight: lighter; font-size: 14px">
              <p> {{ audits.date | date: 'h:mm a, d MMM y' }}</p>
            </div>
          </td>
      </ng-container>
      </tbody>
    </table>
    <mat-paginator [length]="totalAudit"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[5,10,12,25,50,100]"
                   [hidden]="totalAudit == 0"
                   aria-label="Select page">
    </mat-paginator>
  </div>

</div>

