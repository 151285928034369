import {
  Component,
  Input,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  forwardRef, AfterViewInit
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { TextEditorService } from '../../../../services/textEditor.service'; // Import the service

@Component({
  selector: 'app-expandable-textarea',
  templateUrl: './expandable-textarea.component.html',
  styleUrls: ['./expandable-textarea.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ExpandableTextareaComponent),
      multi: true
    }
  ]
})
export class ExpandableTextareaComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() formControl: FormControl;
  isTextareaVisible = false;
  @ViewChild('textarea') textarea: ElementRef;

  private _value: string = '';
  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(
    private elRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private textEditorService: TextEditorService
  ) {}

  ngOnInit(): void {
    this.textEditorService.activeEditor$.subscribe(activeComponent => {
      if (activeComponent !== 'textarea' && this.isTextareaVisible) {
        this.hideTextarea();
      }
    });
  }

  ngAfterViewInit(): void {}

  showTextarea(event: MouseEvent): void {
    event.stopPropagation();
    this.textEditorService.setActiveEditor(this);
    this.isTextareaVisible = true;
    requestAnimationFrame(() => {
      this.cdr.detectChanges();
      this.textarea.nativeElement.focus();
    });
  }

  hideTextarea(): void {
    setTimeout(() => {
      this.textarea?.nativeElement?.classList.add('collapsing');
      this.isTextareaVisible = false;
      this.cdr.detectChanges();
    }, 200);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = this.elRef.nativeElement.contains(event.target);
    if (!clickedInside && event.target !== this.textarea?.nativeElement) {
      this.hideTextarea();
    }
  }

  writeValue(value: string): void {
    if (value !== this._value) {
      this._value = value;
      this.formControl.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
