import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {DataService} from '../../../services/data.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgDialogAnimationService} from 'ng-dialog-animation';
import {debounce} from 'lodash';
import {fromEvent} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {debounceTime, distinctUntilChanged, filter, map, startWith, tap} from 'rxjs/operators';
import {IsDataUpdatedService} from '../../../services/isDataUpdated.service';
import {CreateConsultantsComponent} from "./create-consultants/create-consultants.component";
import {PopUpComponent} from "../../../util/pop-up/pop-up.component";
import {Consultant, Skill} from "../../../shared/interfaces";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {Observable} from "rxjs/internal/Observable";
import {MatDatepicker} from "@angular/material/datepicker";
import {DatePipe} from "@angular/common";
import {FormControl} from "@angular/forms";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";

@Component({
  selector: 'app-manage-consultants',
  templateUrl: './manage-consultants.component.html',
  styleUrls: ['./manage-consultants.component.css']
})

export class ManageConsultantsComponent implements OnInit , AfterViewInit{
  skillControl = new FormControl('');
  filteredSkills: Observable<Skill[]>;
  disabledSkills: Skill[] = [];
  skillsRes: Skill[] = [];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  consultants: Consultant[] = [];
  currentPage = 0;
  showLoader: boolean = true;
  search = '';
  showSearchEnable: boolean = true;
  showFeatured: boolean = false;
  showActive: boolean = true;
  pageSize = 12;
  totalEmployee = 0;
  experienceOptions: { min: number, max: number, label: string }[] = [
    {min: 0, max: 1, label: '0-1 years'},
    {min: 1, max: 3, label: '1-3 years'},
    {min: 3, max: 5, label: '3-5 years'},
    {min: 5, max: 8, label: '5-8 years'},
    {min: 8, max: 10, label: '8-10 years'},
    {min: 10, max: Infinity, label: '10 years+'}
  ];
  minEndDate: string | null;
  consultantStatusOptions: string[] = ['AVAILABLE', 'NOT_AVAILABLE', 'DEPLOYED', 'HOLD'];
  locationPreferenceOptions: string[] = ['REMOTE', 'HYBRID', 'ONSITE'];
  selectedItemsExperience: { min: number, max: number }[] = [];
  selectedItemsSkills: string[] = [];
  selectedItemsConsultantStatus: string[] = [];
  selectedItemsLocationPreference: string[] = [];
  consultantId: string = '';
  consultantName: string = '';
  title: string = '';
  vendorsName: string = '';
  skills: any[] = [];
  isCreatedDate: boolean = false;
  filterType: string = 'CREATED';
  selectedSkill: Skill[] = [];
  SkillsList: any[] = [];
  @ViewChildren('dropdownExperience, dropdownSkills, dropdownConsultantStatus, dropdownLocationPreference,dropdownDate') dropdowns!: QueryList<ElementRef>;
  @ViewChild("chipGrid") chipGrid: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input', {static: true}) input: ElementRef | any;
  @ViewChild('picker') picker1: MatDatepicker<any>;
  @ViewChild('picker1') picker2: MatDatepicker<any>;
  selectedFromDate: string = '';
  selectedToDate: string = '';

  constructor(
    private service: ApiService,
    private dataService: DataService,
    public snackBar: MatSnackBar,
    public dialog: NgDialogAnimationService,
    private isDataUpdatedService: IsDataUpdatedService,
    private datePipe: DatePipe
  ) {
    this.onInputChange = debounce(this.onInputChange, 600);
  }

  ngOnInit(): void {
    this.showLoader = false;
    this.getAllConsultants(this.currentPage, this.pageSize, '', this.search, this.showSearchEnable, this.showActive,this.showFeatured);
    this.getAllConsultants = debounce<any>(this.getAllConsultants, 600);
    this.getSkillType();
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(
      tap(() => {
        this.getAllConsultants(
          this.paginator.pageIndex,
          this.paginator.pageSize,
          '',
          this.search,
          this.showSearchEnable,
          this.showActive,
          this.showFeatured,
          this.selectedItemsExperience,
          {
            filterType: this.filterType,
            from: this.selectedFromDate,
            to: this.selectedToDate
          },
          this.selectedSkill.map((res) => res.id),
          this.selectedItemsLocationPreference,
          this.selectedItemsConsultantStatus,
          this.vendorsName,
          this.consultantName,
          this.title,
          this.consultantId
        );
      })
    ).subscribe();

    if (this.input) {
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => this.getAllConsultants(
            this.paginator.pageIndex,
            this.paginator.pageSize,
            '',
            this.search,
            this.showSearchEnable,
            this.showActive,
              this.showFeatured,
            this.selectedItemsExperience,
            {
              filterType: this.filterType,
              from: this.selectedFromDate,
              to: this.selectedToDate
            },
            this.selectedSkill.map((res) => res.id),
            this.selectedItemsLocationPreference,
            this.selectedItemsConsultantStatus,
            this.vendorsName,
            this.consultantName,
            this.title,
            this.consultantId
          ))
        )
        .subscribe();
    }
  }


  getSkillType(): void {
    this.service.getSkills().then((res: any[]) => {
      this.skillsRes = res.filter((title) => title.title !== "");
      this.filteredSkills = this.skillControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterSkills(value))
      );
    });
  }

  private _filterSkills(value: string | Skill): Skill[] {
    const filterValue = typeof value === 'string' ? value.trim().toLowerCase() : value?.title.trim().toLowerCase() ?? '';
    // this.exactMatchFound = this.skillsRes.some(skill => skill.title.toLowerCase() == filterValue);
    return this.skillsRes.filter(skill =>
      skill.title.toLowerCase().includes(filterValue)
    );
  }

  toggleDropdown(dropdownName: string) {
    const dropdown = this.dropdowns.find(dropdown => dropdown.nativeElement.id === dropdownName);
    if (dropdown) {
      const dropdownMenu = dropdown.nativeElement.querySelector('.dropdown-menu');
      const isOpen = dropdownMenu.style.display === 'block';
      this.closeAllDropdowns();
      if (!isOpen) {
        dropdownMenu.style.display = 'block';
      }
    }
  }


  toggleSelection(option: string, selectedItems: string[]) {
    const index = selectedItems.indexOf(option);
    if (index > -1) {
      selectedItems.splice(index, 1);
    } else {
      selectedItems.push(option);
    }
    this.sendPayload();
  }

  isSelected(option: string, selectedItems: string[]): boolean {
    return selectedItems.includes(option);
  }

  toggleSelectionExperience(option: { min: number, max: number, label: string }, selectedItems: {
    min: number,
    max: number
  }[]) {
    const index = selectedItems.findIndex(item => item.min === option.min && item.max === option.max);
    if (index > -1) {
      selectedItems.splice(index, 1);
    } else {
      selectedItems.push({min: option.min, max: option.max});
    }
    this.sendPayload();
  }


  isSelectedExperience(option: { min: number, max: number, label: string }, selectedItems: {
    min: number,
    max: number
  }[]): boolean {
    return selectedItems.some(item => item.min === option.min && item.max === option.max);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = this.dropdowns.some(dropdown => dropdown.nativeElement.contains(event.target));
    const clickedOnDatePicker = (event.target as Element).closest('mat-datepicker')!== null;
    const clickedOnDatePickerOrDescendant = (event.target as Element).closest('.mat-datepicker,.mat-datepicker-popup')!== null;

    if (!clickedInside &&!clickedOnDatePicker &&!clickedOnDatePickerOrDescendant) {
      this.closeAllDropdowns();
    }
  }

  getAllConsultants(pageNumber: number, size: number, sort: string, query: string,
                    showArchived: boolean, showActive: boolean,featured?:boolean, experience?: { min: number; max: number; }[],
                    dateSearchCriteria?: { filterType: string, from: string, to: string }, skills?: number[], jobType?: string[], statuses?: string[],
                    vendorName?: string, consultantName?: string, title?: string, consultantId?: string): void {
    this.showLoader = true;
    this.service.getAllConsultants(pageNumber, size, sort, query, showArchived, showActive,featured, experience,
      dateSearchCriteria, skills, jobType, statuses, vendorName,consultantName, title, consultantId).subscribe({
      next: res => {
        this.consultants = res.content;
        if (res.content && res.content.primarySkills) {
          this.SkillsList = this.consultants.map((e: any) => e.primarySkills?.map((e: any) => e.title));
        } else {
          this.SkillsList = [];
        }

        this.paginator.pageIndex = res.page.number;
        this.paginator.pageSize = res.page.size;
        this.pageSize = res.page.size;
        this.totalEmployee = res.page.totalElements;
        this.showLoader = false;
      },
      error: err => {
        console.log('error', err);
        this.showLoader = false;
      },
      complete: () => {
        this.showLoader = false;
      }
    });
  }

  toggleSearchEnableConsultant(event: any) {
    this.showSearchEnable = event.checked;
    this.getAllConsultants(
      this.currentPage,
      this.pageSize,
      '',
      this.search,
      this.showSearchEnable,
      this.showActive,
        this.showFeatured,
      this.selectedItemsExperience,
      {
        filterType: this.filterType,
        from: this.selectedFromDate,
        to: this.selectedToDate
      },
      this.selectedSkill.map((res) => res.id),
      this.selectedItemsLocationPreference,
      this.selectedItemsConsultantStatus,
      this.vendorsName,
      this.consultantName,
      this.title,
      this.consultantId
    );
  }
    toggleFeaturedConsultant(event: any) {
        this.showFeatured = event.checked;
        this.getAllConsultants(
            this.currentPage,
            this.pageSize,
            '',
            this.search,
            this.showSearchEnable,
            this.showActive,
          this.showFeatured,
            this.selectedItemsExperience,
            {
                filterType: this.filterType,
                from: this.selectedFromDate,
                to: this.selectedToDate
            },
            this.selectedSkill.map((res) => res.id),
            this.selectedItemsLocationPreference,
            this.selectedItemsConsultantStatus,
            this.vendorsName,
            this.consultantName,
            this.title,
            this.consultantId
        );
    }


    toggleActiveConsultant(event: any) {
    this.showActive = event.checked;
    this.getAllConsultants(
      this.currentPage,
      this.pageSize,
      '',
      this.search,
      this.showSearchEnable,
      this.showActive,
      this.showFeatured,
      this.selectedItemsExperience,
      {
        filterType: this.filterType,
        from: this.selectedFromDate,
        to: this.selectedToDate
      },
      this.selectedSkill.map((res) => res.id),
      this.selectedItemsLocationPreference,
      this.selectedItemsConsultantStatus,
      this.vendorsName,
      this.consultantName,
      this.title,
      this.consultantId
    );
  }
  downloadReport() {
    const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    const fileName = `Consultant_Report${timestamp}.csv`;
    this.service.downloadConsultantsReport(
        this.search,
        this.showSearchEnable,
        this.showActive,
        this.selectedItemsExperience,
        {
          filterType: this.filterType,
          from: this.selectedFromDate,
          to: this.selectedToDate
        },
        this.selectedSkill.map((res) => res.id),
        this.selectedItemsLocationPreference,
        this.selectedItemsConsultantStatus,
        this.vendorsName,
        this.consultantName,
        this.title,
        this.consultantId).subscribe(blob =>{ const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(objectUrl);});
  }
  openDialogs(action: string, element: any): void {
    if (action === 'delete') {
      const dialogRef = this.dialog.open(PopUpComponent, {
        data: {
          message: 'Are you sure to delete',
          type: 'confirmation'
        }
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.service.deleteJob([element.id]).then(() => {
            this.getAllConsultants(this.currentPage, this.pageSize, '', this.search, this.showSearchEnable, this.showActive)
          });
        }
      });
    } else if (action == 'new') {
      const dialogRef = this.dialog.open(CreateConsultantsComponent, this.dataService.jobDialogConfig({id: null}));
      dialogRef.beforeClosed().subscribe((res) => {
        if (res) {
          const newDialogRef = this.dialog.open(CreateConsultantsComponent, this.dataService.jobDialogConfig({
            id: res.id,
            consultantId: res.consultantId,
          }));
          newDialogRef.afterClosed().subscribe(() => {
            this.isDataUpdatedService.setUpdated(false);
            this.getAllConsultants(this.currentPage, this.pageSize, '', this.search, this.showSearchEnable, this.showActive)
          });
        }
      });
    } else if (action == 'edit') {
      const consultEditDialogRef = this.dialog.open(CreateConsultantsComponent, this.dataService.jobDialogConfig({
        id: element.id,
        consultantId: element.consultantId,
        consultantData: element,
      }));
      consultEditDialogRef.afterClosed().subscribe(() => {
        if (this.isDataUpdatedService.getBoolean()) {
          this.getAllConsultants(this.currentPage, this.pageSize, '', this.search, this.showSearchEnable, this.showActive)
          this.isDataUpdatedService.setUpdated(false);
        }
      });
    }
  }

  getSkills(skills: any): { skillsString: string, pendingSkillsCount: number, pendingSkills: string[] } {
    let skillsString = '';
    let count = 0;
    let pendingCount = 0;
    let pendingSkills: string[] = [];
    skills.forEach((e: any, index: number) => {
      if (count < 5) {
        skillsString += e.title;
        if (count < 4 && index < skills.length - 1) {
          skillsString += ' | ';
        }
        count++;
      } else {
        pendingSkills.push(e.title);
        pendingCount++;
      }
    });

    return {
      skillsString: skillsString,
      pendingSkillsCount: pendingCount,
      pendingSkills: pendingSkills
    };
  }

  closeAllDropdowns() {
    this.dropdowns.forEach(dropdown => {
      const dropdownMenu = dropdown.nativeElement.querySelector('.dropdown-menu');
      dropdownMenu.style.display = 'none';
    });
  }

  onInputChange(newValue: string, fieldName: string) {
    switch (fieldName) {
      case 'consultantId':
        this.consultantId = newValue;
        // this.consultantName = '';
        // this.title = '';
        break;
      case 'consultantName':
        this.consultantName = newValue;
        // this.consultantId = '';
        // this.title = '';
        break;
      case 'title':
        this.title = newValue;
        // this.consultantName = '';
        // this.consultantId = '';
        break;
      case 'vendorsName':
        this.vendorsName = newValue;
        break;
      default:
        break;
    }
    this.sendPayload();
  }

  sendPayload() {
    const payload = {
      consultantId: this.consultantId,
      consultantName: this.consultantName,
      title: this.title,
      experience: this.selectedItemsExperience,
      vendorsName: this.vendorsName,
      skills: this.selectedSkill.map((res) => res.id),
      consultantStatus: this.selectedItemsConsultantStatus,
      locations: this.selectedItemsLocationPreference,
      dateSearchCriteria: {
        filterType: this.filterType,
        from: this.selectedFromDate,
        to: this.selectedToDate
      },
    };
    this.getAllConsultants(
      0,
      this.pageSize,
      '',
      this.search,
      this.showSearchEnable,
      this.showActive,
      this.showFeatured,
      payload.experience,
      payload.dateSearchCriteria,
      payload.skills,
      payload.locations,
      payload.consultantStatus,
      payload.vendorsName,
      payload.consultantName,
      payload.title,
      payload.consultantId,
    );
  }
  clearFilter() {
    this.consultantId = '';
    this.consultantName = '';
    this.title = '';
    this.vendorsName = '';
    this.skills = [];
    this.filterType = 'CREATED';
    this.selectedFromDate = '';
    this.selectedToDate='';
    this.selectedSkill = [];
    this.SkillsList = [];
    this.selectedItemsConsultantStatus = [];
    this.selectedItemsLocationPreference = [];
    this.selectedItemsExperience=[];
    this.getAllConsultants(0, this.pageSize, '', this.search, this.showSearchEnable, this.showActive);

  }
  formatLocationData(value: any) {
    return value.map((res: any) => res.name)
  }

  handleDateToggleChange(event: any) {
    this.isCreatedDate = event.target.checked;
    if (this.isCreatedDate) {
      this.resetDateFields();
      this.filterType = "UPDATED";
    } else {
      this.resetDateFields();
      this.filterType = "CREATED";
    }
  }

  resetDateFields() {
    this.selectedFromDate = '';
    this.selectedToDate = '';
  }

  onFromDateChange(event: any): void {
    const date = event.value;
    this.selectedFromDate = this.formatDate(date);
  }

  onToDateChange(event: any): void {
    const date = event.value;
    this.selectedToDate = this.formatDate(date);
    this.sendPayload();
  }

  private formatDate(date: Date | null): string {
    return date ? this.datePipe.transform(date, 'yyyy-MM-dd') || '' : '';
  }

  removeSkill(skill: any): void {
    const index = this.selectedSkill.indexOf(skill);
    if (index >= 0) {
      this.selectedSkill.splice(index, 1);
      this.sendPayload();
    }
  }

  openAutocomplete(trigger: MatAutocompleteTrigger) {
    if (trigger) {
      trigger.openPanel();
    }
  }

  selectSkills(event: MatAutocompleteSelectedEvent): void {
    const skill = event.option.value;
    this.selectedSkill.push(skill);
    this.chipGrid.nativeElement.value = '';
    this.skillControl.setValue(null);
    this.disabledSkills.push({...skill, disabled: true});
    this.sendPayload();
  }

  disableSkill(skill: any): boolean {
    return this.selectedSkill.some(selected => selected.id === skill.id) || this.skills.some(s => s.id === skill.id);
  }

  updateEndDateMinDate() {
    if (this.selectedFromDate) {
      const startDate = new Date(this.selectedFromDate);
      startDate.setDate(startDate.getDate() + 1);
      this.minEndDate = this.datePipe.transform(startDate, 'yyyy-MM-dd');
    } else {
      this.minEndDate = null;
    }
  }

}
