import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DataService } from "../../../services/data.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-create-partner',
  templateUrl: './create-partner.component.html',
  styleUrls: ['./create-partner.component.scss']
})
export class CreatePartnerComponent implements OnInit {
  createPartnerForm: FormGroup;
  domainErrorMsg :string
  sideBar: boolean;

  constructor(
    private service: ApiService,
    private dataService: DataService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.createPartnerForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", Validators.pattern('[a-zA-Z0-9._%+-]+@[a-z0-9A-Z-]+.[a-z]{2,}$')],
      mobile: "",
      businessName: "",
      businessDomain: "",
      website: "",
    });
    this.sideBar = history.state.data;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  createPartner(): void {
    if (this.createPartnerForm.invalid) {
      this.openSnackBar('Please add these field'+ this.createPartnerForm.value, " ");
      console.log('Invalid form,Please try again');
      return;
    } else {
      this.dataService.isLoading.next(true);
      this.service.createPartner(this.createPartnerForm.value).then(res => {
        this.dataService.isLoading.next(false);
        // alert('Partner successfully created');
        this.createPartnerForm.reset();
      }).catch((error: any) => {
        console.log('error',error)
        if(error.status==400){
          this.openSnackBar(error.error.reason, " ");
        }
      })
      this.createPartnerForm.reset()
    }
  }

  onClickListenerDomain(domainName: string){

    if (domainName.length < 4) {
     this.domainErrorMsg = 'Your domain name must be greater than 3 or more lowercase letters and/or numbers.'
    } else {
      if (domainName.length > 3){
        this.domainErrorMsg='';

      }
    }
  }
}
