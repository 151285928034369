import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-suggestiobn-box',
  templateUrl: './suggestiobn-box.component.html',
  styleUrls: ['./suggestiobn-box.component.css']
})
export class SuggestiobnBoxComponent implements OnInit {
  @Input()
  records: any;
  @Input()
  type: string;
  @Output()
  selectedRecord = new EventEmitter<any>();

  constructor(private elRef: ElementRef) {
  }

  ngOnInit(): void {}

  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    this.records = [];
    this.selectedRecord.next(null);
  }

  chooseRecord(record: any): void {
    this.selectedRecord.next(record);
  }

}
