<div>
  <div class="add-Header d-flex align-items-center justify-content-between" mat-dialog-title>
    <div class="ms-sm-4 fs-5">
      Add Video Link
    </div>
    <div class="d-flex align-items-center justify-content-end">
      <div (click)="close()" class="btn close-btn">
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.5143 1L1.22705 17.2871" stroke="#454545" stroke-width="2" stroke-linecap="round"/>
          <path d="M1.22705 1L17.5143 17.2871" stroke="#454545" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </div>
    </div>
  </div>
  <mat-dialog-content class="mat-typography mt-3">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Paste URL</mat-label>
        <input #urlInput matInput placeholder="Paste URL">
      </mat-form-field>
    <div class="d-flex justify-content-end mt-3">
      <button class="btn btn-save theme-btn-color" (click)="saveUrl(urlInput.value)">Save</button>
    </div>
  </mat-dialog-content>
</div>

