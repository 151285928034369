import {Inject} from '@angular/core';
import {Component, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent implements OnInit {
  data: any;

  constructor(@Inject(MAT_DIALOG_DATA) public inputData: any, private dialogRef: MatDialogRef<PopUpComponent>) {
  }

  closeSummaryDialog(): void {
    this.dialogRef.close()
  }

  ngOnInit(): void {
    this.data = this.inputData;
  }

  close(data: any): void {
    this.dialogRef.close(data);
  }

}
