import {
  Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy
} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl, FormArray} from '@angular/forms';
import {map, startWith, takeUntil} from "rxjs/operators";
import {
  CreateSolution,
  IndustryVertical,
  informationTags,
  Members,
  Solution,
  VendorCorp
} from "../../../../shared/interfaces";
import {ApiService} from "../../../../services/api.service";
import {Observable} from "rxjs/internal/Observable";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {MatChipInputEvent} from "@angular/material/chips";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute} from "@angular/router";
import {DatePipe} from "@angular/common";
import {ImageStorageService} from "../../../../services/image-storage.service";
import {Subject} from "rxjs";

interface SolutionField {
  label: string;
  name: string;
  type: string;
  placeholder?: string;
  options?: string[];
}

@Component({
  selector: 'app-solution-information',
  templateUrl: './solution-information.component.html',
  styleUrls: ['./solution-information.component.css']
})
export class SolutionInformationComponent implements OnInit, OnChanges, OnDestroy {
  solutionData: any;
  solutionInfo: SolutionField[] = [
    {label: "Solution Name", name: 'name', type: "text", placeholder: "Placeholder"},
    {
      label: "Solution Category", name: 'category', type: "select",
      options: ["Tier 1 - Premium Customer", "Tier 2 - Regular Customer", "Tier 3 - Partner Customer"]
    },
    {label: "Information Tags", name: 'informationTagId', type: "multiSelect", placeholder: "Placeholder", options: []},
    {label: "Industry Vertical", name: 'industryVerticalId', type: "multiSelect", options: []},
    {label: "Vendor Name", name: 'vendorId', type: "matAutoComplete", options: []},
    {label: "Solution Description", name: 'description', type: "text", placeholder: "Placeholder"},
    {label: "Vendor SPOC", name: 'spocId', type: "matAutoComplete", options: []}
  ];
  groupedSolutionInfo: SolutionField[][] = [];
  solutionForm: FormGroup;
  SPOC_Options: Members[] = [];
  filteredSpoc: Observable<Members[]>;
  spocControl = new FormControl('');
  vendorControl = new FormControl('');
  verticalControl = new FormControl('');
  tagControl = new FormControl('');
  vendorName = '';
  vendorId: string;
  filteredVendors: Observable<VendorCorp[]>;
  filteredVertical: Observable<IndustryVertical[]>;
  filteredTags: Observable<informationTags[]>;
  vendorsRes: VendorCorp[] = [];
  verticalRes: IndustryVertical[];
  @ViewChild('solutionDescriptionInput') solutionDescriptionInput: ElementRef;
  @ViewChild('textareaInput') textareaInput: ElementRef;
  @ViewChild('verticalAutoCompleter') verticalAutoCompleter: ElementRef;
  @ViewChild('tagAutoCompleter') tagAutoCompleter: ElementRef;
  private selectedSpcId: number[];
  separatorKeysCodes: number[] = [13, 188];
  selectedVertical: IndustryVertical[] = [];
  selectedTags: any[] = [];
  exactMatchFound: boolean = false;
  solutionId: number;
  id: number;
  verticalName: string = '';
  tagName: string = '';
  imageSrc: string = '';
  showloader: boolean = false
  @Output() createSolutionId = new EventEmitter<number>();
  @Input() data: Solution
  @Input() imageUrl: string
  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private imageStorageService: ImageStorageService
  ) {
  }

  ngOnInit(): void {
    this.solutionId = this.route?.snapshot?.params['solutionId'];
    this.groupFields();
    this.createForm();
    this.getSPOC();
    this.getVendors();
    this.getAllIndustryByOrganisation();
    this.getAllInformationTags();
    this.imageStorageService.getImageSrc()
      .pipe(takeUntil(this.destroy$))
      .subscribe((src) => {
        this.imageSrc = src ?? '';
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      this.solutionData = this.data.solutionInfo;
      this.solutionId = this.data?.id
      this.imageSrc = this.data?.logoUrl
      this.populateSolutionForm();
    }
  }

  private groupFields(): void {
    this.groupedSolutionInfo = [
      [this.solutionInfo[0]], [this.solutionInfo[1], this.solutionInfo[3]], [this.solutionInfo[2]],
      [this.solutionInfo[4], this.solutionInfo[6]], [this.solutionInfo[5]]
    ];
  }

  private createForm(): void {
    const formControls: { [key: string]: FormControl | FormArray } = {};
    this.solutionInfo.forEach(field => {
      if (field.name === 'industryVerticalId' || field.name === 'informationTagId') {
        formControls[field.name] = this.fb.array([]); // Initialize as a FormArray
      } else {
        formControls[field.name] = new FormControl('', Validators.required);
      }
    });
    this.solutionForm = this.fb.group(formControls);
  }

  private populateSolutionForm(): void {
    if (this.solutionData) {
      this.solutionForm.patchValue({
        name: this.data.name,
        description: this.solutionData?.description,
        category: this.mapCustomValueToCategory(this.solutionData.category),
        vendorId: this.solutionData?.vendor?.id,
        spocId: this.solutionData?.spoc?.id
      });

      if (this.solutionData.vendor) {
        this.vendorControl.setValue(this.solutionData.vendor);
        this.vendorId = this.solutionData?.vendor?.id;
      }

      if (this.solutionData.spoc) {
        this.spocControl.setValue(this.solutionData?.spoc);
        this.selectedSpcId = this.solutionData?.spoc?.id;
      }

      if (this.solutionData.industryVerticals) {
        this.selectedVertical = this.solutionData.industryVerticals;
        const industryVerticalFormArray = this.solutionForm.get('industryVerticalId') as FormArray;
        industryVerticalFormArray.clear();
        this.solutionData.industryVerticals.forEach((vertical: IndustryVertical) => {
          const verticalGroup = this.fb.group({
            id: [vertical.id],
            name: [vertical.name]
          });
          industryVerticalFormArray.push(verticalGroup);
        });
      }

      if (this.solutionData.informationTag) {
        this.selectedTags = this.solutionData.informationTag;
        const informationTagFormArray = this.solutionForm.get('informationTagId') as FormArray;
        informationTagFormArray.clear();
        this.solutionData.informationTag.forEach((tag: any) => {
          const tagGroup = this.fb.group({
            id: [tag.id],
            tagName: [tag.tagName]
          });
          informationTagFormArray.push(tagGroup);
        });
      }
    }
  }

  getAllInformationTags() {
    this.service.getInformationTags().subscribe((res) => {
      this.filteredTags = this.tagControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterTags(value, res))
      );
    });
  }

  private _filterTags(value: any, tags: any[]): any[] {
    const filterValue = (typeof value === 'string' ? value : '').toLowerCase();
    return tags.filter(option =>
      (option.tagName ?? '').toLowerCase().includes(filterValue)
    );
  }

  createInformationTags() {
    if (this.tagControl.value.trim() === '') {
      this.toastr.warning('Please enter a valid tag name.', 'Invalid Input');
      return;
    }
    const payload = {
      name: this.tagControl.value.trim(),
    };
    this.service.createInformationTags(payload).subscribe({
      next: (res) => {
        this.getAllInformationTags();
        this.selectedTags.push(res);
        this.tagControl.setValue('');
        this.tagName = '';
        this.tagAutoCompleter.nativeElement.value = '';
        this.toastr.success('Tag created successfully', 'Success');
      },
      error: (error) => {
        console.error('Error creating tag:', error.message);
        this.toastr.error('Failed to create tag.', 'Error');
      }
    });
  }

  addVerticalType(): void {
    if (this.verticalControl.value.trim() === '') {
      this.toastr.warning('Please enter a valid industry vertical name.', 'Invalid Input');
      return;
    }
    const payload = {
      name: this.verticalControl.value.trim(),
    };

    this.service.addIndustryVertical(payload).subscribe({
      next: (res) => {
        this.getAllIndustryByOrganisation();
        this.selectedVertical.push(res);
        this.verticalControl.setValue('');
        this.verticalName = '';
        this.verticalAutoCompleter.nativeElement.value = '';
        this.toastr.success('Industry Vertical Added Successfully', 'Success');
      },
      error: (e) => {
        console.error('error', e.error.message);
        this.toastr.error('Failed to add industry vertical.', 'Error');
      }
    });
  }

  addTags(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      const newTag = {id: null as unknown as number, name: value.trim()} as IndustryVertical;
      this.selectedTags.push(newTag);
      (this.solutionForm.get('informationTags') as FormArray).push(this.fb.group(newTag));
    }

    if (input) {
      input.value = '';
    }

    this.tagControl.setValue(null);
  }


  selectTags(event: MatAutocompleteSelectedEvent) {
    const tag = event.option.value;
    if (!this.selectedTags.find((t: any) => t.tagName === tag.tagName)) {
      this.selectedTags.push(tag);
      (this.solutionForm.get('informationTagId') as FormArray).push(this.fb.group(tag));
    }
    this.tagControl.setValue('');
  }

  removeTags(tag: any) {
    const index = this.selectedTags.indexOf(tag);
    if (index >= 0) {
      this.selectedTags.splice(index, 1);
      (this.solutionForm.get('informationTagId') as FormArray).removeAt(index);
    }
  }

  disableTags(tag: IndustryVertical): boolean {
    return this.selectedTags.some(selected => selected.id === tag.id);
  }

  getAllIndustryByOrganisation() {
    this.service.getIndustryVertical().subscribe((res: IndustryVertical[]) => {
      this.filteredVertical = this.verticalControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterVertical(value, res))
      );
    });
  }

  private _filterVertical(value: any, industryVerticals: IndustryVertical[]): IndustryVertical[] {
    const filterValue = (typeof value === 'string' ? value : '').toLowerCase();
    this.verticalRes = industryVerticals;
    this.exactMatchFound = this.verticalRes.some(vertical => vertical.name?.toLowerCase() === filterValue);
    return industryVerticals.filter(option =>
      (option.name ?? '').toLowerCase().includes(filterValue)
    );
  }

  addVertical(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      const customValue = this.mapCategoryToCustomValue(value.trim());
      const newVertical = this.fb.group({
        id: [null],
        name: [customValue]
      });
      this.selectedVertical.push({name: customValue} as IndustryVertical);
      (this.solutionForm.get('industryVerticalId') as FormArray).push(newVertical);
    }

    if (input) {
      input.value = '';
    }

    this.verticalControl.setValue(null);
  }

  removeVertical(vertical: IndustryVertical): void {
    const index = this.selectedVertical.indexOf(vertical);
    if (index >= 0) {
      this.selectedVertical.splice(index, 1);
      (this.solutionForm.get('industryVerticalId') as FormArray).removeAt(index);
    }
  }

  openAutocomplete(trigger: MatAutocompleteTrigger) {
    if (trigger) {
      trigger.openPanel();
    }
  }

  selectVertical(event: MatAutocompleteSelectedEvent): void {
    const vertical = event.option.value as IndustryVertical;
    if (!this.selectedVertical.find(v => v.name === vertical.name)) {
      this.selectedVertical.push(vertical);
    }
    this.verticalControl.setValue(null);
  }

  disableVertical(vertical: IndustryVertical): boolean {
    return this.selectedVertical.some(selected => selected.name === vertical.name);
  }


  getSPOC() {
    this.service.getSpocList().subscribe((res: any) => {
      this.SPOC_Options = res.filter((active: any) => active.active);
      this.filteredSpoc = this.spocControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterSPOC(value))
      );
    });
  }

  private _filterSPOC(value: string): Members[] {
    if (typeof value !== 'string') {
      return [];
    }
    const filterValue = value.toLowerCase();
    return this.SPOC_Options.filter(option =>
      (option?.firstName && option.firstName.toLowerCase().includes(filterValue)) ||
      (option?.lastName && option.lastName.toLowerCase().includes(filterValue)) ||
      (option?.role && option.role.toLowerCase().includes(filterValue))
    );
  }

  chooseSpoc(event: MatAutocompleteSelectedEvent) {
    this.selectedSpcId = event?.option?.value?.userId;
    this.solutionForm.get('spocId')?.setValue(event?.option?.value?.userId);
  }

  formatSPOC(option: Members): string {
    if (!option) {
      return '';
    }
    return option.role + ' | ' + option.firstName + ' ' + option.lastName;
  }

  getVendors() {
    this.service.getVendorForConsultant().then(response => {
      this.vendorsRes = response;
      this.filteredVendors = this.vendorControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          const name = typeof value === 'string' ? value : value?.vendorName ?? "";
          return name ? this._vendorsFilter(name as string) : this.vendorsRes.slice();
        })
      );
    });
  }

  _vendorsFilter(name: string): VendorCorp[] {
    const filterValue = name.toLowerCase();
    return this.vendorsRes.filter(option => option.vendorName.toLowerCase().includes(filterValue));
  }

  onVendorChange(event: MatAutocompleteSelectedEvent) {
    this.vendorId = event.option.value.id;
    this.solutionForm.get('vendorId')?.setValue(this.vendorId);
  }

  displayVendorFn(doc: VendorCorp): string {
    return doc && doc.vendorName ? doc.vendorName : '';
  }

  onSubmit(): void {
    console.log(this.solutionForm)
    if (this.solutionForm.invalid) {
      return;
    }

    if (this.selectedVertical.length === 0) {
      this.toastr.error('Please select at least one Industry Vertical.', 'Error');
      return;
    }

    if (this.selectedTags.length === 0) {
      this.toastr.error('Please select at least one Information Tag.', 'Error');
      return;
    }

    const payload: CreateSolution = {
      ...this.solutionForm.value,
      industryVerticalId: this.selectedVertical.map(v => v.id),
      vendorId: this.vendorId,
      spocId: this.selectedSpcId,
      category: this.mapCategoryToCustomValue(this.solutionForm.value.category),
      informationTagId: this.selectedTags.map(tag => tag.id)
    };

    if (this.solutionId) {
      const updatePayload: CreateSolution = {
        ...this.solutionForm.value,
        category: this.mapCategoryToCustomValue(this.solutionForm.value.category),
        industryVerticalId: this.selectedVertical.map(v => v.id),
        informationTagId: this.selectedTags.map(tag => tag.id),
      }
      this.updateSolution(this.solutionId, updatePayload)
    } else {
      this.createSolution(payload);
    }
  }

  private mapCategoryToCustomValue(category: string): string {
    switch (category) {
      case 'Tier 1 - Premium Customer':
        return 'PREMIUM';
      case 'Tier 2 - Regular Customer':
        return 'REGULAR';
      case 'Tier 3 - Partner Customer':
        return 'PARTNER';
      default:
        return '';
    }
  }

  private mapCustomValueToCategory(customValue: string): string {
    switch (customValue) {
      case 'PREMIUM':
        return 'Tier 1 - Premium Customer';
      case 'REGULAR':
        return 'Tier 2 - Regular Customer';
      case 'PARTNER':
        return 'Tier 3 - Partner Customer';
      default:
        return '';
    }
  }

  createSolution(payload: CreateSolution) {
    this.showloader = true
    this.service.createSolutionInfomation(payload).subscribe({
      next: (res) => {
        this.id = res.id;
        this.createSolutionId.emit(this.id);
        this.solutionId = res.id
        this.toastr.success('Solution Created Successfully', 'Success');
        this.showloader = false
      },
      error: (err) => {
        this.toastr.error('Failed to Create Solution.', 'Error');
        this.showloader = false
      }
    });
  }

  updateSolution(solutionId: number, payload: CreateSolution) {
    this.showloader = true
    this.service.updateSolutionById(solutionId, payload).subscribe({
      next: (res) => {
        this.toastr.success('Solution Updated Successfully', 'Success');
        this.showloader = false
      },
      error: (err) => {
        this.toastr.error('Failed to Update Solution. Please try again later.', 'Error');
        this.showloader = false
      }
    });
  }

  getFormControl(controlName: string): FormControl {
    return this.solutionForm?.get(controlName) as FormControl;
  }

  formatDate(originalDate: any): string {
    return <string>this.datePipe.transform(originalDate, 'yyyy-MM-dd')?.toString();
  }
}
