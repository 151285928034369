import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ContentModule} from "./content/content.module";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginComponent} from './login/login.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {CustomInterceptorService} from './services/custom-interceptor.service';
import {ForgetPasswordComponent} from './forget-password/forget-password.component';
import {MaterialModule} from './material.module';
import {DatePipe} from "@angular/common";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatNativeDateModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatChipsModule} from "@angular/material/chips";
import {ToastrModule} from "ngx-toastr";
import {UtilModule} from "./util/util.module";
import {MatRadioModule} from "@angular/material/radio";
import {LoginClientComponent} from "./login/login-client/login-client.component";
import { FilterServices } from './services/cachingService/filters.service';
import {NgOtpInputModule} from "ng-otp-input";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { MatTooltipModule } from '@angular/material/tooltip';
import {RegisterNow} from "./login/register-now/register/register-now";
import {
  FeaturedConsultantViewProfileComponent
} from "./login/featured-consultant-view-profile/featured-consultant-view-profile-component";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {FeaturedConsultantTableComponent} from "./login/Featured-Consultant-table/featured-consultant-table.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginClientComponent,
    ForgetPasswordComponent,
    RegisterNow,
    FeaturedConsultantViewProfileComponent,
      FeaturedConsultantTableComponent,
  ],
  exports:[
    ContentModule,
      FeaturedConsultantTableComponent,
  ],
    imports: [
        MatChipsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        MaterialModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatInputModule,
        MatNativeDateModule,
        MatSelectModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        UtilModule,
        MatRadioModule,
        NgOtpInputModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatAutocompleteModule,

    ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: CustomInterceptorService, multi: true }, DatePipe, FilterServices],
  bootstrap: [AppComponent]
})
export class AppModule {
}
