<div class="consultant_wrapper">
  <h2 class="mb-4">Add Contacts</h2>
  <form [formGroup]="customerContactForm" ngNativeValidate (submit)="addContact()">
    <div class="form-row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red ">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="First Name" formControlName="firstName">
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red ">
          <mat-label>Last Name</mat-label>
          <input matInput placeholder="Last Name" formControlName="lastName">
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red ">
          <mat-label>Designation</mat-label>
          <input #designationAutoCompleter [formControl]="desControl" [(ngModel)]="desTypeName"
                 [matAutocomplete]="desAuto"z
                 type="text" matInput placeholder="e.g. CEO, HR, CTO"
                 aria-describedby="title">
          <mat-autocomplete autoActiveFirstOption #desAuto="matAutocomplete" [displayWith]="displayDocFn">
            <mat-option *ngFor=" let des of filteredDesignation| async"
                        (onSelectionChange)="chooseDesType($event)" [value]="des">
              {{ des.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div *ngIf="(filteredDesignation|async)?.length == 0" class=" dropdown-skills position-relative">
          <ul class="mat-autocomplete-panel mat-autocomplete-visible mw-100 h-50">
            <li class="li_disabled mat-option-disabled">
              No match Found
            </li>
            <hr class="m-0 p-0">
            <li class="font-weight-bold mat-option">
              <div class="d-flex justify-content-between align-items-center"
                   (click)="addDesType($event)">
                <span>Add this Designation type:</span>
                <button class="p-0 bg-transparent border-0 fs-20"> +</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Email id</mat-label>
          <input matInput placeholder="Email" formControlName="email" type="email">
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Mobile number</mat-label>
          <input matInput placeholder="Mobile" minlength="10" maxlength="10" formControlName="mobile" type="text"
                 (input)="onKeyPress($event)">
        </mat-form-field>
        <div *ngIf="displayError" class="text-danger">
          {{ this.errorMessage }}
        </div>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>LinkedIn URL</mat-label>
          <input matInput placeholder="LinkedIn URL" formControlName="linkedIn"
                 #inputMobileNo type="text">
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Vertical</mat-label>
          <input #verticalAutoCompleter [formControl]="verticalControl" [(ngModel)]="verticalName"
                 [matAutocomplete]="autoVertical"
                 type="text" matInput placeholder="e.g. CEO, HR, CTO"
                 aria-describedby="title" >
          <mat-autocomplete autoActiveFirstOption #autoVertical="matAutocomplete" [displayWith]="displayDocFn">
            <mat-option *ngFor=" let vertical of filteredVertical| async"
                        (onSelectionChange)="chooseVerticalType($event)" [value]="vertical">
              {{ vertical.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div *ngIf="(filteredVertical|async)?.length == 0 && (verticalName!=null && verticalName!='')"
             class=" dropdown-skills position-relative">
          <ul class="mat-autocomplete-panel mat-autocomplete-visible mw-100 h-50">
            <li class="li_disabled mat-option-disabled">
              No match Found
            </li>
            <hr class="m-0 p-0">
            <li class="font-weight-bold mat-option">
              <div class="d-flex justify-content-between align-items-center"
                   (click)="addVertical($event)">
                <span>Add this vertical:</span>
                <button class="p-0 bg-transparent border-0 fs-20"> +</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Assigned SPOC</mat-label>
          <input #SPOCAutoCompleter type="text" matInput placeholder="Assigned SPOC" [formControl]="spocControl" [matAutocomplete]="autoSPOC" required>
          <mat-autocomplete #autoSPOC="matAutocomplete" (optionSelected)="chooseSpoc($event)" [displayWith]="formatSPOC">
            <mat-option *ngFor="let option of filteredSpoc | async" [value]="option">
              {{ formatSPOC(option) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

    </div>
    <div class="mt-2">
      <div class="form-group">
        <mat-slide-toggle formControlName="sendEmail"
                          class="example-margin"
                          color="primary"
                          [disabled]=false
                          [checked]="emailStatus"
                          (change)="change($event)">
          <div class="ActiveButton">Send Email</div>
        </mat-slide-toggle>
      </div>
    </div>
    <div class="d-flex justify-content-end align-items-baseline mt-4">
      <div class="save-skill">
        <button (click)="addContact()" *ngIf="!showloader" class="btn btn-save mx-3 theme-bg-color"
                type="submit">Save details
        </button>
        <div *ngIf="showloader" disabled>
          <button class="btn btn-saveform" disabled>
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="document-wrapper mb-5">
  <div class="mx-height-scroll mt-4">
    <div class="col-md-12 d-flex justify-content-end align-items-baseline mx-0" id='search-box2'>
      <mat-slide-toggle
        class="example-margin"
        color="primary"
        [checked]="showArchived"
        (change)="changeArchived($event)">
        <div class="ActiveButton">Show Archived</div>
      </mat-slide-toggle>
    </div>

    <div *ngIf="customerContacts.length > 0" class="mx-height-scroll mt-4">
      <form>
        <table class="table table-striped" style="cursor:pointer;">
          <thead class="tableview theme-bg-color text-white">
          <tr>
            <th>Name</th>
            <th>Designation</th>
            <th>Email Id</th>
            <th>Mobile Number</th>
            <th>LinkedIn URL</th>
            <th>Vertical</th>
            <th>SPOC</th>
            <th><span class="ms-2"> Email Status </span></th>
            <th>Edit</th>
            <th>Active</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let contact of customerContacts;">
            <tr>
              <td style="margin-right: 45px;text-transform:capitalize;font-size: 14px">
                {{ contact.firstName ?? '---'}}
              </td>
              <td style="margin-right: 45px;font-size: 14px">
                {{ contact.designation }}
              </td>
              <td style="margin-right: 45px;font-size: 14px">
                {{ contact.email }}
              </td>
              <td style="margin-right: 45px;font-size: 14px">
                {{ contact.mobile }}
              </td>
              <td style="margin-right: 45px;font-size: 14px">
                <span (click)="goToLink(contact.linkedIn)"><a style="color: blue; font-style: italic; text-decoration: underline;cursor: pointer" >{{contact.linkedIn}}</a></span>
<!--                {{ contact.linkedIn }}-->
              </td>
              <td style="margin-right: 45px;font-size: 14px">
                {{ contact.vertical }}
              </td>
              <td style="margin-right: 45px;font-size: 14px">
                {{ contact.spoc.firstName + " " + contact.spoc.lastName }}
              </td>
              <td style="margin-right: 45px;font-size: 14px" *ngIf="contact.sentEmailStatus===true ">
               <span class="ms-3"> Email Sent</span>
              </td>
              <td style="margin-right: 45px;font-size: 14px" *ngIf="contact.sentEmailStatus===false ">
                <button (click)="confirmationSendEmail(contact.id)" class="btn btn-save  theme-bg-color"
                        type="submit" >Send Email
                </button>
              </td>
              <td>
                <a (click)="editContact(contact)" class="me-3 action_icon">
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V12H19V10H5V20H12V22H5ZM22.125 17L20 14.875L20.725 14.15C20.9083 13.9667 21.1417 13.875 21.425 13.875C21.7083 13.875 21.9417 13.9667 22.125 14.15L22.85 14.875C23.0333 15.0583 23.125 15.2917 23.125 15.575C23.125 15.8583 23.0333 16.0917 22.85 16.275L22.125 17ZM14 23V20.875L19.3 15.575L21.425 17.7L16.125 23H14Z"
                      fill="#545454"/>
                  </svg>
                </a>
              </td>
              <td style="margin-right: 45px;text-transform:lowercase;font-size: 14px">

                <mat-slide-toggle class="example-margin"
                                  color="primary"
                                  [checked]="contact.active"
                                  (change)="toggleContactStatus($event,contact.id)">
                  <div class="ActiveButton"></div>
                </mat-slide-toggle>

              </td>
            </tr>

          </ng-container>
          </tbody>
        </table>
      </form>
    </div>
    <div>
      <mat-paginator [length]="totalElements"
                     [pageSizeOptions]="[5,10,12,25,50,100]"
                     [pageSize]="pageSize"
                     [hidden]="totalElements==0"
                     aria-label="Select page">
      </mat-paginator>
    </div>
  </div>

  <div *ngIf="customerContacts.length == 0" class="d-flex align-items-center justify-content-center mt-2 fs-6">
    <span>Currently no active Contacts available</span>
  </div>
</div>
