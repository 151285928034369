<!--<ngx-spinner class="position-relative"-->
<!--  bdColor="rgba(255,255,255,1)" [fullScreen]="true">-->
<!--  <div class="loader text-center"><h3>Vendor Portal</h3>-->
<!--    <div class="mt-4 loader1">-->
<!--    <span></span>-->
<!--    <span></span>-->
<!--    <span></span>-->
<!--    <span></span>-->
<!--    <span></span>-->
<!--  </div>-->
<!--  </div>-->
<!--</ngx-spinner>-->
<!--<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="type" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>-->
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  [fullScreen] = "true"
>
  <div class="spinner mb-3">
    <div class="reverse-spinner"></div>
  </div>
  <p style="font-size: 20px; color: white">Loading . . . </p>
</ngx-spinner>
