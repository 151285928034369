<div *ngIf="role!=='TA' && openDialogType!='READONLY'" class="vendor_wrapper">
  <div class="row mb-4">
    <div class="col-md-6">
      <h2 class="mb-4 font-weight-bold">Add Documents</h2>
      <mat-form-field appearance="outline" class="w-100 border-red mb-3">
        <mat-label>Document Label</mat-label>
        <input #autoCompleteInput1 [formControl]="docControl" [(ngModel)]="docTypeName"
               [matAutocomplete]="auto1"
               type="text" matInput placeholder="e.g. Pan Card, Aadhar Card"
               aria-describedby="title" (change)="errorMessage = null">
        <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"
                          [displayWith]="displayDocFn">
          <mat-option *ngFor=" let type of filteredDocs| async"
                      (onSelectionChange)="chooseDocType($event)" [value]="type">
            {{ type.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div *ngIf="(filteredDocs|async)?.length == 0" class="dropdown-skills position-relative">
        <ul>
          <li class="li_disabled">
            No match Found
          </li>
          <hr class="m-0 p-0">
          <li class="font-weight-bold">
            <div class="d-flex justify-content-between align-items-center"
                 (click)="addDocType($event,'doc')">
              <span>Add this Document type:</span>
              <button class="p-0 bg-transparent border-0 fs-20"> +</button>
            </div>
          </li>
        </ul>
      </div>

      <div class="my-3" *ngIf="typeId=='0'">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Enter type of document</mat-label>
          <input [(ngModel)]="docNameOther" type="text" matInput placeholder="Document name..."
                 (change)="errorMessage = null"
                 required>
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline" class="w-100 mb-3">
        <mat-label>Start Date (optional)</mat-label>
        <input [(ngModel)]="documentStartDate" (ngModelChange)="updateEndDateMinDate()"
               [matDatepicker]="startDatePicker" type="text" matInput [readonly]="true" [placeholder]="'Select a date'"
               [value]="documentStartDate | date: 'yyyy-MM-dd'" [attr.aria-label]="'Select a start date'"
               [attr.aria-labelledby]="'start-date-label'"
        >
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>

        <mat-form-field appearance="outline" class="w-100 mb-3">
          <mat-label>End Date (optional)</mat-label>
          <input [min]="minEndDate" [matDatepicker]="endDatePicker" [(ngModel)]="documentExpiryDate" type="text"
                 matInput
                 [readonly]="true" [placeholder]="'Select a date'" [value]="documentExpiryDate | date: 'yyyy-MM-dd'"
                 [attr.aria-label]="'Select a end date'" [attr.aria-labelledby]="'end-date-label'"
                 [disabled]="!documentStartDate"
          >
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>

    </div>
    <div class="col-md-6">
      <div class="border my-auto h-100 rounded-3">
        <div class="upload_doc text-center">
          <div *ngIf="!vendorDocuments" (click)="uploadDocTrigger()" (dragover)="handleDragOver($event)"
               (drop)="onSelectDocument($event,true)">
            <input #docInput style="display: none" type="file" class="form-control-file"
                   (change)="onSelectDocument($event,false)"
                   accept=".png,.doc,.docx,application/msword,application/vnd,application/pdf">
            <h2 class="mb-4 font-weight-bold">Upload document</h2>
            <p class="image-uploader_max-file-size mb-3">Max file size: 10MB (pdf, png, jpeg, xlx, doc,
              docx)</p>
            <div class="drag_drop_wrapper">
              <div><img src="assets/img_1.png" style="height: 40px" class=""/></div>
              <div><span><b>Choose a file</b> or drag it here</span></div>
            </div>
          </div>
          <div *ngIf="vendorDocuments" class="abc">
            <h2 class="mb-4 font-weight-bold">Upload document</h2>
            <p class="image-uploader_max-file-size mb-3">Max file size: 10MB (pdf, png, jpeg, xlx, doc,
              docx)</p>
            <div class="drag_drop_wrapper_upload">
              <div>
                {{ doc?.name }}<br><a
                style="color: #3b5998; font-size: small">Size({{ fileSizeFormattor(doc?.size) }})</a>
              </div>
              <span (click)="deleteConfirmation('Delete',docId,false)"><i
                class="fas fa-times-circle"></i></span>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-5">
    <div *ngIf="docError !== ''" >
      <div class="mb-2 ">
                    <span class="containeroferror">
                      Error : {{ docError }}
                    </span>
      </div>
    </div>
    <div class="form-group btns save-form d-flex justify-content-end align-items-center ">
      <button (click)="onDocupload()" *ngIf="!showloader" class="btn btn-save mx-3 theme-btn-color"
              type="submit">Save details
      </button>
      <div *ngIf="showloader" disabled>
        <button class="btn btn-save theme-btn-color" disabled>
          <div class="spinner-border spinner-border-sm me-2" role="status">
            <span class="sr-only"></span>
          </div>
          Save Details
        </button>
      </div>
    </div>
  </div>
</div>

<div class="document-wrapper mb-5">
  <div class="mx-height-scroll mt-4 mb-3">
    <div class="col-md-12 d-flex justify-content-end align-items-baseline mx-0 mb-3" id='search-box2'>
      <mat-slide-toggle
        class="example-margin"
        color="primary"
        [checked]="showArchived"
        (change)="changeArchived($event)">
        <div class="ActiveButton font-weight-bold">Show In-Active</div>
      </mat-slide-toggle>
    </div>
    <table *ngIf="vendorDocs.length > 0" class="table table-striped">
      <thead class="tableview theme-bg-color">
      <tr>
        <th>Document</th>
        <th>Duration</th>
        <th>Uploaded By</th>
        <th>Notification</th>
        <th *ngIf="role!=='TA' && openDialogType!='READONLY'">Action</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let docs of vendorDocs, let i=index">
        <tr>
          <td>
            <a style=" font-weight: lighter; margin-right: 45px;text-transform:capitalize;font-size: 16px">
              {{ docs.type }}</a><br>
            <a type="link" (click)="goToLink(docs.url)" class="text-primary"
               style="font-size: 16px;font-weight: lighter;">{{ docs.fileName }}</a>
          </td>
          <td><a style="text-transform:capitalize;font-size: 13px ;font-weight: lighter;">
            <a *ngIf="docs.startDate">start date : {{
                docs.startDate| date:
                  ' dd MMM yyyy '
              }}</a>
            <a *ngIf="!docs.startDate" class="ms-3 mt-1">
              -----NA-----
            </a><br>
            <a *ngIf="docs.endDate">End date :
              {{ docs.endDate |date: ' dd MMM yyyy ' }}
            </a>
            <a *ngIf="!docs.endDate && !docs.startDate" class="ms-3 mt-1">
              -----NA-----
            </a>
          </a>
          </td>
          <td>
            <a style="text-transform:capitalize;font-size: 13px ;font-weight: lighter;">
              <a *ngIf="docs.createdBy">created by : {{ docs.createdBy }}</a>
              <a *ngIf="!docs.createdBy" class="ms-3 mt-1">
                -----NA-----
              </a><br>
              <a *ngIf="docs.createdDate">Created date :
                {{ docs.createdDate |date: '  hh:mm a, dd MMM yyyy' }}
              </a>
              <a *ngIf="!docs.createdDate && !docs.createdDate" class="ms-3 mt-1">
                -----NA-----
              </a>
            </a>
          </td>
          <td>
            <div *ngIf="docs.expiryStatus ==null">
            </div>
            <a *ngIf="docs.expiryStatus =='EXPIRED'" type="button" class="me-3 action_icon">
            <svg width="25" height="24" viewBox="0 0 25 24"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.5 19V20H3.50002V19L5.50002 17V11C5.50002 7.9 7.53002 5.17 10.5 4.29V4C10.5 3.46957 10.7107 2.96086 11.0858 2.58579C11.4609 2.21071 11.9696 2 12.5 2C13.0305 2 13.5392 2.21071 13.9142 2.58579C14.2893 2.96086 14.5 3.46957 14.5 4V4.29C17.47 5.17 19.5 7.9 19.5 11V17L21.5 19ZM14.5 21C14.5 21.5304 14.2893 22.0391 13.9142 22.4142C13.5392 22.7893 13.0305 23 12.5 23C11.9696 23 11.4609 22.7893 11.0858 22.4142C10.7107 22.0391 10.5 21.5304 10.5 21M20.25 3.19L18.83 4.61C19.6758 5.44542 20.3473 6.44042 20.8056 7.53732C21.264 8.63422 21.5 9.8112 21.5 11H23.5C23.5 8.07 22.34 5.25 20.25 3.19ZM1.50002 11H3.50002C3.50002 8.6 4.46002 6.3 6.17002 4.61L4.75002 3.19C3.7179 4.21157 2.89909 5.42807 2.34116 6.76881C1.78323 8.10955 1.49732 9.54781 1.50002 11Z"
                fill="red"/>
            </svg>
            </a>
            <a *ngIf="docs.expiryStatus =='EXPIRING_SOON'&& docs.active==true" type="button" class="me-3 action_icon">
            <svg width="25" height="24"
                 viewBox="0 0 25 24"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.5 19V20H3.50002V19L5.50002 17V11C5.50002 7.9 7.53002 5.17 10.5 4.29V4C10.5 3.46957 10.7107 2.96086 11.0858 2.58579C11.4609 2.21071 11.9696 2 12.5 2C13.0305 2 13.5392 2.21071 13.9142 2.58579C14.2893 2.96086 14.5 3.46957 14.5 4V4.29C17.47 5.17 19.5 7.9 19.5 11V17L21.5 19ZM14.5 21C14.5 21.5304 14.2893 22.0391 13.9142 22.4142C13.5392 22.7893 13.0305 23 12.5 23C11.9696 23 11.4609 22.7893 11.0858 22.4142C10.7107 22.0391 10.5 21.5304 10.5 21M20.25 3.19L18.83 4.61C19.6758 5.44542 20.3473 6.44042 20.8056 7.53732C21.264 8.63422 21.5 9.8112 21.5 11H23.5C23.5 8.07 22.34 5.25 20.25 3.19ZM1.50002 11H3.50002C3.50002 8.6 4.46002 6.3 6.17002 4.61L4.75002 3.19C3.7179 4.21157 2.89909 5.42807 2.34116 6.76881C1.78323 8.10955 1.49732 9.54781 1.50002 11Z"
                fill="orange"/>
            </svg>
            </a>
            <a *ngIf=" docs.active==false" type="button" class="me-3 action_icon">
            <svg  width="25" height="24" viewBox="0 0 25 24"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.5 19V20H3.50002V19L5.50002 17V11C5.50002 7.9 7.53002 5.17 10.5 4.29V4C10.5 3.46957 10.7107 2.96086 11.0858 2.58579C11.4609 2.21071 11.9696 2 12.5 2C13.0305 2 13.5392 2.21071 13.9142 2.58579C14.2893 2.96086 14.5 3.46957 14.5 4V4.29C17.47 5.17 19.5 7.9 19.5 11V17L21.5 19ZM14.5 21C14.5 21.5304 14.2893 22.0391 13.9142 22.4142C13.5392 22.7893 13.0305 23 12.5 23C11.9696 23 11.4609 22.7893 11.0858 22.4142C10.7107 22.0391 10.5 21.5304 10.5 21M20.25 3.19L18.83 4.61C19.6758 5.44542 20.3473 6.44042 20.8056 7.53732C21.264 8.63422 21.5 9.8112 21.5 11H23.5C23.5 8.07 22.34 5.25 20.25 3.19ZM1.50002 11H3.50002C3.50002 8.6 4.46002 6.3 6.17002 4.61L4.75002 3.19C3.7179 4.21157 2.89909 5.42807 2.34116 6.76881C1.78323 8.10955 1.49732 9.54781 1.50002 11Z"
                fill="grey"/>
            </svg>
            </a>
          </td>
          <td *ngIf="role!=='TA' && openDialogType!='READONLY'">
            <button id="dropdownMenuButton1" class="btn bg-transparent three_dot_btn" data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    type="button">
              <span class="more-dot"></span>
              <span class="more-dot"></span>
              <span class="more-dot"></span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a *ngIf="docs.active" class="dropdown-item cursor-p"
                   (click)="deleteConfirmation('confirmation',docs.id,true)">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20.37 8.91003L19.37 10.64L7.24 3.64003L8.24 1.91003L11.28 3.66003L12.64 3.29003L16.97 5.79003L17.34 7.16003L20.37 8.91003ZM6 19V7.00003H11.07L18 11V19C18 19.5305 17.7893 20.0392 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H8C7.46957 21 6.96086 20.7893 6.58579 20.4142C6.21071 20.0392 6 19.5305 6 19Z"
                      fill="#ED0F0F"/>
                  </svg>
                  Archive
                </a>
                <a *ngIf="!docs.active" class="dropdown-item cursor-p" (click)="restoreTrashedDocument(docs.id)">
                  <i class='fas fa-trash-restore text-success mx-1'></i>Restore
                </a>
                <a *ngIf="hideWidget(docs.createdDate)" class="dropdown-item cursor-p"
                   (click)="deleteConfirmation('Delete',docs.id,false)">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20.37 8.91003L19.37 10.64L7.24 3.64003L8.24 1.91003L11.28 3.66003L12.64 3.29003L16.97 5.79003L17.34 7.16003L20.37 8.91003ZM6 19V7.00003H11.07L18 11V19C18 19.5305 17.7893 20.0392 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H8C7.46957 21 6.96086 20.7893 6.58579 20.4142C6.21071 20.0392 6 19.5305 6 19Z"
                      fill="#ED0F0F"/>
                  </svg>
                  Delete Permanent
                </a>
              </li>
            </ul>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
  <div *ngIf="vendorDocs.length == 0" class="d-flex align-items-center justify-content-center fs-6 ">
    <span>Currently no active documents available</span>
  </div>
  <mat-paginator [length]="totalDocument"
                 [pageSizeOptions]="[5,12,25,50,100]"
                 (page)="handlePageDocument($event)"
                 [hidden]="totalDocument==0"
                 aria-label="Select page">
  </mat-paginator>
</div>

