<div class="content-wrapper">
  <h4 class="page-title">My Task List</h4>
  <div class="col-md-12">
    <div class="content-wrapper-main-inner-content">
      <div class="row mb-2">
        <div class="col-md-4">
          <div id="data-table_filter" class="dataTables_filter">
            <label class="w-100 search-input">
              <input [(ngModel)]="search" (ngModelChange)="updateSearch()" #input class="form-control"
                     placeholder="Search Tasks"
                     type="search" aria-controls="data-table">
              <button class="search-button" (click)="loadMyTaskList(this.currentPage,this.pageSize,this.filters)"
                      type='button'>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.7479 18.5593L14.0096 12.8169C15.1409 11.4602 15.8213 9.72641 15.8213 7.83277C15.8213 3.51266 12.2758 0 7.91475 0C3.55364 0 0 3.51675 0 7.83687C0 12.157 3.54544 15.6696 7.90655 15.6696C9.7592 15.6696 11.4643 15.0343 12.8169 13.9727L18.5757 19.7315C18.9118 20.0676 19.4118 20.0676 19.7479 19.7315C20.084 19.3954 20.084 18.8954 19.7479 18.5593ZM1.6805 7.83687C1.6805 4.44308 4.47587 1.6846 7.90655 1.6846C11.3372 1.6846 14.1326 4.44308 14.1326 7.83687C14.1326 11.2307 11.3372 13.9891 7.90655 13.9891C4.47587 13.9891 1.6805 11.2266 1.6805 7.83687Z"
                    fill="#808080"/>
                </svg>
              </button>
            </label>
          </div>
        </div>
        <div class="col-md-7 d-flex justify-content-end">
          <div class="mt-2">
            <button class="btn btn-create  theme-btn-color" (click)="clearFilter()">
              <i class="fas fa-redo me-2"></i>
              Reset Filters
            </button>
          </div>
        </div>
        <div class="col-md-1 d-flex justify-content-end">
          <div class="button-style" style="position: relative">
            <button id="more-btn" class="more-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="more-dot"></span>
              <span class="more-dot"></span>
              <span class="more-dot"></span>
            </button>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" (click)="openDialogBox()" style="cursor: pointer;">Change Assigned Person</a>
              </li>
              <li>
                <a class="dropdown-item" (click)="openDialogForMarkAsUnread()" style="cursor: pointer;">Mark as
                  Unread</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div *ngIf="showLoader" class=" d-flex justify-content-center align-item-center mt-5 pt-5">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
      <div class="row" *ngIf="!showLoader">
        <div class="table-responsive col-12 col-sm-12 col-lg-12">
          <table class="table table-striped" style="font-size: 12px !important;">
            <thead class="tableview theme-bg-color">
            <tr>
              <th *ngIf="role === 'ADMIN' || ( role==='SALES_HEAD') || ( role==='MANAGER')">
                <mat-checkbox class="example-margin" [(ngModel)]="selectAll" (change)="toggleSelectAll()"></mat-checkbox>
              </th>
              <th>Task ID</th>
              <th>Start Date & Time</th>
              <th>Customer Name</th>
              <th>Sender Name</th>
              <th>Subject</th>
              <th>Assigned SPOC</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody class="search-results">

            <tr search-filter [searchFields]="searchFieldName" [filters]="filters"
                (filtersChanged)="onFiltersChanged($event)" (dateTypeChanged)="dateTypeChanged($event)"
                (resetFilters)="clearFilter()">

              <td *ngIf="role=='ADMIN' || ( role=='SALES_HEAD' ) && myTaskList.length>0"></td>

            </tr>

            <tr *ngFor="let task of myTaskList, let i=index" class="vendors-listing mb-3 position-relative">
              <td *ngIf="role=='ADMIN' || ( role=='SALES_HEAD' ) || ( role=='MANAGER' )">
                <div *ngIf="task?.status!='COMPLETED'">
                  <mat-checkbox class="example-margin" [checked]="selectedTasks.has(task?.uniqueId)"
                                (change)="selectTasks($event,task)"></mat-checkbox>
                </div>
              </td>
              <td (click)="openDialog(task)">
               <span class="theme-text-color fw-bold overflow-hidden cursor-p" style="cursor: pointer"
                     [ngClass]="!isTaskIdOpened(task?.uniqueId) ? 'text-black fw-bold' : ''">
                 {{ task?.uniqueId }}
               </span>
              </td>
              <td
                [ngClass]="!isTaskIdOpened(task?.uniqueId) ? 'text-black fw-bold' : ''">
                <span class="d-flex flex-column">
                  <span>
                  Started On {{ task ? (task.startDate | date: 'd MMM y hh:mm a') : '' }}
                </span>
                <span *ngIf="task?.closingDate">
                   Closed On {{ task ? (task?.closingDate | date: 'd MMM y hh:mm a') : '' }}
                </span>
                </span>
              </td>
              <td
                [ngClass]="!isTaskIdOpened(task?.uniqueId) ? 'text-black fw-bold' : ''">
                {{ task?.sender ? task?.customer?.companyName : '--' }}
              </td>
              <td
                [ngClass]="!isTaskIdOpened(task?.uniqueId) ? 'text-black fw-bold' : ''">
                {{ task?.sender?.firstName ?? '---' }}
              </td>
              <td [ngClass]="!isTaskIdOpened(task?.uniqueId) ? 'text-black fw-bold' : ''">
                <ng-container *ngIf="task?.subject?.solutionId; else consultantTemplate">
                  {{ task?.subject?.solutionId ?? '' }} | {{ task?.subject?.name ?? '' }}
                </ng-container>

                <ng-template #consultantTemplate>
                  {{ task?.subject?.consultantId ?? '' }} | {{ task?.subject?.firstName ?? '' }} {{ task?.subject?.lastName ?? '' }} | {{ task?.subject?.title ?? '' }}
                </ng-template>
              </td>

              <td
                [ngClass]="!isTaskIdOpened(task?.uniqueId) ? 'text-black fw-bold' : ''">
                {{ (task?.assignee != null) ? task?.assignee?.firstName + ' ' + (task?.assignee?.lastName ?? '') : '--' }}
              </td>
              <td style="width: 13%">
                <label [ngClass]="!isTaskIdOpened(task?.uniqueId) ? 'text-black fw-bold' : ''"
                       class="switch" style="color: #305ccd; font-weight: 500;background-color: #e1e9fd"
                       *ngIf="task?.status=='IN_PROGRESS'">{{ task?.status }} </label>
                <label [ngClass]="!isTaskIdOpened(task?.uniqueId) ? 'text-black fw-bold' : ''"
                       class="switch" style="color: #a08e59; font-weight: 500;background-color: #f8f4c8"
                       *ngIf="task?.status=='ON_HOLD'">{{ task?.status }}</label>
                <label [ngClass]="!isTaskIdOpened(task?.uniqueId) ? 'text-black fw-bold' : ''"
                       class="switch" style="color: #147f49; font-weight: 500;background-color: #dffaed"
                       *ngIf="task?.status=='COMPLETED'">{{ task?.status }}</label>
              </td>
            </tr>
            </tbody>
          </table>
          <div *ngIf="!showLoader && myTaskList.length == 0" class="d-flex justify-content-center mt-8">
            <div>
              <img src="assets/images.png"><br>
              <h4> Result Not Found</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-paginator [length]="totalTasks"
                 [pageSize]="pageSize"
                 [pageSizeOptions]="[10,12,25,50,100]"
                 [hidden]="totalTasks==0"
                 aria-label="Select page">
  </mat-paginator>
</div>

