import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.css']
})
export class DeletePopupComponent implements OnInit {
  data: any;
    constructor(@Inject(MAT_DIALOG_DATA) public inputData: any,  public dialogRef: MatDialogRef<DeletePopupComponent>
  ) {
  }


  ngOnInit(): void {
    this.data = this.inputData;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
