import {MatSort} from "@angular/material/sort";
import {AfterViewInit, Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {NgDialogAnimationService} from "ng-dialog-animation";
import {DataService} from "../../../services/data.service";
import {ApiService} from "../../../services/api.service";
import {myTaskDTO, SearchFilters, userDT} from "../../../shared/interfaces";
import {ToastrService} from "ngx-toastr";
import {MatTableDataSource} from "@angular/material/table";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {MatPaginator} from "@angular/material/paginator";
import {ViewMyTaskComponent} from "./view-my-task/view-my-task.component";
import {DialogboxChangeAssigneeComponent} from "./dialogbox-change-assignee/dialogbox-change-assignee.component";
import {debounceTime, distinctUntilChanged, tap} from "rxjs/operators";
import {fromEvent} from "rxjs";
import {IsDataUpdatedService} from "../../../services/isDataUpdated.service";
import {UnreadTaskService} from "../../../services/getUnreadTask.service";
import {
  ToggleConfirmationDialogboxComponent
} from "../../../util/toggle-confirmation-dialogbox/toggle-confirmation-dialogbox.component";
import {ResetFiltersService} from "../../../services/resetFilters.service";

@Component({
  selector: 'app-my-tasks',
  templateUrl: './my-tasks.component.html',
  styleUrls: ['./my-tasks.component.css'],
})

export class MyTasksComponent implements OnInit, AfterViewInit {
  myTaskList: any[] = [];
  search = '';
  totalPages: number;
  currentPage: number = 0;
  showLoader: boolean = true;
  role: string;
  assigneeList: userDT[];
  selectedTasks: Set<number> = new Set();
  selectAll: boolean = false;
  dataSource = new MatTableDataSource<myTaskDTO>();
  totalTasks = 0;
  pageSize = 12;
  isTaskIdsOpened: number[] = []
  searchFieldName = ['','uniqueId', 'dateSearchCriteria', 'customerName', 'sender', 'subject', 'assignee', 'status'];
  minEndDate: string | null;
  isDropdownOpen = false;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input', {static: true}) input: ElementRef | any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChildren('dropdownDate,dropdownConsultantStatus') dropdowns!: QueryList<ElementRef>;
  filters: any = {
    query: "",
    status: [],
    assigneeId: [],
    customerName: "",
    sender: "",
    subject: "",
    assignee: "",
    uniqueId: null,
    dateSearchCriteria: {
      filter: "STARTING",
      from: "",
      to: ""
    }
  };
  constructor(
    public dialog: NgDialogAnimationService,
    private dataService: DataService,
    private service: ApiService,
    private toastr: ToastrService,
    private isMyTaskDataUpdated: IsDataUpdatedService,
    private unReadTaskService: UnreadTaskService,
    private resetFilters: ResetFiltersService,
  ) {
  }

  ngOnInit(): void {
    this.loadMyTaskList(this.currentPage, this.pageSize, this.filters);
    this.getCustomerClients();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.page.pipe(
      tap(() => {
        this.updateSelectAllState();
        this.loadMyTaskList(this.paginator.pageIndex, this.paginator.pageSize, this.filters);
      })
    ).subscribe();
    if (this.input) {
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => {
            this.filters.query = this.search;
            this.paginator.pageIndex = 0;
            this.loadMyTaskList(this.paginator.pageIndex, this.paginator.pageSize, this.filters);
          })
        )
        .subscribe();
    }
  }

  updateSearch() {
    this.filters.query = this.search;
    this.paginator.pageIndex = 0;
    this.loadMyTaskList(this.paginator.pageIndex, this.paginator.pageSize, this.filters);
  }

  toggleSelectAll() {
    if (this.selectAll) {
      this.myTaskList.forEach((task) => {
        const status = task.status.trim().toUpperCase();
        if (status === "IN_PROGRESS" || status === "ON_HOLD") {
          this.selectedTasks.add(task.uniqueId);
        }
      });
    } else {
      this.myTaskList.forEach((task) => {
        this.selectedTasks.delete(task.uniqueId);
      });
    }
    this.updateSelectAllState();
  }

  updateSelectAllState() {
    const filteredTaskList = this.myTaskList.filter(
      (task) => task?.status?.trim()?.toUpperCase() === "IN_PROGRESS" || task?.status?.trim()?.toUpperCase() === "ON_HOLD");
    this.selectAll = filteredTaskList.length > 0 && filteredTaskList.every((task) => this.selectedTasks.has(task?.uniqueId));
  }

  getCustomerClients() {
    this.service.getSpocList().subscribe((res: any) => {
      this.assigneeList = res;
    })
  }

  loadMyTaskList(page: number, size: number, filter: SearchFilters) {
    this.role = localStorage.getItem('role') ?? '';
    if ((this.role == 'ADMIN') || ('SALES_HEAD' == this.role) || ('BSM' == this.role) || ('MANAGER' == this.role)) {
      this.service.getAllAdminMyTasks(page, size, filter).subscribe((response) => {
        this.myTaskList = response.content;
        this.isTaskIdsOpened = this.myTaskList?.filter(task => task?.hasBeenRead)?.map(task => task?.uniqueId);
        this.totalPages = response.page.totalPages;
        this.currentPage = response.page.number
        this.showLoader = false;
        this.totalTasks = response.page.totalElements
        this.paginator.pageIndex = response.page.number;
        this.paginator.pageSize = response.page.size;
        this.pageSize = response.page.size;
        this.updateSelectAllState();
      });
    }
  }

  openDialog(myTaskData: any): void {
    if (myTaskData?.uniqueId && !this.isTaskIdsOpened.includes(myTaskData.uniqueId)) {
      this.service.markTaskAsRead(myTaskData?.uniqueId).subscribe((res) => {
        this.isTaskIdsOpened.push(myTaskData?.uniqueId);
        this.unReadTaskService.emitChange(true);
      });
    }
    const dialogRef = this.dialog.open(ViewMyTaskComponent, this.dataService.dialogConfig({
      id: myTaskData.uniqueId,
      myTask: myTaskData,
    }));
    dialogRef.beforeClosed().subscribe((res) => {
      if (this.isMyTaskDataUpdated.getBoolean()) {
        this.loadMyTaskList(this.currentPage, this.pageSize, this.filters);
        this.isMyTaskDataUpdated.setUpdated(false);
      }
    });
  }

  isTaskIdOpened(id: number) {
    return this.isTaskIdsOpened.includes(id);
  }

  selectTasks(event: MatCheckboxChange, task: myTaskDTO) {
    if (event.checked) {
      this.selectedTasks.add(task.uniqueId);
    } else {
      this.selectedTasks.delete(task.uniqueId);
    }
    this.updateSelectAllState();
  }

  openDialogBox() {
    this.isDropdownOpen = false;
    if (this.selectedTasks.size > 0) {
      const dialogRef = this.dialog.open(DialogboxChangeAssigneeComponent, this.dataService.AssigneeConfig({
        selectedTasks: this.selectedTasks,
        showOnlyActiveMembers: true
      }));
      dialogRef.afterClosed().subscribe(result => {
        this.selectedTasks.clear(); /* Shreya - This should be clear when api call hit and assigned value is change, PLease handle it accordinalty */
        this.loadMyTaskList(this.currentPage, this.pageSize, this.filters);
        this.selectAll = false;
      });
    } else {
      this.toastr.info('Please select the task on click of Check box provided in the list', 'Alert', {
        positionClass: "toast-bottom-right"
      });
    }
  }

  openDialogForMarkAsUnread() {
    this.isDropdownOpen = false
    if (this.selectedTasks.size > 0) {
      const dialogRef = this.dialog.open(ToggleConfirmationDialogboxComponent, {
        data: {
          title: 'Alert',
          message: 'Are you sure you want to mark all selected tasks as unread',
          type: 'confirmation'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.markTaskAsUnread(Array.from(this.selectedTasks));
        }
      });
    } else {
      this.toastr.info('Please select the task on click of Check box provided in the list', 'Alert')
    }
  }

  markTaskAsUnread(taskIds: number[]) {
    this.service.markTaskAsUnRead(taskIds).subscribe((res) => {
      this.selectedTasks.clear();
      this.selectAll = false;
      this.isTaskIdsOpened = this.isTaskIdsOpened.filter(id => !taskIds.includes(id));
      this.toastr.success(`Marked ${taskIds.length} tasks as unread successfully!`);
      this.unReadTaskService.emitChange(true);
    });
  }

  closeAllDropdowns() {
    this.dropdowns.forEach(dropdown => {
      const dropdownMenu = dropdown.nativeElement.querySelector('.dropdowns-menu');
      dropdownMenu.style.display = 'none';
    });
  }

  isSelected(option: string, selectedItems: string[]): boolean {
    return selectedItems.includes(option);
  }

  dateTypeChanged(event: any) {
    if(event === true){
      this.filters.dateSearchCriteria.filter = "CLOSING";
    } else {
      this.filters.dateSearchCriteria.filter = "STARTING";
    }
  }

  clearFilter() {
    this.filters = {
      query: "",
      status: [],
      assigneeId: [],
      customerName: "",
      sender: "",
      subject: "",
      assignee: "",
      uniqueId: null,
      dateSearchCriteria: {
        filter: "STARTING",
        from: "",
        to: ""
      }
    };
    this.resetFilters.emitChange(true);
    this.loadMyTaskList(0, this.pageSize, this.filters);
  }

  onFiltersChanged(updatedFilters: any) {
    this.filters = {
      ...updatedFilters,
      query: this.search
    };
    this.paginator.pageIndex = 0;
    this.filters.query = this.search;
    const fromDate = this.filters.dateSearchCriteria.from;
    const toDate = this.filters.dateSearchCriteria.to;
    const datesValid = (fromDate && toDate) || (!fromDate && !toDate);
    if (datesValid) {
      this.loadMyTaskList(0, this.pageSize, this.filters);
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = this.dropdowns.some(dropdown => dropdown.nativeElement.contains(event.target));
    const clickedOnDatePicker = (event.target as Element).closest('mat-datepicker') !== null;
    const clickedOnDatePickerOrDescendant = (event.target as Element).closest('.mat-datepicker,.mat-datepicker-popup') !== null;

    if (!clickedInside && !clickedOnDatePicker && !clickedOnDatePickerOrDescendant) {
      this.closeAllDropdowns();
    }
  }

  protected readonly event = event;
}
