import {ChangeDetectorRef, Component,  ElementRef, forwardRef, HostListener, Input, OnInit, ViewChild,} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TextEditorService} from "../../../../services/textEditor.service";
import Quill from "quill";
const fontSizeArr = [
  '2px', '4px', '6px', '8px', '10px', '12px', '14px', '16px', '18px', '20px',
  '22px', '24px', '26px', '28px', '30px', '32px', '34px', '36px', '38px', '40px',
  '42px', '44px', '46px', '48px', '50px', '52px', '54px', '56px', '58px', '60px',
  '62px', '64px', '66px', '68px', '70px', '72px', '74px', '76px', '78px', '80px',
  '82px', '84px', '86px', '88px', '90px', '92px', '94px', '96px', '98px', '100px'
];
const Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

const FontAttributor = Quill.import('attributors/class/font');
FontAttributor.whitelist = [
  'Poppins',
  'serif',
  'sans-serif',
  'Arial',
  'Helvetica',
  'monospace'
];
Quill.register(FontAttributor, true);

@Component({
  selector: 'app-expandable-texteditor',
  templateUrl: './expandable-texteditor.component.html',
  styleUrls: ['./expandable-texteditor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ExpandableTexteditorComponent),
      multi: true
    }
  ],
})
export class ExpandableTexteditorComponent implements OnInit {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() formControl: FormControl;
  isTextareaVisible = false;
  @ViewChild('textarea') textarea: ElementRef;

  // Quill toolbar configuration with pixel-based font sizes
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ font: ['Poppins', 'serif', 'sans-serif', 'Arial','Helvetica', 'monospace'] }],
        [{ 'size': fontSizeArr }],
        [{ 'align': [] }],
        ['link', 'image'],
      ],
    },
  };

  quillInstance: any;
  private quillInstanceReady = false;
  private _value: string = '';
  private onChange: (value: string) => void = () => {
  };
  private onTouched: () => void = () => {
  };

  constructor(
    private elRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private textEditorService: TextEditorService
  ) {
  }

  ngOnInit(): void {
    this.textEditorService.activeEditor$.subscribe(activeEditor => {
      if (activeEditor !== this && this.isTextareaVisible) {
        this.hideTextarea();
      }
    });
  }

  onEditorCreated(quill: any): void {
    this.quillInstance = quill;
    this.quillInstanceReady = true;
  }

  showTextarea(event: MouseEvent): void {
    event.stopPropagation();
    this.textEditorService.setActiveEditor(this); // Pass the current instance
    this.isTextareaVisible = true;
    setTimeout(() => {
      if (this.quillInstanceReady) {
        const length = this.quillInstance.getLength();
        this.quillInstance.setSelection(length, length);
        this.quillInstance.focus();
      }
      this.cdr.detectChanges();
    }, 200);
  }

  hideTextarea(): void {
    this.isTextareaVisible = false;
    this.cdr.detectChanges();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = this.elRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.hideTextarea();
    }
  }

  writeValue(value: string): void {
    if (value !== this._value) {
      this._value = value;
      this.formControl.setValue(value, {emitEvent: false});
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  handleSelectionChange(event: Event) {
    console.log(event);
  }

  stripHtmlTags(html: string): string {
    return html?.replace(/<[^>]*>/g, ' ');
  }
}
