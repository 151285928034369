<div class="vendor-search card border-0 flex-column">
  <div class="filter-heading">
    <h5 class="mb-3">Search by Filter</h5>

    <!--    <label *ngIf="countOfSkills>0">-->
    <!--      skills: {{countOfSkills}}-->
    <!--    </label>-->

    <div class="my-3">
      <div *ngIf="showFilter==false" class="d-flex justify-content-between filter_apply">
        <h6>Filters applied shown here</h6>
      </div>
      <div *ngIf="selectedFilterSkills.length>0 || selectedFilterLocation.length>0" class="d-flex justify-content-between align-items-center">
        <div> <h6>Applied Filters</h6></div>
        <button (click)="reset()" class="btn btn-reset ">Clear all <a>
          <svg width="10" height="10" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
            <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </a>
        </button>
      </div>
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let filter of selectedFilterSkills, index as i"
                  class="btn btn-filter theme-bg-color mx-2 align-items-center">
          {{filter.title}}
          <button matChipRemove class="mb-2" (click)="removeFilter(i,filter,'skills')"
                  style="color: #FFFFFF;opacity: 1">
            <mat-icon style="color: #FFFFFF;opacity: 1">
              <svg width="9" height="10" viewBox="0 0 9 10" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M7.49407 1.73789L1.13196 8.12448" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M1.11972 1.75013L7.50631 8.11224" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </mat-icon>
          </button>
        </mat-chip>
      </mat-chip-list>
    </div>

    <span *ngIf="selectedFilterLocation.length>0">
       <mat-chip-list #chipList>
        <mat-chip *ngFor="let filter of selectedFilterLocation, index as i" class="btn btn-filter mx-2 theme-bg-color">
         {{filter.name}}
          <button matChipRemove class="mb-2" (click)="removeFilter(i,filter,'locations')"
                  style="color: #FFFFFF;opacity: 1">
            <mat-icon style="color: #FFFFFF;opacity: 1"><svg width="9" height="10" viewBox="0 0 9 10" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
            <path d="M7.49407 1.73789L1.13196 8.12448" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M1.11972 1.75013L7.50631 8.11224" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            </svg>
            </mat-icon>
          </button>
        </mat-chip>
      </mat-chip-list>
    </span>

    <hr/>
  </div>
  <div class="filter-section-content" *ngIf="selectedFilterSkills || selectedFilterLocation">
    <h5 class="mb-3">Skills</h5>
    <div class="skill-search">
      <ul style="list-style: none;margin: 0;padding: 0;" class="mb-2">
        <li *ngFor="let skill of defaultFilters?.skills">
          <div class="selected_chips">
            <div class="badges" (click)="skillsMethod(skill)">{{skill?.title}}</div>
            <!--//(click)="updateFilters(skill,$event,'skills')"-->
          </div>
        </li>
      </ul>
      <mat-form-field *ngIf="showSkill==true" appearance="outline" class="w-100 border-red">
        <mat-label>Add Skill</mat-label>
        <input (keyup)="searchSkills()" [(ngModel)]="skillQuery" id="skills"
               type="text" matInput placeholder="e.g.Java, Tally, Python"
               aria-describedby="title">
      </mat-form-field>
      <app-suggestiobn-box [type]="'skills'" (selectedRecord)="skillsMethod($event)"
                           [records]="skillsResForSearch"></app-suggestiobn-box>
      <div class="d-flex justify-content-end">
        <button class="btn btn-add mt-2 theme-bg-color" (click)="showSkillButton()">
          <span>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8203 5.92281L1.80565 5.93954" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M6.30463 1.42383L6.32136 10.4385" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
          Add skill
          </span>
        </button>
      </div>
    </div>
    <hr/>
    <h5 class="mb-3">Location</h5>
    <div class="skill-search">
      <ul style="list-style: none;margin: 0;padding: 0;">
        <li *ngFor="let location of defaultFilters?.locations">
          <div class="selected_chips">
            <div class="badges"
                 (click)="selectedLocation(location)">{{location?.formattedAddress ? location?.formattedAddress : location?.name}}</div>
          </div>
        </li>
      </ul>
    </div>
    <mat-form-field *ngIf="showLocation==true" appearance="outline" class="w-100 border-red">
      <mat-label>Add Location</mat-label>
      <input (keyup)="searchStates()" [(ngModel)]="locationQuery" id="locations"
             type="text" matInput placeholder="Search for location"
             aria-describedby="title">
    </mat-form-field>
    <app-suggestiobn-box [type]="'locations'" (selectedRecord)="selectedLocation($event)"
                         [records]="locationsResForSearch"></app-suggestiobn-box>
    <div class="d-flex justify-content-end">
      <button (click)=" showLocationButton();addNewLocations=true;locationQuery='';locationsResForSearch=[]"
              class="btn btn-add theme-bg-color mt-2">
      <span><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8203 5.92281L1.80565 5.93954" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M6.30463 1.42383L6.32136 10.4385" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg> Add location</span>
      </button>
    </div>
    <div class="d-flex justify-content-end align-items-baseline mt-5"
         style="border-top: 1px solid rgb(218, 220, 224); padding-top: 10px;">
      <button (click)="reset()" class="btn btn-reset">Reset</button>
      <button (click)="filterVendorsBySkill(this.currentPage)" class="btn btn-submit theme-bg-color">Show
        results
      </button>
    </div>
  </div>
</div>
