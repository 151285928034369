import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {ImageCropperComponent} from "../../image-cropper/image-cropper.component";
import {ActivatedRoute} from "@angular/router";
import {ImageStorageService} from "../../../../services/image-storage.service";

@Component({
  selector: 'app-solution-settings',
  templateUrl: './solution-settings.component.html',
  styleUrls: ['./solution-settings.component.css']
})
export class SolutionSettingsComponent implements OnInit,OnChanges {
  @Input() consultantData: any;
  @ViewChild('fileInput') fileInput: ElementRef<HTMLDivElement>;
  file: File | null;
  logoLoader = false;
  type = '';
  logoName: any;
  consultantId: number;
  showLoader=false;
  formData: FormData;
  imageSrc!: string;
  @Input() data!: any;
  @Input() createSolutionId!: number;
  solutionId: any;

  constructor(
    private service: ApiService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private imageStorageService: ImageStorageService
  ) {

  }

  ngOnInit(): void {
    this.solutionId = this.route?.snapshot?.params['solutionId'];

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      this.imageSrc = this.data?.logoUrl
    }
  }

  uploadFileTrigger(): void {
    this.fileInput.nativeElement.click();
  }

  onSelectLogo(event: any, dragEvent?: boolean): void {
    event.preventDefault();
    if (dragEvent) {
      this.file = event.dataTransfer.files[0];
    } else {
      if (event.target.files && event.target.files.length > 0) {
        this.file = event.target.files[0];
      } else {
        this.file = null;
        return;
      }
    }
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const disallowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    if (this.file !== null) {
      if (allowedTypes.includes(this.file.type)) {
        const dialogRef3 = this.dialog.open(ImageCropperComponent, {
          width: '60%',
          data: { file: this.file }
        });
        dialogRef3.afterClosed().subscribe((result: any) => {
          if (result) {
            if (result.blob instanceof Blob) {
              this.handleBlobResult(result.blob);
            } else if (result.base64) {
              this.handleBase64Result(result.base64);
            } else {
              this.resetFile();
            }
          } else {
            this.resetFile();
          }
          (event.target as HTMLInputElement).value = '';
        });
      } else if (disallowedTypes.includes(this.file.type)) {
        this.toastr.error('PDF and DOC files are not allowed.', 'Error');
        this.logoLoader = false;
      } else {
        this.toastr.error('Unsupported file type.', 'Error');
        this.logoLoader = false;
      }
    }
  }

  handleBlobResult(blob: Blob): void {
    const filename = this.file?.name || 'cropped_image.png';
    const file = new File([blob], filename, { type: blob.type });
    const formData: FormData = new FormData();
    formData.append('name', file?.name);
    formData.append('file', file);
    this.formData = formData;
    this.logoName = file.name;
  }

  handleBase64Result(base64: string): void {
    const file = this.base64ToFile(base64, this.file?.name || 'cropped_image.png');
    if (file) {
      const formData: FormData = new FormData();
      formData.append('name', file?.name);
      formData.append('file', file);
      this.formData = formData;
      this.logoName = file?.name;
    } else {
      this.resetFile();
    }
  }

  resetFile(): void {
    this.file = null;
    this.logoName = '';
  }

  base64ToFile(data: string, filename: string): File | null {
    if (!data) {
      return null;
    }
    const arr = data.split(',');
    if (arr.length < 2) {
      return null;
    }
    const mimeMatch = arr[0].match(/:(.*?);/);
    if (!mimeMatch) {
      return null;
    }
    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  onUpload() {
    if (!this.file) {
      this.toastr.info('Please select an image.');
      return;
    }
    this.logoLoader = true;
    this.service.uploadLogoForSolution(this.solutionId ?? this.createSolutionId,this.file).subscribe({
      next: (res) => {
        this.imageSrc = res.logoUrl;
        this.imageStorageService.setImageSrc(this.imageSrc);
        this.toastr.success('File uploaded successfully', 'Success');
        this.logoLoader = false;
      },
      error: (e) => {
        this.logoLoader = false;
        this.toastr.error('Failed to upload file', 'Error');
        console.error('Error uploading file:', e);
      }
    });
  }

  handleDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  deleteConfirmation() {
    this.imageSrc = ''
    this.file = null;
    this.imageStorageService.setImageSrc(this.imageSrc);
    this.deleteLogo(this.createSolutionId ?? this.solutionId)
  }

  toggleActiveSolution(event: any) {
    if (event.checked) {
      this.service.markSolutionAsActive(this.solutionId ?? this.createSolutionId).subscribe(
        {
          next: (res) => {
            this.toastr.success('Solution marked as active', 'Success');
          },
          error: (e: any) => {
            this.toastr.error('Failed to mark solution as active', 'Error');
          }
        }
      );
    } else {
      this.service.markSolutionAsInActive(this.solutionId ?? this.createSolutionId).subscribe(
        {
          next: (res) => {
            this.toastr.success('Solution marked as inactive', 'Success');
          },
          error: (e: any) => {
            this.toastr.error('Failed to mark solution as inactive', 'Error');
          }
        }
      );
    }
  }

  toggleSearchEnable(event: any) {
    if (event.checked) {
      this.service.enableSearchForSolution(this.solutionId ?? this.createSolutionId).subscribe(
        {
          next: (res) => {
            this.toastr.success('Search enabled for solution', 'Success');
          },
          error: (e: any) => {
            this.toastr.error('Failed to enable search for solution', 'Error');
          }
        }
      );
    } else {
      this.service.disableSearchForSolution(this.solutionId ?? this.createSolutionId).subscribe(
        {
          next: (res) => {
            this.toastr.success('Search enable for solution', 'Success');
          },
          error: (e: any) => {
            this.toastr.error('Failed to enable search for solution', 'Error');
          }
        }
      );
    }
  }

  deleteLogo(solutionId:number){
    this.service.deleteLogoForSolution(solutionId).subscribe({
      next:(res) => {
        this.toastr.success('Logo deleted successfully', 'Success');
      },
      error:(e) => {
        this.toastr.error('Failed to delete logo', 'Error');
      }
    })
  }

  delete(){
    this.logoName = '';
    this.file = null;
    this.imageSrc = ''
  }

}
