import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabs-footer',
  templateUrl: './tabs-footer.component.html',
  styleUrls: ['./tabs-footer.component.css']
})
export class TabsFooterComponent implements OnInit {
  errorMessage: string | null;
  type = '';
  win: boolean | null;
  visible1 = true;
  visible2 = false;
  constructor() { }

  ngOnInit(): void {
  }

}
