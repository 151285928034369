import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnreadTaskService {
  private hasTaskIdBeenOpened = new BehaviorSubject<boolean>(false);
  hasTaskIdBeenClicked = this.hasTaskIdBeenOpened.asObservable();

  emitChange(hasChanged: boolean) {
    this.hasTaskIdBeenOpened.next(hasChanged);
  }
}
