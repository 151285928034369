import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../../../services/api.service";
import { FormGroup, FormBuilder } from '@angular/forms';
import { DataService } from "../../../services/data.service";
import { SidebarComponent } from "../../sidebar/sidebar.component";
import {ImageCropperComponent} from "../image-cropper/image-cropper.component";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.css']
})
export class ManageProfileComponent implements OnInit {

  company: any;
  companyImage: '' | null;
  type = '';
  organisationForm: FormGroup;
  logoLoader = false;
  showloader = false;
  errorMessage = '';
  loading = false;
  file: File | null;
  data: any;
  orgId: any;
  role: any;

  @ViewChild('fileInput') fileInput: ElementRef<HTMLDivElement>;
  @ViewChild(SidebarComponent) sidebar: SidebarComponent;

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private service: ApiService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    ) {
  }

  ngOnInit(): void {

    this.dataService.isLoading.next(true);
    this.orgId = localStorage.getItem('orgId');
    this.role = localStorage.getItem('role');
    this.organisationForm = this.fb.group({
      name: [""],
      webUrl: "",
    });
    this.service.getOrganisation(this.orgId).subscribe({
      next: res => {
        this.company = res;
        this.companyImage = res.logo;
        this.organisationForm.get("name")?.setValue(res.name);
        this.organisationForm.get("webUrl")?.setValue(res.webUrl);
        this.loading = false;
          console.log('Logo:', res);
      },
      error: err => {

      },
      complete: () =>{
        this.dataService.isLoading.next(false);
      }
    });
  }

  uploadFileTrigger(): void {
    this.fileInput.nativeElement.click();
  }


  onSelectFile(event: any, dragEvent?: boolean): void {
    event.preventDefault();
    this.logoLoader = true;
    this.file = this.getFileFromEvent(event, dragEvent);
    if (!this.file) {
      this.logoLoader = false;
      return;
    }
    if (this.isAllowedFileType(this.file.type)) {
      this.openImageCropperDialog(this.file, event);
    } else {
      this.handleUnsupportedFileType(this.file.type);
    }
  }

  getFileFromEvent(event: any, dragEvent?: boolean): File | null {
    if (dragEvent) {
      return event.dataTransfer?.files[0] || null;
    } else {
      return event.target?.files?.[0] || null;
    }
  }

  isAllowedFileType(fileType: string): boolean {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg',];
    const disallowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    return allowedTypes.includes(fileType) && !disallowedTypes.includes(fileType);
  }

  openImageCropperDialog(file: File, event: any): void {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      width: '60%',
      data: { file }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.processCropperResult(result);
      } else {
        this.handleDialogCloseWithoutResult();
      }
      (event.target as HTMLInputElement).value = '';
    });
  }

  processCropperResult(result: any): void {
    if (result.blob instanceof Blob) {
      this.handleBlobResult(result.blob);
    } else if (result.base64) {
      this.handleBase64Result(result.base64);
    } else {
      console.error('Result is not a valid Blob or base64 string:', result);
      this.resetFile();
    }
  }

  handleUnsupportedFileType(fileType: string): void {
    const errorMessage = fileType.includes('pdf') || fileType.includes('doc') ? 'PDF and DOC files are not allowed.' : 'Unsupported file type.';
    console.error(errorMessage);
    this.toastr.error(errorMessage, 'Error');
    this.logoLoader = false;
  }

  handleBlobResult(blob: Blob): void {
    const filename = this.file?.name || 'cropped_image.png';
    const file = new File([blob], filename, { type: blob.type });
    this.uploadFile(file);
  }

  handleBase64Result(base64: string): void {
    const file = this.base64ToFile(base64, this.file?.name || 'cropped_image.png');
    if (file) {
      this.uploadFile(file);
    } else {
      console.error('Failed to convert base64 to file');
      this.resetFile();
    }
  }

  uploadFile(file: File): void {
    const formData: FormData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);
    this.service.uploadCompanyLogo(formData, this.orgId).subscribe((res => {
      if (res.type == 4) {
        this.updateCustomerData(res);
      }
    }));
  }

  updateCustomerData(res: any): void {
    this.logoLoader = false;
    this.companyImage = res.body.logo;
    this.dataService.companyImage.next(res.body.logo);
    this.company.logo = this.companyImage;
    window.location.reload();
  }

  resetFile(): void {
    this.file = null;
    this.logoLoader = false;
  }

  base64ToFile(data: string, filename: string): File | null {
    if (!data) {
      console.error('No data provided to base64ToFile');
      return null;
    }

    const arr = data.split(',');
    if (arr.length < 2) {
      console.error('Invalid base64 data:', data);
      return null;
    }

    const mimeMatch = arr[0].match(/:(.*?);/);
    if (!mimeMatch) {
      console.error('Invalid MIME type in base64 data:', arr[0]);
      return null;
    }

    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    const u8arr = new Uint8Array(bstr.length);
    for (let i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  }

  handleDialogCloseWithoutResult(): void {
    console.error('Dialog closed without result');
    this.resetFile();
  }

  delete(): void {
    this.service.deleteCompanyLogo(this.orgId).then((res => {
      this.companyImage = null;
      this.dataService.companyImage.next('');
      window.location.reload();
    }));
  }

  updateOrganisation(): void {
    if(this.role=='ADMIN'){
      this.showloader = true;
      this.service. updateOrganisation(this.orgId, this.organisationForm.value).then(res => {
        this.showloader = false;
        this.dataService.companyName.next(res.name);
      }, (e) => {
        this.showloader = false;
        if (e.error.status == 400) {
          this.errorMessage = e.error.message;
        } else if (e.error.status == 500) {
          this.errorMessage = e.error.message;
        } else if (e.error.status == 409) {
          this.errorMessage = e.error.message;
        } else {
          this.errorMessage = 'Error occurred,Please contact server team';
        }
      });
    }
  }
  handleDragOver(event: DragEvent): void {
    event.preventDefault(); // Prevent the default behavior
  }
}
