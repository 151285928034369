import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsDataUpdatedClientService {
  private isClientDataUpdated = new BehaviorSubject<boolean>(false);
  isUpdated = this.isClientDataUpdated.asObservable();
  constructor() {}

  setUpdated(value: boolean) {
    console.log('value',value)
    this.isClientDataUpdated.next(value);
  }

  getBoolean(): boolean {
    return this.isClientDataUpdated.value;
  }

}
