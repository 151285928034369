import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ComponentName, Consultant, FormUpdateEvent, Skill} from "../../../../shared/interfaces";
import {MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {PopUpComponent} from "../../../../util/pop-up/pop-up.component";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Observable} from "rxjs/internal/Observable";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {Subject} from "rxjs";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../../../../services/api.service";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {map, startWith} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";

@Component({
  selector: 'app-consultant-skills',
  templateUrl: './consultant-skills.component.html',
  styleUrls: ['./consultant-skills.component.css']
})
export class ConsultantSkillsComponent implements OnInit {
  rating: number[] = [];
  skillControl = new FormControl('');
  skillControl2 = new FormControl('');
  consultant: Consultant = {} as any;
  type = '';
  skillForm: FormGroup;
  showloader = false;
  errorMessage: string | null;
  field = '';
  primarySkills: Skill[] | any = [];
  secondarySkills: Skill[] | any = [];
  Rating: any;
  file: File;
  doc: File | any;
  size: any;
  length: any;
  notes: any[] = [];
  audit: any[] = [];
  skillName = '';
  skillName2 = '';
  skillsRes: Skill[] = [];
  selectedSkill: Skill[] = [];
  selectedSkillSecondary: Skill[] = [];
  visible2 = false;
  currentPage: number = 0;
  totalPages: number;
  orgId: any;
  pageSize = 4;
  status: boolean;
  active = false;
  role: string | null;
  consultantRating: string | null;
  consultantRatingForm: FormControl;
  filteredSkills: Observable<Skill[]>;
  secondaryFilteredSkills: Observable<Skill[]>;
  primaryLoading: boolean | false | undefined
  secondaryLoading: boolean | false | undefined
  @Input() data: any;
  @Input() primarySkill: Skill[];
  @Input() secondarySkill: Skill[];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  @ViewChild("chipGrid") chipGrid: ElementRef;
  @ViewChild("chipGrid2") chipGrid2: ElementRef;
  showArchived: boolean = false;
  protected _onDestroy = new Subject<void>();
  disabledSkills: Skill[] = [];
  previousFormValue: any;
  initializingForm: boolean = true;
  @Output() formUpdated = new EventEmitter<FormUpdateEvent>();
  exactMatchFoundPrimary: boolean = false;
  exactMatchFoundSecondary: boolean = false;
  @ViewChild('primarySkillAutoCompleter', {read: MatAutocompleteTrigger})
  primarySkillAutoCompleter: MatAutocompleteTrigger;
  @ViewChild('secondarySkillAutoCompleter', {read: MatAutocompleteTrigger})
  secondarySkillAutoCompleter: MatAutocompleteTrigger;

  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    private dialogRef: MatDialogRef<ConsultantSkillsComponent>,
    public dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar,
    private toastr: ToastrService,
    private isDataUpdatedService: IsDataUpdatedService,
  ) {
    this.rating = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    this.consultantRatingForm = new FormControl();
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.getSkillType();
    this.consultant.id = this.data.consultantId;
    if (this.data.id && this.data.consultantData) {
      this.primarySkills = this.primarySkill ?? [];
      this.secondarySkills = this.secondarySkill ?? [];
      this.consultantRating = this.data.consultantData?.communicationRating;
      this.consultantRatingForm.setValue(this.consultantRating);
    }
    this.previousFormValue = {
      primarySkills: [],
      secondarySkills: []
    };
  }

  openAutocomplete(trigger: MatAutocompleteTrigger) {
    if (trigger) {
      trigger.openPanel();
    }
  }
  scrollEvent = (event: any): void => {
    if (this.primarySkillAutoCompleter?.panelOpen) {
      this.primarySkillAutoCompleter.updatePosition();
    } else if (this.secondarySkillAutoCompleter?.panelOpen) {
      this.secondarySkillAutoCompleter.updatePosition();
    }
  };
  addRating(rate: any): void {
    this.service.updateConsultantRating(this.data.id, rate)
      .subscribe((res) => {
        this.isDataUpdatedService.setUpdated(true);
        this.data = res;
        this.toastr.success('Modified English Communication & Articulation Rating', 'Success');
      }, (e) => {
        console.error('error', e.error.message);
        this.toastr.error(e.error.message, 'Request Failed');
      })
  }

  getConsultantById() {
    this.service.getConsultant(this.data.id).subscribe(res => {
      this.primarySkills = res.primarySkills;
      this.secondarySkills = res.secondarySkills;
    });
  }

  removeSkill(skill: any): void {
    const index = this.selectedSkill.indexOf(skill);
    if (index >= 0) {
      this.selectedSkill.splice(index, 1);
    }
    this.checkAndEmitChanges();
  }

  removeSecondarySkill(skill: any): void {
    const index = this.selectedSkillSecondary.indexOf(skill);
    if (index >= 0) {
      this.selectedSkillSecondary.splice(index, 1);
    }
    this.checkAndEmitChanges();
  }

  selectSkills(event: MatAutocompleteSelectedEvent): void {
    const skill = event.option.value;
    this.selectedSkill.push(skill);
    this.chipGrid.nativeElement.value = '';
    this.skillControl.setValue(null);
    this.disabledSkills.push({ ...skill, disabled: true });
    this.checkAndEmitChanges();
  }

  selectSkillsSecondary(event: MatAutocompleteSelectedEvent): void {
    const skill = event.option.value;
    this.selectedSkillSecondary.push(skill);
    this.chipGrid2.nativeElement.value = '';
    this.skillControl2.setValue('');
    this.disabledSkills.push({ ...skill, disabled: true });
    this.checkAndEmitChanges();
  }

  checkAndEmitChanges(): void {
    const primaryChanged = JSON.stringify(this.selectedSkill) !== JSON.stringify(this.previousFormValue.primarySkills);
    const secondaryChanged = JSON.stringify(this.selectedSkillSecondary) !== JSON.stringify(this.previousFormValue.secondarySkills);
    this.formUpdated.emit({updated: primaryChanged || secondaryChanged, componentName: ComponentName.ConsultantSkills});
  }

  disableBothSkills(skill: any): boolean {
    let val: boolean = false;
    if (this.selectedSkill.some(selectedSkill => selectedSkill.id === skill.id) ||
      this.selectedSkillSecondary.some(selectedSkill => selectedSkill.id === skill.id)) {
      val = true;
    } else if (this.disableSkill(skill) !== this.disableSecondarySkill(skill)) {
      val = true;
    }
    return val;
  }

  disableSkill(skill: any): boolean {
    let val: boolean = false;
    this.primarySkills.map((res: { id: any; }) => {
      if (res.id == skill.id)
        val = true;
    })
    return (val)
  }

  disableSecondarySkill(skill: any): boolean {
    let val: boolean = false;
    this.secondarySkills.map((res: { id: any; }) => {
      if (res.id == skill.id)
        val = true;
    })
    return (val)
  }

  _skillFilter(name: string): Skill[] {
    const filterValue = name.toLowerCase();
    return this.skillsRes.filter(option => option.title.toLowerCase().includes(filterValue));
  }

  getSkillType(): void {
    this.service.getSkills().then((res: any[]) => {
      this.skillsRes = res;

      this.filteredSkills = this.skillControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterSkillsPrimary(value))
      );

      this.secondaryFilteredSkills = this.skillControl2.valueChanges.pipe(
        startWith(''),
        map(value => this._filterSkillsSecondary(value))
      );
    });
  }
  private _filterSkillsPrimary(value: string | Skill): Skill[] {
    const filterValue = typeof value === 'string' ? value.trim().toLowerCase() : value?.title.trim().toLowerCase() ?? '';
    this.exactMatchFoundPrimary = this.skillsRes.some(skill => skill.title.toLowerCase() == filterValue);

    return this.skillsRes.filter(skill =>
      skill.title.toLowerCase().includes(filterValue)
    );
  }
  private _filterSkillsSecondary(value: string | Skill): Skill[] {
    const filterValue = typeof value === 'string' ? value.trim().toLowerCase() : value?.title.trim().toLowerCase() ?? '';
    this.exactMatchFoundSecondary = this.skillsRes.some(skill => skill.title.toLowerCase() == filterValue);

    return this.skillsRes.filter(skill =>
        skill.title.toLowerCase().includes(filterValue)
    );
  }
  addSkillType(type: string): void {
    if (type == 'primary') {
      const payload = {
        'title': this.skillName,
      }
      this.service.addOrgSkills(payload).then(res => {
        this.isDataUpdatedService.setUpdated(true);
        this.getSkillType();
        this.selectedSkill.push(res);
        this.skillName = '';
        this.chipGrid.nativeElement.value = '';
        this.skillControl.setValue('');
      }, (e) => {
        console.error('error', e.error.message);
        this.toastr.error(e.error.message, 'Request Failed');
      })
    } else {
      const payload = {
        'title': this.skillName2,
      }
      this.service.addOrgSkills(payload).then(res => {
        this.isDataUpdatedService.setUpdated(true);
        this.getSkillType()
        this.selectedSkillSecondary.push(res);
        this.skillName2 = '';
        this.chipGrid2.nativeElement.value = '';
        this.skillControl2.setValue('');
      }, (e) => {
        console.error('error', e.error.message);
        this.toastr.error(e.error.message, 'Request Failed');
      })
    }
  }

  addSkill(type: string): void {
    if (type == 'primary') {
      const selectedSkill: any = [];
      this.selectedSkill.map(res => {
        selectedSkill.push(res.id);
      })
      this.primaryLoading = true;
      this.service.updateConsultantPrimarySkill(this.data.id, selectedSkill)
        .subscribe((res) => {
          this.isDataUpdatedService.setUpdated(true);
          this.primaryLoading = false;
          this.skillName = '';
          this.data = res;
          this.primarySkills = res.primarySkills;
          this.selectedSkill = [];
          this.showloader = false;
          this.skillControl.setValue('');
          // this.previousFormValue = {
          //   primarySkills: [],
          //   secondarySkills: [...this.selectedSkillSecondary]
          // };
          this.checkAndEmitChanges()
        }, (e) => {
          console.error('error', e.error.message);
        })
    } else {
      const selectedSkillSecondary: any = [];
      this.selectedSkillSecondary.map(res => {
        selectedSkillSecondary.push(res.id);
      })
      this.secondaryLoading = true;
      this.service.updateConsultantSecondarySkill(this.data.id, selectedSkillSecondary)
        .subscribe((res) => {
          this.isDataUpdatedService.setUpdated(true);
          this.secondaryLoading = false;
          this.skillName2 = '';
          this.data = res;
          this.secondarySkills = res.secondarySkills;
          this.selectedSkillSecondary = [];
          this.showloader = false;
          // this.previousFormValue = {
          //   primarySkills: [...this.selectedSkill],
          //   secondarySkills: []
          // };
          this.checkAndEmitChanges()
        }, (e) => {
          console.error('error', e.error.message);
        })
    }
  }

  openDialogSkillDelete(type: string, skillId: number, index: number, skillName: string): void {
    const dialogRef1 = this.dialog.open(PopUpComponent, {
      data: {
        message: 'Are you sure you want to delete this "' + skillName + '" Skill ?',
        type: 'Delete'
      }
    });
    dialogRef1.beforeClosed().subscribe((res) => {
      if (res) {
        if (type == 'primary') {
          this.service.deleteConsultantPrimarySkill(this.data.id, skillId).subscribe(() => {
            this.isDataUpdatedService.setUpdated(true);
            this.openSnackBar('Successfully Deleted', " ");
            this.getConsultantById();
          }, (e) => {
            this.dialogRef.disableClose;
          });
        } else {
          this.service.deleteConsultantSecondarySkill(this.data.id, skillId).subscribe(() => {
            this.isDataUpdatedService.setUpdated(true);
            this.openSnackBar('Successfully Deleted', " ");
            this.getConsultantById();
          }, (e) => {
            this.dialogRef.disableClose;
          });
        }
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  protected readonly console = console;
}
