<div class="main-div">
<div class="col-md-6" style="margin-left: 451px;">
    <div class="content-wrapper">
      <div class="partner-info mx-height-scroll">
        <form [formGroup]="createForm" class="form-wrapper" (submit)="setPassword()" ngNativeValidate>
          <div class="form-group">
            <div class="form-row mb-2" style="justify-content: start; display: flex;">
              <label style="font-weight: bolder;font-size: 20px;">Set Partner information</label><br>
            </div>
            <div class="form-row mb-5" style="justify-content: start; display: flex;">
              <span> Hi {{orgUser?.firstName?orgUser.firstName:'NA'}} {{orgUser?.lastName?orgUser.lastName:'NA'}}, thanks for creating business with Us !!</span>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputFirstName">First Name</label>
                <input formControlName="firstName" type="text" class="form-control" id="inputFirstName"
                       placeholder="First name" required>
              </div>
              <div class="form-group col-md-6">
                <label for="inputFirstName">Last Name</label>
                <input formControlName="lastName" type="text" class="form-control" id="inputLastName"
                       placeholder="Last name" required>
              </div>
              <div class="form-group col-md-12">
                <label for="inputEmail"> Email id </label>
                <input type="email" readonly formControlName="email" class="form-control" id="inputEmail" placeholder="Email">
              </div>
              <div class="form-group col-md-12">
                <label for="inputEmail"> Set Password</label>
                <input type="password" formControlName="password" class="form-control" id="inputPassword" placeholder="Password" required>
              </div>
            </div>
            <div class="alert-danger bg-white mb-2">
              {{errorMessage}}
            </div>
            <div class="form-group btns d-flex justify-content-center save-form">
              <button *ngIf="!showloader" class="btn btn-save" type="submit">Create</button>
              <div *ngIf="showloader" disabled>
                <button id="submitButton1" class="btn btn-saveform" disabled>
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  </div>
