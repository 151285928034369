import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { VendorCorp } from 'src/app/shared/interfaces';

@Component({
  selector: 'app-vendor-detail',
  templateUrl: './vendor-detail.component.html',
  styleUrls: ['./vendor-detail.component.css']
})
export class VendorDetailComponent implements OnInit {
  vendor: VendorCorp;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: ApiService,
    private dialogRef: MatDialogRef<VendorDetailComponent>,
  ) { }

  ngOnInit(): void {
    this.service.getVendorById(this.data.vendorId).subscribe(res => {
      this.vendor = res;
    });
  }

}
