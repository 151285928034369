import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ApiService } from "../../../../services/api.service";
import { IsDataUpdatedService } from "../../../../services/isDataUpdated.service";
import {SettingsService} from "../../../../services/settingsService";

@Component({
  selector: 'app-comment-update-dialog-box',
  templateUrl: './comment-update-dialog-box.component.html',
  styleUrls: ['./comment-update-dialog-box.component.css']
})
export class CommentUpdateDialogBoxComponent implements OnInit {
  onHoldCommentText: string = '';
  dialogMessage: string;
  dialogtitle: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public taskList: any,
    public dialogRef: MatDialogRef<CommentUpdateDialogBoxComponent>,
    private service: ApiService,
    private isMyTaskDataUpdated: IsDataUpdatedService,
    private settingsService: SettingsService
  ) {
    this.dialogtitle = taskList.title;
    this.dialogMessage = taskList.subTitle;
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(false);
  }

  confirmButton() {
    if (this.onHoldCommentText != '') {
      this.service.addCommentInTask(this.onHoldCommentText, 'INTERNAL', this.taskList.selectedTasks.uniqueId).then((res) => {
        this.isMyTaskDataUpdated.setUpdated(true);
        this.settingsService.emitChange(true);
        this.dialogRef.close(true);
      });
    }
  }

}
