<div class="mx-3 d-flex">
  <div class="col-md-6 card-shadow my-4 p-4 pb-2">
    <h3 class="font-weight-bold">Add Comment:</h3>
    <div class="form-row">
      <div class="form-group col-md-12">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Add Comment</mat-label>
          <textarea matInput class="form-control" [(ngModel)]="comment"
                    placeholder="Add Comment here ..."
                    style="height: 80px;"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="justify-content-end d-flex pb-3">
      <button (click)="addComment('INTERNAL')" class="btn action-btn" type="submit"> Add
        Comment
      </button>
    </div>
  </div>
  <div class="col-md-6 card-shadow my-4 p-4 pb-2">
    <h3 class="my-2 font-weight-bold" style="color: rgba(0, 0, 0, 0.7);">Past Communication</h3>
    <div *ngIf="commentsList?.length == 0" class="empty-state">
      <div class="no_data_found themeSecondary-bg-color mt-3">
        Communication not found
      </div>
    </div>
    <div class="comment-sec" *ngIf="commentsList && commentsList.length > 0">
      <div class="row" *ngFor="let comment of commentsList">
          <div class="col mb-3">
            <p
              class="assignee mb-0">{{ (comment?.user?.firstName ?? '-') + ' ' + (comment?.user?.lastName ?? '-') }}
              {{ comment?.updatedDate ? (comment.updatedDate | date: 'hh:mm a dd MMM yyyy') : (comment.createdDate | date: 'hh:mm a dd MMM yyyy') }}
              {{ comment?.updatedDate ? 'Edited' : '' }}
            </p>
            <p *ngIf="!isEditMode(comment.id)"
               class="subHeadings-values mb-0">{{ comment?.comment != '' ? comment?.comment : '--' }} </p>
            <div *ngIf="isEditMode(comment.id)" class="d-flex align-items-center justify-content-between">
              <mat-form-field class="w-50">
                <mat-label>Comment</mat-label>
                <input class="text-capitalize" matInput [(ngModel)]="getComment"/>
              </mat-form-field>
              <div>
                <a (click)="saveEdit(comment)"
                   style="margin-left: 8px;margin-right: 45px;text-transform:capitalize;font-size: 18px;cursor: pointer">
                  <span class="badge bg-success text-white cursor-p">Done</span></a>
              </div>
            </div>
          </div>
          <div class="col d-flex justify-content-end" *ngIf="!isEditMode(comment.id)">
            <div class="dropdown" *ngIf="editableStatus[comment.id]">
              <i class="fas fa-ellipsis-v" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                 aria-expanded="false"></i>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" (click)="editComment(comment.id)" style="cursor: pointer">Edit</a>
                <a class="dropdown-item" (click)="deleteComment(comment.id)" style="cursor: pointer">Delete</a>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>

