import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "../../../../services/api.service";
import {ToastrService} from "ngx-toastr";
import {IsDataUpdatedMembersService} from "../../../../services/isDataUpdatedMembers.service";

@Component({
  selector: 'app-create-member',
  templateUrl: './create-member.component.html',
  styleUrls: ['./create-member.component.css']
})
export class CreateMemberComponent implements OnInit {
  memberForm: FormGroup
  showloader = false;
  errorMessage = '';
  userId = '';
  openSalesDropdown = false;
  type = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private service: ApiService,
    private dialogRef: MatDialogRef<CreateMemberComponent>,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private isMembersDataUpdated :IsDataUpdatedMembersService,
  ) { }

  ngOnInit(): void {
    this.userId = this.data.userId;
    this.memberForm = this.fb.group({
      firstName: ["",Validators.required],
      lastName: ["",Validators.required],
      email: ["", Validators.pattern('[a-zA-Z0-9._%+-]+@[a-z0-9A-Z.-]+.[a-z]{2,}$')],
      role: "",
    })
    if (this.data.userId) {
      this.type = 'edit';
      this.service.getMemberById(this.data.userId).then(res => {

        this.memberForm.get("firstName")?.setValue(res.firstName);
        this.memberForm.get("lastName")?.setValue(res.lastName);
        this.memberForm.get("email")?.setValue(res.email);
        this.memberForm.get("role")?.setValue(res.role);
      })
    } else {
      this.type = 'add';
    }
  };
  addMember(): void {
    if (this.memberForm.invalid) {
      return;
    }
    this.showloader = true;
    if (this.data.userId) {
      this.service.updateMember(this.memberForm.value, this.data.userId).then(res => {
        this.isMembersDataUpdated.setUpdated(true);
        setTimeout(() => {
          this.toastr.success('Member Update Successfully', 'Success', {
            positionClass: "toast-top-right"
          });
          // this.showloader = false;
          this.dialogRef.close()
        }, 600)
      }, (e) => {
        this.showloader = false;
        if (e.error.status == 400) {
          this.dialogRef.disableClose;
          this.errorMessage = e.error.message;
        } else if (e.error.status == 500) {
          this.dialogRef.disableClose;
          this.errorMessage = e.error.message;
        }
      });
    } else {
      this.service.addMember(this.memberForm.value).then(res => {
        this.isMembersDataUpdated.setUpdated(true);
        this.showloader = true;
        this.toastr.success('Member Created Successfully', 'Success', {
          positionClass: "toast-top-right"
        });
        setTimeout(() => {
          this.dialogRef.close()
        }, 600)

      },(e) => {
        this.showloader = false;
        if (e.error.status == 400) {
          this.dialogRef.disableClose;
          this.toastr.error('Role is required for creating the member', 'Error', {
            positionClass: "toast-top-right"
          });
          // this.errorMessage = e.error.message;
        } else if (e.error.status == 500) {
          this.dialogRef.disableClose;
          this.errorMessage = e.error.message;
        } else if (e.error.code==4000){
          this.toastr.error('Sorry! Member for this organisation with same email already exists.', 'Error', {
            positionClass: "toast-top-right"
          });
        }
      });
      this.showloader = false;
    }
  }

  onRoleChange(role: string): void {
    if (role === 'SALES') {
      this.openSalesDropdown =true
    } else {
      this.openSalesDropdown = false;
    }
  }

  onSaleRoleChange(event: any): void {
      const selectedOption = event.value || event;

    if (this.memberForm.value.role === 'SALES') {
      this.memberForm.patchValue({ role: selectedOption });
    }
  }
  close(): void {
    this.dialogRef.close();
  }

}
