<div class="content-wrapper-main-inner-content">
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h5 class="page-title">Change your password</h5>

      </div>
    </div>
  </div>
  <div class="col-md-12 companyProfile_wrapper">
    <div class="companyProfile_header theme-bg-color">
      <h5>Change your password</h5>
    </div>
    <div class="companyProfile_content">
      <div class="create-vendor">
        <div class="card-body" *ngIf="!responseSent">
          <form [formGroup]="changePasswordForm" (submit)="validatePassword()" ngNativeValidate
                class="form-horizontal form-material" id="changePasswordForm">
            <div class="form-group">


              <mat-form-field appearance="outline" class="w-100 border-red mb-3">
                <mat-label>Old password</mat-label>
                <input matInput  #oldPasswordInput formControlName="oldPassword" type="password"
                       placeholder="Old Password" name="oldPassword" required>
                <i #faIconOldPasswordInputButton (click)="togglePassword()" class="fas fa-eye eye_icon"></i>
              </mat-form-field>

              <mat-form-field appearance="outline" class="w-100 border-red mb-3">
                <mat-label>Enter new password</mat-label>
                <input matInput #newPasswordInput formControlName="newPassword" type="password" placeholder="Enter New Password">
                <i #faIconNewPasswordInputButton (click)="toggleNewPassword()" class="fas fa-eye eye_icon"></i>
              </mat-form-field>



              <mat-form-field appearance="outline" class="w-100 border-red mb-3">
                <mat-label>Confirm new password</mat-label>
                <input matInput  #confirmNewPasswordInput formControlName="confirmPassword" type="password"
                       (ngModelChange)="errorMessage = ''" placeholder="Confirm New Password" name="username">
                <i  #faIconConfNewPasswordInputButton (click)="toggleConfNewPassword()" class="fas fa-eye eye_icon"></i>
              </mat-form-field>

            </div>
            <div style="color: red">{{errorMessage}}</div>
            <div class="form-group">
                <div class="form-group btns d-flex justify-content-center">
                  <button  *ngIf="!showloader" class="btn action-btn action-btn-cancel me-3 border-0 theme-btn-color" type="submit">Create</button>
                  <div *ngIf="showloader" disabled>
                    <button id="submitButton1" class="btn w-100 btn-lg btn-info btn-rounded text-white" disabled>
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                </div>
            </div>
          </form>
        </div>
      </div>
    </div></div>
</div>
