import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {DataService} from "../../../../services/data.service";
import {MatPaginator} from "@angular/material/paginator";
import {debounceTime, distinctUntilChanged, filter, tap} from "rxjs/operators";
import {fromEvent} from "rxjs";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {ImageCropperComponent} from "../../image-cropper/image-cropper.component";

@Component({
  selector: 'app-customer-settings',
  templateUrl: './customer-settings.component.html',
  styleUrls: ['./customer-settings.component.css']
})
export class CustomerSettingsComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() customerData: any;
  @Input() refreshFlag: boolean = false;
  @Output() customerImageChange = new EventEmitter<string>();
  isLoading = false;
  customerStatus: boolean;
  customerLogoVisible: boolean;
  customerImage: any;
  logoLoader = false;
  customerLogo: any;
  customerContacts: any[] = [];
  pageSize = 5;
  audit: any[] = [];
  totalAuditPages: number;
  currentAuditPage: number;
  totalAudit = 0;
  file: File | null;
  @ViewChild('fileInput') fileInput: ElementRef<HTMLDivElement>;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input', {static: true}) input: ElementRef | any;

  constructor(
    private service: ApiService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private dataService: DataService,
    private isCustomerDataUpdated: IsDataUpdatedService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['refreshFlag']) {
      if (this.refreshFlag && !this.isLoading) {
        //if(changes['refreshFlag'].currentValue != changes['refreshFlag'].previousValue){
        this.getAuditHistory(this.customerData?.id, this.currentAuditPage, this.pageSize);
      }
    }
  }

  ngOnInit(): void {
    if (this.customerData?.id) {
      this.service.getCustomerById(this.customerData?.id).subscribe({
          next: res => {
            this.customerImage = res.logo;
            this.customerImageChange.emit(res.logo)
          },
          error: err => {
            console.error(err);
          },
          complete: () => {
            // this.previousFormValue = this.consultantForm.value;
            // this.initializingForm = false;
          }
        }
      )
    }
    this.customerStatus = this.customerData?.customerData?.active;
    this.customerLogo = this.customerData?.customerData?.logo ?? '';
    if (typeof this.customerData?.customerData?.logo !== 'undefined') {
      if (this.customerData?.customerData?.logo !== '') {
        this.customerLogo = this.customerData?.customerData?.logo;
        this.customerLogoVisible = true;
      } else {
        this.customerLogoVisible = false;
      }
    } else {
      this.customerLogoVisible = false;
    }
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(
      tap(() => {
        this.getAuditHistory(this.customerData?.id, this.paginator.pageIndex, this.paginator.pageSize);
      })
    ).subscribe();
    if (this.input) {
      fromEvent(this.input?.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => this.getAuditHistory(this.customerData?.id, this.paginator.pageIndex, this.paginator.pageSize)),
        )
        .subscribe();
    }
  }

  uploadFileTrigger(): void {
    this.fileInput.nativeElement.click();
  }

  toggleCustomerStatus() {
    this.service.updateCustomerStatus(this.customerData.id).subscribe(res => {
      this.isCustomerDataUpdated.setUpdated(true);
      this.customerStatus = res.active;
      this.toastr.success('Status of customer ' + (this.customerStatus ? 'activated' : 'deactivated'), 'Success');
    }), (e: any) => {
      this.toastr.error(e.error.reason, 'Request Failed');
    };
  }

  onSelectLogo(event: any, dragEvent?: boolean): void {
    event.preventDefault();
    this.logoLoader = true;
    this.file = this.getFileFromEvent(event, dragEvent);

    if (!this.file) {
      this.logoLoader = false;
      return;
    }

    if (this.isAllowedFileType(this.file.type)) {
      this.openImageCropperDialog(this.file, event);
    } else {
      this.handleUnsupportedFileType(this.file.type);
    }
  }

  getFileFromEvent(event: any, dragEvent?: boolean): File | null {
    if (dragEvent) {
      return event.dataTransfer?.files[0] || null;
    } else {
      return event.target?.files?.[0] || null;
    }
  }

  isAllowedFileType(fileType: string): boolean {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const disallowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    return allowedTypes.includes(fileType) && !disallowedTypes.includes(fileType);
  }

  openImageCropperDialog(file: File, event: any): void {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      width: '60%',
      data: { file }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.processCropperResult(result);
      } else {
        this.handleDialogCloseWithoutResult();
      }
      (event.target as HTMLInputElement).value = '';
    });
  }

  processCropperResult(result: any): void {
    if (result.blob instanceof Blob) {
      this.handleBlobResult(result.blob);
    } else if (result.base64) {
      this.handleBase64Result(result.base64);
    } else {
      console.error('Result is not a valid Blob or base64 string:', result);
      this.resetFile();
    }
  }

  handleUnsupportedFileType(fileType: string): void {
    const errorMessage = fileType.includes('pdf') || fileType.includes('doc') ? 'PDF and DOC files are not allowed.' : 'Unsupported file type.';
    console.error(errorMessage);
    this.toastr.error(errorMessage, 'Error');
    this.logoLoader = false;
  }

  handleBlobResult(blob: Blob): void {
    const filename = this.file?.name || 'cropped_image.png';
    const file = new File([blob], filename, { type: blob.type });
    this.uploadFile(file);
  }

  handleBase64Result(base64: string): void {
    const file = this.base64ToFile(base64, this.file?.name || 'cropped_image.png');
    if (file) {
      this.uploadFile(file);
    } else {
      console.error('Failed to convert base64 to file');
      this.resetFile();
    }
  }

  uploadFile(file: File): void {
    const formData: FormData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);

    this.service.uploadCustomerLogo(formData, this.customerData.id).subscribe((res => {
      if (res.type === 4) {
        this.updateCustomerData(res.body.logo);
      }
    }));
  }

  updateCustomerData(logo: string): void {
    this.isCustomerDataUpdated.setUpdated(true);
    this.logoLoader = false;
    this.customerImage = logo;
    this.customerImageChange.emit(logo);
    this.customerData.logo = logo;
  }

  resetFile(): void {
    this.file = null;
    this.logoLoader = false;
  }

  base64ToFile(data: string, filename: string): File | null {
    if (!data) {
      console.error('No data provided to base64ToFile');
      return null;
    }

    const arr = data.split(',');
    if (arr.length < 2) {
      console.error('Invalid base64 data:', data);
      return null;
    }

    const mimeMatch = arr[0].match(/:(.*?);/);
    if (!mimeMatch) {
      console.error('Invalid MIME type in base64 data:', arr[0]);
      return null;
    }

    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    const u8arr = new Uint8Array(bstr.length);
    for (let i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  }

  handleDialogCloseWithoutResult(): void {
    console.error('Dialog closed without result');
    this.resetFile();
  }

  delete(): void {
    this.logoLoader = true;
    this.service.deleteCustomerLogo(this.customerData.id).then(res => {
      this.isCustomerDataUpdated.setUpdated(true);
      this.customerImage = null;
      this.customerImageChange.emit('');
      this.customerData.logo = "";
      this.logoLoader = false;
      this.customerLogoVisible = false;
    }).catch((error: any) => {
      if (error) {
        this.logoLoader = false;
      }
    });
  }

  getAuditHistory(id: string, page: number, size: number): void {
    this.service.getAuditHistory(id, 'Customer', page, size).then(resp => {
      this.audit = resp.content;
      this.totalAuditPages = resp['totalPages'];
      this.paginator.pageIndex = resp.number;
      this.paginator.pageSize = resp.size;
      this.pageSize = resp.size;
      this.totalAudit = resp.totalElements;
    })
  }

  getInitials(firstName: string, lastName: string): string {
    let firstNames = firstName.trim().replace(/_/g, '').split(' '),
      initials = firstNames[0].substring(0, 1).toUpperCase();
    let lastNames = lastName.trim().replace(/_/g, '').split(' '),
      initialsLast = lastNames[0].substring(0, 1).toUpperCase();

    if (firstNames.length > 1) {
      initials += firstNames[firstNames.length - 1].substring(0, 1).toUpperCase();
    }
    if (lastNames.length > 1) {
      initialsLast += lastNames[lastNames.length - 1].substring(0, 1).toUpperCase();
    }
    return (initials + initialsLast);
  }

  handleDragOver(event: DragEvent): void {
    event.preventDefault();
  }

}
