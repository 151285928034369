import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {MatPaginator} from "@angular/material/paginator";
import {ApiService} from "../../../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ToastrService} from "ngx-toastr";
import {DatePipe} from "@angular/common";
import {DeletePopupComponent} from "../../../delete-popup/delete-popup.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-solution-communication',
  templateUrl: './solution-communication.component.html',
  styleUrls: ['./solution-communication.component.css']
})
export class SolutionCommunicationComponent implements OnInit, OnChanges {
  notesForm: FormGroup;
  editNotesForm: FormGroup;
  showloader = false;
  errorMessage: string | null;
  field = '';
  length: any;
  notes: any[] = [];
  currentPage: number = 0;
  totalPages: number;
  totalElements: number = 0;
  pageSize = 5;
  active = false;
  loading = false;
  previousFormValue: any;
  initializingForm: boolean = true;
  totalCom = 0;
  solutionData: any;
  solutionId: any;
  editableNoteId: number | null = null;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input', {static: true}) input: ElementRef | any;
  @Input() data: any;
  @Input() createSolutionId!: number;

  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    public router: Router,
    public snackBar: MatSnackBar,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.solutionId = this.route?.snapshot?.params['solutionId'];
    this.notesForm = this.fb.group({
      description: ['']
    });

    this.editNotesForm = this.fb.group({
      description: ['']
    });

    if (this.solutionId ?? this.createSolutionId) {
      this.getNotesForSolutions(this.createSolutionId ?? this.solutionId, this.currentPage, this.pageSize);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      this.solutionData = this.data?.solutionInfo;
      if (this.createSolutionId ?? this.solutionId) {
        this.getNotesForSolutions(this.createSolutionId ?? this.solutionId, this.currentPage, this.pageSize);
      }
    }
  }

  handlePageCom(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getNotesForSolutions(this.createSolutionId ?? this.solutionId, this.currentPage, this.pageSize);
  }

  getNotesForSolutions(solutionId: number, page: number, size: number) {
    this.service.getAllNotesForSolutions(solutionId, page, size).subscribe((res) => {
      this.notes = res.content;
      this.currentPage = res?.page?.number;
      this.pageSize = res?.page?.size;
      this.totalPages = res?.page?.totalPages;
      this.totalElements = res.page?.totalElements
    });
  }

  onSubmit() {
    if (this.getFormControl('description').value.trim() === '') {
      this.toastr.error('Please enter a note.', 'Error');
      return;
    }

    this.showloader = true;
    this.service.postNotesForSolutions(this.createSolutionId ?? this.solutionId, this.notesForm.value).subscribe(
      (res) => {
        this.showloader = false;
        this.toastr.success('Note Created Successfully', 'Success');
        this.notesForm.reset();
        this.getNotesForSolutions(this.createSolutionId ?? this.solutionId, this.currentPage, this.pageSize);
      },
      (error) => {
        this.toastr.error('Failed to create note.', 'Error');
        this.showloader = false;
      }
    );
  }

  onUpdate(noteId: number) {
    this.loading = true
    const updatedDescription = this.editNotesForm?.get('description')?.value;
    const updatedNote = {description: updatedDescription};
    this.service.updateNotesForSolutions(this.createSolutionId ?? this.solutionId, noteId, updatedNote).subscribe(
      (res) => {
        this.loading = false
        this.editableNoteId = null;
        this.toastr.success('Note Updated Successfully', 'Success');
        this.getNotesForSolutions(this.data?.id, this.currentPage, this.pageSize);
      },
      (error) => {
        this.toastr.error('Failed to update note.', 'Error');
      }
    );
  }

  getFormControl(controlName: string): FormControl {
    return this.notesForm?.get(controlName) as FormControl;
  }

  formatDate(originalDate: Date): string {
    return this.datePipe.transform(originalDate, 'hh:mm a, yyyy-MMM-dd')?.toString() || '';
  }

  editNotes(note: any) {
    this.editableNoteId = note.id;
    this.editNotesForm.patchValue({
      description: note.description
    });
  }

  deleteNotes(noteId: any): void {
    this.dialog.open(DeletePopupComponent, {
      data: {
        message: 'Are you sure you want to delete this Note?',
        type: 'Delete'
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.service.deleteNotesForSolutions(this.createSolutionId ?? this.solutionId, noteId).subscribe(
          () => {
            this.toastr.success('Note Deleted Successfully', 'Success', {
              positionClass: 'toast-top-right'
            });
            this.getNotesForSolutions(this.createSolutionId ?? this.solutionId, this.currentPage, this.pageSize);
          },
          (error) => {
            this.toastr.error('Failed to delete note.', 'Error', {
              positionClass: 'toast-top-right'
            });
          }
        );
      }
    });
  }

}
