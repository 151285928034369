import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../../services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {DocsType, DocType, solutionAttachment} from "../../../../shared/interfaces";
import {Observable} from "rxjs/internal/Observable";
import {map, startWith, tap} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {MatPaginator} from "@angular/material/paginator";
import {PopUpComponent} from "../../../../util/pop-up/pop-up.component";

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.css']
})
export class AttachmentComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  attachmentForm: FormGroup;
  imageSrc!: string;
  file: File | null;
  logoName: string;
  selectedAttachment: string = 'URL'
  docControl = new FormControl('')
  filteredDocs: Observable<any[]>;
  typeId: string = '';
  docTypeName: string = '';
  @ViewChild('fileInput') fileInput: ElementRef<HTMLDivElement>;
  typeRes: DocsType[] = [];
  currentPage: number = 0;
  totalPages: number = 0;
  totalElements: number = 0;
  pageSize = 5;
  urlType: string = ''
  type: string = ''
  attachmentOptions = [
    {value: 'URL', label: 'URL'},
    {value: 'FILE', label: 'FILE'},
  ];
  documentData: solutionAttachment[];
  @Input() createSolutionId!: number;
  solutionId: number;
  showArchived: boolean = true;
  showloader: boolean = false
  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.solutionId = this.route?.snapshot?.params['solutionId'];
    this.attachmentForm = this.fb.group({
      attachment: ['', Validators.required],
      url: [''],
    });
    this.getDocType();
    if(this.createSolutionId ?? this.solutionId)
    this.getAllDocumentsForSolutions(this.createSolutionId ?? this.solutionId, this.currentPage, this.pageSize, this.showArchived);
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngAfterViewInit(): void {
    this.paginator.page.pipe(
      tap(() => {
        this.getAllDocumentsForSolutions(
          this.createSolutionId ?? this.solutionId,
          this.paginator.pageIndex,
          this.paginator.pageSize,
          this.showArchived
        );
      })
    ).subscribe();
  }

  getDocType() {
    this.service.getDocumentType().then(response => {
      this.typeRes = [];
      response.map((e: DocType) => {
        if (e.active == true) {
          this.typeRes.push(e);
        }
      });
      this.filteredDocs = this.docControl.valueChanges.pipe(
        startWith(''),
        map(value => {
            const name = typeof value === 'string' ? value.trim() : value?.name.trim() ?? "";
            return name ? this._docsFilter(name as string) : this.typeRes.slice();
          }
        ),
      )
      this.typeRes.push({name: 'others', id: 0, seq: '0', active: true});
    });
  }

  _docsFilter(name: string): DocsType[] {
    const filterValue = name.toLowerCase();
    return this.typeRes.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  getAllDocumentsForSolutions(solutionId: number, page: number, size: number, showEnabled: boolean) {
    this.service.getAllDocumentsForSolutions(solutionId, page, size, showEnabled).subscribe((res) => {
      this.documentData = res.content
      this.currentPage = res?.page?.number;
      this.pageSize = res?.page?.size;
      this.totalPages = res?.page?.totalPages;
      this.totalElements = res.page?.totalElements
    })
  }

  private updateValidators(attachmentType: string) {
    const urlControl = this.attachmentForm.get('url');
    if (attachmentType === 'URL') {
      urlControl?.setValidators(Validators.required);
    } else {
      urlControl?.clearValidators();
    }
    urlControl?.updateValueAndValidity();
  }

  onSubmit() {
    this.showloader = true
    if (this.selectedAttachment === 'URL') {
      const payload = {
        url: this.attachmentForm.get('url')?.value,
        typeId: this.typeId,
        docName: this.docTypeName
      };
      if (this.attachmentForm.valid) {
        this.service.uploadDocumentUrlForSolution(this.createSolutionId ?? this.solutionId, payload).subscribe({
          next: (res) => {
            this.showloader = false
            this.docControl.setValue('');
            this.selectedAttachment = 'URL';
            this.urlType = '';
            this.typeId = '';
            this.docControl.reset();
            this.docControl.setErrors(null);
            this.attachmentForm.reset({url: '', attachment: 'URL'});
            this.attachmentForm.get('url')?.setErrors(null);
            this.attachmentForm.get('attachment')?.setErrors(null);
            this.getAllDocumentsForSolutions(this.createSolutionId ?? this.solutionId, this.currentPage, this.pageSize, this.showArchived);
            this.toastr.success('URL Uploaded Successfully', 'Success');
          },
          error: (err) => {
            this.toastr.error(err.error?.message || 'Failed to upload URL. Please try again later.', 'Error');
            this.showloader = false
          }
        });
      } else {
        this.toastr.error('Form is invalid, please check errors', 'Validation Error');
        this.showloader = false
      }

    } else if (this.selectedAttachment === 'FILE') {
      if (this.file) {
        if (this.attachmentForm.valid) {
          this.service.uploadDocumentForSolution(this.createSolutionId ?? this.solutionId, Number(this.typeId), this.file.name ?? 'File Name', this.file).subscribe({
            next: (res) => {
              this.showloader = true
              this.toastr.success('Document Uploaded Successfully', 'Success');
              this.selectedAttachment = 'FILE';
              this.file = null;
              this.docControl.reset();
              this.docControl.setErrors(null);
              this.attachmentForm.reset({attachment: 'FILE'});
              this.getAllDocumentsForSolutions(this.createSolutionId ?? this.solutionId, this.currentPage, this.pageSize, this.showArchived);
              this.attachmentForm.get('attachment')?.setErrors(null);
              this.showloader = false
            },
            error: (err) => {
              this.toastr.error(err.error?.message || 'Failed to upload document. Please try again later.', 'Error');
              this.showloader = false
            }
          });
        } else {
          this.toastr.error('Form is invalid, please check errors', 'Validation Error');
          this.showloader = false
        }
      } else {
        this.toastr.error('Please select a file to upload', 'Validation Error');
        this.showloader = false
      }
    } else {
      this.toastr.error('Please select a valid attachment type', 'Validation Error');
      this.showloader = false
    }
  }

  uploadFileTrigger(): void {
    this.fileInput.nativeElement.click();
  }

  onSelectLogo(event: any, dragEvent?: boolean): void {
    event.preventDefault();
    if (dragEvent) {
      this.file = event.dataTransfer.files[0];
    } else {
      if (event.target.files && event.target.files.length > 0) {
        this.file = event.target.files[0];
      } else {
        this.file = null;
        return;
      }
    }
    if (this.file !== null) {

    }
  }

  handleDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  delete() {
    this.logoName = '';
    this.file = null;
  }

  handleSelect(event: any) {
    this.selectedAttachment = event.value;
    this.updateValidators(this.selectedAttachment);
  }

  togglePublishAttachment(event: any, documentId: number) {
    if (event.checked) {
      this.service.publishDocumentForSolution(documentId).subscribe({
        next: (res) => {
          this.toastr.success('Document published successfully', 'Success');
        },
        error: (e: any) => {
          this.toastr.error('Failed to publish document.', 'Error');
        }
      });
    } else {
      this.service.retractDocumentForSolution(documentId).subscribe({
        next: (res) => {
          this.toastr.success('Document retracted successfully', 'Success');
        },
        error: (e: any) => {
          this.toastr.error('Failed to retract document.', 'Error');
        }
      });
    }
  }

  toggleShowArchivedAttachment(event: any) {
    this.showArchived = event.checked
    if (!event.checked) {
      this.getAllDocumentsForSolutions(this.createSolutionId ?? this.solutionId, this.currentPage, this.pageSize, this.showArchived);
    } else {
      this.getAllDocumentsForSolutions(this.createSolutionId ?? this.solutionId, this.currentPage, this.pageSize, this.showArchived);
    }
  }

  displayDocFn(doc: DocsType): string {
    return doc && doc.name ? doc.name : '';
  }

  chooseDocType(data: any): void {
    const _type = data?.source?.value?.id;
    if (data.isUserInput) {
      this.typeId = _type;
      this.docTypeName = data.source.value?.name;
    } else {
      return
    }
  }

  addDocType(event: any, type: string, docTypeName: string): void {
    if (type === 'doc') {
      let payload = {
        'name': docTypeName,
      };
      this.service.addOrgDocType(payload).then(res => {
        this.getDocType();
        this.typeId = res?.id;
        this.displayDocFn(res);
        this.toastr.success('Document type added successfully', 'Success');
      }).catch(e => {
        this.toastr.error('Failed to add document type.', 'Error');
      });
    }
  }

  // handleArchiveDocument(id: number) {
  //   if (this.showArchived) {
  //     const dialogRef = this.dialog.open(PopUpComponent, {
  //       data: {
  //         title: 'Alert',
  //         message: 'Are you sure you want to Delete this document?',
  //         type: 'confirmation'
  //       }
  //     });
  //
  //     dialogRef.afterClosed().subscribe(result => {
  //       if (result) { // assuming result is true when user confirms
  //         this.service.archiveSolutionDocument(id).subscribe({
  //           next: (res) => {
  //             this.toastr.success('Document Archived successfully', 'Success');
  //           },
  //           error: (e: any) => {
  //             this.toastr.error('Failed to Archived document.', 'Error');
  //           }
  //         });
  //       }
  //     });
  //   } else {
  //     this.service.restoreArchiveSolutionDocument(id).subscribe({
  //       next: (res) => {
  //         this.toastr.success('Document restored successfully', 'Success');
  //       },
  //       error: (e: any) => {
  //         this.toastr.error('Failed to restored document.', 'Error');
  //       }
  //     });
  //   }
  // }
}
