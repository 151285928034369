<div style="margin-left:30px" class="pt-4 pb-2">
  <div (click)="goBack()" class="pb-2 text-primary cursor-p"> <- Back to Listing</div>
  <div>
    <h6 class="page_head">Solutions</h6>
  </div>
</div>

<div>
  <app-solution-information [data]="solutionData" (createSolutionId)="onSolutionId($event)"></app-solution-information>
</div>

<div>
  <app-solution-technology [technologyData]="solutionData" [createSolutionId]="createSolutionId"></app-solution-technology>
</div>

<div>
  <app-attachment [createSolutionId]="createSolutionId"></app-attachment>
</div>

<div>
  <app-solution-communication [data]="solutionData" [createSolutionId]="createSolutionId"></app-solution-communication>
</div>

<div>
  <app-solution-settings [data]="solutionData"  [createSolutionId]="createSolutionId"></app-solution-settings>
</div>

<div>
  <app-solution-ratings></app-solution-ratings>
</div>
