import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopUpComponent } from './pop-up/pop-up.component';
import { MaterialModule } from '../material.module';
import { SuggestiobnBoxComponent } from './suggestiobn-box/suggestiobn-box.component';
import { DateRangesComponent } from './date-ranges/date-ranges.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import { ToggleConfirmationDialogboxComponent } from './toggle-confirmation-dialogbox/toggle-confirmation-dialogbox.component';
import { SpinnerComponent } from './spinner/spinner.component';
import {NgxSpinnerModule} from "ngx-spinner";

@NgModule({
  declarations: [
    PopUpComponent,
    SuggestiobnBoxComponent,
    DateRangesComponent,
    ToggleConfirmationDialogboxComponent,
    SpinnerComponent,
  ],
    imports: [
        MaterialModule,
        CommonModule,
        MatDatepickerModule,
        NgxSpinnerModule,
    ],
    exports: [
        SuggestiobnBoxComponent,
        PopUpComponent,
        DateRangesComponent,
        SpinnerComponent,
    ]

})
export class UtilModule { }
