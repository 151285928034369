import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-toggle-confirmation-dialogbox',
  templateUrl: './toggle-confirmation-dialogbox.component.html',
  styleUrls: ['./toggle-confirmation-dialogbox.component.css']
})
export class ToggleConfirmationDialogboxComponent implements OnInit {
  data: any;
  constructor(@Inject(MAT_DIALOG_DATA) public inputData: any, private dialogRef: MatDialogRef<ToggleConfirmationDialogboxComponent>) {
  }


  ngOnInit(): void {
    this.data = this.inputData;
  }

}
