<label>
    {{vendor.vendorName}}
</label><br>
Address: - {{vendor.address.city.name}} , {{vendor.address.state.name}} , {{vendor.address.country.name}}<br>
Skills: -
<table>
    <tr>
        <th>
            Title
        </th>
        <th>
            Exp.
        </th>
        <th>
            Desc.
        </th>
    </tr>
    <tr *ngFor="let skill of vendor.skills">
        <td>{{skill.title}}</td>
<!--        <td>{{skill.experience}}</td>-->
        <td>{{skill.description}}</td>
    </tr>
</table>
