import {Component, ElementRef, Inject, OnInit, ViewChild,Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from 'src/app/services/api.service';
import {
  ChildFormUpdates, ComponentName,
  DocsType,
  DocType, FormUpdateEvent,
  PlaceDto,
  Skill,
  VendorCorp,
} from 'src/app/shared/interfaces';
import {Observable} from "rxjs/internal/Observable";
import {map, startWith} from "rxjs/operators";
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {PopUpComponent} from "../../../../util/pop-up/pop-up.component";
import {Router} from "@angular/router";
import {MatSnackBar} from '@angular/material/snack-bar';
import {DataService} from "../../../../services/data.service";
import {ToastrService} from "ngx-toastr";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {ImageCropperComponent} from "../../image-cropper/image-cropper.component";

@Component({
  selector: 'app-modify-vendor',
  templateUrl: './modify-vendor.component.html',
  styleUrls: ['./modify-vendor.component.css']
})
export class ModifyVendorComponent implements OnInit {
  vendorLogo:any;
  skillControl = new FormControl('');
  docControl = new FormControl('');
  desControl = new FormControl('');
  vendor: VendorCorp = {} as any;
  vendorImage: any;
  logoCid:string = '';
  prevLink1;
  prevLink2: string;
  type = '';
  vendorForm: FormGroup;
  showloader = false;
  logoLoader = false;
  dragOver: boolean = false;
  errorMessage: string | null;
  field = '';
  skills: any[] = [];
  file: File | null;
  doc: File | any;
  size: any;
  length: any;
  notes: any[] = [];
  audit: any[] = [];
  pageSizes = 5;
  skillsRes: Skill[] = [];
  typeId = '';
  typeRes: DocsType[] = [];
  designationRes: DocType[] = [];
  visible1 = true;
  visible2 = false;
  currentPage: number = 0;
  totalPages: number;
  totalAuditPages: number;
  currentAuditPage: number;
  orgId: any;
  isReadonly:any;
  pageSize = 4;
  status: boolean;
  active = false;
  role: string | null;
  cities: PlaceDto[] = [];
  filteredSkills: Observable<Skill[]>;
  filteredDocs: Observable<DocsType[]>;
  filteredDesignation: Observable<DocsType[]>;
  @ViewChild('fileInput') fileInput: ElementRef<HTMLDivElement>;
  @ViewChild('docInput') docInput: ElementRef<HTMLDivElement>;
  @ViewChild('documentSelectDiv') documentSelectDiv: ElementRef<HTMLDivElement>;
  @ViewChild('documentSelectDiv') designationSelectDiv: ElementRef<HTMLDivElement>;
  @ViewChild('autoCompleteInput', {read: MatAutocompleteTrigger})
  autoComplete: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInput1', {read: MatAutocompleteTrigger})
  autoComplete1: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInput2', {read: MatAutocompleteTrigger})
  autoComplete2: MatAutocompleteTrigger;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  @ViewChild("chipGrid") chipGrid: ElementRef;
  loading = false;
  showArchived: boolean = false;
  vendorDT:any;
  openDialogType:string;
  childFormUpdates: ChildFormUpdates = this.initializeChildFormUpdates();
  @Output() refreshOutputFlag: boolean =false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private service: ApiService,
    private dialogRef: MatDialogRef<ModifyVendorComponent>,
    public dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar,
    private toastr: ToastrService,
    private dataService: DataService,
  ) {
    this.prevLink1 = 'https://www.'
    this.prevLink2 = 'https://www.'
  }

  ngOnInit(): void {
    console.log(this.data)
    this.vendorLogo=this.data?.vendorData?.logo
    this.vendorDT=this.data.vendorData;
    this.openDialogType=this.data.type;
    window.addEventListener('scroll', this.scrollEvent, true);
    this.dialogRef.disableClose = true;
    this.role = localStorage.getItem('role');
    this.orgId = localStorage.getItem('orgId');
    this.typeId = '';
    this.currentPage = 0;
    this.pageSize = 4;
    this.vendorForm = this.fb.group({
      vendorName: ["", Validators.required],
    });
    if (this.data.vendorId) {
      this.loading = false;
      this.type = 'edit';
      this.visible1 = false;
      this.visible2 = true;
      this.vendorImage = this.vendorDT.logo;
      this.skills = this.vendorDT.skills;
      this.vendor.vendorName = this.vendorDT.vendorName;
      this.vendor.id = this.vendorDT.vendorId;
      this.vendor.logo = this.vendorDT.ve;
      this.status = this.vendorDT.status;
      this.loading = false;
    } else {
      this.type = 'create';
      this.visible1 = true;
      this.visible2 = false;
      this.vendorForm.removeControl('skills');
    }

    if (this.role == 'TA') {
      this.isReadonly = true;
    }
  }

  handleImageChange(event:any){
    this.vendorLogo = event
    this.vendorImage =event
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  scrollEvent = (event: any): void => {
    if (this.autoComplete?.panelOpen) {
      this.autoComplete.updatePosition();
    } else if (this.autoComplete1?.panelOpen) {
      this.autoComplete1.updatePosition();
    } else if (this.autoComplete2?.panelOpen) {
      this.autoComplete2.updatePosition();
    }
  }

  _skillFilter(name: string): Skill[] {
    const filterValue = name.toLowerCase();
    return this.skillsRes.filter(option => option.title.toLowerCase().includes(filterValue));
  }

  _docsFilter(name: string): DocsType[] {
    const filterValue = name.toLowerCase();
    return this.typeRes.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  _desFilter(name: string): DocsType[] {
    const filterValue = name.toLowerCase();
    return this.designationRes.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  getSkillType(): void {
    this.service.getSkills().then((res: any) => {
      // this.skillsRes = res;
      res.map((e:DocType)=>{
        if(e.active==true){
          // @ts-ignore
          this.skillsRes.push(e);
        }
      });
      this.filteredSkills = this.skillControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          const name = typeof value === 'string' ? value : value?.title ?? "";
          return name ? this._skillFilter(name as string || '') : this.skillsRes.slice();
        }),
      );
    })
  }

  getDocType() {
    this.service.getDocumentType().then(response => {
      // this.typeRes = response;
      response.map((e:DocType)=>{
        if(e.active==true){
          this.typeRes.push(e);
        }
      });
      this.filteredDocs = this.docControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          console.log(value,'value')
            const name = typeof value === 'string' ? value : value?.name ?? "";
            return name ? this._docsFilter(name as string) : this.typeRes.slice();
          }
        ),
      )
      this.typeRes.push({name: 'others', id: 0, seq: '0',active:true});
    });
  }

  getDesignation(): void {
    this.service.getDesignation().then(resp => {
      // this.designationRes = resp;
      resp.map((e:DocType)=>{
        if(e.active==true){
          this.designationRes.push(e);
        }
      });
      this.filteredDesignation = this.desControl.valueChanges.pipe(
        startWith(''),
        map(value => {
            const name = typeof value === 'string' ? value : value?.name ?? "";
            return name ? this._desFilter(name as string) : this.designationRes.slice();
          }
        ),
      )
    });
  }

  addNewVendor(): void {
    this.errorMessage = '';
    let input: VendorCorp = this.vendorForm?.value;
    input.logoCid = this.logoCid;
    input.logo = this.vendorImage;
    input.skills = this.vendor.skills;
    this.showloader = true;
    if (this.type == 'create' && !this.visible2 && input.vendorName.trim() != '') {
      this.service.createVendors(input).then((createdVendor: VendorCorp) => {
        this.loading = true;
        this.vendor = createdVendor;
        this.vendorLogo =createdVendor.logo
        this.getSkillType();
        this.getDocType();
        this.getDesignation();
        this.service.getVendorById(createdVendor.id).subscribe(res => {
          // this.vendor = res;
          this.vendorDT = res;
          this.showloader = false;
          this.loading = false;
          this.visible1 = false;
          this.visible2 = true;
          this.getAuditHistory(res?.id, 0);
        });

      }, (e: any) => {
        this.showloader = false;
        console.log(e.error);
        this.visible1 = true;
        this.visible2 = false;
        if (e.error.code == 4000) {
          this.showloader = false;
          this.errorMessage = e.error.reason;
          this.openSnackBar(e.error.reason, "ok ");
        } else if (e.error.status == 500) {
          this.errorMessage = e.error.message;
          this.openSnackBar(e.error.reason, "ok ");
        } else if (e.error.status == 409) {
          this.errorMessage = e.error.message;
          this.openSnackBar(e.error.reason, "ok ");
        } else {
          this.errorMessage = 'Error occurred, Please contact server team';
          this.openSnackBar(e.error.reason, "ok ");
        }
      });
    } else if (input.vendorName.trim() == '') {
      this.errorMessage = 'Vendor Name is Required';
      this.showloader=false;
    }

  }

  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 6) { // Assuming 'Settings' tab is the 7th tab (0-based index)
      this.callAuditHistoryAPI(); // Call the method to fetch audit history
    }
  }

  callAuditHistoryAPI(): void {
    // Assuming you have access to vendorData
    this.getAuditHistory(this.vendorDT.id, 0);
  }

  getAuditHistory(id: string, page: number): void {
    this.dataService.isLoading.next(true);
    this.service.getAuditHistory(id,"Vendor", page,this.pageSizes).then(resp => {
      this.dataService.isLoading.next(false);
      this.audit = resp.content;
      this.totalAuditPages = resp['totalPages'];
      this.currentAuditPage = page;
      this.refreshOutputFlag=!this.refreshOutputFlag;
    })
  }

  onSelectLogo(event: any, dragEvent?: boolean): void {
    event.preventDefault();
    this.logoLoader = true;

    this.file = this.extractFile(event, dragEvent);
    if (!this.file) {
      this.logoLoader = false;
      return;
    }

    if (this.isAllowedFileType(this.file.type)) {
      this.openImageCropperDialog(this.file, event);
    } else {
      this.handleUnsupportedFileType(this.file.type);
    }
  }

  extractFile(event: any, dragEvent?: boolean): File | null {
    if (dragEvent) {
      return event.dataTransfer.files[0] || null;
    } else {
      return event.target.files && event.target.files.length > 0 ? event.target.files[0] : null;
    }
  }

  isAllowedFileType(fileType: string): boolean {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const disallowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    return allowedTypes.includes(fileType) && !disallowedTypes.includes(fileType);
  }

  openImageCropperDialog(file: File, event: any): void {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      width: '60%',
      data: { file }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.processCropperResult(result);
      } else {
        this.handleDialogCloseWithoutResult();
      }
      (event.target as HTMLInputElement).value = '';
    });
  }

  processCropperResult(result: any): void {
    if (result.blob instanceof Blob) {
      this.handleFileUpload(result.blob, 'cropped_image.png');
    } else if (result.base64) {
      this.handleBase64Upload(result.base64);
    } else {
      console.error('Result is not a valid Blob or base64 string:', result);
      this.resetFile();
    }
  }

  handleUnsupportedFileType(fileType: string): void {
    const errorMessage = fileType.includes('pdf') || fileType.includes('doc') ? 'PDF and DOC files are not allowed.' : 'Unsupported file type.';
    console.error(errorMessage);
    this.toastr.error(errorMessage, 'Error');
    this.logoLoader = false;
  }

  handleFileUpload(blob: Blob, defaultFileName: string): void {
    const filename = this.file?.name || defaultFileName;
    const file = new File([blob], filename, { type: blob.type });
    this.uploadFile(file);
  }

  handleBase64Upload(base64: string): void {
    const file = this.base64ToFile(base64, this.file?.name || 'cropped_image.png');
    if (file) {
      this.uploadFile(file);
    } else {
      console.error('Failed to convert base64 to file');
      this.resetFile();
    }
  }

  uploadFile(file: File): void {
    const formData: FormData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);

    const uploadObservable = this.type === 'edit' || this.visible2 ?
      this.service.uploadLogoNext(formData, this.vendor.id) :
      this.service.uploadLogo(formData);

    uploadObservable.subscribe((res => {
      if (res.type === 4) {
        this.updateVendorLogo(res.body);
      }
    }));
  }

  updateVendorLogo(body: any): void {
    this.vendorImage = body.logo || body.url;
    this.vendor.logo = this.vendorImage;
    if (body.cid) {
      this.logoCid = body.cid;
    }
    this.logoLoader = false;
  }

  resetFile(): void {
    this.file = null;
    this.logoLoader = false;
  }

  base64ToFile(data: string, filename: string): File | null {
    if (!data) {
      console.error('No data provided to base64ToFile');
      return null;
    }

    const arr = data.split(',');
    if (arr.length < 2) {
      console.error('Invalid base64 data:', data);
      return null;
    }

    const mimeMatch = arr[0].match(/:(.*?);/);
    if (!mimeMatch) {
      console.error('Invalid MIME type in base64 data:', arr[0]);
      return null;
    }

    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    const u8arr = new Uint8Array(bstr.length);
    for (let i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  }

  handleDialogCloseWithoutResult(): void {
    console.error('Dialog closed without result');
    this.resetFile();
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      this.dragOver = true;
    }, 2000);
  }

  onDragEnter(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      this.dragOver = false;
    }, 2000);
  }

  delete(): void {
    this.logoLoader = true;
    this.vendorImage = '';
    this.vendor.logo = '';
    this.logoLoader = false;
  }

  handleFormUpdate(event: FormUpdateEvent) {
    this.childFormUpdates[event.componentName] = event.updated;
  }

  initializeChildFormUpdates(): ChildFormUpdates {
    const updates: ChildFormUpdates = {};
    for (const componentName of Object.values(ComponentName)) {
      updates[componentName] = false;
    }
    return updates;
  }

  closeVendor() {
    const isDirtyVendorForm = Object.values(this.childFormUpdates).some(value => value === true);
    if (isDirtyVendorForm) {
      const confirmationBox = this.dialog.open(PopUpComponent, {
        data: {
          message: 'Do you want to close this without saving',
          type: 'confirmation'
        }
      });
      confirmationBox.afterClosed().subscribe((res) => {
        if (res) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }


}
