<div class="content-wrapper">
  <h4 class="page_head">Reports</h4>
  <div class="content-wrapper-main-inner-content">
    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
      <h5 class="table_head mt-2 mb-4">Customer Analytics</h5>
      <div class="d-flex">
        <div class="me-3 mb-3 ">
          <button class="btn btn-create theme-btn-color" (click)="downloadReport()">
            <i class="fas fa-cloud-download-alt"></i> Download Report
          </button>
        </div>
        <div class=" p-0">
          <button class="btn btn-create theme-btn-color" (click)="clearFilter()">
            <i class="fas fa-redo me-2"></i>
            Reset Filters
          </button>
        </div>
      </div>
    </div>
    <table class="table table-striped table-responsive">
      <thead class="tableview theme-bg-color">
      <tr>
        <th style="width: 10%" *ngFor="let header of tableHeader">{{ header }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <ng-container *ngFor="let data of searchFieldName">
          <td>
            <div class="input-group">
              <input
                *ngIf="data === 'loginTime' || data === 'logoutTime'"
                class="w-100 customInput"
                [matDatepicker]="picker"
                placeholder="Date & Time"
                [(ngModel)]="filters[data]"
                (ngModelChange)="onChangeSearch($event, data)"
                style="position: relative; min-width: 100px"
              />
              <mat-datepicker #picker></mat-datepicker>
              <div *ngIf="data === 'loginTime' || data === 'logoutTime'" style="position: absolute; right: 1px; top: 50%; transform: translateY(-50%); background-color: white">
                <span (click)="picker.open()">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="15px" height="15px"
                       viewBox="-2.4 -2.4 28.80 28.80" stroke="#000000" stroke-width="0.00024000000000000003"
                       transform="rotate(0)"><g id="SVGRepo_bgCarrier" stroke-width="0">
                </g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC"
                     stroke-width="0.096"></g><g id="SVGRepo_iconCarrier">
                    <path
                      d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z">
                    </path>
                  </g>
                </svg>
                </span>
              </div>
            </div>
            <input
              *ngIf="data !== 'loginTime' && data !== 'logoutTime'"
              class="w-100 customInput"
              type="search"
              [(ngModel)]="filters[data]"
              (ngModelChange)="onChangeSearch($event, data)"
              [attr.pattern]="data === 'customerContact' ? '[0-9]*' : null"
              placeholder="Search"/>
          </td>
        </ng-container>
        <td></td>
        <td></td>
      </tr>

      <ng-container *ngIf="customerAnalyticsData.length > 0">
        <ng-container *ngFor="let item of customerAnalyticsData">
          <tr>
            <td>{{ item?.customerContact ?? "--" }}</td>
            <td (click)="openCustomerModal(item.customer?.id)"
                class="text-primary cursor-p">{{ item?.customerName ?? "--" }}
            </td>
            <td>{{ item?.assignedSpoc ?? "--" }}</td>
            <td>{{ item?.deviceUsed ?? "--" }}</td>
            <td>{{ item?.location ?? "--" }}</td>
            <td>{{ item?.loginTime ? formatDateTime(item.loginTime) : "--" }}</td>
            <td>{{ item?.logOutTime ? formatDateTime(item.logOutTime) : "--" }}</td>
            <td>{{ item?.duration ?? "--" }}</td>
            <td><a class="cursor-p text-primary"
                   (click)="openFormModal(item.customerContactId ?? 0, item.sessionId ?? '',item)">View</a></td>
          </tr>
        </ng-container>
      </ng-container>
      </tbody>
    </table>
    <div *ngIf="!showLoader && customerAnalyticsData.length == 0"
         class="d-flex justify-content-center mt-8 text-center">
      <div>
        <img src="assets/images.png">
        <br>
        <h4> No Result Found</h4>
      </div>
    </div>
  </div>
  <div>
    <mat-paginator [length]="totalElements"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[10,12,25,50,100]"
                   [hidden]="totalPages < 1"
                   (page)="handlePage($event)"
                   aria-label="Select page">
    </mat-paginator>
  </div>
</div>
