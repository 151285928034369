import {
    AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges,
    ViewChild
} from '@angular/core';
import {
  ToggleConfirmationDialogboxComponent
} from "../../../../util/toggle-confirmation-dialogbox/toggle-confirmation-dialogbox.component";
import {PopUpComponent} from "../../../../util/pop-up/pop-up.component";
import {ApiService} from "../../../../services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormUpdateEvent} from "../../../../shared/interfaces";
import {ToastrService} from "ngx-toastr";
import {debounceTime, distinctUntilChanged, filter, tap} from "rxjs/operators";
import {fromEvent} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {ImageCropperComponent} from "../../image-cropper/image-cropper.component";

@Component({
  selector: 'app-vendor-settings',
  templateUrl: './vendor-settings.component.html',
  styleUrls: ['./vendor-settings.component.css']
})
export class VendorSettingsComponent implements OnChanges ,AfterViewInit{
  vendorImage: any;
  vendorLogo: any;
  vendorLogoVisible: boolean;
  logoCid = '';
  logoLoader = false;
  role: string | null;
  showloader = false;
  audit: any[] = [];
  totalAuditPages: number;
  @ViewChild('fileInput') fileInput: ElementRef<HTMLDivElement>;
  @Output() vendorImageChange = new EventEmitter<string>();
  pageSizes = 10;
  file: File | null;
  @Input() vendorData: any;
  @Input() openDialogType: string;

  @Input() refreshFlag: boolean = false;
  previousFormValue: any;
  initializingForm : boolean = true;
  @Output() formUpdated = new EventEmitter<FormUpdateEvent>();
  pageSize = 5;
  totalAudit=0;
  currentAuditPage: number=0;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input',{static:true}) input:ElementRef | any;

    constructor(
    private service: ApiService,
    public dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar,
    private toastr: ToastrService,
    private isVendorsDataUpdated: IsDataUpdatedService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['refreshFlag']) {
      if (changes['refreshFlag'].currentValue != changes['refreshFlag'].previousValue) {
        this.getAuditHistory(this.vendorData?.id,this.currentAuditPage,this.pageSize);
      }
    }
  }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    this.getAuditHistory(this.vendorData?.id, this.currentAuditPage,this.pageSize);
    this.vendorLogo = this.vendorData.logo;
    if (typeof this.vendorData.logo !== 'undefined') {
      if (this.vendorData.logo !== '') {
        this.vendorLogo = this.vendorData.logo;
        this.vendorLogoVisible = true;
      } else {
        this.vendorLogoVisible = false;
      }
    } else {
      this.vendorLogoVisible = false;
    }
  }
  ngAfterViewInit() {
    this.paginator.page.pipe(
      tap(() => {
        this.getAuditHistory(this.vendorData?.id,this.paginator.pageIndex, this.paginator.pageSize);

      })
    ).subscribe();
    if (this.input) {
      fromEvent(this.input?.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => this.getAuditHistory(this.vendorData?.id,this.paginator.pageIndex, this.paginator.pageSize )),
        )
        .subscribe();
    }
  }
  getAuditHistory(id: string, page: number,size:number): void {
    this.service.getAuditHistory(id,'Vendor', page,size).then(resp => {
      this.audit = resp.content;
      this.totalAuditPages = resp['totalPages'];
      this.currentAuditPage = page;
      this.totalAudit = resp.totalElements
        this.paginator.pageIndex=resp.number;
        this.paginator.pageSize=resp.size;
    })
  }

  uploadFileTrigger(): void {
    this.fileInput.nativeElement.click();
  }

  onSelectLogo(event: any, dragEvent?: boolean): void {
    event.preventDefault();
    this.logoLoader = true;
    this.file = this.extractFile(event, dragEvent);

    if (!this.file) {
      this.logoLoader = false;
      return;
    }

    if (this.isAllowedFileType(this.file.type)) {
      this.openImageCropperDialog(this.file, event);
    } else {
      this.handleUnsupportedFileType(this.file.type);
    }
  }

  extractFile(event: any, dragEvent?: boolean): File | null {
    if (dragEvent) {
      return event.dataTransfer?.files[0] || null;
    } else {
      return event.target?.files?.[0] || null;
    }
  }

  isAllowedFileType(fileType: string): boolean {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const disallowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    return allowedTypes.includes(fileType) && !disallowedTypes.includes(fileType);
  }

  openImageCropperDialog(file: File, event: any): void {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      width: '60%',
      data: { file }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.processCropperResult(result);
      } else {
        this.handleDialogCloseWithoutResult();
      }
      (event.target as HTMLInputElement).value = '';
    });
  }

  processCropperResult(result: any): void {
    if (result.blob instanceof Blob) {
      this.handleFileUpload(result.blob, 'cropped_image.png');
    } else if (result.base64) {
      this.handleBase64Upload(result.base64);
    } else {
      console.error('Result is not a valid Blob or base64 string:', result);
      this.resetFile();
    }
  }

  handleUnsupportedFileType(fileType: string): void {
    const errorMessage = fileType.includes('pdf') || fileType.includes('doc') ? 'PDF and DOC files are not allowed.' : 'Unsupported file type.';
    console.error(errorMessage);
    this.toastr.error(errorMessage, 'Error');
    this.logoLoader = false;
  }

  handleFileUpload(blob: Blob, defaultFileName: string): void {
    const filename = this.file?.name || defaultFileName;
    const file = new File([blob], filename, { type: blob.type });
    this.uploadFile(file);
  }

  handleBase64Upload(base64: string): void {
    const file = this.base64ToFile(base64, this.file?.name || 'cropped_image.png');
    if (file) {
      this.uploadFile(file);
    } else {
      console.error('Failed to convert base64 to file');
      this.resetFile();
    }
  }

  uploadFile(file: File): void {
    const formData: FormData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);

    this.service.uploadLogoNext(formData, this.vendorData.id).subscribe(res => {
      if (res.type === 4) {
        this.isVendorsDataUpdated.setUpdated(true);
        this.logoLoader = false;
        this.vendorImage = res.body.logo;
        this.vendorImageChange.emit(res.body.logo);
        this.vendorData.logo = this.vendorImage;
      }
    });
  }

  resetFile(): void {
    this.file = null;
    this.logoLoader = false;
  }

  base64ToFile(data: string, filename: string): File | null {
    if (!data) {
      console.error('No data provided to base64ToFile');
      return null;
    }

    const arr = data.split(',');
    if (arr.length < 2) {
      console.error('Invalid base64 data:', data);
      return null;
    }

    const mimeMatch = arr[0].match(/:(.*?);/);
    if (!mimeMatch) {
      console.error('Invalid MIME type in base64 data:', arr[0]);
      return null;
    }

    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    const u8arr = new Uint8Array(bstr.length);
    for (let i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  }

  handleDialogCloseWithoutResult(): void {
    console.error('Dialog closed without result');
    this.resetFile();
  }

  delete(): void {
    this.logoLoader = true;
    this.service.deleteLogo(this.vendorData.id).then(res => {
      this.isVendorsDataUpdated.setUpdated(true);
      this.vendorImage = null;
      this.vendorImageChange.emit('')
      this.vendorData.logo = "";
      this.logoLoader = false;
      this.vendorLogoVisible = false;
    }).catch((error: any) => {
      if (error) {
        this.logoLoader = false;
      }
    });
  }

  change(e: any) {
    if (this.role == 'ADMIN') {
      if (this.vendorData.status) {
        const dialogRef = this.dialog.open(ToggleConfirmationDialogboxComponent, {
          data: {
            title: 'Alert',
            message: 'Are you sure you want to De-Activate this',
            type: 'confirmation'
          }
        });

        dialogRef.afterClosed().subscribe(response => {
          if (response) {
            this.service.vendorInactive(this.vendorData.id).then(res => {
              this.isVendorsDataUpdated.setUpdated(true);
              this.openSnackBar('Vendor is successfully De-Activate', " ");
              this.vendorData.status = !this.vendorData.status;
              this.vendorData.status = false;
            });
          } else {
            e.source.checked = true;
            console.log("toggle should not change if I click the cancel button")
          }
        })
      } else {
        const dialogRefActive = this.dialog.open(ToggleConfirmationDialogboxComponent, {
          data: {
            title: 'Alert',
            message: 'Are you sure you want to Active this',
            type: 'confirmation'
          }
        });
        dialogRefActive.afterClosed().subscribe(response => {
          if (response) {
            this.service.vendorActive(this.vendorData.id, true).then(res => {
              this.isVendorsDataUpdated.setUpdated(true);
              this.openSnackBar('Vendor is successfully Active', " ");
              this.vendorData.status = true;
            });
          } else {
            e.source.checked = false;
          }
        })
      }
    } else {
      this.openSnackBar('Only ADMIN has authority to De-Activate/Activate the vendor', " ");
    }
  }

  openDialog(action: string, id?: any): void {
    if (action === 'delete') {
      const dialogRef1 = this.dialog.open(PopUpComponent, {
        data: {
          title: 'Alert',
          message: 'Are you sure you want to delete this ' + this.vendorData.vendorName +'?',
          type: 'Delete'
        }
      });
      dialogRef1.afterClosed().subscribe((res) => {
        if (res) {
          if (this.role == 'ADMIN') {
            this.service.deleteVendors(this.vendorData.id).then(() => {
              this.isVendorsDataUpdated.setUpdated(true);
            }, (e) => {
              console.error(e);
            });
          } else {
            console.log('Only admin has authority to delete any vendor')
          }
        }
      });
    } else if (action === 'Activate_DeActivate') {
      if (this.role == 'ADMIN' && this.vendorData.status!) {
        const dialogActivate_deactivate = this.dialog.open(PopUpComponent, {
          data: {
            title: 'Alert',
            message: 'Are you sure you want to De-Activate this  ' + this.vendorData.vendorName,
            type: 'confirmation'
          }
        });
        dialogActivate_deactivate.afterClosed().subscribe((res) => {
          if (res) {
            if (this.role == 'ADMIN') {
              if (this.vendorData.status) {
                this.service.vendorInactive(id).then(res => {
                  this.isVendorsDataUpdated.setUpdated(true);
                  this.vendorData.status = false;
                  this.openSnackBar('Vendor is successfully De-Activated', " ");
                });
              } else if (!this.vendorData.status) {
                this.service.vendorActive(id, true).then(res => {
                  this.isVendorsDataUpdated.setUpdated(true);
                  this.vendorData.status = true;
                  this.openSnackBar('Vendor is successfully Activated', " ");
                });
              }
            } else {
              this.openSnackBar('Only ADMIN has authority to de-Activate/Activate the vendor', " ");
            }
          }
        });
      } else {
        if (this.role == 'ADMIN') {
          if (this.vendorData.status) {
            this.service.vendorInactive(id).then(res => {
              this.isVendorsDataUpdated.setUpdated(true);
              this.vendorData.status = false;
              this.openSnackBar('Vendor is successfully In-Active', " ");
            });
          } else if (!this.vendorData.status) {
            this.service.vendorActive(id, true).then(res => {
              this.isVendorsDataUpdated.setUpdated(true);
              this.vendorData.status = true;
              this.openSnackBar('Vendor is successfully Active', " ");
            });
          }
        } else {
          this.openSnackBar('Only ADMIN has authority to de-Activate/Activate the vendor', " ");
        }
      }

    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getInitials(firstName: string, lastName: string): string {
    let firstNames = firstName.trim().replace(/_/g, '').split(' '),
      initials = firstNames[0].substring(0, 1).toUpperCase();
    let lastNames = lastName.trim().replace(/_/g, '').split(' '),
      initialsLast = lastNames[0].substring(0, 1).toUpperCase();

    if (firstNames.length > 1) {
      initials += firstNames[firstNames.length - 1].substring(0, 1).toUpperCase();
    }
    if (lastNames.length > 1) {
      initialsLast += lastNames[lastNames.length - 1].substring(0, 1).toUpperCase();
    }
    return (initials + initialsLast);
  }

  handleDragOver(event: DragEvent): void {
    event.preventDefault();
  }

}
