import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {ComponentName, FormUpdateEvent, socialLink} from "../../../../shared/interfaces";
import {ApiService} from "../../../../services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DeletePopupComponent} from "../../../delete-popup/delete-popup.component";
import {debounceTime, distinctUntilChanged, filter, map} from "rxjs/operators";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";

@Component({
  selector: 'app-vendor-social-links',
  templateUrl: './vendor-social-links.component.html',
  styleUrls: ['./vendor-social-links.component.css']
})

export class VendorSocialLinksComponent implements OnInit {
  socialLinksForm: FormGroup;
  socialError = '';
  showloader = false;
  linkRes: socialLink[] = [];
  role: string | null;
  editSocialForm: FormGroup;

  @Input() vendorData: any;
  @Input() openDialogType: string;


  previousFormValue: any;
  initializingForm : boolean = true;
  @Output() formUpdated = new EventEmitter<FormUpdateEvent>();
  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    public dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar,
    private isVendorsDataUpdated: IsDataUpdatedService,
  ) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    this.socialLinksForm = this.fb.group({
      label: '',
      value: ''
    })
    this.editSocialForm = this.fb.group({
      socialRows: this.fb.array([])
    });
    this.previousFormValue = this.socialLinksForm.value;
    this.initializingForm = false;
    this.getSocialLink();

    this.socialLinksForm.valueChanges.pipe(
      debounceTime(500),
      filter(() => !this.initializingForm),
      map(currentValue => JSON.stringify(currentValue) !== JSON.stringify(this.previousFormValue)),
      distinctUntilChanged()
    ).subscribe(isFormUpdated => {
      this.formUpdated.emit({ updated: isFormUpdated, componentName: ComponentName.VendorSocialLinks });
    });
  }

  getSocialLink() {
    this.service.getSocialLink(this.vendorData.id).then(response => {
      this.linkRes = response;
      const control = <FormArray>this.editSocialForm.get('socialRows');
      this.linkRes?.forEach(res => {
        const form = this.fb.group({
          label: [res.label],
          value: [res.value],
          id: [res.id],
          isEditable: false,
        });
        control.push(form);
      })
    })
  }

  getFormValidationErrors(): string {
    let error = '';
    Object.keys(this.socialLinksForm.controls).forEach(key => {
      const controlErrors = this.socialLinksForm.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          error = error + key + ', ';
        });
      }
    });
    return error;
  }

  addSocial(): void {
    this.socialError = '';
    this.showloader = true;
    if (this.socialLinksForm.invalid) {
      this.showloader = false;
      this.socialError = 'error in' + this.getFormValidationErrors() + 'see console fro details';
      return;
    } else {
      this.service.addVendorSocial(this.socialLinksForm.value, this.vendorData.id).then(res => {
        this.isVendorsDataUpdated.setUpdated(true);
        this.showloader = false;
        this.socialLinksForm.reset();
        this.formUpdated.emit({ updated: false, componentName: ComponentName.VendorSocialLinks });

        if (this.linkRes.length <= 0) {
          this.getSocialLink();
        } else {
          const control = <FormArray>this.editSocialForm.get('socialRows');
          const form = this.fb.group({
            label: [res.label],
            value: [res.value],
            id: [res.id],
            isEditable: false,
          });
          control.push(form);
        }
      }, (e) => {
        this.showloader = false;
        if (e.error.status == 400) {
          this.socialError = 'Select a file ';
        } else if (e.error.status == 500) {
          this.socialError = e.error.message;
        }
      });
    }
  }
  updateSocialLink(group: any) {
    const socialLink = group.get('id').value;
    const formData = {
      label: group.get('label').value,
      value: group.get('value').value,
    }
    this.service.updateSocialLink(formData, this.vendorData.id, socialLink).then(res => {
      this.isVendorsDataUpdated.setUpdated(true);
      group.get('isEditable').setValue(false);
    })
  }

  get getSocialFormControls() {
    return this.editSocialForm.get('socialRows') as FormArray;
  }

  editSocialLink(group: any) {
    group.get('isEditable').setValue(true);
  }

  deleteSocialLink(group: any, index: number) {
    this.dialog.open(DeletePopupComponent,{
      data: {
        message: 'Are you sure you want to delete this Social Link ?',
        type: 'Delete'
      }
    }).afterClosed().subscribe(resp => {
      if (resp) {
        const contactId = group.get('id').value;
        this.service.deleteSocialLinks(this.vendorData.id, contactId).then(() => {
          this.isVendorsDataUpdated.setUpdated(true);
          const control = this.editSocialForm.get('socialRows') as FormArray;
          control.removeAt(index);
        });
      }
    })
  }

}
