import { Injectable } from '@angular/core';
import {LoggedInUser} from "../shared/interfaces";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DataService {
  public isLoading = new BehaviorSubject<boolean>(false);
  public companyName = new BehaviorSubject<string>("");
  public companyImage = new BehaviorSubject<string>("");
  public loggedInUser = new BehaviorSubject<LoggedInUser>(<LoggedInUser>'');

  constructor() { }

  dialogConfig(data: any): any {
    return {
      width: "100%",
      // height: "auto",
      data,
      animation: {
        to: "center",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 600 }
        },
      },
    }
  }

  jobDialogConfig(data: any): any {
    return {
      width: '100%',
      // height:'auto',
      data,
      animation: {
        to: "center",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 600 }
        },
      },
    }
  }

  AssigneeConfig(data: any): any {
    return {
      width: '40%',
      // height: '40%',
      data,
      animation: {
        to: "center",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 600 }
        },
      },
    }
  }

  editCustomerConfig(data: any): any {
    return {
      width: '50%',
      // height: '60%',
      data,
      animation: {
        to: "center",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 600 }
        },
      },
    }
  }
}
