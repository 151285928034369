import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private storageKey = 'navigation-state';

  saveState(scrollPosition: number, pageNumber: number, url: string) {
    const state = {
      scrollPosition,
      pageNumber,
      previousUrl: url
    };
    localStorage.setItem(this.storageKey, JSON.stringify(state));
  }

  getState(): { scrollPosition: number, pageNumber: number, previousUrl: string } | null {
    const state = localStorage.getItem(this.storageKey);
    return state ? JSON.parse(state) : null;
  }

  clearState() {
    localStorage.removeItem(this.storageKey);
  }
}
