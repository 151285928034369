import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ApiService} from "../../../services/api.service";
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {DataService} from "../../../services/data.service";
import {PopUpComponent} from "../../../util/pop-up/pop-up.component";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {ImageCropperComponent} from "../image-cropper/image-cropper.component";
import {Common} from "../../../shared/Common";
import {ThemeLoaderService} from "../../../services/theme-loader.service";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  user: any;
  userImage: string;
  type = '';
  userProfileForm: FormGroup;
  logoLoader = false;
  showloader = false;
  displayError = false;
  errorMessage = '';
  loading = false;
  file: File | null;
  dragOver: boolean = false;
  data: any;
  userId: any;
  @ViewChild('myFileInput') myFileInput: any;

  @ViewChild('fileInput') fileInput: ElementRef<HTMLDivElement>;
  private companyName: any;
  des: string;
  files: boolean;
  loginType = '';

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private service: ApiService,
    public dialog: MatDialog,
    public toaster: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.loginType = localStorage.getItem('loginType') ?? '';
    this.dataService.isLoading.next(true);
    this.userId = localStorage.getItem('orgId');
    this.userProfileForm = this.fb.group({
      firstName: [{value: '', disabled: true}, Validators.required],
      lastName: [{value: '', disabled: true}, Validators.required],
      email: [{
        value: '',
        disabled: true
      }, [Validators.required, Validators.pattern("[a-zA-Z0-9._%+-]+@[a-z0-9A-Z.-]+.[a-z]{2,}$")]],
      mobile: ["", [Validators.required, Validators.pattern("^[0-9]{10}$")]],
      role: ""
    });

    if (this.loginType === 'Vendor') {
      this.getVendorDetails();
    } else {
      this.getUserDetails();
    }
  }

  getVendorDetails() {
    this.service.getLoginUser().subscribe({
      next: res => {
        this.user = res;
        this.userImage = res.picture;
        this.userProfileForm.get("firstName")?.setValue(res.firstName);
        this.userProfileForm.get("lastName")?.setValue(res.lastName);
        this.userProfileForm.get("email")?.setValue(res.email);
        this.userProfileForm.get("mobile")?.setValue(res.mobile);
        this.userProfileForm.get("role")?.setValue(res.role);
        localStorage.setItem('role', res.role);
        this.loading = false;
      },
      error: err => {
      },
      complete: () => {
        this.dataService.isLoading.next(false);
      }
    });
  }

  getUserDetails() {
    this.service.getCustomerLoginUser().subscribe({
      next: res => {
        this.user = res;
        this.userImage = res.profilePicture;
        this.userProfileForm.get("firstName")?.setValue(res.name);
        this.userProfileForm.get("lastName")?.setValue(res.lastName);
        this.userProfileForm.get("email")?.setValue(res.email);
        this.userProfileForm.get("mobile")?.setValue(res.mobile);
        this.userProfileForm.get("role")?.setValue(res.role);
        localStorage.setItem('role', res.role);
        this.loading = false;
      },
      error: err => {
      },
      complete: () => {
        this.dataService.isLoading.next(false);
      }
    });
  }

  onSelectFile(event: any, dragEvent?: boolean): void {
    event.preventDefault();
    this.errorMessage = '';
    this.dragOver = false;
    if (event.dataTransfer && event.dataTransfer.items && event.dataTransfer.items[0].kind !== 'file') {
      this.errorMessage = 'Only files are allowed.';
      return;
    }
    this.file = this.getFileFromEvent(event, dragEvent);
    if (!this.file) {
      return;
    }
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const disallowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    if (allowedTypes.includes(this.file.type)) {
      this.openImageCropperDialog(this.file, event);
    } else if (disallowedTypes.includes(this.file.type)) {
      this.handleDisallowedFileType('PDF and DOC files are not allowed.');
    } else {
      this.handleDisallowedFileType('Unsupported file type.');
    }
  }

  getFileFromEvent(event: any, dragEvent?: boolean): File | null {
    if (dragEvent) {
      return event.dataTransfer?.files[0] || null;
    } else {
      return event.target?.files?.[0] || null;
    }
  }

  openImageCropperDialog(file: File, event: any): void {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      width: '60%',
      data: {file}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.processCropperResult(result);
      } else {
        this.handleDialogCloseWithoutResult();
      }
      (event.target as HTMLInputElement).value = '';
    });
  }

  processCropperResult(result: any): void {
    if (result.blob instanceof Blob) {
      this.handleBlobResult(result.blob);
    } else if (result.base64) {
      this.handleBase64Result(result.base64);
    } else {
      this.handleInvalidResult();
    }
  }

  handleDisallowedFileType(errorMessage: string): void {
    console.error(errorMessage);
    this.logoLoader = false;
    this.dragOver = false;
  }

  handleDialogCloseWithoutResult(): void {
    console.error('Dialog closed without result');
    this.resetFile();
    this.logoLoader = false;
    this.dragOver = false;
  }

  handleInvalidResult(): void {
    console.error('Result is not a valid Blob or base64 string');
    this.resetFile();
    this.logoLoader = false;
    this.dragOver = false;
  }

  handleBlobResult(blob: Blob): void {
    const file = this.createFileFromBlob(blob, this.file?.name || 'cropped_image.png');
    this.uploadFile(file);
  }

  handleBase64Result(base64: string): void {
    const file = this.base64ToFile(base64, this.file?.name || 'cropped_image.png');
    if (file) {
      this.uploadFile(file);
    } else {
      console.error('Failed to convert base64 to file');
      this.resetFile();
    }
  }

  uploadFile(file: File): void {
    const formData: FormData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);
    this.logoLoader = true;
    const uploadObservable = this.loginType === 'Vendor' ?
      this.service.uploadAdminPicture(formData, this.userId) :
      this.service.uploadUserProfilePicture(formData, this.user.id);
    uploadObservable.subscribe(res => {
      this.userImage = res.body?.picture || res.body?.profilePicture;
      if (res.type === 4) {
        this.logoLoader = false;
        this.dragOver = false;
      }
    }, error => {
      console.error(error);
      this.logoLoader = false;
      this.dragOver = false;
    });
  }

  createFileFromBlob(blob: Blob, filename: string): File {
    return new File([blob], filename, {type: blob.type});
  }

  resetFile(): void {
    this.file = null;
    this.logoLoader = false;
  }

  base64ToFile(data: string, filename: string): File | null {
    if (!data) {
      console.error('No data provided to base64ToFile');
      return null;
    }

    const arr = data.split(',');
    if (arr.length < 2) {
      console.error('Invalid base64 data:', data);
      return null;
    }

    const mimeMatch = arr[0].match(/:(.*?);/);
    if (!mimeMatch) {
      console.error('Invalid MIME type in base64 data:', arr[0]);
      return null;
    }

    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    const u8arr = new Uint8Array(bstr.length);
    for (let i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, {type: mime});
  }


  getFormValidationErrors(): string {
    let error = '';
    Object.keys(this.userProfileForm.controls).forEach(key => {
      const controlErrors = this.userProfileForm.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          error = error + key + ', ';
        });
      }
    });
    return error;
  }

  onKeyPress(event: KeyboardEvent): void {
    const allowedChars = /[0-9]/;
    const key = event.key;
    const isValidInput = allowedChars.test(key);

    if (!isValidInput) {
      event.preventDefault();
      this.displayError = true;
      this.errorMessage = "Please enter valid mobile number";
      console.log("please enter only number")
    } else {
      this.errorMessage = "";
    }
  }

  updateuserProfile(): void {
    this.showloader = true;
    if (this.userProfileForm.invalid) {
      this.showloader = false;
      this.errorMessage = 'Please check the fields ' + this.getFormValidationErrors();
      return;
    } else {
      const updatedUserData = {
        firstName: this.userProfileForm.get('firstName')?.value,
        lastName: this.userProfileForm.get('lastName')?.value,
        mobile: this.userProfileForm.get('mobile')?.value,
      };

      this.service.updateUser(updatedUserData).then(res => {
        this.toaster.success('Profile Updated Successfully');
        this.showloader = false;
        this.errorMessage = '';
        this.dataService.companyName.next(res.name);
      }, (e) => {
        this.toaster.error(`Error ${e.error.message}`);
        this.showloader = false;
        if (e.error.status === 400) {
          this.errorMessage = e.error.message;
        } else if (e.error.status === 500) {
          this.errorMessage = e.error.message;
        } else if (e.error.status === 409) {
          this.errorMessage = e.error.message;
        } else {
          this.errorMessage = 'Error occurred, Please contact server team';
        }
      });
    }
  }

  delete(): void {
    console.log('data');
    const dialogDeleteProfile = this.dialog.open(PopUpComponent, {
      data: {
        title: 'Alert',
        message: 'Are you sure you want to delete this ? ',
        type: 'Delete'
      }
    });
    dialogDeleteProfile.afterClosed().subscribe((res: any) => {
      if (res) {
        if (this.loginType == 'Vendor') {
          this.service.deleteVendorPicture()
            .then(() => {
              this.getVendorDetails()
              this.userImage = '';
              this.file = null
              if (this.fileInput && this.fileInput.nativeElement) {
                this.myFileInput.nativeElement.value = '';
              }
              this.dataService.companyImage.next('');
              this.toaster.success('Profile Picture Deleted', 'Success');
            })
            .catch(error => {
              this.toaster.error('Error Occurred', 'error');
              console.error("Error deleting user picture:", error);
            });

        } else {
          this.service.deleteUserPicture(this.user?.id ?? 0)
            .then(() => {
              this.getUserDetails()
              this.userImage = '';
              this.file = null;
              if (this.fileInput && this.fileInput.nativeElement) {
                this.myFileInput.nativeElement.value = '';
              }
              this.dataService.companyImage.next('');
              this.toaster.success('Profile Picture Deleted', 'Success');
            })
            .catch(error => {
              this.toaster.error('Error Occurred', 'error');
              console.error("Error deleting user picture:", error);
            });
        }
      }
    });
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      this.dragOver = true;
    }, 2000);
  }

  onDragEnter(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      this.dragOver = false;
    }, 2000);

  }
}
