import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {shareReplay} from "rxjs/operators";
import {Observable} from 'rxjs/internal/Observable';
import { DataType } from 'src/app/client/enums/dataType.enum';

@Injectable({
  providedIn: 'root'
})

export class FilterServices {

  private cachedData: { [key: string]: Observable<any[]> } = {};

  constructor(protected service: ApiService) {}

  commonSearchTerms<T>(dataType: string, query: string): Observable<T[]> {
    if (!this.cachedData[dataType]) {
      switch (dataType) {
        case DataType.Skills:
          this.cachedData[dataType] = this.service.searchConsultantSkills(query).pipe(shareReplay(1));
          break;
        case DataType.Cities:
          this.cachedData[dataType] = this.service.searchConsultantCities(query).pipe(shareReplay(1));
          break;
        default:
          throw new Error(`Unsupported data type: ${dataType}`);
      }
    }
    return this.cachedData[dataType] as Observable<T[]>;
  }

}
