<div class="modal-popUp-content content-wrapper-main-inner-content job_wrapper">
  <div class="member_wrapper theme-bg-color">
    <div class="row mt-30 modal-inner-content">
      <div class="col-md-4 content-header">
        <div>
          <h1 class="page-title text-white">
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.99992 29.8334C7.26658 29.8334 6.63903 29.5725 6.11725 29.0508C5.59459 28.5281 5.33325 27.9001 5.33325 27.1668V5.83342C5.33325 5.10008 5.59459 4.47208 6.11725 3.94941C6.63903 3.42764 7.26658 3.16675 7.99992 3.16675H18.6666L26.6666 11.1667V16.5001H23.9999V12.5001H17.3332V5.83342H7.99992V27.1668H15.9999V29.8334H7.99992ZM7.99992 27.1668V5.83342V27.1668ZM24.3999 19.8668L25.8332 21.3001L20.6666 26.4334V27.8334H22.0666L27.2332 22.7001L28.6332 24.1001L22.8999 29.8334H18.6666V25.6001L24.3999 19.8668ZM28.6332 24.1001L24.3999 19.8668L26.3332 17.9334C26.5777 17.689 26.8888 17.5668 27.2666 17.5668C27.6444 17.5668 27.9555 17.689 28.1999 17.9334L30.5666 20.3001C30.811 20.5445 30.9332 20.8556 30.9332 21.2334C30.9332 21.6112 30.811 21.9223 30.5666 22.1668L28.6332 24.1001Z"
                fill="white"/>
            </svg>
            {{ 'Edit Customer Contact' }}
          </h1>
        </div>
      </div>
      <div class="col-md-8 d-flex justify-content-end">
        <a style="cursor: pointer" class="cursor-pointer" (click)="closeEditCustomer()">
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2872 1L1 17.2871" stroke="white" stroke-width="2" stroke-linecap="round"/>
            <path d="M1 1L17.2872 17.2871" stroke="white" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </a>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center">
    <div class="create-vendor col-md-10 mt-5">
      <form [formGroup]="customerContactForm" ngNativeValidate (submit)="updateContact()">
        <div class="form-row mb-2">
          <div class="form-group col-md-6">
            <mat-form-field appearance="outline" class="w-100 border-red ">
              <mat-label>First Name</mat-label>
              <input matInput placeholder="First Name" formControlName="firstName">
            </mat-form-field>
          </div>
          <div class="form-group col-md-6">
            <mat-form-field appearance="outline" class="w-100 border-red ">
              <mat-label>Last Name</mat-label>
              <input matInput placeholder="Last Name" formControlName="lastName">
            </mat-form-field>
          </div>
        </div>
        <div class="form-row  mb-2">
          <div class="form-group col-md-6">
            <mat-form-field appearance="outline" class="w-100 border-red ">
              <mat-label>Designation</mat-label>
              <mat-select formControlName="designation">
                <mat-option *ngFor="let option of designationRes"
                            [value]="option.name"
                            (onSelectionChange)="chooseDesType($event)">
                  {{ option.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="(filteredDesignation|async)?.length == 0" class="cdk-overlay-pane dropdown-skills">
              <ul class="mat-autocomplete-panel mat-autocomplete-visible mw-100 h-50">
                <li class="li_disabled mat-option-disabled">
                  No match Found
                </li>
                <hr class="m-0 p-0">
                <li class="font-weight-bold mat-option">
                  <div class="d-flex justify-content-between align-items-center"
                       (click)="addDesType($event)">
                    <span>Add this Designation type:</span>
                    <button class="p-0 bg-transparent border-0 fs-20"> +</button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group col-md-6">
            <mat-form-field appearance="outline" class="w-100 border-red">
              <mat-label>Email id</mat-label>
              <input matInput placeholder="Email" formControlName="email" type="email" readonly>
            </mat-form-field>
          </div>
        </div>
        <div class="form-row  mb-2">
          <div class="form-group col-md-6">
            <mat-form-field appearance="outline" class="w-100 border-red">
              <mat-label>Mobile number</mat-label>
              <input matInput placeholder="Mobile" minlength="10" maxlength="10" formControlName="mobile"
                     (input)="onKeyPress($event)" type="text">
            </mat-form-field>
            <div *ngIf="displayError" class="text-danger">
              {{ this.errorMessage }}
            </div>
          </div>
          <div class="form-group col-md-6">
            <mat-form-field appearance="outline" class="w-100 border-red">
              <mat-label>LinkedIn URL</mat-label>
              <input matInput placeholder="Linkedin URL" formControlName="linkedIn" type="text">
            </mat-form-field>
          </div>
        </div>
        <div class="form-row  mb-2">
          <div class="form-group col-md-6">
            <mat-form-field appearance="outline" class="w-100 border-red ">
              <mat-label>Vertical</mat-label>
              <mat-select formControlName="vertical" required [disabled]="role=='MANAGER' || role=='TA'">
                <mat-option *ngFor="let option of verticalList"
                            [value]="option.name"
                            (onSelectionChange)="chooseVerticalType($event)">
                  {{ option.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group col-md-6">
            <mat-form-field appearance="outline" class="w-100 border-red">
              <mat-label>SPOC</mat-label>
              <mat-select formControlName="spoc" (selectionChange)="chooseSpoc($event)"
                          [disabled]="role=='MANAGER' || role=='TA'" [compareWith]="compareSpoc" [required]="true">
                <mat-option *ngFor="let option of SPOC_Options"
                            [value]="option">
                  {{ option.role + ' | ' + option.firstName + ' ' + option.lastName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex justify-content-end align-items-baseline mt-4">
          <div class="save-skill">
            <button *ngIf="!showloader" class="btn btn-save mx-3 theme-bg-color"
                    type="submit">Update
            </button>
            <div *ngIf="showloader" disabled>
              <button class="btn btn-saveform" disabled>
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
