import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SolutionService {

  private solutionData: any;

  constructor() { }

  saveSolutionData(data: any) {
    this.solutionData = data;
  }

  getSolutionData() {
    return this.solutionData;
  }

}
