import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatStepper } from '@angular/material/stepper';
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  emailFormGroup: FormGroup;
  otpFormGroup: FormGroup | any;
  passwordFormGroup: FormGroup;
  errorMessage = '';
  @ViewChild('confirmPasswordInput') confirmPasswordInput: ElementRef<HTMLInputElement>;
  @ViewChild('passwordInput') passwordInput: ElementRef<HTMLInputElement>;
  @ViewChild('faIconPasswordInputButton') faIconPasswordInputButton: ElementRef<HTMLElement>;
  @ViewChild('faIconConfirmPasswordInputButton') faIconConfirmPasswordInputButton: ElementRef<HTMLElement>;
  constructor(private _formBuilder: FormBuilder, private route: ActivatedRoute, private service: ApiService) {
  }
  ngOnInit(): void {
    this.emailFormGroup = this._formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-z0-9A-Z.-]+.[a-z]{2,}$')]],
    });
    this.otpFormGroup = this._formBuilder.group({
      otp: ['', Validators.required],
    });
    this.passwordFormGroup = this._formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, {
      validator: ConfirmedValidator('password', 'confirmPassword')
    });
    this.route.params.subscribe(res => {
      if (res.username && res.username != 'NA') {
        this.emailFormGroup.get('email')?.setValue(res.username);
      }
    });
  }
  passwordToggle(): void {
    if (this.passwordInput.nativeElement.type == 'text') {
      this.passwordInput.nativeElement.type = 'password';
      this.faIconPasswordInputButton.nativeElement.className = 'fas fa-eye';
    } else {
      this.faIconPasswordInputButton.nativeElement.className = 'fas fa-eye-slash';
      this.passwordInput.nativeElement.type = 'text';
    }
  }

  confirmPasswordToggle(): void {
    if (this.confirmPasswordInput.nativeElement.type == 'text') {
      this.confirmPasswordInput.nativeElement.type = 'password';
      this.faIconConfirmPasswordInputButton.nativeElement.className = 'fas fa-eye';
    } else {
      this.faIconConfirmPasswordInputButton.nativeElement.className = 'fas fa-eye-slash';
      this.confirmPasswordInput.nativeElement.type = 'text';
    }
  }

  sendOtpMail(stepper: MatStepper): void {
    if (this.emailFormGroup.invalid) {
      this.errorMessage = 'Enter a valid email-id'
      return;
    }
    this.errorMessage = '';
    this.service.sendResetMail(this.emailFormGroup.get('email')?.value).then(res => {
      stepper.next();
    }, (e) => {
      this.errorMessage = e.error.reason;
    });
  }
  get otp() {
    return this.otpFormGroup.get('otp');
  }
  verifyOtp(stepper: MatStepper): void {
    if (this.otpFormGroup.invalid) {
      this.errorMessage = 'Enter OTP'
      return;
    }
    this.errorMessage = '';
    this.service.checkResetPasswordToken(this.otpFormGroup.get('otp')?.value).then(res => {
      if (res == 'valid') {
        stepper.next();
      } else if ("invalidToken") {
        this.errorMessage = 'OTP is invalid';
      }
    });
  }

  changePassword(stepper: MatStepper): void {
    if (this.passwordFormGroup.invalid) {
      let error = '';
      Object.keys(this.passwordFormGroup.controls).forEach(key => {
        const controlErrors = this.passwordFormGroup.get(key)?.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            if (keyError === 'required') {
              error = error + key + ' is required.';
            } else {
              error = error + 'New passwod and Confirm password are not matching.';
            }
          });
        }
      });
      this.errorMessage = error;
      return;
    }
    this.errorMessage = '';
    if (this.passwordFormGroup.get('password')?.value === this.passwordFormGroup.get('confirmPassword')?.value) {
      this.service.resetPassword(this.otpFormGroup.get('otp')?.value,
        this.passwordFormGroup.get('password')?.value,
        this.emailFormGroup.get('email')?.value).then(updated => {
          if (updated) {
            stepper.next();
          }
        });
    }
  }

}
export function ConfirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
