<div class="content-wrapper">
  <h1 class="text-center mb-4 mt-4" style="font-size: 40px; font-weight: bold">How ProTeamMate Portal Works?</h1>
  <div class="container-fluid">
    <div class="row">
      <div class=" ">
        <div class=" mb-1 bg-white">
          <div class="d-flex justify-content-between">
            <div class=" col-md-4 vendor-search mb-3">
              <div id='search-box'>
                <input (keyup)="onSearchClick()" [(ngModel)]="search" id='search-text'
                       type='text' placeholder="Search candidates"/>
                <button class="search-button" (click)="onSearchClick()" type='button'>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                            d="M19.7479 18.5593L14.0096 12.8169C15.1409 11.4602 15.8213 9.72641 15.8213 7.83277C15.8213 3.51266 12.2758 0 7.91475 0C3.55364 0 0 3.51675 0 7.83687C0 12.157 3.54544 15.6696 7.90655 15.6696C9.7592 15.6696 11.4643 15.0343 12.8169 13.9727L18.5757 19.7315C18.9118 20.0676 19.4118 20.0676 19.7479 19.7315C20.084 19.3954 20.084 18.8954 19.7479 18.5593ZM1.6805 7.83687C1.6805 4.44308 4.47587 1.6846 7.90655 1.6846C11.3372 1.6846 14.1326 4.44308 14.1326 7.83687C14.1326 11.2307 11.3372 13.9891 7.90655 13.9891C4.47587 13.9891 1.6805 11.2266 1.6805 7.83687Z"
                            fill="#383838"/>
                  </svg>
                </button>
              </div>
            </div>
            <div class="d-flex justify-content-end align-items-center">
              <div class="ms-3 p-0"*ngIf="viewType=='tabular'">
                <button class="btn btn-create theme-btn-color" (click)="clearFilter()">
                  <i class="fas fa-redo me-2"></i>
                  Reset Filters
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-12 vendor-search-table">
            <div *ngIf="viewType=='tabular'">
              <table class="table table-striped">
                <thead class="tableview theme-bg-color">
                <tr>
                  <th style="cursor: pointer ; width: 15%" >Consultant ID</th>
                  <th style="cursor: pointer;  width: 30%">Role Title</th>
                  <th style="cursor: pointer;  width: 20%">Experience(YY/MM)</th>
                  <th style="cursor: pointer;  width: 35%">Skills</th>
                </tr>
                </thead>
                <tbody>

                <ng-container>
                  <tr>
                    <td>
                      <input
                        class="customInput"
                        type="search"
                        placeholder="Search"
                        [(ngModel)]="consultantId"
                        (ngModelChange)="onInputChange($event,'consultantId')"
                      >
                    </td>
                    <td>
                      <input
                        class="customInput"
                        type="search"
                        placeholder="Search"
                        [(ngModel)]="roleTitle"
                        (ngModelChange)="onInputChange($event,'consultantName')"
                      >
                    </td>
                    <td>
                      <div class="dropdown" id="dropdownExperience" #dropdownExperience>
                        <button class="experienceButton dropdown-toggle" type="button"
                                (click)="toggleDropdown('dropdownExperience')"
                                style="font-size: 12px; color: rgba(0,0,0,0.5)">
                          Experience({{ selectedItemsExperience.length }})
                        </button>
                        <div class="dropdown-menu">
                          <div
                            *ngFor="let option of experienceOptions"
                            class="dropdown-item"
                            [class.selected]="isSelectedExperience(option, selectedItemsExperience)"
                            (click)="toggleSelectionExperience(option, selectedItemsExperience)">
                            {{ option.label }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="dropdown" id="dropdownSkills" #dropdownSkills>
                        <button class="experienceButton dropdown-toggle" type="button"
                                style="font-size: 12px; color: rgba(0,0,0,0.5)"
                                (click)="toggleDropdown('dropdownSkills')">
                          Skills({{ selectedSkill.length }})
                        </button>
                        <div class="dropdown-menu">
                          <mat-form-field appearance="outline" class="customMultiSelect">
                            <mat-chip-list #chipList aria-label="skill selection">
                              <mat-chip
                                class="matChip"
                                *ngFor="let skill of selectedSkill"
                                (removed)="removeSkill(skill)">
                                {{ skill.title }}
                                <button class="removeButton" matChipRemove><span>&#x2716;</span></button>
                              </mat-chip>
                              <input #skillsAutoCompleter
                                     placeholder="skills..."
                                     #chipGrid
                                     [formControl]="skillControl"
                                     [matAutocomplete]="auto"
                                     [matChipInputFor]="chipList"
                                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                     [(ngModel)]="skillName"
                                     #autoTrigger='matAutocompleteTrigger'
                                     (click)="openAutocomplete(autoTrigger)"
                              >
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectSkills($event)">
                              <mat-option *ngFor="let skill of filteredSkills | async" [value]="skill"
                                          [disabled]="disableSkill(skill)">
                                {{ skill.title }}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>

                <tr *ngFor="let consultant of consultants , let i=index" class="vendors-listing mb-3 position-relative">
                  <td class="col-md-2">
                  <span style="cursor: pointer; color: #71BF45" class="theme-text-color fs-7 fw-bold" title="Edit "
                        (click)="openDialogs(consultant)">
                    {{ consultant.consultantId }} {{ consultant.shortlisted ? "*" : "" }}</span>
                  </td>
                  <td class="col-md-3 text-capitalize">{{ consultant.title ?? '--' }}</td>
                  <td class="col-md-2">{{
                      (consultant.experienceYear || consultant.experienceMonth) ?
                        ((consultant.experienceYear != 0) ? (consultant.experienceYear + ' yrs') : '' || '') + " " + (((consultant.experienceMonth != 0) ? (consultant.experienceMonth + ' months') : '') || '') :
                        '--'
                    }}
                  </td>
                  <td class="col-md text-capitalize">
                    <div *ngIf="consultant.primarySkills!=null">
                      <div>
                        {{ this.getSkills((consultant.primarySkills.concat(consultant.secondarySkills ?? [])))['skillsString'] }}
                        <span matTooltip="{{ (this.getSkills(consultant.primarySkills.concat(consultant.secondarySkills ?? []))).pendingSkills.length > 0
                                      ? (this.getSkills(consultant.primarySkills.concat(consultant.secondarySkills ?? []))).pendingSkills.join(', ')
                                      : (this.getSkills(consultant.primarySkills.concat(consultant.secondarySkills ?? []))).skillsString }}"
                              matTooltipPosition="before"
                              matTooltipClass="custom-tooltip"
                              *ngIf=" this.getSkills((consultant.primarySkills.concat( consultant.secondarySkills??[]) ))['pendingSkillsCount']>0"
                              class="badge badge-pill badge-light">
                <i class="fas fa-plus fa-xs "></i>
                  <span class="pt-1"
                        style="font-size: 11px">     {{ this.getSkills((consultant.primarySkills.concat(consultant.secondarySkills ?? [])))['pendingSkillsCount'] }}</span>
                </span>
                      </div>
                    </div>
                    <div *ngIf="consultant.primarySkills?.length==0 && consultant.secondarySkills?.length==0 ">
                      --
                    </div>
                  </td>

                </tr>
                </tbody>
              </table>
            </div>

            <div *ngIf="viewType=='grid'">
              <div *ngIf="consultants.length>0" class="grid_wrapper mt-1">
                <div (click)="openDialogs(consultant)" class="card border-0 mb-4 shadow-sm"
                     *ngFor="let consultant of consultants  let i = index"
                     [attr.data-index]="i">
                  <div style="height: 20px;border-top-left-radius: 4px;border-top-right-radius: 4px"
                       class="theme-bg-color"></div>
                  <div class="card-body d-flex justify-content-between">
                    <div>
                      <div class="card-title">
                        <div>
                          <img class="circle"
                               src="{{(consultant.showProfilePicture)? (consultant.profilePicture!=null?consultant.profilePicture: 'assets/other.svg.png'):'assets/other.svg.png' }}"/>
                        </div>
                        <div class="consultant_info">
                          <div class="consult ms-2">Consultant ID: <b>{{ consultant.consultantId ?? '--' }}</b></div>
                          <div class="subtitle ms-3 mt-1">{{ consultant.title ?? '--' }}</div>
                        </div>
                      </div>
                      <div class="bottom-line"></div>
                      <div class="work_exp">Work Experience (YY/MM): <span>{{
                          (consultant.experienceYear || consultant.experienceMonth) ?
                            ((consultant.experienceYear != 0) ? (consultant.experienceYear + ' yrs') : '' || '') + " " + (((consultant.experienceMonth != 0) ? (consultant.experienceMonth + ' months') : '') || '') :
                            '--'
                        }}</span></div>
                      <div class="bottom-line"></div>
                      <div *ngIf="consultant.primarySkills != null">
                        <div class="work_exp">
                          Skills:
                          <span class="me-2">
                          {{
                              getSkillsForGrid(consultant.primarySkills.concat(consultant.secondarySkills ?? []))['skillsString'] ?
                                getSkillsForGrid(consultant.primarySkills.concat(consultant.secondarySkills ?? []))['skillsString'] : "--"
                            }}
                        </span>
                          <span matTooltip="{{ (this.getSkills(consultant.primarySkills.concat(consultant.secondarySkills ?? []))).pendingSkills.length > 0
                                      ? (this.getSkills(consultant.primarySkills.concat(consultant.secondarySkills ?? []))).pendingSkills.join(', ')
                                      : (this.getSkills(consultant.primarySkills.concat(consultant.secondarySkills ?? []))).skillsString }}"
                                matTooltipPosition="before"
                                matTooltipClass="custom-tooltip"
                                *ngIf=" this.getSkills((consultant.primarySkills.concat( consultant.secondarySkills??[]) ))['pendingSkillsCount']>0"
                                class="badge bg-secondary text-white p-1">
                          <i class="fas fa-plus fa-xs"></i>
                           <span class="pt-1"
                                 style="font-size: 10px">     {{ this.getSkills((consultant.primarySkills.concat(consultant.secondarySkills ?? [])))['pendingSkillsCount'] }}</span>
                </span>
                        </div>
                      </div>
                    </div>
                    <div class="dropdown">
                      <button id="more-btn dropdownMenuButton" class="more-btn" data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              type="button">
                        <span class="more-dot"></span>
                        <span class="more-dot"></span>
                        <span class="more-dot"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive col-12 col-sm-12 col-lg-12">
              <mat-paginator [length]="totalElements"
                             [pageSize]="pageSize"
                             [hidden]="totalPages < 1"
                             [pageSizeOptions]="[10,12,25,100]"
                             aria-label="Select page">
              </mat-paginator>
            </div>
          </div>
          <div *ngIf="consultants.length<=0 && !loading"
               class="no-results d-flex flex-column align-items-center vendor-search-table">
            <img src="../../assets/illustration-empty-results.svg" width="250">
            <h4>No Consultants matches your requirements</h4>
            <p>Take a another pass with a different search term</p>
          </div>
        </div>
      </div>
<!--      <div class="col-md-4 filter-section">-->
<!--        <app-consultantfilter (selectedFilters)="applyFilter($event)"></app-consultantfilter>-->
<!--      </div>-->
    </div>
  </div>
</div>

