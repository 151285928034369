<section id="wrapper">
  <div class="login-register">
    <div class="login-box card">
      <div class="card-body">
        <mat-stepper orientation="vertical" [linear]="true" #stepper>
          <mat-step [stepControl]="emailFormGroup">
            <form [formGroup]="emailFormGroup">
              <ng-template matStepLabel>Fill out your email</ng-template>
              <div class="form-group position-relative mt-5 form-wrapper">
                <div class="col-xs-12 d-flex justify-content-between align-items-baseline mb-4 mt-4">
                  <div class="icon">
                    <i class="fas fa-envelope text-muted mx-2"></i>
                  </div>
                  <div class="input-group">
                    <input matInput formControlName="email" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>Enter your registered email id</label>
                  </div>
                </div>
                <div class="d-flex justify-content-center">
                  <button class="btn w-50 btn-info btn-rounded text-white" (click)="sendOtpMail(stepper)">Next</button>
                </div>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="otpFormGroup">
            <form [formGroup]="otpFormGroup">
              <ng-template matStepLabel>Fill OTP received in mail</ng-template>
              <div class="form-group position-relative mt-5 form-wrapper" style="width:60%;">
                <div class="input-group mt-3 mb-4">
                  <ng-otp-input  [formCtrl]="otp" [config]="{length: 8}" ></ng-otp-input>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn w-25 btn-info btn-rounded text-white" matStepperPrevious>Back</button>
                  <button class="btn w-25 btn-info btn-rounded text-white me-2"
                    (click)="verifyOtp(stepper)">Next</button>
                </div>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="passwordFormGroup">
            <form [formGroup]="passwordFormGroup">
              <ng-template matStepLabel>Reset password</ng-template>
              <div class="form-group position-relative mt-5 form-wrapper">
                <div class="col-xs-12 d-flex justify-content-between align-items-baseline">
                  <div class="input-group mt-3 mb-4">
                    <input #passwordInput matInput type="password" formControlName="password" name="password" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>New password</label>
                  </div>
                  <div class="show-pass">
                    <i #faIconPasswordInputButton (click)="passwordToggle()" class="fas fa-eye"></i>
                  </div>
                </div>
                <div class="col-xs-12 d-flex justify-content-between align-items-baseline">
                  <div class="input-group mt-3 mb-4">
                    <input #confirmPasswordInput matInput type="password" formControlName="confirmPassword"
                      name="password" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>Confirm password</label>
                  </div>
                  <div class="show-pass">
                    <i #faIconConfirmPasswordInputButton (click)="confirmPasswordToggle()" class="fas fa-eye"></i>
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn w-25 btn-info btn-rounded text-white" matStepperPrevious>Back</button>
                  <button class="btn w-25 btn-info btn-rounded text-white mx-5"
                    (click)="changePassword(stepper)">Next</button>
                </div>
              </div>
            </form>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>Done</ng-template>
            <div class="form-group position-relative mt-5 form-wrapper">
              <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
              </svg>
              <h3 class="text-center m-b-30">Reset password succesfully</h3>
              <p class="text-center fs-2">Hurrah!! You have successfully confirm your new password. Please use your new
                password when logging in.</p>
              <div class="d-flex justify-content-center">
                <button class="btn w-25 btn-info btn-rounded text-white" routerLink="/login">Login</button>
              </div>
            </div>
          </mat-step>
        </mat-stepper>
        <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
          {{errorMessage}}
        </div>
      </div>
    </div>
  </div>
</section>
