<div class="modal_wrapper">
  <div class="delete-icon" mat-dialog-title>
    <svg style="width: 50px;display: flex;color: gray;" class="mx-auto text-gray" fill="none" stroke="currentColor"
         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
    </svg>
  </div>
  <mat-dialog-content class="mat-typography">
    <h3>{{ data.message }}</h3>
  </mat-dialog-content>
  <mat-dialog-actions class="d-flex justify-content-center align-items-center">
    <button mat-button (click)="onNoClick()" style="background-color: #a5a5a53b;">No Thanks</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial style="background-color: #000;color: #fff;">Ok</button>
  </mat-dialog-actions>
</div>
