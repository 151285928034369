  <div class="content-wrapper">
    <div class="row">
      <div class="col-md-12">
        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
          <h5 class="page-title">Portal Settings</h5>
        </div>
      </div>
    </div>
    <div class="col-md-12 companyProfile_wrapper">
      <div class="companyProfile_header theme-bg-color">
        <h5>Add company information</h5>
      </div>
      <div class="companyProfile_content d-flex justify-content-center">
        <div class="create-vendor col-md-10">
          <form [formGroup]="createPartnerForm" ngNativeValidate class="form-wrapper" (submit)="createPartner()">
            <div class="form-group">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <mat-form-field appearance="outline" class="w-100 border-red">
                    <mat-label>First Name</mat-label>
                    <input matInput #inputFirstName formControlName="firstName" type="text" required
                           placeholder="First name">
                  </mat-form-field>
                </div>
                <div class="form-group col-md-6">
                  <mat-form-field appearance="outline" class="w-100 border-red">
                    <mat-label>Last Name</mat-label>
                    <input matInput #inputLastName  formControlName="lastName" type="text" id="inputLastName"
                           placeholder="Last name">
                  </mat-form-field>
                </div>
              </div>
              <div class="form-row">
                 <div class="form-group col-md-6">
                   <mat-form-field appearance="outline" class="w-100 border-red">
                     <mat-label>Email id</mat-label>
                     <input matInput #inputEmail  type="email" formControlName="email" id="inputEmail"
                            placeholder="Email" required>
                   </mat-form-field>
                 </div>
                 <div class="form-group col-md-6">
                   <mat-form-field appearance="outline" class="w-100 border-red">
                     <mat-label>Mobile number</mat-label>
                     <input matInput #inputMobile  type="text" formControlName="mobile" id="inputMobile"
                            placeholder="Mobile" minlength="10" maxlength="10" required>
                   </mat-form-field>
                 </div>
               </div>
             <div class="form-row">
               <div class="form-group col-md-6">
                 <mat-form-field appearance="outline" class="w-100 border-red">
                   <mat-label>Business Name</mat-label>
                   <input matInput #inputBusiness  type="text" formControlName="businessName" id="inputBusiness"
                          placeholder="BusinessName" required>
                 </mat-form-field>
               </div>

               <div class="form-group col-md-6">
                 <mat-form-field appearance="outline" class="w-100 border-red">
                   <mat-label>Business Domain</mat-label>
                   <input matInput #inputBusinessDomain  type="text" formControlName="businessDomain" id="inputBusinessDomain"
                          placeholder="Domain" aria-describedby="basic-addon2" required>
                 </mat-form-field>
                 </div>
                 <div style="">{{domainErrorMsg}}</div>
               </div>

               <div class="form-row">
                 <div class="form-group col-md-12">
                   <mat-form-field appearance="outline" class="w-100 border-red">
                     <mat-label>Website Link</mat-label>
                     <input matInput #inputWebsite  type="text" formControlName="website" id="inputWebsite"
                            placeholder="Website" required>
                   </mat-form-field>
                 </div>
               </div>
            </div>
            <div class="form-group btns d-flex justify-content-center save-form">
              <button class="btn action-btn action-btn-cancel me-3 border-0 theme-btn-color" type="submit">Create</button>
            </div>
          </form>
        </div>
      </div>
  </div>
</div>
