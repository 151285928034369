<div class="mx-4">
  <div class="row card-shadow my-4 p-4 pb-2">
    <h3 class="font-weight-bold p-0">Customer Information:</h3>
    <div class="row p-0">
      <div class="col-md-6">
        <p class="subHeadings-labels">Customer Name : <span
          class="subHeadings-values">{{ myTask.myTask?.customer?.companyName ?? '-' }}</span></p>
      </div>
      <div class="col-md-6">
        <p class="subHeadings-labels">Sender Name :
          <span
            class="subHeadings-values">{{ myTask.myTask?.sender?.firstName ?? '---' }} {{ myTask.myTask?.sender?.lastName ?? '' }}</span>
        </p>
      </div>
    </div>
  </div>

  <div class="row card-shadow my-3 p-4 pb-2">
    <h3 class="font-weight-bold p-0">Customer Enquiry :</h3>
    <div class="col-md-6 p-0">
      <p class="subHeadings-labels">Consultant Id : <span
        class="subHeadings-values"> {{myTask.myTask.subject.consultantId }}</span></p>
      <p class="subHeadings-labels">Start Date : <span
        class="subHeadings-values"> {{myTask.myTask.startDate| date: 'd MMM y' ?? '-' }}</span>
      </p>
      <p  *ngIf="myTask.myTask?.job"  class="subHeadings-labels">Working Time Zone : <span
        class="subHeadings-values"> {{ myTask.myTask?.consultantWorkTimeZone ?? '-' }}</span></p>
    </div>
    <div *ngIf="myTask.myTask?.job" class="col-md-6">
      <p class="subHeadings-labels">No. of Positions : <span
        class="subHeadings-values"> {{ myTask.myTask?.job?.noOfOpenings ?? '-' }}</span></p>
      <p class="subHeadings-labels">Duration : <span
        class="subHeadings-values"> {{ myTask.myTask?.job?.duration ?? '-' }}</span></p>
      <p class="subHeadings-labels">Closing Date : <span
        class="subHeadings-values"> {{ (myTask.myTask?.closingDate | date: 'd MMM y') ?? '-' }}</span>
      </p>
    </div>
    <div class="col-md-12 p-0">
      <p  *ngIf="myTask.myTask.jobDescription"  class="subHeadings-labels">Job Description : <span
        class="subHeadings-values"> {{ myTask.myTask?.jobDescription ?? '-' }}</span></p>
    </div>
  </div>
</div>
