import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {SolutionService} from "../../../../services/solution.service";
import {NavigationService} from "../../../../services/navigation.service";

@Component({
  selector: 'app-create-solution',
  templateUrl: './create-solution.component.html',
  styleUrls: ['./create-solution.component.css']
})
export class CreateSolutionComponent implements OnInit {
  createSolutionId: number;
  solutionId: number;
  solutionData: any;

  constructor(
    private service: ApiService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private solutionService: SolutionService,
    private navigationService: NavigationService,
    private router: Router,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnInit(): void {
    this.solutionId = this.route?.snapshot?.params['solutionId'];
    if (this.solutionId) {
      this.getSolutionById(this.solutionId);
    }
  }

  getSolutionById(solutionId: number) {
    this.service.getSolutionById(solutionId).subscribe((res) => {
      this.solutionData = res;
      this.solutionService.saveSolutionData(this.solutionData);
    });
  }

  onSolutionId(id: number) {
    this.createSolutionId = id;
  }

  goBack() {
    const state = this.navigationService.getState();
    if (state) {
      this.router.navigateByUrl(state.previousUrl).then(() => {
        window.scrollTo(0, state.scrollPosition);
      });
    } else {
      this.router.navigate(['/dashboard/solutions']);
    }
    // this.navigationService.clearState();
  }
}
