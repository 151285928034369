<div class="modal-popUp-content  consultant-detail_wrapper overflow-hidden">
  <div mat-dialog-title>
    <div class="member_wrapper theme-bg-color text-white">
      <div class="d-flex align-items-center justify-content-between">
        <div class="">
          <h5 class="mb-0"> Consultant Details</h5>
        </div>
        <div class="cross_icon" (click)="close()">
          <a>
            <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.2872 1L1 17.2871" stroke="white" stroke-width="2" stroke-linecap="round"/>
              <path d="M1 1L17.2872 17.2871" stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
  <mat-dialog-content class="mat-typography m-0">
    <div class="py-2 border-bottom">
      <div class="row  d-flex align-items-lg-start">
        <div class="col-6">
          <div class="d-flex">
            <div class="upload shadow p-2 rounded-circle">
              <img class="thumbnail-image"
                   [src]="consultant?.consultantData?.profilePicture!=null?consultant?.consultantData?.profilePicture:'./assets/avatars.png'"
                   alt="..."/>
            </div>
            <div class="ms-4">
              <div class="d-flex align-items-center mb-1">
                <h6 class="mb-0 me-2 secondaryText d-flex"> Consultant Id : <h6
                  class="text-dark ms-2 mb-0 secondaryText"> {{ consultant.consultantData.consultantId }} </h6></h6>
              </div>
              <div class="secondaryText fw-bold text-capitalize mb-1">{{ consultant?.consultantData?.title ?? '--' }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 overflow-hidden">
          <div  *ngIf="consultant?.consultantData?.summary">
            <h6 class="headingText fw-bold">Technical Summary</h6>
            <div class="mt-2 summaryTexts text-capitalize">
              <span>
                <div class="bg-white d-flex flex-row align-items-center">
                  <div class="flex-grow-1">
                    <span [innerHTML]="getSummaryText()" class=""
                          style="white-space: pre-line;">
                    </span>
                    <span>
                      <a class="bg-white show-more" (click)="openSummaryDialog()"
                         *ngIf="consultant?.consultantData?.summary && consultant?.consultantData?.summary.split('\n').length > 3">
                        Show more
                      </a>
                    </span>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="themeSecondary-bg-color border-top border-bottom">
      <div class="p-2">
        <h3 class="pt-2 headingText"><b>Profile Details</b></h3>
        <div class="row py-2 align-items-center">
          <div class="col-3">
            <div class="d-flex">
              <i class="fas fa-briefcase fs-5"></i>
              <div class="ms-2">
                <span class="subHeadingText" style="font-size: 17px"> Work Experience: </span>
                <h5 class="smallTextValue ms-1">
                  {{
                    (consultant?.consultantData?.experienceYear != null ||consultant?.consultantData?.experienceMonth != null) ?
                      (((consultant?.consultantData?.experienceYear != 0) ? (consultant?.consultantData?.experienceYear + ' yrs') : '' || '') + " " + (((consultant?.consultantData?.experienceMonth != 0) ? (consultant?.consultantData?.experienceMonth + ' months') : '') || '')) :
                      '--'
                  }}
                </h5>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="d-flex">
              <i class="fas fa-graduation-cap fs-5"></i>
              <div class="ms-2">
                <span class="subHeadingText" style="font-size: 17px"> Degree: </span> <h5
                class="smallTextValue ms-1">{{
                  consultant?.consultantData?.education != null ? consultant?.consultantData?.education?.name
                    ?? '--' : '--'
                }}</h5>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="d-flex" *ngIf="consultant?.consultantData?.jobType">
              <i class="fas fa-map-marker-alt fs-5 mt-1"></i>
              <div class="ms-2">
                <span class="subHeadingText" style="font-size: 17px">Location Preference: </span>
                <span class="d-flex smallTextValue ms-1">
                  {{ consultant?.consultantData?.jobType }}
                  <span *ngIf="consultant?.consultantData?.preferredLocations?.length !== 0"> - </span>
                  {{ getJobTypeString(consultant?.consultantData?.preferredLocations ?? []) }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-3" *ngIf="consultant?.consultantData?.availabilityToJoin">
            <div class="d-flex">
              <i class="fa fa-check fs-5 mt-1"></i>
              <div class="ms-2">
                <span class="subHeadingText" style="font-size: 17px">Availability To Join: </span> <h5
                class="smallTextValue ms-1">{{ consultant?.consultantData?.availabilityToJoin ?? '--' }} Days</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <div class="row mb-2 d-flex align-items-lg-start">
        <div class="col-6">
          <h1 class="headingText fw-bold mb-2">Primary Skills</h1>
          <div *ngIf="consultant?.consultantData?.primarySkills !== null" class="d-flex flex-wrap ">
            <p *ngFor="let pSkill of consultant?.consultantData?.primarySkills" class="badge-skill rounded-2 text-black me-2 ">{{ pSkill?.title ?? "--" }}</p>
          </div>
          <div *ngIf="consultant?.consultantData?.primarySkills?.length === 0">
            <p class="badge rounded-pill bg-light text-dark">--</p>
          </div>
        </div>

        <div class="col-6">
          <h1 class="headingText fw-bold mb-2">Secondary Skills</h1>
          <div *ngIf="consultantDT?.secondarySkills!=undefined" class="d-flex flex-wrap">
            <p *ngFor="let sSkill of consultant?.consultantData?.secondarySkills" class="badge-skill rounded-2 me-2 text-dark ">{{ sSkill?.title  ?? "--"}}</p>
          </div>
          <div *ngIf="consultant?.consultantData?.secondarySkills?.length==0">
            <p class="badge rounded-pill bg-light text-dark">--</p>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="consultant?.consultantData?.communicationRating">
        <div class="col my-2">
          <h3 class="mb-0 me-2 text-black d-flex font-weight-bold"> English Communication & Articulation Rating : <h3
            class="text-dark ms-2 mb-0"> {{ consultant?.consultantData?.communicationRating ?? '0' }}/10 </h3></h3>
        </div>
      </div>
    </div>

  </mat-dialog-content>
</div>
