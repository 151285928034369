import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormControl} from "@angular/forms";
import {ComponentName, DocType, FormUpdateEvent, Skill, VendorCorp} from "../../../../shared/interfaces";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {Observable} from "rxjs/internal/Observable";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ApiService} from "../../../../services/api.service";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ToastrService} from "ngx-toastr";
import {map, startWith} from "rxjs/operators";
import {PopUpComponent} from "../../../../util/pop-up/pop-up.component";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";

@Component({
  selector: 'app-vendor-skills',
  templateUrl: './vendor-skills.component.html',
  styleUrls: ['./vendor-skills.component.css']
})
export class VendorSkillsComponent implements OnInit{
  skillControl = new FormControl('');
  selectedSkill: Skill[] = [];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  skillName = '';
  filteredSkills: Observable<Skill[]>;
  skills: any[] = [];
  @ViewChild("chipGrid") chipGrid: ElementRef;
  vendor: VendorCorp = {} as any;
  skillsRes: Skill[] = [];
  showloader = false;
  role: string | null;
  type = '';
  visible2 = false;
  @ViewChild('skillsAutoCompleter', {read: MatAutocompleteTrigger})
  skillsAutoCompleter: MatAutocompleteTrigger;
  @Input() vendorData: any;
  @Input() openDialogType: string;
  loading: boolean = false
  disabledSkills: Skill[] = [];
  previousFormValue: any;
  initializingForm: boolean = true;
  @Output() formUpdated = new EventEmitter<FormUpdateEvent>();
  exactMatchFound: boolean = false;
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private service: ApiService,
      public dialog: MatDialog,
      public router: Router,
      public snackBar: MatSnackBar,
      private toastr: ToastrService,
      private isVendorsDataUpdated: IsDataUpdatedService,
  ) {
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.skills = this.vendorData.skills;
    this.getSkillType();
    this.role = localStorage.getItem('role');
    this.previousFormValue = {
      skills: [],
    };
  }

  openAutocomplete(trigger: MatAutocompleteTrigger) {
    if (trigger) {
      trigger.openPanel();
    }
  }
  scrollEvent = (event: any): void => {
    if(this.skillsAutoCompleter.panelOpen)
      this.skillsAutoCompleter.updatePosition();
  };

  disableSkill(skill: any): boolean {
    return this.selectedSkill.some(selected => selected.id === skill.id) || this.skills.some(s => s.id === skill.id);
  }

  removeSkill(skill: any): void {
    const index = this.selectedSkill.indexOf(skill);
    if (index >= 0) {
      this.selectedSkill.splice(index, 1);
    }
    this.checkAndEmitChanges();
  }

  selectSkills(event: MatAutocompleteSelectedEvent): void {
    const skill = event.option.value;
    this.selectedSkill.push(skill);
    this.chipGrid.nativeElement.value = '';
    this.skillControl.setValue(null);
    this.disabledSkills.push({ ...skill, disabled: true });
    this.checkAndEmitChanges();
  }

  addSkillType(): void {
    if(this.skillName === "") {
      return
    } else {
      let payload = {
        'title': this.skillName,
      }
      this.service.addOrgSkills(payload).then(res => {
        this.isVendorsDataUpdated.setUpdated(true);
        this.getSkillType();
        this.selectedSkill.push(res);
        this.skillName = '';
        this.chipGrid.nativeElement.value = '';
        this.skillControl.setValue('');
      }, (e) => {
        console.log('error', e.error.message);
      })
    }
  }
  addSkill(): void {
    this.loading = true
    const selectedSkill: any = [];
    if (this.selectedSkill.length > 0) {
      this.selectedSkill.map(res => {
        selectedSkill.push(res.id);
      })
      this.service.updateVendorSkill(this.vendorData.id, selectedSkill)
          .then((res) => {
            this.isVendorsDataUpdated.setUpdated(true);
            this.loading = false
            this.vendor = res;
            this.skillName = '';
            this.skills = res.skills;
            this.selectedSkill = [];
            this.previousFormValue = {
              skills: [],
            };
            this.formUpdated.emit({updated: false, componentName: ComponentName.VendorSkills});
            this.showloader = false;
            this.getSkillType();
          }, (e) => {
            console.log('error', e.error.message);
            this.loading = false
          })
    } else {
      this.toastr.info('Please add Skill ', 'Info');
      this.loading = false
    }
  }
  getSkillType(): void {
    this.service.getSkills().then((res: any[]) => {
      this.skillsRes = res.filter((title) => title.title !== "");
      this.filteredSkills = this.skillControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filterSkills(value))
      );
    });
  }
  private _filterSkills(value: string | Skill): Skill[] {
    const filterValue = typeof value === 'string' ? value.trim().toLowerCase() : value?.title.trim().toLowerCase() ?? '';
    this.exactMatchFound = this.skillsRes.some(skill => skill.title.toLowerCase() == filterValue);
    console.log('exactMatchFound',this.exactMatchFound)
    return this.skillsRes.filter(skill =>
        skill.title.toLowerCase().includes(filterValue)
    );
  }

  openDialogSkillDelete(skillId: number, index: number, skillName: string): void {
    const dialogRef1 = this.dialog.open(PopUpComponent, {
      data: {
        message: 'Are you sure you want to delete this "' + skillName + '" Skill ?',
        type: 'Delete'
      }
    });
    dialogRef1.beforeClosed().subscribe((res) => {
      if (res) {
        if (this.role == 'ADMIN') {
          this.service.deleteSkill(this.vendorData.id, skillId).then((_res) => {
            this.isVendorsDataUpdated.setUpdated(true);
            if (index > -1) {
              this.skills.splice(index, 1);
              this.openSnackBar('Successfully Deleted', " ");
            }
            this.getSkillType();
          }, (e) => {
            console.error(e);
          });
        }
      } else {
        console.log('Only admin has authority to delete skills ', this.role);
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  checkAndEmitChanges(): void {
    const skillChanged = JSON.stringify(this.selectedSkill) !== JSON.stringify(this.previousFormValue.skills);
    this.formUpdated.emit({updated: skillChanged, componentName: ComponentName.VendorSkills});
  }

}
