<div class="consultantStatus_wrap" [formGroup]="deployedForm">
  <div class="row p-3 shadow-sm m-3 rounded-3">
    <div class="col">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Current Status</mat-label>
        <mat-select (selectionChange)="onStatusChange($event)" [(value)]="this.status">
          <mat-option *ngFor="let st of statusList" [value]="st">
            {{ st }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Availability to Join(in days)</mat-label>
        <mat-select [formControl]="daysListForm">
          <mat-option *ngFor="let days of daysList" [value]="days" class="dropItems"
                      (click)="onAvailabilityDays(days)">
            <a class="dropdown-item">
              {{ days }}
            </a>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col d-flex justify-content-end align-items-center">
      <mat-slide-toggle
        class="example-margin"
        color="primary"
        [disabled]=false
        [checked]="searchStatus"
        (change)="change($event)">
        <div class="ActiveButton">Enable Search</div>
      </mat-slide-toggle>
    </div>
  </div>
  <div class="row p-3 m-3 shadow-sm">
    <div class="col-6 border-right">
      <div>
        <mat-label><h3 class="font-weight-bold">Location Preferences:</h3></mat-label>
        <div class="d-flex justify-content-between align-items-center">
          <div class='d-flex'>
            <div class="d-inline-block checkBox-width">
              <mat-radio-group name="jobType" aria-label="Job Type"
                               (change)="onRadioChange($event)">
                <mat-radio-button class="me-3" [value]="'REMOTE'" [checked]="this.jobType === 'REMOTE'">REMOTE
                </mat-radio-button>
                <mat-radio-button class="me-3" [value]="'HYBRID'" [checked]="this.jobType === 'HYBRID'">HYBRID
                </mat-radio-button>
                <mat-radio-button class="me-3" [value]="'ONSITE'" [checked]="this.jobType === 'ONSITE'">ONSITE
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row mt-2">
        <form [formGroup]="cityform" (submit)="updatePreferredLocation()" class="w-100"
              (keydown.enter)="$event.preventDefault()">
          <div class="form-group">
            <div *ngIf="jobType ==='HYBRID' || jobType === 'ONSITE'">
              <div>
                <h4 class="mb-0">City</h4>
                <mat-form-field appearance="outline" class="w-75 border-red mt-2">
                  <mat-label>City</mat-label>
                  <mat-select [formControl]="cityControl" [compareWith]="compareCities" [value]="selectedCities"
                              (selectionChange)="selectCities($event)" placeholder="City" multiple>
                    <mat-option>
                      <ngx-mat-select-search [formControl]="cityServerCtrl" [searching]="searchCity"
                                             [placeholderLabel]="'Search City Name...'"
                                             [noEntriesFoundLabel]="'No Result Found'"
                                             [closeIcon]="closeIcon"
                      >
                        <mat-icon ngxMatSelectSearchClear>
                          <div class="d-flex align-items-center justify-content-center mt-sm-1">
                            <a>
                              <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                                <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                              </svg>
                            </a>
                          </div>
                        </mat-icon>
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let city of cities" [value]="city">
                      {{ city.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <ng-container *ngIf="jobType ==='HYBRID' || jobType === 'ONSITE'">
            <div class="border my-3 rounded-3 shadow-sm">
              <h3 *ngIf="selectedCities?.length != 0" class="p-2">Selected Cities:</h3>
              <div *ngIf="selectedCities?.length===0" class="d-flex align-items-center justify-content-center">
                <h4 class="my-4">No city selected.</h4>
              </div>
              <ng-container *ngIf="selectedCities?.length">
                <ul class="city-list p-2">
                  <mat-chip
                    class="rounded-2 me-2 mt-2"
                    *ngFor="let city of selectedCities"
                    (removed)="removeCity(city)">
                    {{ city?.name }}
                    <button matChipRemove>
                      <i class="fas fa-times"></i>
                    </button>
                  </mat-chip>
                </ul>
              </ng-container>
            </div>
          </ng-container>

          <!--          <div class="d-flex justify-content-end align-items-end" *ngIf="!this.showSelectedCities ">-->
          <!--            <button *ngIf="!loadingCity" class="btn btn-save theme-btn-color" type="submit" >Save </button>-->
          <!--            <button *ngIf="loadingCity" class="btn btn-save theme-btn-color" disabled>-->
          <!--              <div class="spinner-border spinner-border-sm" role="status">-->
          <!--                <span class="sr-only"></span>-->
          <!--              </div>-->
          <!--              Save-->
          <!--            </button>-->
          <!--          </div>-->

        </form>
      </div>
    </div>
    <div class="col-6">
      <div class="upload_doc mb-3">
        <div class="d-flex justify-content-between">
          <mat-label><h3 class="font-weight-bold xmb-0">Upload Resume:</h3></mat-label>
          <div class="d-block" *ngIf="file || resumeData?.resumeName">
            <mat-slide-toggle
              class="example-margin"
              color="primary"
              [disabled]=false
              [checked]="resumeData.resumeStatus"
              (change)="toggleResumeStatus($event)">
              <div class="ActiveButton">Display Resume</div>
            </mat-slide-toggle>
          </div>
        </div>
        <div *ngIf="!resumeData?.resumeName" class="w-50">
          <div *ngIf="!file"
               (click)="fileInput.click()"
               (drop)="onSelectFile($event, true)"
               (dragover)="onDragOver($event)"
               (dragenter)="onDragEnter($event)"
               (dragleave)="onDragLeave($event)"
          >
            <input #fileInput style="display: none" type="file" class="form-control-file"
                   (change)="onSelectFile($event)"
                   accept=".png,.doc,.docx,application/msword,application/vnd,application/pdf">
            <div class="drag_drop_wrapper " *ngIf="!logoLoader">
              <div><img src="assets/img_1.png" style="height: 40px" class=""/></div>
              <div><span><b>Choose a file</b> or drag it here</span></div>
            </div>
          </div>
          <div *ngIf="file">
            <div class="drag_drop_wrapper_upload" *ngIf="logoLoader">
              <ng-container>
                <mat-spinner diameter="50"></mat-spinner>
                <p class="m-0 mt-1">Uploading...</p>
              </ng-container>
            </div>
            <ng-container *ngIf="!logoLoader">
              <div class=" d-flex mt-3">
                <div>
                  <button class="btn btn-preview theme-btn-color" (click)="downloadResume()">
                    <i class="fas fa-step-forward me-2"></i>Download Resume
                  </button>
                </div>
                <div class="btn d-flex justify-content-center align-items-center"
                     (click)="deleteConfirmation(this.consultantData?.id)">
                  <svg width="12" height="12" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5143 1L1.22705 17.2871" stroke="#454545" stroke-width="2" stroke-linecap="round"/>
                    <path d="M1.22705 1L17.5143 17.2871" stroke="#454545" stroke-width="2" stroke-linecap="round"/>
                  </svg>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div *ngIf="resumeData?.resumeName" class="w-50">
          <div class="drag_drop_wrapper_upload" *ngIf="logoLoader">
            <ng-container>
              <mat-spinner diameter="50"></mat-spinner>
              <p class="m-0 mt-1">Uploading...</p>
            </ng-container>
          </div>
          <ng-container *ngIf="!logoLoader">
            <div class="d-flex">
              <div>
                <button class="btn btn-preview theme-btn-color" (click)="downloadResume()">
                  <i class="fas fa-step-forward me-2"></i>Download Resume
                </button>
              </div>
              <div class="btn d-flex justify-content-center align-items-center"
                   (click)="deleteConfirmation(this.consultantData?.id)">
                <svg width="12" height="12" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.5143 1L1.22705 17.2871" stroke="#454545" stroke-width="2" stroke-linecap="round"/>
                  <path d="M1.22705 1L17.5143 17.2871" stroke="#454545" stroke-width="2" stroke-linecap="round"/>
                </svg>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <form [formGroup]="videoUrlForm">
        <div class="video_wrap ">
          <div class="d-flex justify-content-between">
            <mat-label><h3 class="font-weight-bold">Add Introduction Video:</h3></mat-label>
            <div class="d-block" *ngIf="videoUrl">
              <mat-slide-toggle
                class="example-margin"
                color="primary"
                [disabled]=false
                [checked]="videoVisible"
                (change)="toggleConsultantVideo($event)"
              >
                <div class="ActiveButton">Display Video</div>
              </mat-slide-toggle>
            </div>
          </div>
          <div *ngIf="!videoUrl">
            <mat-form-field appearance="outline" class="w-100 border-red">
              <mat-label>Paste URL</mat-label>
              <input matInput formControlName="videoUrl" placeholder="Paste URL" class="form-control">
            </mat-form-field>
          </div>
          <div *ngIf="videoUrl" class="d-flex justify-content-between">
            <div class=" d-flex ">
              <div>
                <button class="btn btn-preview theme-btn-color" (click)="openVideoModal()">
                  <i class="fas fa-step-forward me-2"></i>Preview Video
                </button>
              </div>
              <div class="btn d-flex justify-content-center align-items-center" (click)="deleteVideo()">
                <svg width="12" height="12" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.5143 1L1.22705 17.2871" stroke="#454545" stroke-width="2" stroke-linecap="round"/>
                  <path d="M1.22705 1L17.5143 17.2871" stroke="#454545" stroke-width="2" stroke-linecap="round"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-12">
      <form [formGroup]="summaryForm">
        <div class="m-2">
          <mat-label><h3 class="font-weight-bold">Technical Skills & Feedback:</h3></mat-label>
          <mat-form-field appearance="outline" class="w-100 border-red status_notes">
            <mat-label>Technical Skills & Feedback</mat-label>
            <textarea formControlName="summary" matInput class="form-control summary_note" placeholder="Notes"
                      [cols]="10"></textarea>
          </mat-form-field>
        </div>
        <div class="d-flex justify-content-end">
          <button *ngIf="!loading"
                  class="btn btn-save theme-btn-color"
                  (click)="updateSummary()"> {{ (hasSummary()) ? 'Update' : 'Save' }}
          </button>
          <button *ngIf="loading" class="btn btn-save theme-btn-color" disabled>
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="sr-only"></span>
            </div>
            {{ (hasSummary()) ? 'Update' : 'Save' }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>


