import { DataService } from 'src/app/services/data.service';
import {ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnDestroy  {

  isLoading: boolean = false;
  sidebarClass_1: any;
  private subscription: Subscription;

  constructor(public data: DataService) {
    this.subscription = this.data.isLoading.subscribe(value => {
      setTimeout(() => {
        this.isLoading = value;
      }, 200);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getSidebarClass (data: any) {
    this.sidebarClass_1 = data;
    console.log(this.sidebarClass_1,'data')
    return data;
  }
}
