import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../../services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ToastrService} from "ngx-toastr";
import {DataService} from "../../../../services/data.service";
import {ComponentName, DocsType, DocType, FormUpdateEvent} from "../../../../shared/interfaces";
import {Observable} from "rxjs/internal/Observable";
import {DeletePopupComponent} from "../../../delete-popup/delete-popup.component";
import {debounceTime, distinctUntilChanged, filter, map, startWith, tap} from "rxjs/operators";
import {EditVendorContactComponent} from "./edit-vendor-contact/edit-vendor-contact.component";
import {MatPaginator} from "@angular/material/paginator";
import {fromEvent} from "rxjs";
import {IsDataUpdatedForModalsService} from "../../../../services/isDataUpdatedForModals.service";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";

@Component({
  selector: 'app-vendor-contacts',
  templateUrl: './vendor-contacts.component.html',
  styleUrls: ['./vendor-contacts.component.css']
})
export class VendorContactsComponent implements OnInit,AfterViewInit {
  role: string | null;
  desControl = new FormControl('');
  contactForm: FormGroup;
  errorMessage: string | null;
  showloader = false;
  desTypeName = '';
  filteredDesignation: Observable<DocsType[]>;
  field = '';
  contactError = '';
  vendorContacts: any[] = [];
  totalVendorContact =0
  pageSize = 5;
  currentPage: number=0;
  docError = '';
  visible2 = false;
  designationRes: DocType[] = [];
  designationId: '';
  desEditTypeName = '';
  displayError: boolean = false;
  @Input() vendorData: any;
  @Input() openDialogType: string;
  @ViewChild('autoCompleteInput2', {read: MatAutocompleteTrigger})
  autoCompleteInput2: MatAutocompleteTrigger;

  previousFormValue: any;
  initializingForm : boolean = true;
  @Output() formUpdated = new EventEmitter<FormUpdateEvent>();
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input',{static:true}) input:ElementRef | any;
  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    public dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar,
    private toastr: ToastrService,
    private dataService: DataService,
    private isVendorsDataUpdated: IsDataUpdatedService,
    private isVendersContactDataUpdated: IsDataUpdatedForModalsService,
  ) { }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.getDesignation();
    this.getContacts(this.currentPage,this.pageSize);
    this.role = localStorage.getItem('role');
    this.contactForm = this.fb.group({
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.pattern("[a-zA-Z0-9._%+-]+@[a-z0-9A-Z.-]+.[a-z]{2,}$")]],
      phone: ["", [Validators.required ,Validators.pattern("^[0-9]*$")]],
      designation: [''],
      linkedIn:['']
    });
    this.previousFormValue = this.contactForm.value;
    this.initializingForm = false;

    this.contactForm.valueChanges.pipe(
      debounceTime(500),
      filter(() => !this.initializingForm),
      map(currentValue => JSON.stringify(currentValue) !== JSON.stringify(this.previousFormValue)),
      distinctUntilChanged()
    ).subscribe(isFormUpdated => {
      this.formUpdated.emit({ updated: isFormUpdated, componentName: ComponentName.VendorContacts });
    });

  }
  ngAfterViewInit() {
    this.paginator.page.pipe(
        tap(() => {
          this.getContacts(this.paginator.pageIndex, this.paginator.pageSize);

        })
    ).subscribe();
    if (this.input) {
      fromEvent(this.input?.nativeElement, 'keyup')
          .pipe(
              filter(Boolean),
              debounceTime(500),
              distinctUntilChanged(),
              tap(() => this.getContacts(this.paginator.pageIndex, this.paginator.pageSize )),
          )
          .subscribe();
    }
  }
  getContacts(page:number,size:number): void {
    this.service.getVendorContacts(this.vendorData.id,page,size).then(res => {
      this.vendorContacts = res.content;
      this.totalVendorContact = res.totalElements
      this.paginator.pageIndex=res.number;
      this.paginator.pageSize=res.size;
      // this.contactForm.reset();
    });
  }

  scrollEvent = (event: any): void => {
    if(this.autoCompleteInput2?.panelOpen)
      this.autoCompleteInput2.updatePosition();
  };

  addContact(): void {
    this.contactError = '';
    this.showloader = true;
    if (this.contactForm.invalid) {
      this.showloader = false;
      this.contactError = 'please check the fields ' + this.contactError , this.getFormValidationErrors() + " ";
      return;
    }
      this.service.addVendorContact(this.contactForm.value, this.vendorData.id).then((res: any) => {
        this.isVendorsDataUpdated.setUpdated(true);
        this.showloader = false;
        //load data again of contact table
        this.service.getVendorContacts(this.vendorData.id,this.currentPage,this.pageSize).then(res => {
          this.vendorContacts = res.content;
          this.contactForm.reset();
          this.desControl.reset();
          this.errorMessage='';
        });
        this.formUpdated.emit({ updated: false, componentName: ComponentName.VendorContacts });
        this.desTypeName = '';
        this.contactForm.reset();
      }, (e) => {
        this.showloader = false;
        if (e.error.code == 4000) {
          this.toastr.error('Sorry! Vendor contact with this email already exists.', 'Error');
          this.desTypeName = '';
          // this.contactForm.reset();
        } else if (e.error.status == 500) {
          // this.dialogRef.disableClose;
          this.contactError = e.error.message;
        } else if (e.error.status == 400) {
          // this.dialogRef.disableClose;
          this.contactError = e.error.message;
          // this.toastr.error(this.contactError, 'Error');
        }
      });

  }

  getFormValidationErrors(): string {
    let error = '';
    Object.keys(this.contactForm.controls).forEach(key => {
      const controlErrors = this.contactForm.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          // console.log(' Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          error = error + key + ', ';
        });
      }
    });
    return error;
  }

  displayDocFn(doc: DocsType): string {
    return doc && doc.name ? doc.name : '';
  }

  onKeyPress(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const numericValue = inputValue.replace(/\D/g, '');
    if (numericValue.length === 10) {
      event.preventDefault();
      this.displayError = false;
      this.errorMessage = "";
    } else {
      this.displayError = true;
      this.errorMessage = "Please enter a valid 10-digit mobile number";
    }
    inputElement.value = numericValue;
  }
  chooseDesType(data: any, group?: any): void {
    if (data.isUserInput && !group) {
      this.designationId = data?.source?.value?.id;
      this.desTypeName = data.source.value?.name;
      this.contactForm.get('designation')?.setValue(data.source.value.name);
    } else if (data.isUserInput && group) {
      this.designationId = data?.source?.value?.id;
      this.desEditTypeName = data.source.value?.name;
      group.get('designation')?.setValue(data.source.value.name);
    }
  }

  addDesType(event: any): void {
    if (this.desTypeName) {
      let payload = {
        'name': this.desTypeName,
      }
      this.service.addOrgDesType(payload).then(res => {
        this.isVendorsDataUpdated.setUpdated(true);
        this.getDesignation();
        this.desTypeName = res.name;
        this.contactForm.get('designation')?.setValue(res.name);
        this.displayDocFn(res);
      }, (e) => {
        console.log('error', e.error.message);
      })
    } else if (this.desEditTypeName) {
      let payload = {
        'name': this.desEditTypeName,
      }
      this.service.addOrgDesType(payload).then(res => {
        this.isVendorsDataUpdated.setUpdated(true);
        this.getDesignation();
        this.desEditTypeName = res.name;
        // this.editContactForm.get('designation')?.setValue(res.name);
        this.displayDocFn(res);
      }, (e) => {
        console.log('error', e.error.message);
      })
    }
  }
  editRow(group: any) {
    // @ts-ignore
    group.get('isEditable').setValue(true);
  }
  doneRow(group: any) {
    // @ts-ignore
    group.get('isEditable').setValue(false);
    const contactId = group.get('id').value;
    const formData = {
      name: group.get('name').value,
      designation: group.get('designation').value,
      email: group.get('email').value,
      phone: group.get('phone').value,
      linkedIn:group.get('linkedIn').value,
    }
    this.service.updateContact(formData, this.vendorData.id, contactId).then(res => {
      this.isVendorsDataUpdated.setUpdated(true);
    })
  }


  deleteRow(contact: any) {
    this.dialog.open(DeletePopupComponent, {
        data: {
          message: 'Are you sure you want to delete this Contact ?',
          type: 'Delete'
        }
      }
    ).afterClosed().subscribe(resp => {
      if (resp) {
        const contactId = contact.id;
        this.service.deleteVendorContact(this.vendorData.id, contactId).then(() => {
          this.isVendorsDataUpdated.setUpdated(true);
          this.toastr.success('Vendor contact deleted successfully.', 'Success')
          // const control = this.editContactForm.get('tableRows') as FormArray;
          // control.removeAt(index);
          this.getContacts(this.currentPage,this.pageSize);
        });
      }
    })
  }
  goToLink(url: string) {
    window.open(url, " ");
  }
  editContact( contact: any) {
    const consultEditDialogRef = this.dialog.open(EditVendorContactComponent, {
      data: {
        customerContact: contact,
        vendorid:this.vendorData.id
      },
      width: '50%',
      height: '60%'
    });
    consultEditDialogRef.afterClosed().subscribe(() => {
      if (this.isVendersContactDataUpdated.getBoolean()) {
        this.getContacts(this.currentPage,this.pageSize);
        this.isVendersContactDataUpdated.setUpdated(false);
      }
    });
  }

  getDesignation(): void {
    this.service.getDesignation().then(resp => {
      // this.designationRes = resp;
      resp.map((e:DocType)=>{
        if(e.active==true){
          this.designationRes.push(e);
        }
      });
      this.filteredDesignation = this.desControl.valueChanges.pipe(
        startWith(''),
        map(value => {
            const name = typeof value === 'string' ? value : value?.name ?? "";
            return name ? this._desFilter(name as string) : this.designationRes.slice();
          }
        ),
      )
    });
  }

  _desFilter(name: string): DocsType[] {
    const filterValue = name.toLowerCase();
    return this.designationRes.filter(option => option.name.toLowerCase().includes(filterValue));
  }

}
