<div class="consultant_wrapper">
  <div class="row">
    <div class="col-md-6 border-right">
      <h2>Display picture</h2>
      <div class="d-flex">
        <div class="me-2 ">
          <img class="thumbnail-image" [src]="imageSrc ? imageSrc : this.consultantData?.consultantData?.gender ==
              'Female' ? './assets/images/femaleDefault.jpg' : './assets/images/maleDefault.jpg'"
               alt="Consultant Image"/>
        </div>
        <div class="w-100" *ngIf="!consultantImgName && !consultantImage">
          <div class="" (click)="uploadFileTrigger()">
            <input #fileInput style="display: none" type="file" class="form-control-file"
                   (change)="onSelectLogo($event)"/>
            <div class="drag_drop_wrapper text-center" (dragover)="handleDragOver($event)"
                 (drop)="onSelectLogo($event,true)">
              <div class="d-flex justify-content-center"><img src="assets/img_1.png" style="height: 40px"/></div>
              <div class="d-flex justify-content-center">
                <div><span><b>Choose a file</b> or drag it here</span></div>
              </div>
            </div>
          </div>
        </div>
        <div class="drag_drop_wrapper justify-content-center" *ngIf="consultantImgName && !logoLoader">
          <p class="px-2 d-flex text-center">{{ consultantImgName }}</p>
          <span class="d-flex justify-content-center" (click)="delete()"><i
            class="fas fa-times-circle"></i></span>
        </div>
      </div>
      <div class="d-flex align-items-center mt-5">
        <div class="form-group btns save-form">
          <button *ngIf="!logoLoader && !consultantImage" class="btn btn-save mx-3 theme-btn-color"
                  type="submit" (click)="onUpload()">Save
          </button>
          <button class="btn btn-save theme-btn-color" *ngIf="logoLoader" disabled>
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="sr-only"></span>
            </div>
            Save
          </button>
          <button *ngIf="!showLoader && consultantImage" class="btn btn-save mx-3"
                  style="color: red;background-color: #eaeaea"
                  type="submit" (click)="deleteConfirmation('Delete',defaultImage)">Remove Picture
          </button>
          <div *ngIf="showLoader" disabled>
            <button class="btn btn-saveform" disabled>
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 m-auto">
     <div class="d-grid justify-content-center">
       <mat-slide-toggle
         class="example-margin mb-4"
         color="primary"
         [disabled]=false
         [checked]="pictureStatus"
         (change)="toggleProfilePictureStatus($event)">
         <div class="ActiveButton">Display Profile Picture</div>
       </mat-slide-toggle>


       <mat-slide-toggle
         class="example-margin"
         color="primary"
         [disabled]=false
         [checked]="consultantStatus"
         (change)="toggleActiveConsultant($event)">
         <div class="ActiveButton">Active Consultant</div>
       </mat-slide-toggle>


       <mat-slide-toggle
         class="example-margin pt-3"
         color="primary"
         [disabled]=false
         [checked]="featured"
         (change)="toggleFeatureConsultant($event)">
         <div class="ActiveButton">Feature Consultant</div>
       </mat-slide-toggle>
     </div>
    </div>
  </div>
</div>

