import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsDataUpdatedForModalsService {
  private isAssignmentDataUpdated = new BehaviorSubject<boolean>(false);
  isUpdated = this.isAssignmentDataUpdated.asObservable();
  constructor() {}

  setUpdated(value: boolean) {
    this.isAssignmentDataUpdated.next(value);
  }

  getBoolean(): boolean {
    return this.isAssignmentDataUpdated.value;
  }

}
