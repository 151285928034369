import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {DataService} from "../../../services/data.service";
import {MatDialog} from "@angular/material/dialog";
import {PopUpComponent} from "../../../util/pop-up/pop-up.component";
import {ComponentName, VendorCorp} from "../../../shared/interfaces";
import {Common} from "../../../shared/Common";
import {ToastrService} from "ngx-toastr";
import {MatSnackBar} from "@angular/material/snack-bar";
import { environment } from '../../../../environments/environment';
import {MatPaginator} from "@angular/material/paginator";
import {debounceTime, distinctUntilChanged, filter, tap} from "rxjs/operators";
import {fromEvent} from "rxjs";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  protected environment = environment;
  docFiles: any[] = [];
  doc: File | any;
  document = '';
  editDocumentForm: FormGroup;
  editDesignationForm: FormGroup;
  editSkillForm: FormGroup;
  editVerticalForm: FormGroup;
  verticalFiles: any[] = [];
  editEducationForm: FormGroup;
  educationFiles: any[] = [];
  designationFiles: any[] = [];
  designationForm: FormGroup;
  designation: '';
  skill: '';
  vertical:'';
  education:'';
  role: string | null;
  type: string | null;
  skillFiles: any[] =[];
  showloader = false;
  errorMessage: string| null;
  vendor: VendorCorp = {} as any;
  editVerticalFile: number | null;
  editEducationFile: number | null;
  editDocument: number | null;
  editDes: number | null;
  editSkill: number | null;
  themeCustom:any[]=[];
  totalDocuments=0;
  totalDesignation=0;
  totalSkills=0;
  totalVerticals=0;
  totalEducation=0;
  pageSize = 12;
  currentPage:number = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input',{static:true}) input:ElementRef | any;
  @ViewChild('fileInput1') fileInput1: ElementRef<HTMLDivElement>;
  @ViewChild('fileInput2') fileInput2: ElementRef<HTMLDivElement>;
  @ViewChild('fileInput3') fileInput3: ElementRef<HTMLDivElement>;
  @ViewChild('fileInput4') fileInput4: ElementRef<HTMLDivElement>;
  @ViewChild('fileInput5') fileInput5: ElementRef<HTMLDivElement>;
  @ViewChild('fileInput6') fileInput6: ElementRef<HTMLDivElement>;


  name = 'Angular';
  activeThem = 'oceanBlueThemProps';

  themeData: any[] = []
  themeForm! : FormGroup;
  selectedTheme: string = '';


  constructor(
    private service: ApiService,
    private dataService: DataService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private toast: ToastrService,
  ) { }
  public getEnvironment(): any {
    return this.environment;
  }
  ngOnInit(): void {
    this.dataService.isLoading.next(true);
    this.editDocumentForm = this.fb.group({
      docRows: this.fb.array([])
    });
    this.editDesignationForm = this.fb.group({
      desRows: this.fb.array([])
    });
    this.editSkillForm = this.fb.group({
      skillRows: this.fb.array([])
    });
    this.editVerticalForm = this.fb.group({
      verticalRows: this.fb.array([])
    });
    this.editEducationForm = this.fb.group({
      educationRows: this.fb.array([])
    });
    this.designationForm = this.fb.group({
      file: ''
    });

    this.getAllDocFiles(this.currentPage,this.pageSize);
    this.getAllDesignation(this.currentPage,this.pageSize);
    this.getAllSkill( this.currentPage, this.pageSize);
    this.getAllThemes();
    this.getAllVerticals(this.currentPage, this.pageSize);
    this.getAllEducation(this.currentPage, this.pageSize);
    this.themeForm = new FormGroup ({
      radioOptions: new FormControl(''),
      customColor: new FormControl(''),
    });

  }
  ngAfterViewInit(): void {
    this.role = localStorage.getItem('role');
    this.paginator.page.pipe(
        tap(() => {
          this.getAllDocFiles(this.paginator.pageIndex, this.paginator.pageSize);
        })
    ).subscribe();

    if (this.input) {
      fromEvent(this.input.nativeElement, 'keyup')
          .pipe(
              filter(Boolean),
              debounceTime(500),
              distinctUntilChanged(),
              tap(() => {
                this.getAllDocFiles(this.paginator.pageIndex, this.paginator.pageSize);
              })
          )
          .subscribe();
    }
  }
  uploadFileTrigger(type: string): void {
    if(type == 'DOC'){
      this.fileInput1.nativeElement.click();
    }
    else if(type == 'DES'){
      this.fileInput2.nativeElement.click();
    }else if(type =='SKL'){
      this.fileInput3.nativeElement.click();
    }else if(type =='CLR'){
      console.log('clr',this.fileInput4);
      this.fileInput4.nativeElement.click();
    }else if (type == 'VERTICAL'){
      this.fileInput5.nativeElement.click();
    }else if(type == 'EDUCATION'){
      this.fileInput6.nativeElement.click()
    }
  }

  selectTheme(theme: any) {
    this.themeForm.value.radioOptions = theme;
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }
   onSubmit() {
    this.showloader = true;
    if(this.themeForm.value.radioOptions) {
      this.service.updateOrgTheme(this.themeForm.value.radioOptions.id,this.themeForm.value.radioOptions.name,this.themeForm.value.radioOptions.colour).subscribe({
        next: res => {
          Common.setThemeColorLocally(this.themeForm.value.radioOptions.colour);
          this.getAllThemes();
        },
        error: err =>  {
          this.showloader = false;
          if (err.error.code == 4000) {
            this.showloader = false;
            this.errorMessage = err.error.reason;
            this.openSnackBar(err.error.reason, "ok ");
          }
        },complete: () =>{
          this.showloader = false;
        }
      });
    } else if(this.themeForm.value.customColor) {
      /* Custom Color API Pending to implement */
      this.service.updateOrgCustomTheme(this.themeForm.value.customColor).subscribe({
        next: res => {
          console.log('res',res)
          this.service.updateOrgTheme(res.id,res.name,res.colour).subscribe({
            next: res => {
              Common.setThemeColorLocally(this.themeForm.value.customColor);
            },
            error: err => {
              console.log('err',err)
              this.showloader = false;
              if (err.error.code == 4000) {
                this.showloader = false;
                this.errorMessage = err.error.reason;
                this.openSnackBar(err.error.reason, "ok ");
                // this.dialogRef.disableClose;
              }
            },
            complete: () => {
              this.showloader = false;
              this.getAllThemes();
          }
          });
        },
        error: error => {
          console.log('err',error)
          this.showloader = false;
          if (error.error.code == 4000) {
            this.showloader = false;
            this.errorMessage = error.error.reason;
            this.openSnackBar(error.error.reason, "ok ");
          }
          else if(error.error.status == 403) {
            this.toast.error('Only Admin has Permissions to Add !', 'Failed', {
              positionClass: "toast-top-right"
            });
          }
        },
      });
    }
  }


  onInputFocus() : void {
    this.themeForm.controls.radioOptions.setValue('');
  }

  onRadioClick() : void {
    this.themeForm.controls.customColor.setValue('');
  }

  deleteCustomTheme(id: string) {
    const dialogRef = this.dialog.open(PopUpComponent,{
      data: {
        message: 'Are you sure to delete',
        type: 'confirmation'
    }});
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.service.deleteCustomTheme(id).subscribe({
          next: res => {
            this.getAllThemes();
            this.toast.success('Theme Deleted', 'Success', {
              positionClass: "toast-top-right"
            });
          },
          error: err => {
            console.log('err',err)
            if(err.error.status == 403) {
              this.toast.error('Only Admin has Permissions to Delete !', 'Failed', {
                positionClass: "toast-top-right"
              });
            }
            else{
              this.toast.error('Failed to Delete', 'Failed', {
                positionClass: "toast-top-right"
              });
            }
          },
          complete: () => {
          }
        })
      }
    });

  }

  getAllDocFiles(page:number,size:number):void {
    const control = <FormArray>this.editDocumentForm.get('docRows');
    control.clear();
    this.service.getAllFilesPaginated(page,size).then(res=>{
      this.dataService.isLoading.next(false);
      this.docFiles = res.content;
      this.totalDocuments=res.page.totalElements
      this.paginator.pageIndex=res.page.number;
      this.paginator.pageSize=res.page.size;
      this.pageSize=res.page.size;
      this.currentPage=res.page.number
      this.docFiles.forEach(res => {
        const form = this.fb.group({
          id: [res.id],
          name: [res.name],
          active: [res.active],
          isEditable: false,
        })
        control.push(form);
      })
    });
  }

  getAllThemes() {
    this.themeData=[];
    this.themeCustom=[];
    this.service.getAllThemes().subscribe({
      next: res => {
        res.forEach((e:any)=>{
            if(e['custom']==true){
              this.themeCustom.push(e);
            }
            else{
              this.themeData.push(e);
            }
        })
        this.themeForm.get('radioOptions')?.setValue(this.themeData.find(theme => theme.active === true));
      },
      error: err => {
        console.error('err',err.error);
      }
    })
  }

  get getDocFormControls() {
    return this.editDocumentForm.get('docRows') as FormArray;
  }
  get getDesFormControls() {
    return this.editDesignationForm.get('desRows') as FormArray;
  }
  get getSkillFormControls() {
    return this.editSkillForm.get('skillRows') as FormArray;
  }
  get getVerticalFormControls() {
    return this.editVerticalForm.get('verticalRows') as FormArray;
  }
  get getEducationFormControls() {
    return this.editEducationForm.get('educationRows') as FormArray;
  }

  editRow(group: any) {
    console.log('#group'+ group.get('id').value)
    this.editVerticalFile = group.get('id').value;
  }

  checkIfVerticalSelected(id: number){
    return id === this.editVerticalFile ? true : false;
  }
  editEducationRow(group: any){
    this.editEducationFile = group.get('id').value;
  }
  checkIfEducationSelected(id: number){
    return id === this.editEducationFile ? true : false;
  }
  editDocumentTypeRow(group: any) {
    console.log('#group'+ group.get('id').value)
    this.editDocument = group.get('id').value;
  }
  checkIfDocTypeSelected(id: number){
    return id === this.editDocument ? true : false;
  }
  editDesignationRow(group: any) {
    console.log('#group'+ group.get('id').value)
    this.editDes = group.get('id').value;
  }
  checkIfDesSelected(id: number){
    return id === this.editDes ? true : false;
  }
  editSkillRow(group: any) {
    console.log('#group'+ group.get('id').value)
    this.editSkill = group.get('id').value;
  }
  checkIfSkillSelected(id: number){
    return id === this.editSkill ? true : false;
  }
  doneRow(group: any, type: string) {
    // @ts-ignore
    this.editVerticalFile = group.get('id').value;
    this.editEducationFile = group.get('id').value;
    const docId = group.get('id').value;
    const formData = {
      id: group.get('id').value,
      name: group.get('name')?.value,
      seq: 0,
      isEditable: false,
    };
    if (type == 'SKL') {
      const _formData = {
        id: group.get('id').value,
        title: group.get('title').value,
      }
      this.service.updateSkillType(docId, _formData).then(res =>{
        this.editSkill=null;
      })
    }
    if (type === 'DOC') {
      this.service.updateDocumentType(docId, formData)
        .then(res => {
          this.editDocument = null;
          this.getAllDocFiles(this.currentPage,this.pageSize);
        })
        .catch(error => {
          let errorMessage = "File Name already exists";
          if (error && error.response && error.response.data && error.response.data.error === "Internal Server Error" &&
            error.response.data.exception === "org.springframework.dao.DataIntegrityViolationException") {
            errorMessage = "A duplicate entry error occurred. This document type already exists.";
          }
          this.toast.error(errorMessage, 'Error', {
            positionClass: "toast-top-right"
          });
        });
    }
    if (type === 'DES') {
      this.service.updateDesignationType(docId, formData).then(res =>{
        this.editDes=null;
        this.getAllDesignation(this.currentPage,this.pageSize);
        })
        .catch(error => {
          let errorMessage = "File Name already exists";
          if (error && error.response && error.response.data && error.response.data.error === "Internal Server Error" &&
            error.response.data.exception === "org.springframework.dao.DataIntegrityViolationException") {
            errorMessage = "A duplicate entry error occurred. This document type already exists.";
          }
          this.toast.error(errorMessage, 'Error', {
            positionClass: "toast-top-right"
          });
        });
    }
    if (type == 'VERTICAL')
      this.service.updateVertical(docId, formData).subscribe(res => {
        console.log("response" + res.data);
        this.editVerticalFile = null;
        this.getAllVerticals(this.currentPage, this.pageSize);
      }, error => {
        let errorMessage = "File Name already exists";
        if (error && error.response && error.response.data && error.response.data.error === "Internal Server Error" &&
          error.response.data.exception === "org.springframework.dao.DataIntegrityViolationException") {
          errorMessage = "A duplicate entry error occurred. This document type already exists.";
        }
        this.toast.error(errorMessage, 'Error', {
          positionClass: "toast-top-right"
        });
      })
    if (type == 'EDUCATION')
      this.service.updateEducation(docId, formData).subscribe(res => {
        this.editEducationFile=null;
        console.log("response" + res.data);
      }, error => {
        let errorMessage = "File Name already exists";
        if (error && error.response && error.response.data && error.response.data.error === "Internal Server Error" &&
          error.response.data.exception === "org.springframework.dao.DataIntegrityViolationException") {
          errorMessage = "A duplicate entry error occurred. This document type already exists.";
        }
        this.toast.error(errorMessage, 'Error', {
          positionClass: "toast-top-right"
        });
      })
  }

  getAllDesignation(page:number,size:number): void {
    const control = <FormArray>this.editDesignationForm.get('desRows');
    control.clear();
    this.service.getDesignationPaginated(page,size).then(res => {
      this.dataService.isLoading.next(false);
      this.designationFiles = res.content;
      this.totalDesignation=res.page.totalElements
      this.paginator.pageIndex=res.page.number;
      this.paginator.pageSize=res.page.size;
      this.pageSize=res.page.size;
      this.currentPage=res.page.number;
      this.designationFiles.forEach(res => {
        const form = this.fb.group({
          id: [res.id],
          name: [res.name],
          active: [res.active],
          isEditable: false,
        })
        control.push(form);
      })
    })
  }
  handlePageDesignation(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getAllDesignation( this.currentPage, this.pageSize);
  }
  handlePageSkills(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getAllSkill( this.currentPage, this.pageSize);
  }
  handlePageVerticals(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getAllVerticals( this.currentPage, this.pageSize);
  }
  handlePageEducation(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getAllEducation( this.currentPage, this.pageSize);
  }
  getAllSkill(page:number,size:number): void{
    const control = <FormArray>this.editSkillForm.get('skillRows');
    control.clear();
    this.service.getAllSkillsPaginated(page,size).then(res => {
      this.dataService.isLoading.next(false);
      this.skillFiles = res.content;
      this.totalSkills=res.page.totalElements
      this.paginator.pageIndex=res.page.number;
      this.paginator.pageSize=res.page.size;
      this.pageSize=res.page.size;
      this.currentPage=res.page.number;
      this.skillFiles.forEach(res => {
        const form = this.fb.group({
          id: [res.id],
          title: [res.title],
          active: [res.active],
          isEditable: false,
        })
        control.push(form);
      })
    })
  }

  getAllVerticals(page:number,size:number): void{
    const control = <FormArray>this.editVerticalForm.get('verticalRows');
    control.clear();
    this.service.getVerticalsPaginated(page,size).then(res => {
      this.dataService.isLoading.next(false);
      this.verticalFiles = res.content;
      this.totalVerticals=res.page.totalElements
      this.paginator.pageIndex=res.page.number;
      this.paginator.pageSize=res.page.size;
      this.pageSize=res.page.size;
      this.currentPage=res.page.number;
      this.verticalFiles.forEach(res => {
        const form = this.fb.group({
          id: [res.id],
          name: [res.name],
          active: [res.active],
          isEditable: false,
        })
        control.push(form);
      })
    })
  }

  getAllEducation(page:number,size:number): void{
    const control = <FormArray>this.editEducationForm.get('educationRows');
    control.clear();
    this.service.getEducationPaginated(page,size).then(res => {
      this.dataService.isLoading.next(false);
      this.educationFiles = res.content;
      this.totalEducation=res.page.totalElements
      this.paginator.pageIndex=res.page.number;
      this.paginator.pageSize=res.page.size;
      this.pageSize=res.page.size;
      this.currentPage=res.page.number;
      this.educationFiles.forEach(res => {
        const form = this.fb.group({
          id: [res.id],
          name: [res.name],
          active: [res.active],
          isEditable: false,
        })
        control.push(form);
      })
    })
  }

  onSelectDocument(event: any,type: string,dragEvent: boolean): void {
    console.log('eve',event);
    this.errorMessage = null;
    if(dragEvent) {
      this.doc = event.dataTransfer.files[0];
    } else {
      this.doc = event.target.files[0];
    }
    const formData: FormData = new FormData();
    formData.append('fileName',this.doc.name);
    formData.append('file',this.doc);
    event.preventDefault();
    if(type == 'DOC'){
      this.document=this.doc.name;
    } else if (type == 'DES') {
      this.designation = this.doc.name;
    }else if(type == 'SKL'){
      this.skill =this.doc.name;
    }else if(type == 'VERTICAL'){
      this.vertical = this.doc.name;
    }else if(type == 'EDUCATION'){
      this.education = this.doc.name;
    }
  }

  setErrorMessage(type: string): void {
    if(type == "DOC" ) {
      this.type = type;
      this.errorMessage = 'Please Select a Valid File';
    }
    else if(type == "DES") {
      this.type = type;
      this.errorMessage = 'Please select a Valid File';
    }else if(type == "SKL"){
      this.type=type;
      this.errorMessage= 'Please select a Valid  File';
    }else if(type == "VERTICAL"){
      this.type=type;
      this.errorMessage= 'Please select a Valid  File';
    }else if(type == "EDUCATION"){
      this.type=type;
      this.errorMessage= 'Please select a Valid  File';
    }
  }
  onDocumentUpload(type: string): void {
    if(this.doc == null){
      this.showloader = false;
      this.setErrorMessage(type);
      return;
    }
    else if(type == "DOC") {
      this.showloader = true;
      this.type = type;
      const formData: FormData = new FormData();
      formData.append('fileName', this.doc.name);
      formData.append('file', this.doc);
      formData.append('type', this.type);
      this.service.uploadSettingFile(formData).subscribe(res=>{
        this.editDocumentForm = this.fb.group({
          docRows: this.fb.array([])
        });
        this.doc = '';
        this.showloader = false;
        this.document = '';
        this.type = "";
        this.toast.success('File Uploaded', 'Success', {
          positionClass: "toast-top-right"
        });
        this.getAllDocFiles(this.currentPage,this.pageSize);
      },(e) =>{
        this.showloader = false;
        if(e.error.status == 500) {
          this.errorMessage = 'Please Try Again Later';
        }
        else if(e.error.status == 400) {
          this.errorMessage = 'Select a valid File';
        }
      })
    } else if(type == "DES") {
      this.showloader = true;
      this.type = type;
      const formData: FormData = new FormData();
      formData.append('fileName', this.doc.name);
      formData.append('file', this.doc);
      formData.append('type', this.type);
      this.service.uploadSettingFile(formData).subscribe(res=>{
        this.editDesignationForm = this.fb.group({
          desRows: this.fb.array([])
        });
        this.doc = '';
        this.showloader = false;
        this.designation = '';
        this.type = "";
        this.toast.success('File Uploaded', 'Success', {
          positionClass: "toast-top-right"
        });
        this.getAllDesignation(this.currentPage,this.pageSize);
      },(e) =>{
        this.showloader = false;
        if(e.error.status == 500) {
          this.errorMessage = 'Please Try Again Later';
        }
        else if(e.error.status == 400) {
          this.errorMessage = 'Select a File';
        }
      })
    }else if(type == "SKL"){
      this.showloader = true;
      this.type=type;
      const formData: FormData =new FormData();
      formData.append('file', this.doc);
      this.service.uploadOrgSkills(formData).subscribe( res => {
        this.editSkillForm = this.fb.group({
          skillRows: this.fb.array([])
        });
        this.doc='';
        this.showloader= false;
        this.skill = '';
        this.type ="";
        this.toast.success('File Uploaded', 'Success', {
          positionClass: "toast-top-right"
        });
        this.getAllSkill( this.currentPage, this.pageSize);
      }, (e) =>{
        this.showloader = false;
        if(e.error.status == 500){
          this.errorMessage = 'Please Try Again Later';
        }
        else if(e.error.status == 400){
          this.errorMessage = 'select a File';
        }
      })
    }else if(type == "VERTICAL"){
      this.showloader = true;
      this.type=type;
      const formData: FormData =new FormData();
      formData.append('file', this.doc);
      this.service.uploadOrgVerticals(formData).subscribe( res => {
        this.editVerticalForm = this.fb.group({
          verticalRows: this.fb.array([])
        });
        this.doc='';
        this.showloader= false;
        this.vertical = '';
        this.type ="";
        this.toast.success('File Uploaded', 'Success', {
          positionClass: "toast-top-right"
        });
        this.getAllVerticals(this.currentPage, this.pageSize);
      }, (e) =>{
        this.showloader = false;
        if(e.error.status == 500){
          this.errorMessage = 'Please Try Again Later';
        }
        else if(e.error.status == 400){
          this.errorMessage = 'select a File';
        }
      })
    }else if(type == "EDUCATION"){
      this.showloader = true;
      this.type=type;
      const formData: FormData =new FormData();
      formData.append('file', this.doc);
      this.service.uploadOrgEducation(formData).subscribe( res => {
        this.editEducationForm = this.fb.group({
          educationRows: this.fb.array([])
        });
        this.doc='';
        this.showloader= false;
        this.education = '';
        this.type ="";
        this.toast.success('File Uploaded', 'Success', {
          positionClass: "toast-top-right"
        });
        this.getAllEducation(this.currentPage, this.pageSize);
      }, (e) =>{
        this.showloader = false;
        if(e.error.status == 500){
          this.errorMessage = 'Please Try Again Later';
        }
        else if(e.error.status == 400){
          this.errorMessage = 'select a File';
        }
      })
    }
  }

  toggleDocType(event: any,group: any) {
    const id = group.get('id')?.value;
    const isActive = event.checked;
    if(isActive){
      this.service.activeDocumentType(id).subscribe({
        next: res => {
          group.get('active')?.setValue(true);
          // this.getAllDocFiles();
        }
      })
    } else {
      this.service.inactiveDocumentType(id).subscribe({
        next: res => {
          group.get('active')?.setValue(false);
          // this.getAllDocFiles();
        }
      })
    }
  }
  toggleDesType(event: any,group: any) {
    const id = group.get('id')?.value;
    const isActive = event.checked;
    if(isActive){
      this.service.activeDesignationType(id).subscribe({
        next: res => {
          group.get('active')?.setValue(true);
        }
      })
    } else {
      this.service.inactiveDesignationType(id).subscribe({
        next: res => {
          group.get('active')?.setValue(false);
        }
      })
    }
  }
  toggleSkillType(event: any,group: any) {
    const id = group.get('id')?.value;
    const isActive = event.checked;
    if(isActive){
      this.service.activeSkillType(id).subscribe({
        next: res => {
          group.get('active')?.setValue(true);
        }
      })
    } else {
      this.service.inactiveSkillType(id).subscribe({
        next: res => {
          group.get('active')?.setValue(false);
        }
      })
    }
  }

  toggleVerticalStatus(event:any, group:any) {
    const id = group.get('id')?.value;
    const isActive = event.checked;
    if(isActive){
      this.service.updateVerticalStatus(id).subscribe({
        next: res => {
          group.get('active')?.setValue(true);
        }
      })
    }else {
      this.service.updateVerticalStatus(id).subscribe(res => {
        group.get('active')?.setValue(false);
        console.log(res, 'res', id, group.get('active')?.value)

        // this.toast.success('Status of customer ' + (res.active ? 'activated' : 'deactivated'), 'Success');
      }), (e: any) => {
        this.toast.error(e.error.reason, 'Request Failed');
      };
    }
  }

  toggleEducationStatus(event:any,group: any) {
    const id = group.get('id')?.value;
    const isActive = event.checked;
    if(isActive){
      this.service.updateEducationStatus(id).subscribe({
        next: res => {
          group.get('active')?.setValue(true);
        }
      })
    }else {
      this.service.updateEducationStatus(id).subscribe(res => {
        group.get('active')?.setValue(false);
        // this.toast.success('Status of customer ' + (res.active ? 'activated' : 'deactivated'), 'Success');
      }), (e: any) => {
        this.toast.error(e.error.reason, 'Request Failed');
      };
    }

  }
  deleteFile(group?: any,index?: number,type?: string): void {
    const id= group.get('id').value;
    this.dialog.open(PopUpComponent, {
      data: {
        message: 'Are you sure you want to delete this ?',
        type: 'Delete'
      }
    }).afterClosed().subscribe(resp => {
      if (resp) {
        if (id) {
          if (type == 'SKL') {
            this.service.deleteOrgSkill(id).then(() => {
              const control = this.editSkillForm.get('skillRows') as FormArray;
              control.removeAt(index ?? 0);
            })
          } else {
            console.log(this.vendor.id+"vendorId ")
            this.service.deleteDocumentType(id).then(() => {
              if (type == 'DOC') {
                const control = this.editDocumentForm.get('docRows') as FormArray;
                control.removeAt(index ?? 0);
              } else if (type == 'DES') {
                const control = this.editDesignationForm.get('desRows') as FormArray;
                control.removeAt(index ?? 0);
              }
            }, (e) => {
              if (e.error.status == 500) {
                // alert('Could not Delete !! Please try again Later ');
              }
            });
          }

        } else {
          this.doc = '';
          this.document = '';
          this.designation = '';
          this.skill = '';
        }
      }
    })
  }
  removeSelectedFile(type: string) {
    if (type == 'DOC')
      this.document = ''
    else if (type == 'DES') {
      this.designation = ''
    }else if (type == 'SKL') {
      this.skill = ''
    }else if (type == 'VERTICAL'){
      this.vertical = ''
    }else if (type == 'EDUCATION'){
      this.education = ''
    }
  }
  handleDragOver(event: DragEvent): void {
    event.preventDefault(); // Prevent the default behavior
  }

}


