<div class="content-wrapper-main-inner-content">
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h5 class="page-title">Manage User Profile</h5>
      </div>
    </div>
  </div>
  <div class="col-md-12 companyProfile_wrapper">
    <div class="companyProfile_header theme-bg-color">
      <h5>Add Personal Information</h5>
    </div>
    <div class="companyProfile_content">
      <div class="create-vendor">
        <form [formGroup]="userProfileForm" class="form-wrapper" (submit)="updateuserProfile()">
          <div class="row form-group">
            <div class="col-md-6 form-group">
              <div class="profile-details">
                <div class="profileimage_wrapper d-flex justify-content-center align-items-center" *ngIf="logoLoader">
                  <mat-spinner diameter="50"></mat-spinner>
                </div>
                  <div class="profileimage_wrapper" *ngIf="!file && !userImage"
                       (drop)="onSelectFile($event, true)"
                       (dragover)="onDragOver($event)"
                       (dragenter)="onDragEnter($event)"
                       (dragleave)="onDragLeave($event)"
                  >
                    <img
                      *ngIf="!dragOver && !logoLoader" class="rounded-circle profileimage" id="fileInput"
                         [src]="'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909__340.png'">
                  </div>
                <div class="profileimage_wrapper" *ngIf="userImage">
                  <img
                     class="rounded-circle profileimage" id="fileInput2"
                    [src]="userImage">
                </div>
                  <div class="alignment">
                    <input style="display: none"  #myFileInput type="file" class="form-control-file" (change)="onSelectFile($event)"
                           #fileInput>
                    <button type='button' [disabled]="userImage" (click)="fileInput.click()" class="btn btn-save theme-btn-color text-white mr-5">UPLOAD</button>
                    <button type='button' [disabled]="!userImage" (click)="delete()" class="btn delete cursor-pointer">DELETE</button>
                  </div>
              </div>
            </div>

            <div class="col-md-6 ">
              <mat-form-field appearance="outline" class="w-100 border-red mb-3">
                <mat-label>First Name</mat-label>
                <input class="custom-input-fileds" matInput #inputFirstName formControlName="firstName" type="text"
                       required [readonly]="true" placeholder="First name">
              </mat-form-field>

              <mat-form-field appearance="outline" class="w-100 border-red mb-3">
                <mat-label>Last Name</mat-label>
                <input class="custom-input-fileds" matInput #inputLastName
                       formControlName="lastName" type="text" placeholder="Last name" [readonly]="true">
              </mat-form-field>

              <mat-form-field appearance="outline" class="w-100 border-red mb-3">
                <mat-label>Email Id</mat-label>
                <input class="custom-input-fileds" matInput #inputEmailId formControlName="email" type="email" [readonly]="true"
                       placeholder="Email Id">
              </mat-form-field>

              <mat-form-field appearance="outline" class="w-100 border-red mb-3">
                <mat-label>Mobile No.</mat-label>
                <input matInput #inputMobId formControlName="mobile" type="text" placeholder="Mobile number"
                       maxlength="10" minlength="10" (keypress)="onKeyPress($event)">
              </mat-form-field>

              <div *ngIf="displayError"  class="text-danger">
                {{ this.errorMessage }}
              </div>
            </div>
          </div>
          <div class="form-group btns d-flex justify-content-end save-form">
            <button *ngIf="!showloader" class="btn btn-save theme-btn-color" type="submit">Save</button>
            <div *ngIf="showloader" disabled>
              <button id="submitButton1" class="btn btn-saveform" disabled>
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
