import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private hasChangedSubject = new BehaviorSubject<boolean>(false); // Initialize with default value
  hasChanged$ = this.hasChangedSubject.asObservable();

  emitChange(hasChanged: boolean) {
    this.hasChangedSubject.next(hasChanged);
  }
}
