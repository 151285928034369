<form [formGroup]="vendorForm" (submit)="updateVendor()" ngNativeValidate class="vendor_form">
  <div class="vendor_wrapper shadow-sm border">
    <h2 class="mb-4">Vendor's Information</h2>
    <div class="form-row mb-2">
      <div class="form-group col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Vendor Company Name</mat-label>
          <input matInput formControlName="vendorName" type="text" required
                 [readonly]="role=='TA' || openDialogType=='READONLY'"/>
          <div *ngIf="field == 'vendorName'" class="alert">
            <div>
              {{ this.errorMessage }}
            </div>
          </div>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>CEO Name</mat-label>
          <input matInput formControlName="ceoName" type="text" required
                 [readonly]="role=='TA' || openDialogType=='READONLY'">
          <div *ngIf="field == 'ceoName'" class="alert">
            <div>
              {{ this.errorMessage }}
            </div>
          </div>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row mb-2">
      <div class="form-group col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Website</mat-label>
          <input matInput formControlName="websiteUrl" type="text" [(ngModel)]="this.prevLink2"
                 required [readonly]="role=='TA' || openDialogType=='READONLY'">
          <div *ngIf="field == 'vendorWebsite'" class="alert">
            <div>
              {{ this.errorMessage }}
            </div>
          </div>
        </mat-form-field>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Company Email id</mat-label>
          <input matInput type="email" formControlName="emailAddress" (change)="errorMessage = null"
                 style="text-transform:lowercase" required [readonly]="role=='TA' || openDialogType=='READONLY'">
          <div *ngIf="field == 'emailAddress'" class="alert">
            <div>
              {{ this.errorMessage }}
            </div>
          </div>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row mb-2">
      <div class="form-group col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Mobile</mat-label>
          <input matInput type="text" maxlength="10" formControlName="mobileNumber" class="test_css"
                 [readonly]="role=='TA' || openDialogType=='READONLY'" (input)="onKeyPress($event)" required>
        </mat-form-field>
        <div *ngIf="displayError" class="text-danger">
          {{ this.errorMessage }}
        </div>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Phone number</mat-label>
          <input matInput type="text" maxlength="10" formControlName="phoneNumber" (keypress)="onKeyPressPhone($event)"
                 [readonly]="role=='TA' || openDialogType=='READONLY'">

        </mat-form-field>
        <div CLASS="text-danger">
          {{ this.errorMessagePhone }}
        </div>
      </div>
    </div>

    <div class="form-row mb-2">
      <div class="form-group col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>No. Of employees</mat-label>
          <input #noEmployeesAutoCompleter
            type="text"
            matInput
            placeholder="Number Of Employees"
            [formControl]="employeeRange"
            [matAutocomplete]="auto"
            required
          />
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onRangeSelected($event)">
            <mat-option *ngFor="let range of filteredRange | async" [value]="range">
              {{ range }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div *ngIf="employeeRange.invalid && (employeeRange.dirty || employeeRange.touched)" class="alert">
          <div *ngIf="employeeRange.errors?.pattern" class="text-danger">
            Invalid format. Please enter a number range (e.g., 1801-1900).
          </div>
          <div *ngIf="employeeRange.errors?.invalidRange" class="text-danger">
            Invalid range. The first number should be less than the second number.
          </div>
          <div *ngIf="employeeRange.errors?.max" class="text-danger">
            Number should be greater than {{ getMaximumNumberOfEmployees() }}.
          </div>
        </div>

        <div *ngIf="(filteredRange | async)?.length == 0 && employeeRange.valid"
             class="dropdown-skills position-relative">
          <ul>
            <li class="li_disabled">No match Found</li>
            <hr class="m-0 p-0"/>
            <li class="font-weight-bold">
              <div class="d-flex justify-content-between align-items-center" (click)="createDateRangeForEmployee()">
                <span>Add this to Employee Range</span>
                <button type="button" class="p-0 bg-transparent border-0 fs-20">+</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="form-group col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>GST number</mat-label>
          <input matInput type="text" formControlName="gstNumber"
                 #inputgstNumber [readonly]="role=='TA' || openDialogType=='READONLY'">
        </mat-form-field>
      </div>
    </div>

    <div class="form-row mb-2">
      <div class="form-group col-md-6">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>SPOC</mat-label>
          <input #SPOCAutoCompleter type="text" matInput placeholder="SPOC" [formControl]="spocControl" [matAutocomplete]="autoSPOC" required>
          <mat-autocomplete #autoSPOC="matAutocomplete" (optionSelected)="chooseSpoc($event)" [displayWith]="formatSPOC">
            <mat-option *ngFor="let option of filteredSpoc | async" [value]="option">
              {{ formatSPOC(option) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="vendor_wrapper shadow-sm border">
    <h2 class="mb-4">Address information</h2>
    <div class="form-row mb-2">
      <div formGroupName="address" class="form-group col-md-8">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Street address</mat-label>
          <input matInput type="text" formControlName="streetAddress"
                 [readonly]="role=='TA' || openDialogType=='READONLY'">
        </mat-form-field>
      </div>
      <div formGroupName="address" class="form-group col-md-4">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Postal code</mat-label>
          <input matInput type="text" maxlength="6" minlength="6" formControlName="postalCode"
                 [readonly]="role=='TA' || openDialogType=='READONLY'">
        </mat-form-field>
      </div>
    </div>


    <div formGroupName="address" class="form-row">
      <div formGroupName="country" class="form-group col-md-4">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Country</mat-label>
          <mat-select [formControl]="countryControl" [(value)]="selectedCountry" [compareWith]="compareFunction"
                      (valueChange)="selectCountry($event)" placeholder="Country">
            <mat-option>
              <ngx-mat-select-search [formControl]="countryServerCtrl" [searching]="searchCountry"
                                     [placeholderLabel]="'Search Country Name...'"
                                     [noEntriesFoundLabel]="'No Result Found'" >
                <mat-icon ngxMatSelectSearchClear>
                  <div class="d-flex align-items-center justify-content-center mt-sm-1">
                    <a>
                      <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                      </svg>
                    </a>
                  </div>
                </mat-icon>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let country of countries" [value]="country">
              {{ country.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div formGroupName="state" class="form-group col-md-4">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>State</mat-label>
          <mat-select [formControl]="stateControl" [(value)]="selectedState" (valueChange)="selectState($event)"
                      [compareWith]="compareFunction"
                      placeholder="State" [disabled]="!selectedCountry">
            <mat-option>
              <ngx-mat-select-search [formControl]="stateServerCtrl" [searching]="searchState"
                                     [placeholderLabel]="'Search State Name...'"
                                     [noEntriesFoundLabel]="'No Result Found'">
                <mat-icon ngxMatSelectSearchClear>
                  <div class="d-flex align-items-center justify-content-center mt-sm-1">
                    <a>
                      <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                      </svg>
                    </a>
                  </div>
                </mat-icon>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let state of states" [value]="state">
              {{ state.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div formGroupName="city" class="form-group col-md-4">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>City</mat-label>
          <mat-select [formControl]="cityControl" [(value)]="selectedCity" (valueChange)="selectCity($event)"
                      [compareWith]="compareFunction"
                      placeholder="City" [disabled]="!selectedState || openDialogType=='READONLY'">
            <mat-option>
              <ngx-mat-select-search [formControl]="cityServerCtrl" [searching]="searchCity"
                                     [placeholderLabel]="'Search City Name...'"
                                     [noEntriesFoundLabel]="'No Result Found'">
                <mat-icon ngxMatSelectSearchClear>
                  <div class="d-flex align-items-center justify-content-center mt-sm-1">
                    <a>
                      <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                      </svg>
                    </a>
                  </div>
                </mat-icon>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let city of cities" [value]="city">
              {{ city.name }}
            </mat-option>
          </mat-select>


        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center align-items-center mt-4">
    <div *ngIf="this.errors.size>0" class="alert alert-danger">
      {{ getError() }}
    </div>
  </div>

  <div *ngIf="openDialogType!='READONLY'" class="d-flex justify-content-end align-items-center mt-4">
    <div class="form-group btns save-form">
      <button (click)="updateVendor()" *ngIf="!showloader" class="btn btn-save mx-3 theme-btn-color"
              type="submit">
        Save details
      </button>

      <div *ngIf="showloader" disabled>
        <button class="btn btn-save theme-btn-color" disabled>
          <div class="spinner-border spinner-border-sm me-2" role="status">
            <span class="sr-only"></span>
          </div>
          Save Details
        </button>
      </div>
    </div>
  </div>

</form>
