<div class="content-wrapper">
  <div class="col-md-12">
    <div class="content-wrapper-main-inner-content">
      <div class="content-wrapper">
        <div class="d-flex justify-content-between mat-head mb-2">
          <h5>Select Theme</h5>
        </div>
        <form [formGroup]="themeForm" (ngSubmit)="onSubmit()">
          <div class="theme_grid">
            <div *ngFor="let theme of themeData; index as i">
              <div class="theme_wrapper" (click)="selectTheme(theme)"
                   [ngClass]="[themeForm.value.radioOptions === theme ? 'border border-primary border-primary-custom': '']">
                <div class="radio">
                  <div class="theme_color" [ngStyle]="{'background-color': theme.colour}"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between mat-head mt-5 mb-2">
            <h5>Select Custom Theme</h5>
          </div>
          <div class="theme_grid mb-4">
            <div class="theme_wrapper">
              <input type="color" id="favcolor" (focus)="onInputFocus()" class="w-100"
                     formControlName="customColor"/>
            </div>
          </div>
          <div class="theme_grid">
            <div *ngFor="let theme of themeCustom; index as i">
              <div class="theme_wrapper" (click)="selectTheme(theme)"
                   [ngClass]="[themeForm.value.radioOptions === theme ? 'border border-primary border-primary-custom': '']">
                <div class="radio"
                     style="  align-items: center; display: flex;justify-content: center;flex-direction: row-reverse;">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="align-self-end" (click)="deleteCustomTheme(theme.id)" style="width: 10%;">
                      <svg width="30" height="30" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M4.66675 14C4.30008 14 3.9863 13.8696 3.72541 13.6087C3.46408 13.3473 3.33341 13.0333 3.33341 12.6667V4C3.14453 4 2.98608 3.93622 2.85808 3.80867C2.73053 3.68067 2.66675 3.52222 2.66675 3.33333C2.66675 3.14444 2.73053 2.986 2.85808 2.858C2.98608 2.73044 3.14453 2.66667 3.33341 2.66667H6.00008C6.00008 2.47778 6.06408 2.31933 6.19208 2.19133C6.31964 2.06378 6.47786 2 6.66675 2H9.33342C9.5223 2 9.68075 2.06378 9.80875 2.19133C9.9363 2.31933 10.0001 2.47778 10.0001 2.66667H12.6667C12.8556 2.66667 13.0139 2.73044 13.1414 2.858C13.2694 2.986 13.3334 3.14444 13.3334 3.33333C13.3334 3.52222 13.2694 3.68067 13.1414 3.80867C13.0139 3.93622 12.8556 4 12.6667 4V12.6667C12.6667 13.0333 12.5363 13.3473 12.2754 13.6087C12.0141 13.8696 11.7001 14 11.3334 14H4.66675ZM4.66675 4V12.6667H11.3334V4H4.66675ZM6.00008 10.6667C6.00008 10.8556 6.06408 11.0138 6.19208 11.1413C6.31964 11.2693 6.47786 11.3333 6.66675 11.3333C6.85564 11.3333 7.01408 11.2693 7.14208 11.1413C7.26964 11.0138 7.33342 10.8556 7.33342 10.6667V6C7.33342 5.81111 7.26964 5.65267 7.14208 5.52467C7.01408 5.39711 6.85564 5.33333 6.66675 5.33333C6.47786 5.33333 6.31964 5.39711 6.19208 5.52467C6.06408 5.65267 6.00008 5.81111 6.00008 6V10.6667ZM8.66675 10.6667C8.66675 10.8556 8.73075 11.0138 8.85875 11.1413C8.9863 11.2693 9.14453 11.3333 9.33342 11.3333C9.5223 11.3333 9.68075 11.2693 9.80875 11.1413C9.9363 11.0138 10.0001 10.8556 10.0001 10.6667V6C10.0001 5.81111 9.9363 5.65267 9.80875 5.52467C9.68075 5.39711 9.5223 5.33333 9.33342 5.33333C9.14453 5.33333 8.9863 5.39711 8.85875 5.52467C8.73075 5.65267 8.66675 5.81111 8.66675 6V10.6667ZM4.66675 4V12.6667V4Z"
                          fill="#ff7777"/>
                      </svg>
                    </div>
                  </div>
                  <div style="width: 90%" class="theme_color" [ngStyle]="{'background-color': theme.colour}"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-3">
            <button *ngIf="!showloader" class="action-btn theme-bg-color" type="submit">Save details</button>
            <button *ngIf="showloader" class="action-btn theme-bg-color" disabled>
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
