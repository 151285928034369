<div class="consultant_skills_wrap">
  <div>
    <div class="p-3 shadow-sm m-2 rounded-3">
      <h2 class="mb-0">Primary Skills</h2>
      <div *ngIf="primarySkills.length > 0" class="me-5 pe-5">
        <mat-chip class="me-3 mt-2"
                  *ngFor="let skill of primarySkills; let i= index"
                  (removed)=" openDialogSkillDelete('primary',skill.id,i,skill.title)">
          {{ skill.title }}
          <button matChipRemove [attr.aria-label]="'remove ' + skill"><span>&#x2716;</span></button>
        </mat-chip>
      </div>
      <div *ngIf="(filteredSkills|async)?.length !== 0 && !exactMatchFoundPrimary && skillControl.value" class="dropdown-skills position-relative mt-2">
        <ul>
          <li class="font-weight-bold">
            <div class="d-flex justify-content-between align-items-center" (click)="addSkillType('primary')">
              <span>Add this Skill to Skill Types</span>
              <button class="p-0 bg-transparent border-0 fs-20"> +</button>
            </div>
          </li>
        </ul>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <mat-form-field appearance="outline" class="border-red skills_drop w-75 me-3 mt-3">
          <mat-label>Skills</mat-label>
          <mat-chip-list #chipList aria-label="skill selection">
            <mat-chip class="me-3"
                      *ngFor="let skill of selectedSkill"
                      (removed)="removeSkill(skill)">
              {{ skill.title }}
              <button matChipRemove [attr.aria-label]="'remove ' + skill"><span>&#x2716;</span></button>
            </mat-chip>
            <input #primarySkillAutoCompleter
              #chipGrid
              placeholder="Add your primary skills..."
              [formControl]="skillControl"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [(ngModel)]="skillName"
              #autoTrigger='matAutocompleteTrigger'
              (click)="openAutocomplete(autoTrigger)"
            >
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectSkills($event)">
            <mat-option *ngFor="let skill of filteredSkills | async" [value]="skill"
                        [disabled]="disableBothSkills(skill)">
              {{ skill.title }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div class="save-skill" *ngIf="!primaryLoading">
          <button [disabled]="selectedSkill.length === 0" (click)="addSkill('primary')"
                  class="btn btn-save theme-btn-color">Save
          </button>
        </div>
        <div class="save-skill" *ngIf="primaryLoading">
          <button class="btn btn-save theme-btn-color d-flex" disabled>
            <div class="spinner-border spinner-border-sm me-2" role="status">
              <span class="sr-only"></span>
            </div>
            Save
          </button>
        </div>
      </div>
      <div *ngIf="(filteredSkills|async)?.length == 0" class="dropdown-skills position-relative">
        <ul>
          <li class="li_disabled">
            No match Found
          </li>
          <hr class="m-0 p-0">
          <li class="font-weight-bold">
            <div class="d-flex justify-content-between align-items-center" (click)="addSkillType('primary')">
              <span>Add this Skill to Skill Types</span>
              <button class="p-0 bg-transparent border-0 fs-20"> +</button>
            </div>
          </li>
        </ul>
      </div>

    </div>
    <div class="p-3 shadow-sm m-2 rounded-3">
      <h2 class="mb-0">Secondary Skills</h2>
      <div *ngIf="secondarySkills?.length > 0" class="me-5 pe-5">
        <mat-chip class="me-3 mt-2"
                  *ngFor="let skill of secondarySkills; let i= index"
                  (removed)="openDialogSkillDelete('secondary',skill.id,i,skill.title)">
          {{ skill.title }}
          <button matChipRemove [attr.aria-label]="'remove ' + skill"><span>&#x2716;</span></button>
        </mat-chip>
      </div>
      <div *ngIf="(secondaryFilteredSkills|async)?.length !== 0 && !exactMatchFoundSecondary && skillControl2.value" class="dropdown-skills position-relative mt-2">
        <ul>
          <li class="font-weight-bold">
            <div class="d-flex justify-content-between align-items-center" (click)="addSkillType('secondary')">
              <span>Add this Skill to Skill Types</span>
              <button class="p-0 bg-transparent border-0 fs-20"> +</button>
            </div>
          </li>
        </ul>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <mat-form-field appearance="outline" class="border-red skills_drop w-75 me-3 mt-3">
          <mat-label>Skills</mat-label>
          <mat-chip-list #chipList2 aria-label="skill selection">
            <mat-chip
              class="me-3 mb-2"
              *ngFor="let skill of selectedSkillSecondary"
              (removed)="removeSecondarySkill(skill)">
              {{ skill.title }}
              <button matChipRemove [attr.aria-label]="'remove ' + skill"><span>&#x2716;</span></button>
            </mat-chip>
            <input #secondarySkillAutoCompleter
              placeholder="Add your secondary skills..."
              #chipGrid2
              [formControl]="skillControl2"
              [matAutocomplete]="auto2"
              [matChipInputFor]="chipList2"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [(ngModel)]="skillName2"
              #auto2Trigger="matAutocompleteTrigger"
              (click)="openAutocomplete(auto2Trigger)"
            >
          </mat-chip-list>
          <mat-autocomplete #auto2="matAutocomplete" (optionSelected)=" selectSkillsSecondary($event)">
            <mat-option *ngFor="let skill of secondaryFilteredSkills | async" [value]="skill"
                        [disabled]="disableBothSkills(skill)">
              {{ skill.title }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div class="save-skill" *ngIf="!secondaryLoading">
          <!--        <button  (click)="addSkill('secondary')" class="btn btn-save theme-btn-color">Save</button>-->
          <button [disabled]="selectedSkillSecondary.length === 0" (click)="addSkill('secondary')"
                  class="btn btn-save theme-btn-color">Save
          </button>
        </div>
        <div class="save-skill" *ngIf="secondaryLoading">
          <button class="btn btn-save theme-btn-color d-flex" disabled>
            <div class="spinner-border spinner-border-sm me-2" role="status">
              <span class="sr-only"></span>
            </div>
            Save
          </button>
        </div>
      </div>
      <div *ngIf="(secondaryFilteredSkills|async)?.length == 0" class="dropdown-skills position-relative">
        <ul>
          <li class="li_disabled">
            No match Found
          </li>
          <hr class="m-0 p-0">
          <li class="font-weight-bold">
            <div class="d-flex justify-content-between align-items-center" (click)="addSkillType('secondary')">
              <span>Add this Skill to Skill Types</span>
              <button class="p-0 bg-transparent border-0 fs-20"> +</button>
            </div>
          </li>
        </ul>
      </div>
     </div>
  </div>
  <div class="p-3 shadow-sm m-2 rounded-3">
    <h2 class="mb-4">English Communication & Articulation Rating</h2>
    <mat-form-field appearance="outline" class="w-100 border-red">
      <mat-label>English Communication & Articulation</mat-label>
      <mat-select [formControl]="consultantRatingForm" [required]="true">
        <mat-option *ngFor="let rate of rating" [value]="rate" class="dropItems" (click)="addRating(rate)">
          <a class="dropdown-item">{{ rate }}</a>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>




