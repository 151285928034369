import { Component, Inject, Input,  OnInit} from '@angular/core';
import { ApiService } from "../../../../services/api.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import {commentList} from "../../../../shared/interfaces";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {SettingsService} from "../../../../services/settingsService";

@Component({
  selector: 'app-internal-communication',
  templateUrl: './internal-communication.component.html',
  styleUrls: ['./internal-communication.component.css']
})
export class InternalCommunicationComponent implements OnInit {
  comment: string = '';
  commentsList: commentList[];
  getComment: string = '';
  editingCommentId: string | null = null;
  editableStatus: { [id: string]: boolean } = {}; // Object to store editable status for each comment ID
  @Input() hasChanged: boolean = false;

  constructor(
    private service: ApiService,
    @Inject(MAT_DIALOG_DATA) public selectedMyTask: any,
    private toastr: ToastrService,
    private isMyTaskDataUpdated:IsDataUpdatedService,
    private settingsService: SettingsService
  ) {
    this.getComments();
  }

  ngOnInit(): void {
    this.settingsService.hasChanged$.subscribe((value: boolean) => {
      if (value) {
        this.getComments()
      }
    });
    this.getComments();
    setInterval(() => {
      this.updateEditableStatus();
    }, 60000);
  }



  getComments() {
    this.service.getCommentsForTasks(this.selectedMyTask.id, 'INTERNAL').subscribe((res) => {
      this.commentsList = res;
// Initialize editable status for each comment ID
      this.commentsList.forEach(comment => {
        this.editableStatus[comment.id] = this.isEditable(comment.createdDate);
      });
    });
  }

  addComment(type: String) {
    if (type == 'INTERNAL') {
      if (this.comment.trim() != '') {
        this.service.addCommentInTask(this.comment, 'INTERNAL', this.selectedMyTask.id).then((res) => {
          this.isMyTaskDataUpdated.setUpdated(true);
          this.comment = '';
          this.getComments();
        });
      } else {
        this.toastr.info('Please add Comment ', 'Info');
      }
    }
  }

  deleteComment(commentId: string) {
    this.service.deleteCommentForAdminClient(Number(commentId)).subscribe((res) => {
      this.isMyTaskDataUpdated.setUpdated(true);
      this.toastr.success('Comment Deleted Successfully', 'Delete');
      this.getComments();
    })
  }

  isEditMode(commentId: string): boolean {
    return this.editingCommentId === commentId;
  }

  editComment(commentId: string) {
    this.editingCommentId = commentId;
    this.service.getSingleComment(Number(commentId)).subscribe((res) => {
      this.getComment = res.comment
    })
  }

  saveEdit(comment: commentList) {
    const originalComment = this.commentsList.find(c => c.id === comment.id);
    if (originalComment && originalComment.comment !== this.getComment) {
      const payload = {
        comment: this.getComment,
        communicationType: "EXTERNAL"
      };
      this.service.editCommentForAdminClient(Number(comment.id), payload).subscribe((res) => {
        this.isMyTaskDataUpdated.setUpdated(true);
        this.editingCommentId = null;
        this.toastr.success('Comment Updated Successfully', 'Update')
        this.getComments();
      });
    } else {
      this.closeEdit(comment.id);
    }
  }

  closeEdit(commentId: string) {
    this.editingCommentId = null;
  }

  isEditable(createdDate: any): boolean {
    const commentDate = new Date(createdDate);
    const now = new Date();
    const elapsedTime = now.getTime() - commentDate.getTime();
    const editableWindow = 5 * 60 * 1000; // 5 minutes in milliseconds
    return elapsedTime < editableWindow;
  }

  updateEditableStatus() {
    const now = new Date();
    this.commentsList.forEach(comment => {
      const commentDate = new Date(comment.createdDate);
      const elapsedTime = now.getTime() - commentDate.getTime();
      const editableWindow = 5 * 60 * 1000; // 5 minutes in milliseconds
      this.editableStatus[comment.id] = elapsedTime < editableWindow;
    });
  }

}
