import { Component, OnInit } from '@angular/core';
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {NgModule,CUSTOM_ELEMENTS_SCHEMA} from "@angular/core";

@Component({
  selector: 'app-date-ranges',
  templateUrl: './date-ranges.component.html',
  styleUrls: ['./date-ranges.component.css']
})
export class DateRangesComponent implements OnInit {

  startDate: Date;
  endDate: Date;

  constructor() { }

  ngOnInit(): void {
  }

  onInput(datetype: string, event: MatDatepickerInputEvent<Date>):void{
    if (event.value) {
      this.setDateRange(datetype, event.value);
    }
}

  onChange(dateType:string,event: MatDatepickerInputEvent<Date>):void{
    if (event.value) {
      this.setDateRange(dateType, event.value)
    }

  }

  setDateRange(datetype : string ,  dateValue: Date){
    if(datetype === 'startDateInput'){
      this.startDate=dateValue;
    }
    if(datetype === 'endDateInput'){
      this.endDate=dateValue;
    }

  }

}
