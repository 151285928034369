<div class="content-wrapper">
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h5 class="page-title">Manage Company Profile</h5>
      </div>
    </div>
  </div>
  <div class="col-md-12 companyProfile_wrapper">
    <div class="companyProfile_header theme-bg-color">
      <h5>Company Profile</h5>
      <span class="font-weight-light">Update your company logo and details here</span>
    </div>
    <div class="companyProfile_content">
      <div class="col-md-8">
        <form [formGroup]="organisationForm" class="form-wrapper" (submit)="updateOrganisation()">
          <div class="form-group">
            <div class="form-row mb-2">
              <div class="form-group col-md-12">
                <mat-form-field appearance="outline" class="w-100 border-red">
                  <mat-label>Company name</mat-label>
                  <input matInput formControlName="name" type="text" id="inputfirstName" placeholder="Company name"
                         [readOnly]="role!='ADMIN'">
                </mat-form-field>
              </div>
              <div class="form-group col-md-12">
                <mat-form-field appearance="outline" class="w-100 border-red">
                  <mat-label>Website</mat-label>
                  <input matInput formControlName="webUrl" id="inputEmail" placeholder="Company website" [readOnly]="role!='ADMIN'">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="form-group btns d-flex justify-content-end save-form :save-form-disbale">
            <button *ngIf="!showloader " class="btn btn-save theme-btn-color" type="submit">Save Details</button>
            <div *ngIf="showloader" disabled>
              <button id="submitButton1" class="btn btn-saveform" disabled>
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-4">
        <div class="vendor_wrapper mt-0 h-100">
          <div class="upload_doc text-center">
            <div *ngIf="companyImage == null && !logoLoader">
              <input #fileInput style="display: none" type="file" class="form-control-file" (change)="onSelectFile($event)"
                     accept=".png,.doc,.docx,application/msword,application/vnd,application/pdf">
              <h5>Upload company logo</h5>
              <p class="image-uploader_max-file-size mb-3">Max file size: 10MB (pdf, png, jpeg, xlx, doc,
                docx)</p>
              <div class="drag_drop_wrapper" (click)="uploadFileTrigger()" (dragover)="handleDragOver($event)" (drop)="onSelectFile($event,true)">
                <div><img src="assets/img_1.png" style="height: 40px" class=""/></div>
                <div><span><b>Choose a file</b> or drag it here</span></div>
              </div>
            </div>
            <div *ngIf="companyImage != null && !logoLoader" class="abc">
            <h5>Upload company logo</h5>
            <p class="image-uploader_max-file-size mb-3">Max file size: 10MB (pdf, png, jpeg, xlx, doc,
              docx)</p>
            <div class="drag_drop_wrapper_upload">
              <img [src]="companyImage" class="uploaded_img">
              <span *ngIf="role=='ADMIN'" (click)="delete()"><i class="fas fa-times-circle"></i></span>
            </div>
            </div>
            <div *ngIf="logoLoader" disabled>
              <button id="logoLoader" class="btn btn-saveform" disabled>
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
