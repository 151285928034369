export class Common {

  static setThemeColorLocally(colorCode: string): void {
    const rootVariable = document.documentElement,
      opcaity = 0.1,
      ColorObj = {primaryColor: colorCode, secondaryColor: this.rgbaToHex(this.hexToRGBA(colorCode, opcaity))};
    localStorage.setItem('selectedTheme', JSON.stringify(ColorObj));
    rootVariable.style.setProperty('--primaryColor', colorCode);
    rootVariable.style.setProperty('--secondaryColor', this.rgbaToHex(this.hexToRGBA(colorCode, opcaity)));
  }


  static hexToRGBA(hex: string, alpha: number = 1): string {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

 static rgbaToHex(rgba: string): string {
    const matches = rgba.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d*(?:\.\d+)?|\d+)\)$/);
    if (!matches) {
      return '';
    }
    const r = parseInt(matches[1], 10);
    const g = parseInt(matches[2], 10);
    const b = parseInt(matches[3], 10);
    const a = Math.round(parseFloat(matches[4]) * 255);
    const hex = ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1) + (a < 16 ? '0' : '') + a.toString(16);
    return `#${hex}`;
  }
}
