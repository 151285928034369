import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {Common} from "../../shared/Common";
import {PopUpComponent} from "../../util/pop-up/pop-up.component";
import {ApiService} from "../../services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ToastrService} from "ngx-toastr";
import {ThemeLoaderService} from "../../services/theme-loader.service";

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss']
})
export class ThemeComponent implements OnInit {
  themeForm : FormGroup;
  themeData: any[] = []
  themeCustom:any[]=[];
  showloader = false;
  errorMessage: string| null;

  constructor(
    private service: ApiService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private toast: ToastrService,
    private themeLoaderService: ThemeLoaderService
  ) { }

  ngOnInit(): void {
    this.getAllThemes();
    this.themeForm = new FormGroup ({
      radioOptions: new FormControl(''),
      customColor: new FormControl(''),
    });

  }

  getAllThemes() {
    this.themeData=[];
    this.themeCustom=[];
    this.service.getAllThemes().subscribe({
      next: res => {
        res.forEach((e:any)=>{
          if(e['custom']==true){
            this.themeCustom.push(e);
          }
          else{
            this.themeData.push(e);
          }
        })
        this.themeForm?.get('radioOptions')?.setValue(this.themeData?.find(theme => theme.active === true));
      },
      error: err => {
        console.error('err',err.error);
      }
    })
  }

  selectTheme(theme: any) {
    this.themeForm.value.radioOptions = theme;
  }

  onInputFocus() : void {
    this.themeForm.controls.radioOptions.setValue('');
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  onSubmit() {
    this.showloader = true;
    const rootVariable = document.documentElement;
    if(this.themeForm.value.radioOptions) {
      this.service.updateOrgTheme(this.themeForm.value.radioOptions.id,this.themeForm.value.radioOptions.name,this.themeForm.value.radioOptions.colour).subscribe({
        next: res => {
          const primaryColor = this.themeForm.value.radioOptions.colour ?? res?.theme?.color;
          const secondaryColor = Common.rgbaToHex(Common.hexToRGBA(primaryColor, 0.1));
          const ColorObj = {primaryColor, secondaryColor};
          localStorage.setItem('selectedTheme', JSON.stringify(ColorObj));
          rootVariable.style.setProperty('--primaryColor', primaryColor);
          rootVariable.style.setProperty('--secondaryColor', secondaryColor);
          this.themeLoaderService.setTheme(primaryColor, secondaryColor);
          Common.setThemeColorLocally(this.themeForm.value.radioOptions.colour);
        },
        error: err =>  {
          this.showloader = false;
          if (err.error.code == 4000) {
            this.showloader = false;
            this.errorMessage = err.error.reason;
            this.openSnackBar(err.error.reason, "ok ");
          }
        },complete: () =>{
          this.showloader = false;
        }
      });
    } else if(this.themeForm.value.customColor) {
      /* Custom Color API Pending to implement */
      this.service.updateOrgCustomTheme(this.themeForm.value.customColor).subscribe({
        next: res => {
          console.log('res',res)
          this.service.updateOrgTheme(res.id,res.name,res.colour).subscribe({
            next: res => {
              const primaryColor = this.themeForm.value.customColor ?? res?.theme?.colour;
              const secondaryColor = Common.rgbaToHex(Common.hexToRGBA(primaryColor, 0.1));
              const ColorObj = {primaryColor, secondaryColor};
              localStorage.setItem('selectedTheme', JSON.stringify(ColorObj));
              rootVariable.style.setProperty('--primaryColor', primaryColor);
              rootVariable.style.setProperty('--secondaryColor', secondaryColor);
              this.themeLoaderService.setTheme(primaryColor, secondaryColor);
              Common.setThemeColorLocally(this.themeForm.value.customColor);
            },
            error: err => {
              console.log('err',err)
              this.showloader = false;
              if (err.error.code == 4000) {
                this.showloader = false;
                this.errorMessage = err.error.reason;
                this.openSnackBar(err.error.reason, "ok ");
                // this.dialogRef.disableClose;
              }
            },
            complete: () => {
              this.showloader = false;
              this.getAllThemes();
            }
          });
        },
        error: error => {
          console.log('err',error)
          this.showloader = false;
          if (error.error.code == 4000) {
            this.showloader = false;
            this.errorMessage = error.error.reason;
            this.openSnackBar(error.error.reason, "ok ");
          }
          else if(error.error.status == 403) {
            this.toast.error('Only Admin has Permissions to Add !', 'Failed', {
              positionClass: "toast-top-right"
            });
          }
        },
      });
    }
  }

  deleteCustomTheme(id: string) {
    const dialogRef = this.dialog.open(PopUpComponent,{
      data: {
        message: 'Are you sure to delete',
        type: 'confirmation'
      }});
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.service.deleteCustomTheme(id).subscribe({
          next: res => {
            this.getAllThemes();
            this.toast.success('Theme Deleted', 'Success', {
              positionClass: "toast-top-right"
            });
          },
          error: err => {
            console.log('err',err)
            if(err.error.status == 403) {
              this.toast.error('Only Admin has Permissions to Delete !', 'Failed', {
                positionClass: "toast-top-right"
              });
            }
            else{
              this.toast.error('Failed to Delete', 'Failed', {
                positionClass: "toast-top-right"
              });
            }
          },
          complete: () => {
          }
        })
      }
    });
  }
}
