<div class="content-wrapper">
  <h6 class="mb-4">Communication</h6>

  <form [formGroup]="notesForm" ngNativeValidate (submit)="onSubmit()">
    <label>Add Notes:</label>
    <app-expandable-textarea
      [formControl]="getFormControl('description')"
      label="Enter your Feedback here">
    </app-expandable-textarea>

    <div class="form-group btns d-flex justify-content-end save-form">
      <div *ngIf="!showloader" class="d-flex align-items-center justify-content-end">
        <button mat-raised-button class="theme-bg-color text-white">Save Details</button>
      </div>
      <div *ngIf="showloader" class="d-flex align-items-center justify-content-end">
        <button mat-raised-button class="theme-bg-color text-white">
          <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only"></span>
          </div>
          Save Details
        </button>
      </div>
    </div>
  </form>

  <div class="document-wrapper">
    <div class="col-md-12 justify-content-center mx-height-scroll mt-2">
      <form [formGroup]="editNotesForm">
        <table class="table table-striped" *ngIf="notes.length > 0">
          <thead class="tableview theme-bg-color">
          <tr>
            <th class="w-75">Notes</th>
            <th>Activity</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let note of notes; let i = index">
            <td *ngIf="editableNoteId !== note.id">{{ note.description }}</td>
            <td *ngIf="editableNoteId === note.id">
              <mat-form-field class="w-100">
                <textarea matInput formControlName="description" class="form-control"
              placeholder="Notes"
              style="height: 120px;" id="textareaNotes"></textarea>
              </mat-form-field>
            </td>
            <td>{{ formatDate(note?.createdAt) + ' ' + note?.createdBy }}</td>
            <td>
              <div class="d-flex" *ngIf="editableNoteId !== note.id">
                <div (click)="editNotes(note)" class="cursor-p">
                  <span class="badge text-white">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V12H19V10H5V20H12V22H5ZM22.125 17L20 14.875L20.725 14.15C20.9083 13.9667 21.1417 13.875 21.425 13.875C21.7083 13.875 21.9417 13.9667 22.125 14.15L22.85 14.875C23.0333 15.0583 23.125 15.2917 23.125 15.575C23.125 15.8583 23.0333 16.0917 22.85 16.275L22.125 17ZM14 23V20.875L19.3 15.575L21.425 17.7L16.125 23H14Z"
                        fill="#545454"/>
                    </svg>
                  </span>
                </div>
                <div (click)="deleteNotes(note.id)" class="cursor-p">
                  <span class="badge text-white">
                   <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg"><path
                     d="M20.37 8.91003L19.37 10.64L7.24 3.64003L8.24 1.91003L11.28 3.66003L12.64 3.29003L16.97 5.79003L17.34 7.16003L20.37 8.91003ZM6 19V7.00003H11.07L18 11V19C18 19.5305 17.7893 20.0392 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H8C7.46957 21 6.96086 20.7893 6.58579 20.4142C6.21071 20.0392 6 19.5305 6 19Z"
                     fill="#ED0F0F"/>
                    </svg>
                  </span>
                </div>
              </div>
              <div *ngIf="editableNoteId === note.id">
                <button *ngIf="!loading" type="button" (click)="onUpdate(note.id);" class="btn btn-success btn-sm">Done</button>
                <div *ngIf="loading">
                  <button class="btn btn-save theme-btn-color" disabled>
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only"></span>
                    </div>
                    Done
                  </button>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>
    <mat-paginator [length]="totalElements"
                   (page)="handlePageCom($event)"
                   [pageSizeOptions]="[5, 10, 20, 50]"
                   [hidden]="totalElements <= 5"
                   aria-label="Select page">
    </mat-paginator>
  </div>
</div>
