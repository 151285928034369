<div class="content-wrapper">
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h5 class="page-title">Manage Members</h5>
        <div class="page-title-right me-3" *ngIf="role=='ADMIN' || role== 'SALES_HEAD'">
          <a routerlink="add-member" class="btn btn-create theme-btn-color" (click)="openDialog('new',null)"> <i
            class="fas fa-plus fa-xs me-2"></i>Create
            Member
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="content-wrapper-main-inner-content">
      <div class="d-flex align-items-center justify-content-end">
        <mat-slide-toggle
          class="example-margin m-1 p-1"
          color="primary"
          [disabled]=false
          [checked]="showInactive"
          (change)="toggleActiveMembers($event)">
          <div>Show In-Active</div>
        </mat-slide-toggle>
      </div>
      <mat-tab-group mat-align-tabs="start" animationDuration="10ms" class="remove-border-bottom" color="transparent"
                     (selectedIndexChange)="tabOnChange($event)">

        <mat-tab label="ADMIN" class="remove-border-bottom">
          <div *ngIf="loading" class=" d-flex justify-content-center align-item-center mt-5 pt-5">
            <mat-spinner diameter="50"></mat-spinner>
          </div>
          <div *ngIf="!loading && adminAry.length>0" class="grid_wrapper mt-5">

            <div class="card border-0 mb-4 shadow-sm" *ngFor="let member of adminAry; let i = index" [attr.data-index]="i">
              <div
                style="height: 20px;border-top-left-radius: 4px;border-top-right-radius: 4px"
                class="theme-bg-color"></div>

              <div class="card-body d-flex justify-content-between">
                <div>

                  <h6 class="card-title">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M15 28.5C22.4557 28.5 28.5 22.4557 28.5 15C28.5 7.54425 22.4557 1.5 15 1.5C7.54425 1.5 1.5 7.54425 1.5 15C1.5 22.4557 7.54425 28.5 15 28.5ZM15 30C23.2845 30 30 23.2845 30 15C30 6.7155 23.2845 0 15 0C6.7155 0 0 6.7155 0 15C0 23.2845 6.7155 30 15 30Z"
                            fill="#249953"/>
                      <path
                        d="M6 23.7226C6 22.9478 6.579 22.2931 7.35 22.2076C13.1363 21.5671 16.89 21.6248 22.6635 22.2218C22.9518 22.2521 23.2251 22.3657 23.4499 22.5487C23.6747 22.7317 23.8414 22.9762 23.9295 23.2524C24.0176 23.5286 24.0234 23.8244 23.9462 24.1039C23.8689 24.3833 23.712 24.6341 23.4945 24.8258C16.6808 30.7651 12.7868 30.6833 6.48 24.8318C6.1725 24.5468 6 24.1418 6 23.7233V23.7226Z"
                        fill="#249953"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M22.5863 22.9672C16.8585 22.3747 13.1588 22.3192 7.43175 22.953C7.24324 22.9749 7.06945 23.0657 6.94365 23.2078C6.81786 23.3499 6.74891 23.5334 6.75 23.7232C6.75 23.9377 6.83925 24.141 6.99 24.282C10.116 27.1815 12.4845 28.4917 14.7998 28.5C17.1233 28.5082 19.6193 27.2085 23.0018 24.261C23.1092 24.1653 23.1866 24.0405 23.2244 23.9017C23.2623 23.7629 23.259 23.6161 23.215 23.4792C23.171 23.3422 23.0881 23.221 22.9765 23.1302C22.8649 23.0395 22.7293 22.9831 22.5863 22.968V22.9672ZM7.2675 21.462C13.1145 20.8147 16.923 20.8732 22.7415 21.4755C23.1752 21.5207 23.5863 21.6915 23.9245 21.9668C24.2627 22.2421 24.5133 22.6101 24.6455 23.0256C24.7778 23.4412 24.7861 23.8862 24.6693 24.3064C24.5525 24.7266 24.3157 25.1035 23.988 25.3912C20.5568 28.3822 17.6993 30.0112 14.7953 30C11.883 29.9895 9.1515 28.3327 5.97075 25.3815C5.74302 25.1693 5.56147 24.9125 5.43746 24.6271C5.31345 24.3416 5.24963 24.0337 5.25 23.7225C5.24891 23.1633 5.45416 22.6234 5.82643 22.2061C6.1987 21.7889 6.71182 21.5236 7.2675 21.4612V21.462Z"
                            fill="#249953"/>
                      <path
                        d="M21 12C21 13.5913 20.3679 15.1174 19.2426 16.2426C18.1174 17.3679 16.5913 18 15 18C13.4087 18 11.8826 17.3679 10.7574 16.2426C9.63214 15.1174 9 13.5913 9 12C9 10.4087 9.63214 8.88258 10.7574 7.75736C11.8826 6.63214 13.4087 6 15 6C16.5913 6 18.1174 6.63214 19.2426 7.75736C20.3679 8.88258 21 10.4087 21 12Z"
                        fill="#249953"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M15 16.5C16.1935 16.5 17.3381 16.0259 18.182 15.182C19.0259 14.3381 19.5 13.1935 19.5 12C19.5 10.8065 19.0259 9.66193 18.182 8.81802C17.3381 7.97411 16.1935 7.5 15 7.5C13.8065 7.5 12.6619 7.97411 11.818 8.81802C10.9741 9.66193 10.5 10.8065 10.5 12C10.5 13.1935 10.9741 14.3381 11.818 15.182C12.6619 16.0259 13.8065 16.5 15 16.5ZM15 18C16.5913 18 18.1174 17.3679 19.2426 16.2426C20.3679 15.1174 21 13.5913 21 12C21 10.4087 20.3679 8.88258 19.2426 7.75736C18.1174 6.63214 16.5913 6 15 6C13.4087 6 11.8826 6.63214 10.7574 7.75736C9.63214 8.88258 9 10.4087 9 12C9 13.5913 9.63214 15.1174 10.7574 16.2426C11.8826 17.3679 13.4087 18 15 18Z"
                            fill="#249953"/>
                    </svg>
                    {{ member.firstName }} {{ member.lastName }}
                  </h6>
                  <div class="bottom-line"></div>
                  <p class="card-text">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M30 6H6C4.35 6 3.015 7.35 3.015 9L3 27C3 28.65 4.35 30 6 30H30C31.65 30 33 28.65 33 27V9C33 7.35 31.65 6 30 6ZM30 12L18 19.5L6 12V9L18 16.5L30 9V12Z"
                        fill="#249953"/>
                    </svg>

                    {{ member.email }}
                  </p>
                </div>
                <div class="dropdown">
                  <button id="more-btn dropdownMenuButton" class="more-btn" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="false"
                          type="button">
                    <span class="more-dot"></span>
                    <span class="more-dot"></span>
                    <span class="more-dot"></span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right border-0" aria-labelledby="dropdownMenuButton">
                    <div class="{{role=='ADMIN'?'dropdown-item card-edit':'dropdown-item card-edit-disable'}}"
                         (click)="role=='ADMIN'? openDialog('edit',member):disableEdit()">
                 <span>
                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.00008 14.6666C3.63341 14.6666 3.31964 14.5361 3.05875 14.2753C2.79741 14.0139 2.66675 13.6999 2.66675 13.3333V2.66659C2.66675 2.29992 2.79741 1.98592 3.05875 1.72459C3.31964 1.4637 3.63341 1.33325 4.00008 1.33325H9.33341L13.3334 5.33325V7.99992H12.0001V5.99992H8.66675V2.66659H4.00008V13.3333H8.00008V14.6666H4.00008ZM4.00008 13.3333V2.66659V13.3333ZM12.2001 9.68325L12.9167 10.3999L10.3334 12.9666V13.6666H11.0334L13.6167 11.0999L14.3167 11.7999L11.4501 14.6666H9.33341V12.5499L12.2001 9.68325ZM14.3167 11.7999L12.2001 9.68325L13.1667 8.71658C13.289 8.59436 13.4445 8.53325 13.6334 8.53325C13.8223 8.53325 13.9779 8.59436 14.1001 8.71658L15.2834 9.89992C15.4056 10.0221 15.4667 10.1777 15.4667 10.3666C15.4667 10.5555 15.4056 10.711 15.2834 10.8333L14.3167 11.7999Z"
                      d="M4.00008 14.6666C3.63341 14.6666 3.31964 14.5361 3.05875 14.2753C2.79741 14.0139 2.66675 13.6999 2.66675 13.3333V2.66659C2.66675 2.29992 2.79741 1.98592 3.05875 1.72459C3.31964 1.4637 3.63341 1.33325 4.00008 1.33325H9.33341L13.3334 5.33325V7.99992H12.0001V5.99992H8.66675V2.66659H4.00008V13.3333H8.00008V14.6666H4.00008ZM4.00008 13.3333V2.66659V13.3333ZM12.2001 9.68325L12.9167 10.3999L10.3334 12.9666V13.6666H11.0334L13.6167 11.0999L14.3167 11.7999L11.4501 14.6666H9.33341V12.5499L12.2001 9.68325ZM14.3167 11.7999L12.2001 9.68325L13.1667 8.71658C13.289 8.59436 13.4445 8.53325 13.6334 8.53325C13.8223 8.53325 13.9779 8.59436 14.1001 8.71658L15.2834 9.89992C15.4056 10.0221 15.4667 10.1777 15.4667 10.3666C15.4667 10.5555 15.4056 10.711 15.2834 10.8333L14.3167 11.7999Z"
                      fill="#8E8E8E"/>
                    </svg>
                    Edit
                 </span>
                    </div>
                    <div *ngIf="member.active"
                         class=" {{hasDeletePermission(member.userId)?'dropdown-item card-delete':'dropdown-item card-delete-disable' }}"
                         (click)="hasDeletePermission(member.userId)? openDialog('delete',member):disableDelete()">
                 <span>
                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.66675 14C4.30008 14 3.9863 13.8696 3.72541 13.6087C3.46408 13.3473 3.33341 13.0333 3.33341 12.6667V4C3.14453 4 2.98608 3.93622 2.85808 3.80867C2.73053 3.68067 2.66675 3.52222 2.66675 3.33333C2.66675 3.14444 2.73053 2.986 2.85808 2.858C2.98608 2.73044 3.14453 2.66667 3.33341 2.66667H6.00008C6.00008 2.47778 6.06408 2.31933 6.19208 2.19133C6.31964 2.06378 6.47786 2 6.66675 2H9.33342C9.5223 2 9.68075 2.06378 9.80875 2.19133C9.9363 2.31933 10.0001 2.47778 10.0001 2.66667H12.6667C12.8556 2.66667 13.0139 2.73044 13.1414 2.858C13.2694 2.986 13.3334 3.14444 13.3334 3.33333C13.3334 3.52222 13.2694 3.68067 13.1414 3.80867C13.0139 3.93622 12.8556 4 12.6667 4V12.6667C12.6667 13.0333 12.5363 13.3473 12.2754 13.6087C12.0141 13.8696 11.7001 14 11.3334 14H4.66675ZM4.66675 4V12.6667H11.3334V4H4.66675ZM6.00008 10.6667C6.00008 10.8556 6.06408 11.0138 6.19208 11.1413C6.31964 11.2693 6.47786 11.3333 6.66675 11.3333C6.85564 11.3333 7.01408 11.2693 7.14208 11.1413C7.26964 11.0138 7.33342 10.8556 7.33342 10.6667V6C7.33342 5.81111 7.26964 5.65267 7.14208 5.52467C7.01408 5.39711 6.85564 5.33333 6.66675 5.33333C6.47786 5.33333 6.31964 5.39711 6.19208 5.52467C6.06408 5.65267 6.00008 5.81111 6.00008 6V10.6667ZM8.66675 10.6667C8.66675 10.8556 8.73075 11.0138 8.85875 11.1413C8.9863 11.2693 9.14453 11.3333 9.33342 11.3333C9.5223 11.3333 9.68075 11.2693 9.80875 11.1413C9.9363 11.0138 10.0001 10.8556 10.0001 10.6667V6C10.0001 5.81111 9.9363 5.65267 9.80875 5.52467C9.68075 5.39711 9.5223 5.33333 9.33342 5.33333C9.14453 5.33333 8.9863 5.39711 8.85875 5.52467C8.73075 5.65267 8.66675 5.81111 8.66675 6V10.6667ZM4.66675 4V12.6667V4Z"
                      fill="#8E8E8E"/>
                    </svg>
                    Inactive
                 </span>
                    </div>
                    <div *ngIf="!member.active"
                         class=" {{hasDeletePermission(member.userId)?'dropdown-item card-delete':'dropdown-item card-delete-disable' }}"
                         (click)="hasDeletePermission(member.userId)? openDialogActive('delete',member):disableDelete()">
                 <span>
                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.66675 14C4.30008 14 3.9863 13.8696 3.72541 13.6087C3.46408 13.3473 3.33341 13.0333 3.33341 12.6667V4C3.14453 4 2.98608 3.93622 2.85808 3.80867C2.73053 3.68067 2.66675 3.52222 2.66675 3.33333C2.66675 3.14444 2.73053 2.986 2.85808 2.858C2.98608 2.73044 3.14453 2.66667 3.33341 2.66667H6.00008C6.00008 2.47778 6.06408 2.31933 6.19208 2.19133C6.31964 2.06378 6.47786 2 6.66675 2H9.33342C9.5223 2 9.68075 2.06378 9.80875 2.19133C9.9363 2.31933 10.0001 2.47778 10.0001 2.66667H12.6667C12.8556 2.66667 13.0139 2.73044 13.1414 2.858C13.2694 2.986 13.3334 3.14444 13.3334 3.33333C13.3334 3.52222 13.2694 3.68067 13.1414 3.80867C13.0139 3.93622 12.8556 4 12.6667 4V12.6667C12.6667 13.0333 12.5363 13.3473 12.2754 13.6087C12.0141 13.8696 11.7001 14 11.3334 14H4.66675ZM4.66675 4V12.6667H11.3334V4H4.66675ZM6.00008 10.6667C6.00008 10.8556 6.06408 11.0138 6.19208 11.1413C6.31964 11.2693 6.47786 11.3333 6.66675 11.3333C6.85564 11.3333 7.01408 11.2693 7.14208 11.1413C7.26964 11.0138 7.33342 10.8556 7.33342 10.6667V6C7.33342 5.81111 7.26964 5.65267 7.14208 5.52467C7.01408 5.39711 6.85564 5.33333 6.66675 5.33333C6.47786 5.33333 6.31964 5.39711 6.19208 5.52467C6.06408 5.65267 6.00008 5.81111 6.00008 6V10.6667ZM8.66675 10.6667C8.66675 10.8556 8.73075 11.0138 8.85875 11.1413C8.9863 11.2693 9.14453 11.3333 9.33342 11.3333C9.5223 11.3333 9.68075 11.2693 9.80875 11.1413C9.9363 11.0138 10.0001 10.8556 10.0001 10.6667V6C10.0001 5.81111 9.9363 5.65267 9.80875 5.52467C9.68075 5.39711 9.5223 5.33333 9.33342 5.33333C9.14453 5.33333 8.9863 5.39711 8.85875 5.52467C8.73075 5.65267 8.66675 5.81111 8.66675 6V10.6667ZM4.66675 4V12.6667V4Z"
                      fill="#8E8E8E"/>
                    </svg>
                    Active
                 </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="adminAry.length==0 && this.loading ==false" class=" mt-5 empty_table_wrapper theme-border-color">
            <span>No data Found.</span>
          </div>
        </mat-tab>

        <mat-tab label="MANAGER" class="remove-border-bottom">
          <div *ngIf="loading" class=" d-flex justify-content-center align-item-center mt-5 pt-5">
            <mat-spinner diameter="50"></mat-spinner>
          </div>
          <div *ngIf="!loading && managerAry.length>0" class="grid_wrapper mt-5">
            <div class="card border-0 mb-4 shadow-sm" *ngFor="let member of managerAry">
              <div
                style="height: 20px;border-top-left-radius: 4px;border-top-right-radius: 4px"
                class="theme-bg-color"></div>

              <div class="card-body d-flex justify-content-between">
                <div>
                  <h6 class="card-title">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M15 28.5C22.4557 28.5 28.5 22.4557 28.5 15C28.5 7.54425 22.4557 1.5 15 1.5C7.54425 1.5 1.5 7.54425 1.5 15C1.5 22.4557 7.54425 28.5 15 28.5ZM15 30C23.2845 30 30 23.2845 30 15C30 6.7155 23.2845 0 15 0C6.7155 0 0 6.7155 0 15C0 23.2845 6.7155 30 15 30Z"
                            fill="#249953"/>
                      <path
                        d="M6 23.7226C6 22.9478 6.579 22.2931 7.35 22.2076C13.1363 21.5671 16.89 21.6248 22.6635 22.2218C22.9518 22.2521 23.2251 22.3657 23.4499 22.5487C23.6747 22.7317 23.8414 22.9762 23.9295 23.2524C24.0176 23.5286 24.0234 23.8244 23.9462 24.1039C23.8689 24.3833 23.712 24.6341 23.4945 24.8258C16.6808 30.7651 12.7868 30.6833 6.48 24.8318C6.1725 24.5468 6 24.1418 6 23.7233V23.7226Z"
                        fill="#249953"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M22.5863 22.9672C16.8585 22.3747 13.1588 22.3192 7.43175 22.953C7.24324 22.9749 7.06945 23.0657 6.94365 23.2078C6.81786 23.3499 6.74891 23.5334 6.75 23.7232C6.75 23.9377 6.83925 24.141 6.99 24.282C10.116 27.1815 12.4845 28.4917 14.7998 28.5C17.1233 28.5082 19.6193 27.2085 23.0018 24.261C23.1092 24.1653 23.1866 24.0405 23.2244 23.9017C23.2623 23.7629 23.259 23.6161 23.215 23.4792C23.171 23.3422 23.0881 23.221 22.9765 23.1302C22.8649 23.0395 22.7293 22.9831 22.5863 22.968V22.9672ZM7.2675 21.462C13.1145 20.8147 16.923 20.8732 22.7415 21.4755C23.1752 21.5207 23.5863 21.6915 23.9245 21.9668C24.2627 22.2421 24.5133 22.6101 24.6455 23.0256C24.7778 23.4412 24.7861 23.8862 24.6693 24.3064C24.5525 24.7266 24.3157 25.1035 23.988 25.3912C20.5568 28.3822 17.6993 30.0112 14.7953 30C11.883 29.9895 9.1515 28.3327 5.97075 25.3815C5.74302 25.1693 5.56147 24.9125 5.43746 24.6271C5.31345 24.3416 5.24963 24.0337 5.25 23.7225C5.24891 23.1633 5.45416 22.6234 5.82643 22.2061C6.1987 21.7889 6.71182 21.5236 7.2675 21.4612V21.462Z"
                            fill="#249953"/>
                      <path
                        d="M21 12C21 13.5913 20.3679 15.1174 19.2426 16.2426C18.1174 17.3679 16.5913 18 15 18C13.4087 18 11.8826 17.3679 10.7574 16.2426C9.63214 15.1174 9 13.5913 9 12C9 10.4087 9.63214 8.88258 10.7574 7.75736C11.8826 6.63214 13.4087 6 15 6C16.5913 6 18.1174 6.63214 19.2426 7.75736C20.3679 8.88258 21 10.4087 21 12Z"
                        fill="#249953"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M15 16.5C16.1935 16.5 17.3381 16.0259 18.182 15.182C19.0259 14.3381 19.5 13.1935 19.5 12C19.5 10.8065 19.0259 9.66193 18.182 8.81802C17.3381 7.97411 16.1935 7.5 15 7.5C13.8065 7.5 12.6619 7.97411 11.818 8.81802C10.9741 9.66193 10.5 10.8065 10.5 12C10.5 13.1935 10.9741 14.3381 11.818 15.182C12.6619 16.0259 13.8065 16.5 15 16.5ZM15 18C16.5913 18 18.1174 17.3679 19.2426 16.2426C20.3679 15.1174 21 13.5913 21 12C21 10.4087 20.3679 8.88258 19.2426 7.75736C18.1174 6.63214 16.5913 6 15 6C13.4087 6 11.8826 6.63214 10.7574 7.75736C9.63214 8.88258 9 10.4087 9 12C9 13.5913 9.63214 15.1174 10.7574 16.2426C11.8826 17.3679 13.4087 18 15 18Z"
                            fill="#249953"/>
                    </svg>
                    {{ member.firstName }} {{ member.lastName }}
                  </h6>
                  <div class="bottom-line"></div>
                  <p class="card-text">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M30 6H6C4.35 6 3.015 7.35 3.015 9L3 27C3 28.65 4.35 30 6 30H30C31.65 30 33 28.65 33 27V9C33 7.35 31.65 6 30 6ZM30 12L18 19.5L6 12V9L18 16.5L30 9V12Z"
                        fill="#249953"/>
                    </svg>

                    {{ member.email }}
                  </p>
                </div>
                <div class="dropdown">
                  <button id="more-btn dropdownMenuButtonManager" class="more-btn" data-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false"
                          type="button">
                    <span class="more-dot"></span>
                    <span class="more-dot"></span>
                    <span class="more-dot"></span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right border-0" aria-labelledby="dropdownMenuButton">
                    <div class="{{role=='ADMIN'?'dropdown-item card-edit':'dropdown-item card-edit-disable'}}"
                         (click)="role=='ADMIN'? openDialog('edit',member):disableEdit()">
                 <span>
                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.00008 14.6666C3.63341 14.6666 3.31964 14.5361 3.05875 14.2753C2.79741 14.0139 2.66675 13.6999 2.66675 13.3333V2.66659C2.66675 2.29992 2.79741 1.98592 3.05875 1.72459C3.31964 1.4637 3.63341 1.33325 4.00008 1.33325H9.33341L13.3334 5.33325V7.99992H12.0001V5.99992H8.66675V2.66659H4.00008V13.3333H8.00008V14.6666H4.00008ZM4.00008 13.3333V2.66659V13.3333ZM12.2001 9.68325L12.9167 10.3999L10.3334 12.9666V13.6666H11.0334L13.6167 11.0999L14.3167 11.7999L11.4501 14.6666H9.33341V12.5499L12.2001 9.68325ZM14.3167 11.7999L12.2001 9.68325L13.1667 8.71658C13.289 8.59436 13.4445 8.53325 13.6334 8.53325C13.8223 8.53325 13.9779 8.59436 14.1001 8.71658L15.2834 9.89992C15.4056 10.0221 15.4667 10.1777 15.4667 10.3666C15.4667 10.5555 15.4056 10.711 15.2834 10.8333L14.3167 11.7999Z"
                      fill="#8E8E8E"/>
                    </svg>
                    Edit
                 </span>
                    </div>
                    <div *ngIf="member.active"
                         class=" {{hasDeletePermission(member.userId)?'dropdown-item card-delete':'dropdown-item card-delete-disable' }} "
                         (click)="hasDeletePermission(member.userId)? openDialog('delete',member):disableDelete()">
                 <span>
                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.66675 14C4.30008 14 3.9863 13.8696 3.72541 13.6087C3.46408 13.3473 3.33341 13.0333 3.33341 12.6667V4C3.14453 4 2.98608 3.93622 2.85808 3.80867C2.73053 3.68067 2.66675 3.52222 2.66675 3.33333C2.66675 3.14444 2.73053 2.986 2.85808 2.858C2.98608 2.73044 3.14453 2.66667 3.33341 2.66667H6.00008C6.00008 2.47778 6.06408 2.31933 6.19208 2.19133C6.31964 2.06378 6.47786 2 6.66675 2H9.33342C9.5223 2 9.68075 2.06378 9.80875 2.19133C9.9363 2.31933 10.0001 2.47778 10.0001 2.66667H12.6667C12.8556 2.66667 13.0139 2.73044 13.1414 2.858C13.2694 2.986 13.3334 3.14444 13.3334 3.33333C13.3334 3.52222 13.2694 3.68067 13.1414 3.80867C13.0139 3.93622 12.8556 4 12.6667 4V12.6667C12.6667 13.0333 12.5363 13.3473 12.2754 13.6087C12.0141 13.8696 11.7001 14 11.3334 14H4.66675ZM4.66675 4V12.6667H11.3334V4H4.66675ZM6.00008 10.6667C6.00008 10.8556 6.06408 11.0138 6.19208 11.1413C6.31964 11.2693 6.47786 11.3333 6.66675 11.3333C6.85564 11.3333 7.01408 11.2693 7.14208 11.1413C7.26964 11.0138 7.33342 10.8556 7.33342 10.6667V6C7.33342 5.81111 7.26964 5.65267 7.14208 5.52467C7.01408 5.39711 6.85564 5.33333 6.66675 5.33333C6.47786 5.33333 6.31964 5.39711 6.19208 5.52467C6.06408 5.65267 6.00008 5.81111 6.00008 6V10.6667ZM8.66675 10.6667C8.66675 10.8556 8.73075 11.0138 8.85875 11.1413C8.9863 11.2693 9.14453 11.3333 9.33342 11.3333C9.5223 11.3333 9.68075 11.2693 9.80875 11.1413C9.9363 11.0138 10.0001 10.8556 10.0001 10.6667V6C10.0001 5.81111 9.9363 5.65267 9.80875 5.52467C9.68075 5.39711 9.5223 5.33333 9.33342 5.33333C9.14453 5.33333 8.9863 5.39711 8.85875 5.52467C8.73075 5.65267 8.66675 5.81111 8.66675 6V10.6667ZM4.66675 4V12.6667V4Z"
                      fill="#8E8E8E"/>
                    </svg>
                    Inactive
                 </span>
                    </div>
                    <div *ngIf="!member.active"
                         class=" {{hasDeletePermission(member.userId)?'dropdown-item card-delete':'dropdown-item card-delete-disable' }}"
                         (click)="hasDeletePermission(member.userId)? openDialogActive('delete',member):disableDelete()">
                 <span>
                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.66675 14C4.30008 14 3.9863 13.8696 3.72541 13.6087C3.46408 13.3473 3.33341 13.0333 3.33341 12.6667V4C3.14453 4 2.98608 3.93622 2.85808 3.80867C2.73053 3.68067 2.66675 3.52222 2.66675 3.33333C2.66675 3.14444 2.73053 2.986 2.85808 2.858C2.98608 2.73044 3.14453 2.66667 3.33341 2.66667H6.00008C6.00008 2.47778 6.06408 2.31933 6.19208 2.19133C6.31964 2.06378 6.47786 2 6.66675 2H9.33342C9.5223 2 9.68075 2.06378 9.80875 2.19133C9.9363 2.31933 10.0001 2.47778 10.0001 2.66667H12.6667C12.8556 2.66667 13.0139 2.73044 13.1414 2.858C13.2694 2.986 13.3334 3.14444 13.3334 3.33333C13.3334 3.52222 13.2694 3.68067 13.1414 3.80867C13.0139 3.93622 12.8556 4 12.6667 4V12.6667C12.6667 13.0333 12.5363 13.3473 12.2754 13.6087C12.0141 13.8696 11.7001 14 11.3334 14H4.66675ZM4.66675 4V12.6667H11.3334V4H4.66675ZM6.00008 10.6667C6.00008 10.8556 6.06408 11.0138 6.19208 11.1413C6.31964 11.2693 6.47786 11.3333 6.66675 11.3333C6.85564 11.3333 7.01408 11.2693 7.14208 11.1413C7.26964 11.0138 7.33342 10.8556 7.33342 10.6667V6C7.33342 5.81111 7.26964 5.65267 7.14208 5.52467C7.01408 5.39711 6.85564 5.33333 6.66675 5.33333C6.47786 5.33333 6.31964 5.39711 6.19208 5.52467C6.06408 5.65267 6.00008 5.81111 6.00008 6V10.6667ZM8.66675 10.6667C8.66675 10.8556 8.73075 11.0138 8.85875 11.1413C8.9863 11.2693 9.14453 11.3333 9.33342 11.3333C9.5223 11.3333 9.68075 11.2693 9.80875 11.1413C9.9363 11.0138 10.0001 10.8556 10.0001 10.6667V6C10.0001 5.81111 9.9363 5.65267 9.80875 5.52467C9.68075 5.39711 9.5223 5.33333 9.33342 5.33333C9.14453 5.33333 8.9863 5.39711 8.85875 5.52467C8.73075 5.65267 8.66675 5.81111 8.66675 6V10.6667ZM4.66675 4V12.6667V4Z"
                      fill="#8E8E8E"/>
                    </svg>
                    Active
                 </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="managerAry.length==0 && this.loading ==false" class=" mt-5 empty_table_wrapper theme-border-color">
            <span>No data Found.</span>
          </div>
        </mat-tab>

        <mat-tab label="TA" class="remove-border-bottom">
          <div *ngIf="loading" class=" d-flex justify-content-center align-item-center mt-5 pt-5">
            <mat-spinner diameter="50"></mat-spinner>
          </div>
          <div *ngIf="!loading && taAry.length>0" class="grid_wrapper mt-5">

            <div class="card border-0 mb-4 shadow-sm" *ngFor="let member of taAry">
              <div
                style="height: 20px;border-top-left-radius: 4px;border-top-right-radius: 4px"
                class="theme-bg-color"></div>
              <div class="card-body d-flex justify-content-between">
                <div>
                  <h6 class="card-title">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M15 28.5C22.4557 28.5 28.5 22.4557 28.5 15C28.5 7.54425 22.4557 1.5 15 1.5C7.54425 1.5 1.5 7.54425 1.5 15C1.5 22.4557 7.54425 28.5 15 28.5ZM15 30C23.2845 30 30 23.2845 30 15C30 6.7155 23.2845 0 15 0C6.7155 0 0 6.7155 0 15C0 23.2845 6.7155 30 15 30Z"
                            fill="#249953"/>
                      <path
                        d="M6 23.7226C6 22.9478 6.579 22.2931 7.35 22.2076C13.1363 21.5671 16.89 21.6248 22.6635 22.2218C22.9518 22.2521 23.2251 22.3657 23.4499 22.5487C23.6747 22.7317 23.8414 22.9762 23.9295 23.2524C24.0176 23.5286 24.0234 23.8244 23.9462 24.1039C23.8689 24.3833 23.712 24.6341 23.4945 24.8258C16.6808 30.7651 12.7868 30.6833 6.48 24.8318C6.1725 24.5468 6 24.1418 6 23.7233V23.7226Z"
                        fill="#249953"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M22.5863 22.9672C16.8585 22.3747 13.1588 22.3192 7.43175 22.953C7.24324 22.9749 7.06945 23.0657 6.94365 23.2078C6.81786 23.3499 6.74891 23.5334 6.75 23.7232C6.75 23.9377 6.83925 24.141 6.99 24.282C10.116 27.1815 12.4845 28.4917 14.7998 28.5C17.1233 28.5082 19.6193 27.2085 23.0018 24.261C23.1092 24.1653 23.1866 24.0405 23.2244 23.9017C23.2623 23.7629 23.259 23.6161 23.215 23.4792C23.171 23.3422 23.0881 23.221 22.9765 23.1302C22.8649 23.0395 22.7293 22.9831 22.5863 22.968V22.9672ZM7.2675 21.462C13.1145 20.8147 16.923 20.8732 22.7415 21.4755C23.1752 21.5207 23.5863 21.6915 23.9245 21.9668C24.2627 22.2421 24.5133 22.6101 24.6455 23.0256C24.7778 23.4412 24.7861 23.8862 24.6693 24.3064C24.5525 24.7266 24.3157 25.1035 23.988 25.3912C20.5568 28.3822 17.6993 30.0112 14.7953 30C11.883 29.9895 9.1515 28.3327 5.97075 25.3815C5.74302 25.1693 5.56147 24.9125 5.43746 24.6271C5.31345 24.3416 5.24963 24.0337 5.25 23.7225C5.24891 23.1633 5.45416 22.6234 5.82643 22.2061C6.1987 21.7889 6.71182 21.5236 7.2675 21.4612V21.462Z"
                            fill="#249953"/>
                      <path
                        d="M21 12C21 13.5913 20.3679 15.1174 19.2426 16.2426C18.1174 17.3679 16.5913 18 15 18C13.4087 18 11.8826 17.3679 10.7574 16.2426C9.63214 15.1174 9 13.5913 9 12C9 10.4087 9.63214 8.88258 10.7574 7.75736C11.8826 6.63214 13.4087 6 15 6C16.5913 6 18.1174 6.63214 19.2426 7.75736C20.3679 8.88258 21 10.4087 21 12Z"
                        fill="#249953"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M15 16.5C16.1935 16.5 17.3381 16.0259 18.182 15.182C19.0259 14.3381 19.5 13.1935 19.5 12C19.5 10.8065 19.0259 9.66193 18.182 8.81802C17.3381 7.97411 16.1935 7.5 15 7.5C13.8065 7.5 12.6619 7.97411 11.818 8.81802C10.9741 9.66193 10.5 10.8065 10.5 12C10.5 13.1935 10.9741 14.3381 11.818 15.182C12.6619 16.0259 13.8065 16.5 15 16.5ZM15 18C16.5913 18 18.1174 17.3679 19.2426 16.2426C20.3679 15.1174 21 13.5913 21 12C21 10.4087 20.3679 8.88258 19.2426 7.75736C18.1174 6.63214 16.5913 6 15 6C13.4087 6 11.8826 6.63214 10.7574 7.75736C9.63214 8.88258 9 10.4087 9 12C9 13.5913 9.63214 15.1174 10.7574 16.2426C11.8826 17.3679 13.4087 18 15 18Z"
                            fill="#249953"/>
                    </svg>
                    {{ member.firstName }} {{ member.lastName }}
                  </h6>
                  <div class="bottom-line"></div>
                  <p class="card-text">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M30 6H6C4.35 6 3.015 7.35 3.015 9L3 27C3 28.65 4.35 30 6 30H30C31.65 30 33 28.65 33 27V9C33 7.35 31.65 6 30 6ZM30 12L18 19.5L6 12V9L18 16.5L30 9V12Z"
                        fill="#249953"/>
                    </svg>

                    {{ member.email }}
                  </p>
                </div>
                <div class="dropdown">
                  <button id="more-btn dropdownMenuButtonTA" class="more-btn" data-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false"
                          type="button">
                    <span class="more-dot"></span>
                    <span class="more-dot"></span>
                    <span class="more-dot"></span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right border-0" aria-labelledby="dropdownMenuButton">
                    <div class="{{role=='ADMIN'?'dropdown-item card-edit':'dropdown-item card-edit-disable'}}"
                         (click)="role=='ADMIN'? openDialog('edit',member):disableEdit()">
                 <span>
                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.00008 14.6666C3.63341 14.6666 3.31964 14.5361 3.05875 14.2753C2.79741 14.0139 2.66675 13.6999 2.66675 13.3333V2.66659C2.66675 2.29992 2.79741 1.98592 3.05875 1.72459C3.31964 1.4637 3.63341 1.33325 4.00008 1.33325H9.33341L13.3334 5.33325V7.99992H12.0001V5.99992H8.66675V2.66659H4.00008V13.3333H8.00008V14.6666H4.00008ZM4.00008 13.3333V2.66659V13.3333ZM12.2001 9.68325L12.9167 10.3999L10.3334 12.9666V13.6666H11.0334L13.6167 11.0999L14.3167 11.7999L11.4501 14.6666H9.33341V12.5499L12.2001 9.68325ZM14.3167 11.7999L12.2001 9.68325L13.1667 8.71658C13.289 8.59436 13.4445 8.53325 13.6334 8.53325C13.8223 8.53325 13.9779 8.59436 14.1001 8.71658L15.2834 9.89992C15.4056 10.0221 15.4667 10.1777 15.4667 10.3666C15.4667 10.5555 15.4056 10.711 15.2834 10.8333L14.3167 11.7999Z"
                      fill="#8E8E8E"/>
                    </svg>
                    Edit
                 </span>
                    </div>
                    <div *ngIf="member.active"
                         class=" {{hasDeletePermission(member.userId)?'dropdown-item card-delete':'dropdown-item card-delete-disable' }} "
                         (click)="hasDeletePermission(member.userId)? openDialog('delete',member):disableDelete()">
                 <span>
                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.66675 14C4.30008 14 3.9863 13.8696 3.72541 13.6087C3.46408 13.3473 3.33341 13.0333 3.33341 12.6667V4C3.14453 4 2.98608 3.93622 2.85808 3.80867C2.73053 3.68067 2.66675 3.52222 2.66675 3.33333C2.66675 3.14444 2.73053 2.986 2.85808 2.858C2.98608 2.73044 3.14453 2.66667 3.33341 2.66667H6.00008C6.00008 2.47778 6.06408 2.31933 6.19208 2.19133C6.31964 2.06378 6.47786 2 6.66675 2H9.33342C9.5223 2 9.68075 2.06378 9.80875 2.19133C9.9363 2.31933 10.0001 2.47778 10.0001 2.66667H12.6667C12.8556 2.66667 13.0139 2.73044 13.1414 2.858C13.2694 2.986 13.3334 3.14444 13.3334 3.33333C13.3334 3.52222 13.2694 3.68067 13.1414 3.80867C13.0139 3.93622 12.8556 4 12.6667 4V12.6667C12.6667 13.0333 12.5363 13.3473 12.2754 13.6087C12.0141 13.8696 11.7001 14 11.3334 14H4.66675ZM4.66675 4V12.6667H11.3334V4H4.66675ZM6.00008 10.6667C6.00008 10.8556 6.06408 11.0138 6.19208 11.1413C6.31964 11.2693 6.47786 11.3333 6.66675 11.3333C6.85564 11.3333 7.01408 11.2693 7.14208 11.1413C7.26964 11.0138 7.33342 10.8556 7.33342 10.6667V6C7.33342 5.81111 7.26964 5.65267 7.14208 5.52467C7.01408 5.39711 6.85564 5.33333 6.66675 5.33333C6.47786 5.33333 6.31964 5.39711 6.19208 5.52467C6.06408 5.65267 6.00008 5.81111 6.00008 6V10.6667ZM8.66675 10.6667C8.66675 10.8556 8.73075 11.0138 8.85875 11.1413C8.9863 11.2693 9.14453 11.3333 9.33342 11.3333C9.5223 11.3333 9.68075 11.2693 9.80875 11.1413C9.9363 11.0138 10.0001 10.8556 10.0001 10.6667V6C10.0001 5.81111 9.9363 5.65267 9.80875 5.52467C9.68075 5.39711 9.5223 5.33333 9.33342 5.33333C9.14453 5.33333 8.9863 5.39711 8.85875 5.52467C8.73075 5.65267 8.66675 5.81111 8.66675 6V10.6667ZM4.66675 4V12.6667V4Z"
                      fill="#8E8E8E"/>
                    </svg>
                    Inactive
                 </span>
                    </div>
                    <div *ngIf="!member.active"
                         class=" {{hasDeletePermission(member.userId)?'dropdown-item card-delete':'dropdown-item card-delete-disable' }}"
                         (click)="hasDeletePermission(member.userId)? openDialogActive('delete',member):disableDelete()">
                 <span>
                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.66675 14C4.30008 14 3.9863 13.8696 3.72541 13.6087C3.46408 13.3473 3.33341 13.0333 3.33341 12.6667V4C3.14453 4 2.98608 3.93622 2.85808 3.80867C2.73053 3.68067 2.66675 3.52222 2.66675 3.33333C2.66675 3.14444 2.73053 2.986 2.85808 2.858C2.98608 2.73044 3.14453 2.66667 3.33341 2.66667H6.00008C6.00008 2.47778 6.06408 2.31933 6.19208 2.19133C6.31964 2.06378 6.47786 2 6.66675 2H9.33342C9.5223 2 9.68075 2.06378 9.80875 2.19133C9.9363 2.31933 10.0001 2.47778 10.0001 2.66667H12.6667C12.8556 2.66667 13.0139 2.73044 13.1414 2.858C13.2694 2.986 13.3334 3.14444 13.3334 3.33333C13.3334 3.52222 13.2694 3.68067 13.1414 3.80867C13.0139 3.93622 12.8556 4 12.6667 4V12.6667C12.6667 13.0333 12.5363 13.3473 12.2754 13.6087C12.0141 13.8696 11.7001 14 11.3334 14H4.66675ZM4.66675 4V12.6667H11.3334V4H4.66675ZM6.00008 10.6667C6.00008 10.8556 6.06408 11.0138 6.19208 11.1413C6.31964 11.2693 6.47786 11.3333 6.66675 11.3333C6.85564 11.3333 7.01408 11.2693 7.14208 11.1413C7.26964 11.0138 7.33342 10.8556 7.33342 10.6667V6C7.33342 5.81111 7.26964 5.65267 7.14208 5.52467C7.01408 5.39711 6.85564 5.33333 6.66675 5.33333C6.47786 5.33333 6.31964 5.39711 6.19208 5.52467C6.06408 5.65267 6.00008 5.81111 6.00008 6V10.6667ZM8.66675 10.6667C8.66675 10.8556 8.73075 11.0138 8.85875 11.1413C8.9863 11.2693 9.14453 11.3333 9.33342 11.3333C9.5223 11.3333 9.68075 11.2693 9.80875 11.1413C9.9363 11.0138 10.0001 10.8556 10.0001 10.6667V6C10.0001 5.81111 9.9363 5.65267 9.80875 5.52467C9.68075 5.39711 9.5223 5.33333 9.33342 5.33333C9.14453 5.33333 8.9863 5.39711 8.85875 5.52467C8.73075 5.65267 8.66675 5.81111 8.66675 6V10.6667ZM4.66675 4V12.6667V4Z"
                      fill="#8E8E8E"/>
                    </svg>
                    Active
                 </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="taAry.length==0 && this.loading ==false" class=" mt-5 empty_table_wrapper theme-border-color">
            <span>No data Found.</span>
          </div>
        </mat-tab>

        <mat-tab label="SALES" class="remove-border-bottom">
          <div *ngIf="loading" class=" d-flex justify-content-center align-item-center mt-5 pt-5">
            <mat-spinner diameter="50"></mat-spinner>
          </div>
          <div *ngIf="!loading && salesAry.length>0" class="grid_wrapper mt-5">
            <div class="card border-0 mb-4 shadow-sm" *ngFor="let member of salesAry">
              <div
                style="height: 20px;border-top-left-radius: 4px;border-top-right-radius: 4px"
                class="theme-bg-color"></div>

              <div class="card-body d-flex justify-content-between">
                <div>
                  <h6 class="card-title">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M15 28.5C22.4557 28.5 28.5 22.4557 28.5 15C28.5 7.54425 22.4557 1.5 15 1.5C7.54425 1.5 1.5 7.54425 1.5 15C1.5 22.4557 7.54425 28.5 15 28.5ZM15 30C23.2845 30 30 23.2845 30 15C30 6.7155 23.2845 0 15 0C6.7155 0 0 6.7155 0 15C0 23.2845 6.7155 30 15 30Z"
                            fill="#249953"/>
                      <path
                        d="M6 23.7226C6 22.9478 6.579 22.2931 7.35 22.2076C13.1363 21.5671 16.89 21.6248 22.6635 22.2218C22.9518 22.2521 23.2251 22.3657 23.4499 22.5487C23.6747 22.7317 23.8414 22.9762 23.9295 23.2524C24.0176 23.5286 24.0234 23.8244 23.9462 24.1039C23.8689 24.3833 23.712 24.6341 23.4945 24.8258C16.6808 30.7651 12.7868 30.6833 6.48 24.8318C6.1725 24.5468 6 24.1418 6 23.7233V23.7226Z"
                        fill="#249953"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M22.5863 22.9672C16.8585 22.3747 13.1588 22.3192 7.43175 22.953C7.24324 22.9749 7.06945 23.0657 6.94365 23.2078C6.81786 23.3499 6.74891 23.5334 6.75 23.7232C6.75 23.9377 6.83925 24.141 6.99 24.282C10.116 27.1815 12.4845 28.4917 14.7998 28.5C17.1233 28.5082 19.6193 27.2085 23.0018 24.261C23.1092 24.1653 23.1866 24.0405 23.2244 23.9017C23.2623 23.7629 23.259 23.6161 23.215 23.4792C23.171 23.3422 23.0881 23.221 22.9765 23.1302C22.8649 23.0395 22.7293 22.9831 22.5863 22.968V22.9672ZM7.2675 21.462C13.1145 20.8147 16.923 20.8732 22.7415 21.4755C23.1752 21.5207 23.5863 21.6915 23.9245 21.9668C24.2627 22.2421 24.5133 22.6101 24.6455 23.0256C24.7778 23.4412 24.7861 23.8862 24.6693 24.3064C24.5525 24.7266 24.3157 25.1035 23.988 25.3912C20.5568 28.3822 17.6993 30.0112 14.7953 30C11.883 29.9895 9.1515 28.3327 5.97075 25.3815C5.74302 25.1693 5.56147 24.9125 5.43746 24.6271C5.31345 24.3416 5.24963 24.0337 5.25 23.7225C5.24891 23.1633 5.45416 22.6234 5.82643 22.2061C6.1987 21.7889 6.71182 21.5236 7.2675 21.4612V21.462Z"
                            fill="#249953"/>
                      <path
                        d="M21 12C21 13.5913 20.3679 15.1174 19.2426 16.2426C18.1174 17.3679 16.5913 18 15 18C13.4087 18 11.8826 17.3679 10.7574 16.2426C9.63214 15.1174 9 13.5913 9 12C9 10.4087 9.63214 8.88258 10.7574 7.75736C11.8826 6.63214 13.4087 6 15 6C16.5913 6 18.1174 6.63214 19.2426 7.75736C20.3679 8.88258 21 10.4087 21 12Z"
                        fill="#249953"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M15 16.5C16.1935 16.5 17.3381 16.0259 18.182 15.182C19.0259 14.3381 19.5 13.1935 19.5 12C19.5 10.8065 19.0259 9.66193 18.182 8.81802C17.3381 7.97411 16.1935 7.5 15 7.5C13.8065 7.5 12.6619 7.97411 11.818 8.81802C10.9741 9.66193 10.5 10.8065 10.5 12C10.5 13.1935 10.9741 14.3381 11.818 15.182C12.6619 16.0259 13.8065 16.5 15 16.5ZM15 18C16.5913 18 18.1174 17.3679 19.2426 16.2426C20.3679 15.1174 21 13.5913 21 12C21 10.4087 20.3679 8.88258 19.2426 7.75736C18.1174 6.63214 16.5913 6 15 6C13.4087 6 11.8826 6.63214 10.7574 7.75736C9.63214 8.88258 9 10.4087 9 12C9 13.5913 9.63214 15.1174 10.7574 16.2426C11.8826 17.3679 13.4087 18 15 18Z"
                            fill="#249953"/>
                    </svg>
                    {{ member.firstName }} {{ member.lastName }}
                  </h6>
                  <div class="bottom-line"></div>
                  <p class="card-text">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M30 6H6C4.35 6 3.015 7.35 3.015 9L3 27C3 28.65 4.35 30 6 30H30C31.65 30 33 28.65 33 27V9C33 7.35 31.65 6 30 6ZM30 12L18 19.5L6 12V9L18 16.5L30 9V12Z"
                        fill="#249953"/>
                    </svg>

                    {{ member.email }}
                  </p>
                  <p class="card-text">Role: {{ getRole(member.role) }}</p>
                </div>
                <div class="dropdown">
                  <button id="more-btn dropdownMenuButtonSales" class="more-btn" data-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false"
                          type="button">
                    <span class="more-dot"></span>
                    <span class="more-dot"></span>
                    <span class="more-dot"></span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right border-0" aria-labelledby="dropdownMenuButton">
                    <div class="{{role=='ADMIN'?'dropdown-item card-edit':'dropdown-item card-edit-disable'}}"
                         (click)="role=='ADMIN'? openDialog('edit',member):disableEdit()">
                 <span>
                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.00008 14.6666C3.63341 14.6666 3.31964 14.5361 3.05875 14.2753C2.79741 14.0139 2.66675 13.6999 2.66675 13.3333V2.66659C2.66675 2.29992 2.79741 1.98592 3.05875 1.72459C3.31964 1.4637 3.63341 1.33325 4.00008 1.33325H9.33341L13.3334 5.33325V7.99992H12.0001V5.99992H8.66675V2.66659H4.00008V13.3333H8.00008V14.6666H4.00008ZM4.00008 13.3333V2.66659V13.3333ZM12.2001 9.68325L12.9167 10.3999L10.3334 12.9666V13.6666H11.0334L13.6167 11.0999L14.3167 11.7999L11.4501 14.6666H9.33341V12.5499L12.2001 9.68325ZM14.3167 11.7999L12.2001 9.68325L13.1667 8.71658C13.289 8.59436 13.4445 8.53325 13.6334 8.53325C13.8223 8.53325 13.9779 8.59436 14.1001 8.71658L15.2834 9.89992C15.4056 10.0221 15.4667 10.1777 15.4667 10.3666C15.4667 10.5555 15.4056 10.711 15.2834 10.8333L14.3167 11.7999Z"
                      fill="#8E8E8E"/>
                    </svg>
                    Edit
                 </span>
                    </div>
                    <div *ngIf="member.active"
                         class=" {{hasDeletePermission(member.userId)?'dropdown-item card-delete':'dropdown-item card-delete-disable' }} "
                         (click)="hasDeletePermission(member.userId)? openDialog('delete',member):disableDelete()">
                 <span>
                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.66675 14C4.30008 14 3.9863 13.8696 3.72541 13.6087C3.46408 13.3473 3.33341 13.0333 3.33341 12.6667V4C3.14453 4 2.98608 3.93622 2.85808 3.80867C2.73053 3.68067 2.66675 3.52222 2.66675 3.33333C2.66675 3.14444 2.73053 2.986 2.85808 2.858C2.98608 2.73044 3.14453 2.66667 3.33341 2.66667H6.00008C6.00008 2.47778 6.06408 2.31933 6.19208 2.19133C6.31964 2.06378 6.47786 2 6.66675 2H9.33342C9.5223 2 9.68075 2.06378 9.80875 2.19133C9.9363 2.31933 10.0001 2.47778 10.0001 2.66667H12.6667C12.8556 2.66667 13.0139 2.73044 13.1414 2.858C13.2694 2.986 13.3334 3.14444 13.3334 3.33333C13.3334 3.52222 13.2694 3.68067 13.1414 3.80867C13.0139 3.93622 12.8556 4 12.6667 4V12.6667C12.6667 13.0333 12.5363 13.3473 12.2754 13.6087C12.0141 13.8696 11.7001 14 11.3334 14H4.66675ZM4.66675 4V12.6667H11.3334V4H4.66675ZM6.00008 10.6667C6.00008 10.8556 6.06408 11.0138 6.19208 11.1413C6.31964 11.2693 6.47786 11.3333 6.66675 11.3333C6.85564 11.3333 7.01408 11.2693 7.14208 11.1413C7.26964 11.0138 7.33342 10.8556 7.33342 10.6667V6C7.33342 5.81111 7.26964 5.65267 7.14208 5.52467C7.01408 5.39711 6.85564 5.33333 6.66675 5.33333C6.47786 5.33333 6.31964 5.39711 6.19208 5.52467C6.06408 5.65267 6.00008 5.81111 6.00008 6V10.6667ZM8.66675 10.6667C8.66675 10.8556 8.73075 11.0138 8.85875 11.1413C8.9863 11.2693 9.14453 11.3333 9.33342 11.3333C9.5223 11.3333 9.68075 11.2693 9.80875 11.1413C9.9363 11.0138 10.0001 10.8556 10.0001 10.6667V6C10.0001 5.81111 9.9363 5.65267 9.80875 5.52467C9.68075 5.39711 9.5223 5.33333 9.33342 5.33333C9.14453 5.33333 8.9863 5.39711 8.85875 5.52467C8.73075 5.65267 8.66675 5.81111 8.66675 6V10.6667ZM4.66675 4V12.6667V4Z"
                      fill="#8E8E8E"/>
                    </svg>
                    Inactive
                 </span>
                    </div>
                    <div *ngIf="!member.active"
                         class=" {{hasDeletePermission(member.userId)?'dropdown-item card-delete':'dropdown-item card-delete-disable' }}"
                         (click)="hasDeletePermission(member.userId)? openDialogActive('delete',member):disableDelete()">
                 <span>
                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.66675 14C4.30008 14 3.9863 13.8696 3.72541 13.6087C3.46408 13.3473 3.33341 13.0333 3.33341 12.6667V4C3.14453 4 2.98608 3.93622 2.85808 3.80867C2.73053 3.68067 2.66675 3.52222 2.66675 3.33333C2.66675 3.14444 2.73053 2.986 2.85808 2.858C2.98608 2.73044 3.14453 2.66667 3.33341 2.66667H6.00008C6.00008 2.47778 6.06408 2.31933 6.19208 2.19133C6.31964 2.06378 6.47786 2 6.66675 2H9.33342C9.5223 2 9.68075 2.06378 9.80875 2.19133C9.9363 2.31933 10.0001 2.47778 10.0001 2.66667H12.6667C12.8556 2.66667 13.0139 2.73044 13.1414 2.858C13.2694 2.986 13.3334 3.14444 13.3334 3.33333C13.3334 3.52222 13.2694 3.68067 13.1414 3.80867C13.0139 3.93622 12.8556 4 12.6667 4V12.6667C12.6667 13.0333 12.5363 13.3473 12.2754 13.6087C12.0141 13.8696 11.7001 14 11.3334 14H4.66675ZM4.66675 4V12.6667H11.3334V4H4.66675ZM6.00008 10.6667C6.00008 10.8556 6.06408 11.0138 6.19208 11.1413C6.31964 11.2693 6.47786 11.3333 6.66675 11.3333C6.85564 11.3333 7.01408 11.2693 7.14208 11.1413C7.26964 11.0138 7.33342 10.8556 7.33342 10.6667V6C7.33342 5.81111 7.26964 5.65267 7.14208 5.52467C7.01408 5.39711 6.85564 5.33333 6.66675 5.33333C6.47786 5.33333 6.31964 5.39711 6.19208 5.52467C6.06408 5.65267 6.00008 5.81111 6.00008 6V10.6667ZM8.66675 10.6667C8.66675 10.8556 8.73075 11.0138 8.85875 11.1413C8.9863 11.2693 9.14453 11.3333 9.33342 11.3333C9.5223 11.3333 9.68075 11.2693 9.80875 11.1413C9.9363 11.0138 10.0001 10.8556 10.0001 10.6667V6C10.0001 5.81111 9.9363 5.65267 9.80875 5.52467C9.68075 5.39711 9.5223 5.33333 9.33342 5.33333C9.14453 5.33333 8.9863 5.39711 8.85875 5.52467C8.73075 5.65267 8.66675 5.81111 8.66675 6V10.6667ZM4.66675 4V12.6667V4Z"
                      fill="#8E8E8E"/>
                    </svg>
                    Active
                 </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="salesAry.length==0 && !this.loading" class=" mt-5 empty_table_wrapper theme-border-color">
            <span>No data Found.</span>
          </div>
        </mat-tab>

      </mat-tab-group>
      <div *ngIf="totalPages>1 && this.loading==false " class="d-flex justify-content-end mt-4">
        <button class="btn btn-dark round mx-3 text-white" [ngClass]="currentPage<=0?'disabled':''"
                (click)="loadMembers(this.selectedRole,true,currentPage-1)" [disabled]="currentPage<=0">Previous
        </button>
        <span class="ml-4 mr-4 align-self-center text-center">{{ currentPage + 1 }} of {{ totalPages }}</span>
        <button class="btn btn-dark round mx-3 text-white" [ngClass]="currentPage>=totalPages-1?'disabled':''"
                (click)="loadMembers(this.selectedRole,true,currentPage+1)" [disabled]="currentPage>=totalPages-1">Next
        </button>
      </div>
    </div>
  </div>
</div>
