import {Component, Input, OnInit} from '@angular/core';
import {myTaskDTO} from "../../../../shared/interfaces";

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.css']
})
export class TaskDetailComponent implements OnInit {
  myTask:myTaskDTO;
  @Input() data: myTaskDTO;
  constructor() { }

  ngOnInit(): void {
    this.myTask =this.data;
  }

}
