import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResetFiltersService {
  private resetFilters = new BehaviorSubject<boolean>(false);
  clickedFilter = this.resetFilters.asObservable();

  emitChange(hasChanged: boolean) {
    console.log('hasChanged',hasChanged)
    this.resetFilters.next(hasChanged);
  }

}
