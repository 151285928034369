<div class="content-wrapper">
  <div class="row">
    <div class="w-50 border-right">
      <h6>Settings</h6>
      <div>
        <h6>Upload Solution Logo:</h6>
        <div class="d-flex">
          <div class="me-2 ">
            <img class="thumbnail-image" *ngIf="imageSrc" [src]="imageSrc" alt="Consultant Image"/>
          </div>
          <div class="w-100" *ngIf="!imageSrc && !this.file">
            <div class="" (click)="uploadFileTrigger()">
              <input #fileInput style="display: none" type="file" class="form-control-file"
                     (change)="onSelectLogo($event)"/>
              <div class="d-flex align-items-center justify-content-center">
                <div class="drag_drop_wrapper text-center w-75" (dragover)="handleDragOver($event)"
                     (drop)="onSelectLogo($event,true)">
                  <div *ngIf="!data?.logoUrl || !imageSrc">
                    <div class="d-flex justify-content-center">
                      <img src="assets/img_1.png" style="height: 40px"/>
                    </div>
                    <div class="d-flex justify-content-center">
                      <div>
                        <span><b>Drag & Drop or choose file to upload</b></span><br/>
                        <span> Select Jpeg,PNG</span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="data?.logoUrl || imageSrc">
                    <img [src]="imageSrc" style="height: 60px"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex row align-items-center justify-content-center" *ngIf="!imageSrc && this.file">
          <div class="drag_drop_wrapper justify-content-center w-75 d-flex align-items-center justify-content-center">
            <p class="px-2 d-flex text-center">{{ logoName }}</p>
            <span class="d-flex justify-content-center" (click)="delete()">
              <i class="fas fa-times-circle pb-3"></i></span>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end mt-5 w-100">
        <div class="form-group btns save-form">
          <button *ngIf="!logoLoader && !imageSrc" class="btn btn-save mx-3 theme-btn-color"
                  type="submit" (click)="onUpload()">Save
          </button>
          <button class="btn btn-save theme-btn-color" *ngIf="logoLoader" disabled>
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="sr-only"></span>
            </div>
            Save
          </button>
          <button *ngIf="!showLoader && imageSrc" class="btn btn-save mx-3"
                  style="color: red;background-color: #eaeaea"
                  type="submit" (click)="deleteConfirmation()">Remove Picture
          </button>
          <div *ngIf="showLoader" disabled>
            <button class="btn btn-saveform" disabled>
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="w-50">
      <div>
        <h6>Activate / Deactivate Solution:</h6>
      </div>
      <div class=" ps-5 pt-3 d-flex align-items-center justify-content-start">
        <div class="ActiveButton pe-2">Deactive</div>
        <mat-slide-toggle
          class="example-margin"
          color="primary"
          [disabled]=false
          [checked]="this.data?.active"
          (change)="toggleActiveSolution($event)">
          <div class="ActiveButton">Active</div>
        </mat-slide-toggle>
      </div>
      <div>
        <div class=" ps-5 pt-3 d-flex align-items-center justify-content-start">
          <mat-slide-toggle
            class="example-margin"
            color="primary"
            [disabled]=false
            [checked]="this.data?.enableSearch"
            (change)="toggleSearchEnable($event)">
            <div class="ActiveButton">Enable Search</div>
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>

</div>
