import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { JwtUserHash } from 'src/app/shared/interfaces';
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-complete-partner',
  templateUrl: './complete-partner.component.html',
  styleUrls: ['./complete-partner.component.css']
})
export class CompletePartnerComponent implements OnInit {
  password: string;
  orgUser: any;
  jwtUser: JwtUserHash;
  createForm: FormGroup;
  showloader: boolean;
  errorMessage: '' | any;
  constructor(private route: ActivatedRoute,private router: Router, private service: ApiService,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    this.createForm = this.fb.group({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      token: ''
    })
    this.route.params.subscribe(res => {
      this.service.getInvitation(res.id).then(jwtUser => {
        this.jwtUser = jwtUser;
        this.service.getOrgUser(jwtUser.userId, jwtUser.orgId).then((org: any) => {
          this.orgUser = org;
          this.createForm.get("firstName")?.setValue(org.firstName);
          this.createForm.get("lastName")?.setValue(org.lastName);
          this.createForm.get("email")?.setValue(org.email);
          this.createForm.get("token")?.setValue(this.jwtUser.hash);
        })
      });
    });
  }
  setPassword(): void {
    this.showloader = true;
    this.service.setInvitationPassword(this.createForm.value , this.jwtUser.userId).then(res => {
      this.showloader = false;
      this.router.navigate(['login']);
      console.log('Password set successfully');
    },(e)=>{
      if(e.error.status == 500) {
        this.showloader = false;
        this.errorMessage = "Password Already set For this Partner";
      }
    });
  }

}
