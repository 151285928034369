<div class="content-wrapper">
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h5 class="page-title">Manage Customers</h5>
        <div class="page-title-right me-3">
          <a routerlink="add-customer" class="btn btn-create theme-btn-color" (click)="openDialog('add',null)"> Create
            Customer
            <i class="fas fa-plus fa-xs"></i></a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="content-wrapper-main-inner-content">
      <div class="row mb-2">
        <div class="col-md-4">
          <div id="data-table_filter" class="dataTables_filter">
            <label class="w-100 search-input">
              <input [(ngModel)]="search" class="form-control" placeholder="Search Customers"
                     (ngModelChange)="updateSearch()"  type="search"
                     aria-controls="data-table">
              <button class="search-button" (click)="loadCustomers(this.currentPage,this.pageSize,'',this.filters)" type='button'>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.7479 18.5593L14.0096 12.8169C15.1409 11.4602 15.8213 9.72641 15.8213 7.83277C15.8213 3.51266 12.2758 0 7.91475 0C3.55364 0 0 3.51675 0 7.83687C0 12.157 3.54544 15.6696 7.90655 15.6696C9.7592 15.6696 11.4643 15.0343 12.8169 13.9727L18.5757 19.7315C18.9118 20.0676 19.4118 20.0676 19.7479 19.7315C20.084 19.3954 20.084 18.8954 19.7479 18.5593ZM1.6805 7.83687C1.6805 4.44308 4.47587 1.6846 7.90655 1.6846C11.3372 1.6846 14.1326 4.44308 14.1326 7.83687C14.1326 11.2307 11.3372 13.9891 7.90655 13.9891C4.47587 13.9891 1.6805 11.2266 1.6805 7.83687Z"
                    fill="#808080"/>
                </svg>
              </button>
            </label>
          </div>
        </div>
        <div class="col-md-8 d-flex justify-content-end">
          <mat-slide-toggle
            class="example-margin m-1 p-1"
            color="primary"
            [disabled]=false
            [checked]="showArchived"
            (change)="toggleActiveCustomers($event)">
            <div>Show In-Active</div>
          </mat-slide-toggle>
          <div class="ms-3 p-0">
            <button class="btn btn-create theme-btn-color" (click)="clearFilter()">
              <i class="fas fa-redo me-2"></i>
              Reset Filters
            </button>
          </div>
        </div>
      </div>


<!--      <div *ngIf="showLoader" class=" d-flex justify-content-center align-item-center mt-5 pt-5">-->
<!--        <mat-spinner diameter="50"></mat-spinner>-->
<!--      </div>-->

      <div class="row">
        <div class="table-responsive col-12 col-sm-12 col-lg-12">
          <table class="table table-striped">
            <thead class="tableview theme-bg-color">
            <tr>
              <th>Customer ID</th>
              <th>Customer Name</th>
              <th>Location</th>
              <th>Date</th>
            </tr>
            </thead>
            <tbody class="search-results">
            <tr search-filter [searchFields]="searchFields" [filters]="filters" (filtersChanged)="onFiltersChanged($event)"
                (dateTypeChanged)="dateTypeChanged($event)"  (resetFilters)="clearFilter()"
            ></tr>
            <ng-container>
            <tr *ngFor="let customer of customers,let i=index" class="vendors-listing mb-3 position-relative">
              <td (click)="openDialog('edit',customer)"><span class="theme-text-color fw-bold" style="cursor: pointer">{{ customer.customerId }}</span>
              </td>
              <td> {{ customer.companyName ? customer.companyName : '--' }}</td>
              <td>
                {{ (customer.address.city == null || customer.address.city.name == "") ? "-" : customer.address.city.name + ',' }}
                {{ (customer.address.state == null || customer.address.state.name == "") ? "-" : customer.address.state.name + ', ' }}
                {{ (customer.address.country == null || customer.address.country.name == "") ? "-" : customer.address.country.name }}
              </td>
              <td>
                  <span class="p-0 m-0" style="font-size: 13px;">
                     Created on : {{ customer.createdDate | date: 'd MMM y hh:mm a' }}<br>
                      Updated on : {{ customer.updateDate | date: 'd MMM y hh:mm a' }}
                    </span>
              </td>
            </tr>
            </ng-container>
            </tbody>
          </table>
          <div *ngIf="!showLoader && customers.length == 0" class="d-flex align-items-center justify-content-center">
            <div>
              <img src="assets/images.png">
              <br>
              <h4> No Result Found</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="table-responsive col-12 col-sm-12 col-lg-12">
    <mat-paginator [length]="length"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"
                   [hidden]="length==0"
                   aria-label="Select page">
    </mat-paginator>
  </div>
</div>

