import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ComponentName, Customer, FormUpdateEvent} from "../../../../shared/interfaces";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Observable} from "rxjs/internal/Observable";
import {MatPaginator} from "@angular/material/paginator";
import {ApiService} from "../../../../services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {debounce} from "lodash";
import {debounceTime, distinctUntilChanged, filter, map, startWith} from "rxjs/operators";
import {ConsultantsDeployModalComponent} from "../consultants-deploy-modal/consultants-deploy-modal.component";
import {DeletePopupComponent} from "../../../delete-popup/delete-popup.component";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {IsDataUpdatedForModalsService} from "../../../../services/isDataUpdatedForModals.service";

@Component({
  selector: 'app-consultant-deployed',
  templateUrl: './consultant-deployed.component.html',
  styleUrls: ['./consultant-deployed.component.css']
})
export class ConsultantDeployedComponent implements OnInit {
  @Input() consultantData: any;
  @Output() formUpdated = new EventEmitter<FormUpdateEvent>();
  daysList: number[] = [];
  status: string
  searchStatus: boolean
  showloader = false;
  deployedForm: FormGroup;
  auditHistory: any[] = [];
  clientControl = new FormControl('');
  customerClients: Customer[] = []
  filteredOptions: Observable<Customer[]>;
  customerid: any;
  daysListForm: FormControl;
  availabilityToJoin: string | null;
  pageSize = 5;
  currentPage = 0;
  totalPages: 0;
  previousFormValue: any;
  initializingForm: boolean = true;
  totalElements: 0
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input', {static: true}) input: ElementRef | any;

  constructor(
    private service: ApiService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private isDataUpdatedService: IsDataUpdatedService,
    private isAssignmentDataUpdated: IsDataUpdatedForModalsService,
  ) {
    this.daysList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
    this.daysListForm = new FormControl();
  }

  ngOnInit(): void {
    if (this.consultantData?.consultantData != null) {
      this.searchStatus = this.consultantData?.consultantData?.enableSearch ?? false;
      this.status = this.consultantData?.consultantData?.status;
    }
    this.deployedForm = this.fb.group({
      customerId: [""],
      contractStartDate: [""],
      contractEndDate: [""],
      contractPeriod: [""],
      workMode: [""],
      tier: [""],
      engagementType: [""],
    });
    if (this.consultantData?.consultantData?.id) {
      this.availabilityToJoin = this.consultantData?.consultantData?.availabilityToJoin;
      this.daysListForm.setValue(this.availabilityToJoin);
    }
    this.previousFormValue = this.deployedForm?.value;
    this.initializingForm = false;
    this.getCustomerClients();
    this.getDeployedHistory((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id, this.currentPage, this.pageSize);
    this.getDeployedHistory = debounce<any>(this.getDeployedHistory, 600);
    this.deployedForm.valueChanges.pipe(
      debounceTime(500),
      filter(() => !this.initializingForm),
      map(currentValue => JSON.stringify(currentValue) !== JSON.stringify(this.previousFormValue)),
      distinctUntilChanged()
    ).subscribe(isFormUpdated => {
      this.formUpdated.emit({updated: isFormUpdated, componentName: ComponentName.ConsultantStatus});
    });
  }

  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    if (this.consultantData?.id || this.consultantData?.consultantData?.id)
      this.getDeployedHistory((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id, this.currentPage, this.pageSize);
  }

  getDeployedHistory(consultantId: string, page: number, size: number): void {
    this.service.getConsultantDeployedHistory(consultantId, page, size).subscribe(res => {
      this.auditHistory = res.content;
      this.pageSize = res.pageable?.pageSize?.size;
      this.totalElements = res?.totalElements;
      this.currentPage = res?.pageable?.pageNumber;
      this.totalPages = res?.totalPages;
    })
  }

  getCustomerClients() {
    this.service.getActiveCustomerClients().subscribe((response) => {
      this.customerClients = response;
      this.customerClients.forEach((client) => {
        this.customerid = client.id;
      });
      // @ts-ignore
      this.filteredOptions = this.clientControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          this.customerid = value.id;
          const name = typeof value === 'string' ? value : value?.name ?? "";
          return name ? this._filter(name as string) : this.customerClients.slice();
        }),
      );
    });
  }

  _filter(name: string): Customer[] {
    const filterValue = name.toLowerCase();
    return this.customerClients?.filter((options: Customer) => options.companyName.toLowerCase().includes(filterValue));
  }

  openFormModal(): void {
    const dialogRef = this.dialog.open(ConsultantsDeployModalComponent, {
      width: '60%',
      data: {
        id: (this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id
      }
    })
    dialogRef.afterClosed().subscribe(() => {
      this.getDeployedHistory((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id, 0, 5);
    });
  }

  editFormModal(id: number) {
    const dialogRef = this.dialog.open(ConsultantsDeployModalComponent, {
      width: '60%',
      data: {
        id: (this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id,
        consultantDeployHistoryId: id
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      if (this.isAssignmentDataUpdated.getBoolean()) {
        this.getDeployedHistory((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id, 0, 5);
        this.isAssignmentDataUpdated.setUpdated(false);
      }
    });
  }

  deleteRow(id: any) {
    this.dialog.open(DeletePopupComponent, {
      data: {
        message: 'Are you sure you want to delete this Assignment ?',
        type: 'Delete'
      }
    }).afterClosed().subscribe((resp) => {
      if (resp) {
        if (id) {
          this.service.deleteConsultantDeployedHistory(id).subscribe(
            () => {
              this.isDataUpdatedService.setUpdated(true);
              this.getDeployedHistory((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id, 0, 5);
            },
            (error) => {
              console.error('Error deleting consultant history:', error);
            }
          );
        }
      }
    });
  }

  modifyTypeOfEngagement(type:string) {
    if(type === 'PREMIUM') {
      return 'Tier 1 - Premium Customer'
    }else if(type === 'REGULAR'){
      return 'Tier 2 - Regular Customer'
    } else if(type === 'PARTNER'){
      return 'Tier 3 - Partner Customer'
    } else {
      return ''
    }
  }
}
