import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {CustomerAnalytics, CustomerAnalyticsSearch} from "../../../shared/interfaces";
import {debounce} from "lodash";
import {DatePipe} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {CustomerActivityModalComponent} from "../customer-activity-modal/customer-activity-modal.component";
import {CreateCustomersComponent} from "../manage-customers/create-customers/create-customers.component";

@Component({
  selector: 'app-customer-analytics',
  templateUrl: './customer-analytics.component.html',
  styleUrls: ['./customer-analytics.component.css']
})

export class CustomerAnalyticsComponent implements OnInit {
  customerAnalyticsData: CustomerAnalytics[] = [];
  currentPage: number = 0;
  pageSize: number = 12;
  totalPages: number = 0;
  totalElements: number = 0;
  showLoader:boolean=false;
  tableHeader: string[] = ['Customer Contact', 'Customer Name', 'Assigned SPOC', 'Device Used', 'Location', 'Login Time', 'Logout Time', 'Duration', 'Activity'];
  searchFieldName = ['customerContact', 'customerName', 'assignedSpoc', 'deviceUsed', 'location', 'loginTime', 'logoutTime'];

  filters: any = {
    customerContact: "",
    customerName: '',
    assignedSpoc: '',
    deviceUsed: '',
    location: '',
    loginTime:'',
    logoutTime:'',
  };

  constructor(
    private apiService: ApiService,
    private datePipe: DatePipe,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.getAllCustomerAnalyticsDetails(this.currentPage, this.pageSize, [], this.filters);
  }
  clearFilter(){
    this.filters  = {
      customerContact: "",
      customerName: '',
      assignedSpoc: '',
      deviceUsed: '',
      location: '',
      loginTime:'',
      logoutTime:'',
    };
    this.getAllCustomerAnalyticsDetails(0, this.pageSize, [], this.filters);
  }
  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getAllCustomerAnalyticsDetails(this.currentPage, this.pageSize, [], this.filters);
  }

  getAllCustomerAnalyticsDetails(page: number, size: number, sort: string[], payload: CustomerAnalyticsSearch) {
    this.showLoader = true;
    this.apiService.getAllCustomersAnalytics(page, size, sort, payload).subscribe(
      (res) => {
        this.customerAnalyticsData = res.content;
        this.currentPage = res.page.number;
        this.pageSize = res.page.size;
        this.totalElements = res.page.totalElements;
        this.totalPages = res.page.totalPages;
        this.showLoader = false;
      },
      (error) => {
        console.log('error',error);
        this.showLoader = false;
      }
    );
  }

  onChangeSearch = debounce((value: string, fieldName: string) => {
    if (fieldName === '') {
      return;
    }
    else if(fieldName === 'loginTime' || fieldName === 'logoutTime'){
      this.filters[fieldName] = this.datePipe.transform(value, 'yyyy-MM-dd')
    }
    else {
      this.filters[fieldName] = value ?? "";
    }
console.log('filters',this.filters)
    this.getAllCustomerAnalyticsDetails(0, this.pageSize, [], this.filters);
  }, 600);

  formatDateTime(date: any): string {
    return this.datePipe.transform(date, 'dd MMM YYYY hh:mm a') || "--";
  }
  downloadReport() {
    const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    const fileName = `Customer_Report_${timestamp}.csv`;
    this.apiService.downloadCostumerReport(
      this.filters?.customerName,
      this.filters?.customerContact,
      this.filters?.assignedSpoc,
      this.filters?.deviceUsed,
      this.filters?.location,
      this.filters?.loginTime,
      this.filters?.logoutTime,
    ).subscribe(blob =>{ const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(objectUrl);});
  }
  openFormModal(customerContactId:number,sessionId:string,data:CustomerAnalytics): void {
    const dialogRef = this.dialog.open(CustomerActivityModalComponent, {
      width: '60%',
      data: {
        customerContactId:customerContactId,
        sessionId:sessionId,
        customerContact:data.customerContact ?? '',
        customerContactEmail:data.customerContactEmail,
        customerContactPhone:data.customerContactPhone,
      }
    })
    dialogRef.afterClosed().subscribe(() => {

    });
  }

  openCustomerModal(id:string): void {
    const dialogRef = this.dialog.open(CreateCustomersComponent, {
      width: '70%',
      data: {
        id: id,
        customerAnalyticsRoute: true,
      }
    })
    dialogRef.afterClosed().subscribe(() => {

    });
  }
  clearDateField(data: string) {
    this.filters[data] = null;
    this.getAllCustomerAnalyticsDetails(0, this.pageSize, [], this.filters);
  }
}

