import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageStorageService {
  private imageSrcSubject = new BehaviorSubject<string | null>(null);

  setImageSrc(imageSrc: string) {
    this.imageSrcSubject.next(imageSrc);
  }

  getImageSrc() {
    return this.imageSrcSubject.asObservable();
  }

  // Optional: Method to get the current value without subscribing
  getCurrentImageSrc(): string | null {
    return this.imageSrcSubject.value;
  }
}
