import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from "../services/api.service";
import {DataService} from "../services/data.service";
import {LoggedInUser, SessionMap} from "../shared/interfaces";
import {Common} from "../shared/Common";
import {ThemeLoaderService} from "../services/theme-loader.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  loginRadio: FormGroup | any;
  visible1=true;
  visible2=false;
  password = '';
  loginform: FormGroup;
  showloader = false;
  errorMessage: '';

  @ViewChild('loginPasswordInput') loginPasswordInput: ElementRef<HTMLInputElement>;
  @ViewChild('faIconLoginPasswordInputButton') faIconLoginPasswordInputButton: ElementRef<HTMLElement>;

  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    private router: Router,
    private dataService:DataService,
    private themeLoaderService:ThemeLoaderService
  ) {
  }

  login(): void {
    this.showloader = true;
    this.errorMessage = '';
    let payload = this.loginform.value;
    if(localStorage.getItem('sessions')){
      let str = localStorage.getItem('sessions');
      if(str){
        let sessionMaps: [SessionMap] = JSON.parse(str);
        sessionMaps.forEach(map => {
          if(map.userEmail === payload.username){
            payload.sessionId = map.sessionId;
          }
        })
      }
    }
    this.service.login(payload).then(res => {
      localStorage.setItem('access_token', res.access_token);
      localStorage.setItem('orgId', res.org_id);
      localStorage.setItem('name', res.name);
      localStorage.setItem('userId', res.user_id);
      localStorage.setItem('role', res.role);
      const rootVariable = document.documentElement;
      if(res.session_id){
        let currentSessionMap :SessionMap = {
          userEmail: res.user_email,
          sessionId: res.session_id
        };
        if(localStorage.getItem('sessions')){
          let str = localStorage.getItem('sessions');
          if (str) {
            let sessionMaps: [SessionMap] = JSON.parse(str);
            sessionMaps.push(currentSessionMap);
            localStorage.setItem('sessions', JSON.stringify(sessionMaps));
          }
        } else {
          let sessionMaps = [];
          sessionMaps.push(currentSessionMap);
          localStorage.setItem('sessions', JSON.stringify(sessionMaps));
        }
      }
      this.service.getLoginUser().subscribe({
        next: res => {
          const primaryColor = res?.theme?.colour ?? '#76ba991a';
          const secondaryColor = Common.rgbaToHex(Common.hexToRGBA(primaryColor, 0.1));
          const ColorObj = { primaryColor, secondaryColor };
          localStorage.setItem('selectedTheme', JSON.stringify(ColorObj));
          rootVariable.style.setProperty('--primaryColor', primaryColor);
          rootVariable.style.setProperty('--secondaryColor', secondaryColor);
          this.themeLoaderService.setTheme(primaryColor, secondaryColor);
        },
        error: err => {
          console.error('Error fetching user theme:', err);
        },
        complete: () => {
          this.dataService.isLoading.next(false);
        }
      });
      this.showloader = false;
      this.router.navigate(['/dashboard'],);

      /* Redirection for employee */
      // this.router.navigate(['']);
    }, (e) => {
      this.showloader = false;
      if (e.error.status == 400) {
        this.errorMessage = e.error.message;
      } else {
        this.errorMessage = e.error.message;
      }
    })
  }

  togglePassword(): void {
    if (this.loginPasswordInput.nativeElement.type == 'text'){
      this.loginPasswordInput.nativeElement.type = 'password';
      this.faIconLoginPasswordInputButton.nativeElement.className ='fas fa-eye';
    } else{
      this.faIconLoginPasswordInputButton.nativeElement.className = 'fas fa-eye-slash';
      this.loginPasswordInput.nativeElement.type = 'text';
    }
  }

  getForgotPassword(): string {
    if (this.loginform.get('username')?.value) {
      return '/forget-password/' + this.loginform.get('username')?.value;
    } else {
      return '/forget-password/NA';
    }
  }

  ngOnInit(): void {
    this.loginRadio = this.fb.group({
      type:[''],
    });
    localStorage.setItem('loginType','Vendor');
    this.loginRadio.get('type').setValue('Vendor');
    this.loginform = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    if (localStorage.getItem('access_token') && localStorage.getItem('role') == 'ADMIN') {
      this.router.navigate(['/dashboard']);
    } else if(localStorage.getItem('access_token') && localStorage.getItem('role') == 'Sales') {
      this.router.navigate(['/client']);
    }
  }

}
