import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Customer, userDT} from "../../../../shared/interfaces";
import {ApiService} from "../../../../services/api.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-dialogbox-change-assignee',
  templateUrl: './dialogbox-change-assignee.component.html',
  styleUrls: ['./dialogbox-change-assignee.component.css']
})
export class DialogboxChangeAssigneeComponent implements OnInit {
  assigneeList: userDT[];
  selectedAssignee: userDT;

  constructor(
    @Inject(MAT_DIALOG_DATA) public taskList: any,
    private service: ApiService,
    public dialogRef: MatDialogRef<DialogboxChangeAssigneeComponent>,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.getCustomerClients();
  }

  getCustomerClients() {
    this.service.getSpocList().subscribe((res: any) => {
      if (this.taskList.showOnlyActiveMembers) {
        this.assigneeList = res.filter((memberItem: Customer) => {
          return memberItem.active === this.taskList.showOnlyActiveMembers;
        });
      } else {
        this.assigneeList = res;
      }
    })
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onChangeAssignee(event: any) {
    this.selectedAssignee = event.value;
  }

  submitAssignee() {
    if (this.taskList?.customerSelectedTasks instanceof Set) {
      const allNumbers = Array.from(this.taskList.customerSelectedTasks).every((item: any) => typeof item === 'number');
      if (allNumbers) {
        const selectedTasksArray = Array.from(this.taskList.customerSelectedTasks) as number[];
        this.service.customerContactChangeAssignee(selectedTasksArray, this.selectedAssignee.userId).subscribe((res) => {
          this.toastr.success('Tasks Successfully assigned to ' + this.selectedAssignee.firstName + ' ' + this.selectedAssignee.lastName, 'Success');
          this.dialogRef.close();
        });
        return;
      }
    }
    if (this.taskList?.selectedTasks instanceof Set) {
      const allNumbers = Array.from(this.taskList.selectedTasks).every((item: any) => typeof item === 'number');
      console.log('Are all items in selectedTasks numbers?', allNumbers);
      if (allNumbers) {
        const selectedTasksArray = Array.from(this.taskList.selectedTasks) as number[];
        this.service.changeBulkAssignee(selectedTasksArray, this.selectedAssignee.userId).subscribe((res) => {
          this.toastr.success('Tasks Successfully assigned to ' + this.selectedAssignee.firstName + ' ' + this.selectedAssignee.lastName, 'Success');
          this.dialogRef.close();
        });
        return;
      }
    }
    this.toastr.error('No tasks selected or invalid task types', 'Error');
  }


}
