import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {DataService} from "../../services/data.service";
import {ApiService} from "../../services/api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Consultant, Skill} from "../../shared/interfaces";
import {MatDialog} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {debounce} from "lodash";
import {IsDataUpdatedClientService} from "../../services/isDataUpdatedClient.service";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {FormControl} from "@angular/forms";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {Observable} from "rxjs/internal/Observable";
import {map, startWith, tap} from "rxjs/operators";
import {
  FeaturedConsultantViewProfileComponent
} from "../featured-consultant-view-profile/featured-consultant-view-profile-component";

@Component({
  selector: 'app-featured-consultant-table',
  templateUrl: './featured-consultant-table.component.html',
  styleUrls: ['./featured-consultant-table.component.css'],
  // encapsulation:ViewEncapsulation.None
})
export class FeaturedConsultantTableComponent implements OnInit, AfterViewInit {
  skillControl = new FormControl('');
  members: any[] = [];
  consultants: Consultant[] = [];
  pageSize = 12;
  currentPage = 0;
  totalPages = 0;
  totalElements: any;
  message: string | null;
  userId: any;
  role: any;
  firstName: any;
  search = '';
  viewType = '';
  index: number;
  custContactId: number;
  loading: boolean;
  searchEnable: boolean = true;
  active: boolean = true;
  interested: boolean = false;
  private selectedjobType: any[] = [];
  private locations: any[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  previousSearch: string = '';
  consultantId: string = '';
  roleTitle: string = '';
  selectedItemsExperience: { min: number, max: number }[] = [];
  experienceOptions: { min: number, max: number, label: string }[] = [
    {min: 0, max: 1, label: '0-1 years'},
    {min: 1, max: 3, label: '1-3 years'},
    {min: 3, max: 5, label: '3-5 years'},
    {min: 5, max: 8, label: '5-8 years'},
    {min: 8, max: 10, label: '8-10 years'},
    {min: 10, max: Infinity, label: '10 years+'}
  ];
  selectedSkill: Skill[] = [];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  skillName = '';
  filteredSkills: Observable<Skill[]>;
  disabledSkills: Skill[] = [];
  skills: any[] = [];
  skillsRes: Skill[] = [];
  @ViewChild("chipGrid") chipGrid: ElementRef;
  @ViewChildren('dropdownExperience, dropdownSkills') dropdowns!: QueryList<ElementRef>;
  @ViewChild('input', {static: true}) input: ElementRef | any;

  constructor(
    private dataService: DataService,
    private service: ApiService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private isClientDataUpdated: IsDataUpdatedClientService
  ) {
  }

  ngOnInit(): void {
    this.getSkillType();
    this.loadConsultants(this.currentPage,this.pageSize,'',this.search);
    this.loadConsultants = debounce<any>(this.loadConsultants, 600);
    this.viewType = 'tabular';
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(
      tap(() => {
        const payload = {
          experience: this.selectedItemsExperience.map(exp => ({min: exp.min, max: exp.max})),
          skills: this.selectedSkill.map(skill => skill.id),
        };
        this.loadConsultants(this.currentPage,this.pageSize,'',this.search,payload.experience,payload.skills,this.roleTitle,this.consultantId);

      })
    ).subscribe();
  }

  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    const payload = {
      experience: this.selectedItemsExperience.map(exp => ({min: exp.min, max: exp.max})),
      skills: this.selectedSkill.map(skill => skill.id),
    };
    this.loadConsultants(this.currentPage,this.pageSize,'',this.search,payload.experience,payload.skills,this.roleTitle,this.consultantId);

  }


  clearFilter(){
    this.consultantId='';
    this.selectedItemsExperience=[];
    this.selectedjobType=[];
    this.locations=[];
    this.selectedSkill=[];
    this.roleTitle='';
    this.search=''
    this.loadConsultants(this.currentPage,this.pageSize,'',this.search);


  }
  getSkillType(): void {
    this.service.getSkillsConsumer().then((res: any[]) => {
      this.skillsRes = res.filter((title) => title.title !== "");
      this.filteredSkills = this.skillControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterSkills(value))
      );
    });
  }

  private _filterSkills(value: string | Skill): Skill[] {
    const filterValue = typeof value === 'string' ? value.trim().toLowerCase() : value?.title.trim().toLowerCase() ?? '';
    return this.skillsRes.filter(skill =>
      skill.title.toLowerCase().includes(filterValue)
    );
  }

  onSearchClick(): void {
    const payload = {
      experience: this.selectedItemsExperience.map(exp => ({min: exp.min, max: exp.max})),
      skills: this.selectedSkill.map(skill => skill.id),
    };
    if (this.search.trim() === '') {
      if (this.previousSearch.trim() !== '') {
        this.paginator.pageIndex = 0;
        this.loadConsultants(this.currentPage,this.pageSize,'',this.search,payload.experience,payload.skills,this.roleTitle,this.consultantId);
      }
    } else {
      this.paginator.pageIndex = 0;
      this.loadConsultants(this.currentPage,this.pageSize,'',this.search,payload.experience,payload.skills,this.roleTitle,this.consultantId);

    }
    this.previousSearch = this.search;
  }

   loadConsultants(pageNumber: number, size: number, sort: string, query: string,
  experience?: { min: number; max: number; }[], skills?: number[],
  title?: string, consultantId?: string): void {
  this.service.getAllFeaturedConsultants(pageNumber, size, sort, query,  experience,skills,
                                         title, consultantId).subscribe({
                                                                          next: res => {
  this.consultants = res.content;
  this.pageSize = res.page.size;
  this.totalElements = res.page.totalElements;
  this.totalPages = res.page.totalPages;
},
error: err => {
  console.log('error', err);
},
    complete: () => {
}
});
}

  changeViewType(type: string) {
    this.viewType = type;
  }

  openDialogs(consultant: any): void {
    const dialogRef = this.dialog.open(FeaturedConsultantViewProfileComponent, this.dataService.jobDialogConfig({
      id: consultant.id,
      consultantData: consultant,
    }));
    dialogRef.afterClosed().subscribe(() => {

    });
  }

  onInterestedList(event: boolean) {
    if (!event) {
      this.interested = true;
      this.currentPage = 0;
      this.paginator.pageIndex = 0;
      // @ts-ignore
      this.custContactId = parseInt(localStorage.getItem('customerContactId'));
      this.loadConsultants(this.currentPage,this.pageSize,'',this.search);

    } else {
      this.currentPage = 0;
      this.paginator.pageIndex = 0;
      this.interested = false;
      this.custContactId = parseInt('');
      this.loadConsultants(this.currentPage,this.pageSize,'',this.search);

    }
  }

  getSkills(skills: any): { skillsString: string, pendingSkillsCount: number, pendingSkills: string[] } {
    let skillsString = '';
    let count = 0;
    let pendingCount = 0;
    let pendingSkills: string[] = [];

    skills.forEach((e: any, index: number) => {
      if (count < 5) {
        skillsString += e.title;
        if (count < 4 && index < skills.length - 1) {
          skillsString += ' | ';
        }
        count++;
      } else {
        pendingSkills.push(e.title);
        pendingCount++;
      }
    });

    return {
      skillsString: skillsString,
      pendingSkillsCount: pendingCount,
      pendingSkills: pendingSkills
    };
  }

  getSkillsForGrid(skills: any) {
    let skillsString = '';
    let count = 0;
    let pendingCount = 0;
    skills.forEach((e: any, index: number) => {
      if (count < 3) {
        if (skillsString !== '') {
          skillsString += ' | ';
        }
        skillsString += e.title;
        count++;
      } else {
        pendingCount++;
      }
    });

    return {
      skillsString: skillsString,
      pendingSkillsCount: pendingCount
    };
  }

  closeAllDropdowns() {
    this.dropdowns.forEach(dropdown => {
      const dropdownMenu = dropdown.nativeElement.querySelector('.dropdown-menu');
      dropdownMenu.style.display = 'none';
    });
  }

  onInputChange(newValue: string, fieldName: string) {
    switch (fieldName) {
      case 'consultantId':
        this.consultantId = newValue;
        break;
      case 'title':
        this.roleTitle = newValue;
        break;
      default:
        break;
    }
    this.sendPayload();
  }

  toggleDropdown(dropdownName: string) {
    const dropdown = this.dropdowns.find(dropdown => dropdown.nativeElement.id === dropdownName);
    if (dropdown) {
      const dropdownMenu = dropdown.nativeElement.querySelector('.dropdown-menu');
      const isOpen = dropdownMenu.style.display === 'block';
      this.closeAllDropdowns();
      if (!isOpen) {
        dropdownMenu.style.display = 'block';
      }
    }
  }

  toggleSelectionExperience(option: { min: number, max: number, label: string }, selectedItems: {
    min: number,
    max: number
  }[]) {
    const index = selectedItems.findIndex(item => item.min === option.min && item.max === option.max);
    if (index > -1) {
      selectedItems.splice(index, 1);
    } else {
      selectedItems.push({min: option.min, max: option.max});
    }
    this.sendPayload();
  }

  isSelectedExperience(option: { min: number, max: number, label: string }, selectedItems: {
    min: number,
    max: number
  }[]): boolean {
    return selectedItems.some(item => item.min === option.min && item.max === option.max);
  }

  removeSkill(skill: any): void {
    const index = this.selectedSkill.indexOf(skill);
    if (index >= 0) {
      this.selectedSkill.splice(index, 1);
    }
    this.sendPayload();
  }

  openAutocomplete(trigger: MatAutocompleteTrigger) {
    if (trigger) {
      trigger.openPanel();
    }
  }

  selectSkills(event: MatAutocompleteSelectedEvent): void {
    const skill = event.option.value;
    this.selectedSkill.push(skill);
    this.chipGrid.nativeElement.value = '';
    this.skillControl.setValue(null);
    this.disabledSkills.push({...skill, disabled: true});
    this.loadConsultants(this.currentPage,this.pageSize,'',this.search);

    this.sendPayload();
  }

  disableSkill(skill: any): boolean {
    return this.selectedSkill.some(selected => selected.id === skill.id) || this.skills.some(s => s.id === skill.id);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = this.dropdowns.some(dropdown => dropdown.nativeElement.contains(event.target));
    if (!clickedInside) {
      this.closeAllDropdowns();
    }
  }

  sendPayload() {
    const payload = {
      experience: this.selectedItemsExperience.map(exp => ({min: exp.min, max: exp.max})),
      skills: this.selectedSkill.map(skill => skill.id),
    };
    this.paginator.pageIndex = 0;
    this.loadConsultants(this.currentPage,this.pageSize,'',this.search,payload.experience,payload.skills,this.roleTitle,this.consultantId);

  }

}
