import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {PopUpComponent} from "../../../../util/pop-up/pop-up.component";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {ImageCropperComponent} from "../../image-cropper/image-cropper.component";

@Component({
  selector: 'app-consultant-settings',
  templateUrl: './consultant-settings.component.html',
  styleUrls: ['./consultant-settings.component.css']
})
export class ConsultantSettingsComponent implements OnInit {
@Input() createConsultantImage: string;
@Input() consultantData: any;
  @ViewChild('fileInput') fileInput: ElementRef<HTMLDivElement>;
  @Output() consultantImageChange = new EventEmitter<string>();
  file: File | null;
  logoLoader = false;
  type = '';
  consultantImage: string | null;
  defaultImage: string ='./assets/avatars.png';
  consultantImgName: any;
  pictureStatus: boolean;
  consultantStatus: boolean;
  consultantId: number;
  showLoader=false;
  formData: FormData;
  customerLogoVisible: boolean;
  imageSrc!: string;
  featured: boolean = false;
  constructor(
    private service: ApiService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private isDataUpdatedService: IsDataUpdatedService,
  ) {

  }

  ngOnInit(): void {
    if (this.consultantData?.id || this.consultantData?.consultantData?.id) {
      this.service.getConsultant((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe({
          next: res => {
            this.consultantImage = res?.profilePicture ?? '';
            this.imageSrc =res.profilePicture ?? '';
            this.customerLogoVisible = false;
            this.featured = res.featured
          },
          error: err => {
            console.error(err);
          },
          complete: () => {
            // this.previousFormValue = this.consultantForm.value;
            // this.initializingForm = false;
          }
        }
      )
    }
    if (this.consultantData?.consultantData) {
      this.consultantImage = this.consultantData.consultantData.profilePicture || null;
      this.pictureStatus = this.consultantData.consultantData.showProfilePicture;
      this.consultantStatus = this.consultantData.consultantData.active;
      this.updateImageSource();
    }
  }

  private updateImageSource() {
    if (this.consultantImage && this.consultantImage != '') {
      this.imageSrc = this.consultantImage;
      this.consultantImageChange.emit(this.imageSrc);
    } else {
      this.imageSrc = this.consultantData?.consultantData?.gender
      == 'Female' ? './assets/images/femaleDefault.jpg' : './assets/images/maleDefault.jpg';
      this.consultantImageChange.emit(this.imageSrc);
    }
  }

  uploadFileTrigger(): void {
    this.fileInput.nativeElement.click();
  }

  toggleProfilePictureStatus(event: any){
    console.log('toggleProfilePictureStatus',event);
    if(event.checked){
      this.service.displayConsultantProfilePicture((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe( (res=> {
        this.pictureStatus = true;
        this.isDataUpdatedService.setUpdated(true);
        this.toastr.success('Consultant Profile Enabled At Talent-pool ' , 'Success');
      }),(e:any)=> {
        console.log(e);
        this.toastr.error(e.message , 'Request Failed');
      });
    } else {
      this.service.hideConsultantProfilePicture((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe((res=>{
        this.pictureStatus = false;
        this.isDataUpdatedService.setUpdated(true);
        this.toastr.success('Consultant profile Disabled At Talent-pool ' , 'Success');
      }),(e:any)=>{
        this.toastr.error(e.message , 'Request Failed');
      });
    }
  }

  onSelectLogo(event: any, dragEvent?: boolean): void {
    event.preventDefault();
    if (dragEvent) {
      this.file = event.dataTransfer.files[0];
    } else {
      if (event.target.files && event.target.files.length > 0) {
        this.file = event.target.files[0];
      } else {
        this.file = null;
        return;
      }
    }
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const disallowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    if (this.file !== null) {
      if (allowedTypes.includes(this.file.type)) {
        const dialogRef3 = this.dialog.open(ImageCropperComponent, {
          width: '60%',
          data: { file: this.file }
        });
        dialogRef3.afterClosed().subscribe((result: any) => {
          console.log('result', result);
          if (result) {
            if (result.blob instanceof Blob) {
              this.handleBlobResult(result.blob);
            } else if (result.base64) {
              this.handleBase64Result(result.base64);
            } else {
              console.error('Result is not a valid Blob or base64 string:', result);
              this.resetFile();
            }
          } else {
            console.error('Dialog closed without result:', result);
            this.resetFile();
          }
          (event.target as HTMLInputElement).value = '';
        });
      } else if (disallowedTypes.includes(this.file.type)) {
        console.error('PDF and DOC files are not allowed.');
        this.toastr.error('PDF and DOC files are not allowed.', 'Error');
        this.logoLoader = false;
      } else {
        this.toastr.error('Unsupported file type.', 'Error');
        console.error('Unsupported file type.');
        this.logoLoader = false;
      }
    }
  }

  handleBlobResult(blob: Blob): void {
    const filename = this.file?.name || 'cropped_image.png';
    const file = new File([blob], filename, { type: blob.type });
    const formData: FormData = new FormData();
    formData.append('name', file?.name);
    formData.append('file', file);
    this.formData = formData;
    this.consultantImgName = file.name;
  }

  handleBase64Result(base64: string): void {
    const file = this.base64ToFile(base64, this.file?.name || 'cropped_image.png');
    if (file) {
      const formData: FormData = new FormData();
      formData.append('name', file?.name);
      formData.append('file', file);
      this.formData = formData;
      this.consultantImgName = file?.name;
    } else {
      console.error('Failed to convert base64 to file');
      this.resetFile();
    }
  }

  resetFile(): void {
    this.file = null;
    this.consultantImgName = '';
  }

  base64ToFile(data: string, filename: string): File | null {
    if (!data) {
      console.error('No data provided to base64ToFile');
      return null;
    }
    const arr = data.split(',');
    if (arr.length < 2) {
      console.error('Invalid base64 data:', data);
      return null;
    }
    const mimeMatch = arr[0].match(/:(.*?);/);
    if (!mimeMatch) {
      console.error('Invalid MIME type in base64 data:', arr[0]);
      return null;
    }
    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  onUpload() {
    if (!this.file) {
      this.toastr.info('Please select an image.');
      return;
    }
    this.logoLoader = true;
    this.service.uploadConsultantProfilePicture(this.formData, (this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe((res => {
      if (res.type === 4) {
        this.isDataUpdatedService.setUpdated(true);
        this.consultantImage = res.body.url;
        this.imageSrc = res.body.url;
        this.logoLoader = false;
        this.consultantImgName = '';
        this.consultantImageChange.emit(this.imageSrc);
      }
    }), (e) => {
      this.logoLoader = false;
      if (e.error.status == 500) {
      }
      else if (e.error.status == 400) {
      }
    });
  }


  handleDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  deleteConfirmation(action: string, avatar: string) {
    if (action === 'Delete') {
      const dialogRef2 = this.dialog.open(PopUpComponent, {
        data: {
          title: 'Alert',
          message: 'Are you sure you want to remove picture?',
          type: 'confirmation'
        }
      });
      dialogRef2.afterClosed().subscribe((res) => {
        if (res) {
          this.service.removeProfilePicture((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe(() => {
            this.consultantImage = "";
            this.imageSrc = '';
            this.isDataUpdatedService.setUpdated(true);
            if (this.consultantImage && this.consultantImage != '') {
              this.imageSrc = this.consultantImage;
              this.consultantImageChange.emit(this.imageSrc);
            } else {
              this.imageSrc = this.consultantData?.consultantData?.gender
              == 'Female' ? './assets/images/femaleDefault.jpg' : './assets/images/maleDefault.jpg';
              this.consultantImageChange.emit(this.imageSrc);
            }
            this.customerLogoVisible = false;

            this.toastr.success('Removed picture', 'Success');
          })
        }
      })
    }
  }

  toggleActiveConsultant(event: any) {
    if(event.checked){
      this.service.switchConsultantStatus((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe( {
        next: res =>{
          this.consultantStatus = res.active
          this.isDataUpdatedService.setUpdated(true);
          this.toastr.success('Consultant activated' , 'Success');
        }
      }),(e:any)=>{
        this.toastr.error(e.message , 'Request Failed');
      }
    } else {
      this.service.switchConsultantStatus((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe({
        next: res =>{
          this.consultantStatus = res.active
          this.isDataUpdatedService.setUpdated(true);
          this.toastr.success('Consultant de-activated ' , 'Success');
        }
      }),(e:any)=>{
        this.toastr.error(e.message , 'Request Failed');
      }
    }
  }

  delete(){
      this.consultantImgName = '';
      this.file = null;
    }

  toggleFeatureConsultant(event: any) {
    if(event.checked){
      this.service.featureConsultant((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe( {
        next: res =>{
          this.toastr.success('Consultant has been successfully featured.', 'Success');
        }
      }),(e:any)=>{
        this.toastr.error(e.message , 'Request Failed');
      }
    } else {
      this.service.unFeatureConsultant((this.consultantData?.consultantData?.id) ? this.consultantData?.consultantData?.id : this.consultantData?.id).subscribe({
        next: res =>{
          this.toastr.success('Consultant has been successfully unFeatured.', 'Success');
        }
      }),(e:any)=>{
        this.toastr.error(e.message , 'Request Failed');
      }
    }
  }

}
