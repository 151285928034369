import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from "@angular/core";
import {ApiService} from "../../../services/api.service";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {ToastrService} from "ngx-toastr";
import {ResetFiltersService} from "../../../services/resetFilters.service";
import {ActivatedRoute} from "@angular/router";
import {SolutionsData} from "../../../shared/interfaces";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";


@Component({
  selector: 'app-solution-detail-view',
  templateUrl: './solution-detail-view.component.html',
  styleUrls: ['./solution-detail-view.component.css']
})
export class SolutionDetailViewComponent implements OnInit {
  solutionId: any;
  solutionData: SolutionsData;
  solutionAttachments: any;
  isNeedMoreDetail: boolean = false;
  isNeedMoreFormDisplay: boolean = false;
  needMoreDetailForm: FormGroup;
  durations: any[] = [];
  sanitizedDescription: SafeHtml;
  sanitizedCompliance: SafeHtml;

  @ViewChild('detailFormElement') detailFormElement!: ElementRef;

  constructor(
    private apiService: ApiService,
    public dialog: NgDialogAnimationService,
    private toastr: ToastrService,
    private resetFilters: ResetFiltersService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    this.durations = [
      {label: '1 Week', value: '1'},
      {label: '2 Weeks', value: '2'},
      {label: '3 Weeks', value: '3'},
      {label: '4 Weeks', value: '4'},
      {label: '5 Weeks', value: '5'},
      {label: '6 Weeks', value: '6'},
      {label: '7 Weeks', value: '7'},
      {label: '8 Weeks', value: '8'},
      {label: '9 Weeks', value: '9'},
      {label: '10 Weeks', value: '10'}
    ];

  }

  ngOnInit(): void {
    this.solutionId = this.route?.snapshot?.params['solutionId'];
    if (this.solutionId) {
      this.getSolutionById(this.solutionId)
      this.getSolutionAttachments(this.solutionId)
    }
    this.needMoreDetailForm = new FormGroup({
      notes: new FormControl(''),
      tentativeStartTime: new FormControl('')

    });
  }


  getSolutionById(solutionId: number) {
    this.apiService.getClientSolutionById(solutionId).subscribe((res) => {
      this.solutionData = res;
      this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(this.solutionData?.solutionInfo?.description || '--');
      this.sanitizedCompliance = this.sanitizer.bypassSecurityTrustHtml(this.solutionData?.solutionTechnology?.compliance || '--');
    });
  }

  getSolutionAttachments(solutionId: number) {
    this.apiService.getClientSolutionAttachments(solutionId).subscribe((res) => {
      this.solutionAttachments = res;
    });
  }

  formatDataIndustryVertical(value: any): string {
    if (!value) {
      return '--';
    }
    if (Array.isArray(value)) {
      return value
        .map((info: any) => {
          if (!info.industryVerticals || !Array.isArray(info.industryVerticals)) {
            // console.warn('formatData: industryVerticals is not an array:', info.industryVerticals);
            return '--';
          }
          return info.industryVerticals
            .map((vertical: any) => vertical.name)
            .join(", ");
        })
        .join("; ");
    } else {
      if (!value.industryVerticals || !Array.isArray(value.industryVerticals)) {
        // console.warn('formatData: industryVerticals is not an array:', value.industryVerticals);
        return '--';
      }
      return value.industryVerticals
        .map((vertical: any) => vertical.name)
        .join(" | ");
    }
  }

  formatTeckStack(techStackUsed: any) {
    if (!techStackUsed) {
      return '--';
    }
    return techStackUsed?.map((res: any) => res.title)
      .join(" | ");
  }

  onNeedMoreDetail(event: any) {
    if (event) {
      this.isNeedMoreDetail = false;
      this.isNeedMoreFormDisplay = false;
    } else {
      this.isNeedMoreDetail = true;
      this.isNeedMoreFormDisplay = true;
      setTimeout(() => this.detailFormElement.nativeElement.scrollIntoView({behavior: 'smooth'}), 300);
    }
  }

  goToAttachment(url: string) {
    window.open(url, " ");
  }

  onSubmit() {
    const payload = {
      notes: this.needMoreDetailForm.value.notes,
      tentativeStartTime: this.needMoreDetailForm.value.tentativeStartTime
    };

    this.apiService.sendRequestDetailPage(this.solutionId, payload).subscribe((res) => {
      this.toastr.success('Request Sent Successfully', 'Success');
      this.needMoreDetailForm.setValue({
        ...this.needMoreDetailForm.value,
        notes: '',
        tentativeStartTime: ''
      });

    });
  }

}
