import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs/internal/Observable";
import {
  ComponentName, customerContact,
  DocsType,
  DocType,
  FormUpdateEvent, Members,
  userDT
} from "../../../../shared/interfaces";
import {ApiService} from "../../../../services/api.service";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {debounceTime, distinctUntilChanged, filter, map, startWith, tap} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {EditCustomerContactComponent} from "./edit-customer-contact/edit-customer-contact.component";
import {DataService} from "../../../../services/data.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {MatPaginator} from "@angular/material/paginator";
import {fromEvent} from "rxjs";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {IsDataUpdatedForModalsService} from "../../../../services/isDataUpdatedForModals.service";
import {PopUpComponent} from "../../../../util/pop-up/pop-up.component";

@Component({
  selector: 'app-customer-contacts',
  templateUrl: './customer-contacts.component.html',
  styleUrls: ['./customer-contacts.component.css']
})
export class CustomerContactsComponent implements OnInit, AfterViewInit {
  emailStatus: boolean = false
  customerContactForm: FormGroup;
  showloader = false;
  showloader1 = false;
  filteredDesignation: Observable<DocsType[]>;
  desControl = new FormControl('');
  filteredVertical: Observable<DocsType[]>;
  verticalControl = new FormControl('');
  spocControl = new FormControl('');
  desTypeName = '';
  verticalName: '';
  contactError = '';
  customerContactStatus: boolean;
  @ViewChild('designationAutoCompleter', {read: MatAutocompleteTrigger})
  designationAutoCompleter: MatAutocompleteTrigger;
  @ViewChild('verticalAutoCompleter', {read: MatAutocompleteTrigger})
  verticalAutoCompleter: MatAutocompleteTrigger;
  @ViewChild('SPOCAutoCompleter', {read: MatAutocompleteTrigger})
  SPOCAutoCompleter: MatAutocompleteTrigger;
  designationId: '';
  verticalId: '';
  desEditTypeName = '';
  verticalEditName = '';
  designationRes: DocType[] = [];
  verticalList: any[] = [];
  customerContacts: customerContact[] = [];
  SPOC_Options: Members[] = [];
  selectedSpcId: string;
  errorMessage = '';
  showArchived: boolean = false;
  displayError: boolean = false;
  totalPages = 0;
  currentPage: number = 0;
  totalElements: 0
  pageSize = 5;
  @Input() customerData: any;
  previousFormValue: any;
  initializingForm: boolean = true;
  @Output() formUpdated = new EventEmitter<FormUpdateEvent>();
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input', {static: true}) input: ElementRef | any;
  filteredCustomerContacts: Observable<customerContact[]>;
  searchControl = new FormControl('');
  filteredSpoc: Observable<Members[]>;
  spoc: string[];

  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    private toastr: ToastrService,
    private dataService: DataService,
    public snackBar: MatSnackBar,
    public dialog: NgDialogAnimationService,
    private isCustomerDataUpdated: IsDataUpdatedService,
    private IsDataUpdatedForModal:IsDataUpdatedForModalsService
  ) {
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.getSPOC();
    this.getDesignation();
    this.getContacts(this.currentPage, this.pageSize, this.showArchived);
    this.getVerticalList();
    this.filteredSpoc = this.spocControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterSPOC(value))
    );
    this.customerContactForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: [""],
      email: ["", [Validators.required, Validators.pattern("[a-zA-Z0-9._%+-]+@[a-z0-9A-Z.-]+.[a-z]{2,}$")]],
      mobile: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      designation: [''],
      linkedIn: [''],
      vertical: [''],
      spoc: [''],
      spocId: ['',Validators.required],
      active: [''],
      sendEmail: ['']
    });
    this.filteredCustomerContacts = this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      map(value => this.filterContacts(value))
    );
    this.previousFormValue = this.customerContactForm.value;
    this.initializingForm = false;

    this.customerContactForm.valueChanges.pipe(
      debounceTime(500),
      filter(() => !this.initializingForm),
      map(currentValue => JSON.stringify(currentValue) !== JSON.stringify(this.previousFormValue)),
      distinctUntilChanged()
    ).subscribe(isFormUpdated => {
      this.formUpdated.emit({updated: isFormUpdated, componentName: ComponentName.CustomerContacts});
    });
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(
      tap(() => {
        this.getContacts(this.paginator.pageIndex, this.paginator.pageSize, this.showArchived);
      })
    ).subscribe();
    if (this.input) {
      fromEvent(this.input?.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => this.getContacts(this.paginator.pageIndex, this.paginator.pageSize, this.showArchived)),
        )
        .subscribe();
    }
  }
  scrollEvent = (event: any): void => {
    if (this.designationAutoCompleter?.panelOpen) {
      this.designationAutoCompleter.updatePosition();
    } else if (this.verticalAutoCompleter?.panelOpen) {
      this.verticalAutoCompleter.updatePosition();
    } else if (this.SPOCAutoCompleter?.panelOpen) {
      this.SPOCAutoCompleter.updatePosition();
    }
  };
  filterContacts(value: string): customerContact[] {
    const filterValue = value.toLowerCase();
    return this.customerContacts.filter(contact =>
      contact.firstName.toLowerCase().includes(filterValue) ||
      contact.lastName.toLowerCase().includes(filterValue) ||
      contact.email.toLowerCase().includes(filterValue) ||
      contact.mobile.includes(filterValue)
    );
  }
  getContacts(page: number, size: number, archived: boolean): void {
    this.service.getCustomerContacts(this.customerData.id, page, size, archived).subscribe(res => {
      this.customerContacts = res.content;
      this.customerContactForm.reset();
      this.totalPages = res.totalPages;
      this.currentPage = page
      this.paginator.pageIndex = res.number;
      this.paginator.pageSize = res.size;
      this.pageSize = res.size;
      this.totalElements = res.totalElements;
    });
  }

  onKeyPress(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const numericValue = inputValue.replace(/\D/g, '');
    if (numericValue.length === 10) {
      event.preventDefault();
      this.displayError = false;
      this.errorMessage = "";
    } else {
      this.displayError = true;
      this.errorMessage = "Please enter a valid 10-digit mobile number";
    }
    inputElement.value = numericValue;
  }

  addContact() {
    this.contactError = '';
    this.showloader = true;
    if (this.customerContactForm.invalid) {
      this.showloader = false;
      this.contactError = 'please check the fields ' + this.contactError , this.getFormValidationErrors() + " ";
      return;
    }
    const formValue = {
      ...this.customerContactForm.value,
      emailStatus: this.emailStatus
    };
    this.service.createCustomerContact(formValue, this.customerData.id).subscribe(
      (res) => {
        this.isCustomerDataUpdated.setUpdated(true);
        this.showloader = false;
        this.getContacts(this.currentPage, this.pageSize, this.showArchived);
        this.toastr.success('Customer contact save successfully.', 'Success')
        this.desControl.reset();
        this.verticalControl.reset();
        this.spocControl.reset();
        this.customerContactForm.reset();
        this.errorMessage = '';
        this.formUpdated.emit({updated: false, componentName: ComponentName.CustomerContacts});
      }, e => {
        this.showloader = false;
        if (e.error.code == 4000) {
          this.toastr.error('Sorry! Customer contact with this email already exists.', 'Error');
          // this.customerContactForm.reset();
          // this.desControl.reset();
          // this.verticalControl.reset();
        }
      });
  }

  editContact(contact: any) {
    const consultEditDialogRef = this.dialog.open(EditCustomerContactComponent, {
      data: {
        customerContact: contact
      },
      width: '50%',
      height: '60%'
    });
    consultEditDialogRef.afterClosed().subscribe(() => {
      if (this.IsDataUpdatedForModal.getBoolean()) {
        this.getContacts(this.currentPage, this.pageSize, this.showArchived);
        this.IsDataUpdatedForModal.setUpdated(false);
      }
    });
  }
  confirmationSendEmail(contactId: number) {
    const dialogRef2 = this.dialog.open(PopUpComponent, {
      data: {
        title: 'Alert',
        message: 'Are you sure you want to Send Email ?',
        type: 'confirmation'
      }
    });

    dialogRef2.afterClosed().subscribe((res) => {
      if (res) {
        this.showloader1=true
        this.service.sendEmail(contactId).subscribe({
          next: (res) => {
            this.getContacts(this.currentPage, this.pageSize, this.showArchived);
            this.toastr.success('Email sent successfully', 'Success');
            this.showloader1=false
          },
          error: (err) => {
            console.error('Error sending email', err);
            this.showloader1=false
            this.toastr.error('Error while sending email', 'Error');
          }
        });
      }
    });
  }


  getFormValidationErrors(): string {
    let error = '';
    Object.keys(this.customerContactForm.controls).forEach(key => {
      const controlErrors = this.customerContactForm.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          error = error + key + ', ';
        });
      }
    });
    console.error(error);
    return error;
  }

  chooseDesType(data: any, group?: any) {
    if (data.isUserInput && !group) {
      this.designationId = data?.source?.value?.id;
      this.desTypeName = data.source.value?.name;
      this.customerContactForm.get('designation')?.setValue(data.source.value.name);
    } else if (data.isUserInput && group) {
      this.designationId = data?.source?.value?.id;
      this.desEditTypeName = data.source.value?.name;
      group.get('designation')?.setValue(data.source.value.name);
    }
  }

  chooseVerticalType(data: any, group?: any) {
    if (data.isUserInput && !group) {
      this.verticalId = data?.source?.value?.id;
      this.verticalName = data.source.value?.name;
      this.customerContactForm.get('vertical')?.setValue(data.source.value.name);
    } else if (data.isUserInput && group) {
      this.verticalId = data?.source?.value?.id;
      this.verticalEditName = data.source.value?.name;
      group.get('vertical')?.setValue(data.source.value.name);
    }
  }

  displayDocFn(doc: DocsType): string {
    return doc && doc.name ? doc.name : '';
  }
  goToLink(url: string) {
    window.open(url, " ");
  }
  addDesType($event: MouseEvent) {
    if (this.desTypeName) {
      let payload = {
        'name': this.desTypeName,
      }
      this.service.addOrgDesType(payload).then(res => {
        this.isCustomerDataUpdated.setUpdated(true);
        this.getDesignation();
        this.desTypeName = res.name;
        this.customerContactForm.get('designation')?.setValue(res.name);
        this.displayDocFn(res);
      }, (e) => {
        console.error('error', e.error.message);
      })
    } else if (this.desEditTypeName) {
      let payload = {
        'name': this.desEditTypeName,
      }
      this.service.addOrgDesType(payload).then(res => {
        this.isCustomerDataUpdated.setUpdated(true);
        this.getDesignation();
        this.desEditTypeName = res.name;
        this.customerContactForm.get('designation')?.setValue(res.name);
        this.displayDocFn(res);
      }, (e) => {
        console.error('error', e.error.message);
      })
    }
  }

  getVerticalList() {
    this.service.getVerticalListForCustomerContacts().subscribe({
      next: response => {
        // this.verticalList = response;
        this.verticalList = [];
        if (response.length > 0) {
          response.map((e: any) => {
            if (e.active) {
              this.verticalList.push(e);
            }
          });
        }
        this.filteredVertical = this.verticalControl.valueChanges.pipe(
          startWith(''),
          map(value => {
              const name = typeof value === 'string' ? value : value?.name ?? "";
              return name ? this._verticalFilter(name as string) : this.verticalList.slice();
            }
          ),
        )
      }
    })
  }

  _verticalFilter(name: string): DocsType[] {
    const filterValue = name.toLowerCase();
    return this.verticalList.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  getDesignation(): void {
    this.service.getDesignation().then(resp => {
      resp.map((e: DocType) => {
        if (e.active == true) {
          this.designationRes.push(e);
        }
      });
      this.filteredDesignation = this.desControl.valueChanges.pipe(
        startWith(''),
        map(value => {
            const name = typeof value === 'string' ? value : value?.name ?? "";
            return name ? this._desFilter(name as string) : this.designationRes.slice();
          }
        ),
      )
    });
  }

  _desFilter(name: string): DocsType[] {
    const filterValue = name.toLowerCase();
    return this.designationRes.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  addVertical($event: MouseEvent) {
    if (this.verticalName) {
      let payload = {
        'name': this.verticalName,
      }
      this.service.addOrgVertical(payload).then(res => {
        this.isCustomerDataUpdated.setUpdated(true);
        this.getVerticalList();
        this.verticalName = res.name;
        this.customerContactForm.get('vertical')?.setValue(res.name);
        this.displayDocFn(res);
      }, (e) => {
        console.error('error', e.error.message);
      })
    } else if (this.verticalEditName) {
      let payload = {
        'name': this.verticalEditName,
      }
      this.service.addOrgVertical(payload).then(res => {
        this.isCustomerDataUpdated.setUpdated(true);
        this.getVerticalList();
        this.verticalEditName = res.name;
        this.customerContactForm.get('vertical')?.setValue(res.name);
        this.displayDocFn(res);
      }, (e) => {
        console.error('error', e.error.message);
      })
    }
  }

  toggleContactStatus(event: any, contactId: number) {
    if (event.checked) {
      this.service.enableCustomerContact(contactId).subscribe({
        next: res => {
          this.isCustomerDataUpdated.setUpdated(true);
          this.customerContactStatus = res.active;
          this.currentPage = 0
          this.toastr.success('Customer successfully activated', 'Success');
          this.getContacts(this.currentPage, this.pageSize, this.showArchived)
        }
      })
    } else {
      this.service.disableCustomerContact(contactId).subscribe({
        next: res => {
          this.isCustomerDataUpdated.setUpdated(true);
          this.customerContactStatus = res.active;
          this.currentPage = 0
          this.toastr.success('Customer successfully deactivated', 'Success');
          this.getContacts(this.currentPage, this.pageSize, this.showArchived)
        }
      })
    }
  }

  getSPOC() {
    this.service.getSpocList().subscribe((res: any) => {
      this.SPOC_Options = res.filter((active:any)=> active.active);
      this.filteredSpoc = this.spocControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterSPOC(value))
      );
    });
  }

  formatSPOC(option: Members): string {
    if (!option) {
      return '';
    }
    return option.role + ' | ' + option.firstName + ' ' + option.lastName;
  }

  private _filterSPOC(value: string): Members[] {
    console.log('rtethgyt',value)
    if (typeof value !== 'string') {
      return [];
    }
    const filterValue = value.toLowerCase();
    return this.SPOC_Options.filter(option =>
        (option?.firstName && option.firstName.toLowerCase().includes(filterValue)) ||
        (option?.lastName && option.lastName.toLowerCase().includes(filterValue)) ||
        (option?.role && option.role.toLowerCase().includes(filterValue))
    );
  }


  chooseSpoc(event: any) {
    console.log('event',event.option.value)
    this.selectedSpcId = event?.option?.value?.userId
    this.customerContactForm.get('spocId')?.setValue(event?.option?.value?.userId)
  }

  changeArchived(event: any) {
    this.showArchived = event.checked;
    this.getContacts(this.currentPage, this.pageSize, this.showArchived)
  }

  change(e: any) {
    if (e.checked) {
      this.emailStatus = true;
    } else {
      this.emailStatus = false;
    }
  }

}


