<div class="modal-popUp-content content-wrapper-main-inner-content job_wrapper overflow-hidden">
  <div class="member_wrapper theme-bg-color">
    <div class="row d-flex align-items-center">
      <div class="col-6">
        <h5>{{ dialogtitle }}</h5>
      </div>
      <div class="col-md-6 d-flex justify-content-end">
        <div class="cross_icon" (click)="close()">
          <a>
            <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.2872 1L1 17.2871" stroke="white" stroke-width="2" stroke-linecap="round"/>
              <path d="M1 1L17.2872 17.2871" stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="mx-4 my-4">
    <div>
      <p>{{ dialogMessage }}</p>
      <div class="form-group ">
        <mat-form-field appearance="outline" class="w-100 border-red">
          <mat-label>Add comment</mat-label>
          <input matInput [(ngModel)]="onHoldCommentText">
        </mat-form-field>
      </div>
    </div>
    <div class="pt-5 mt-2 justify-content-end d-flex">
      <button mat-button (click)="close()" class="me-4 themeSecondary-bg-color">Cancel</button>
      <button mat-button class="confirm-btn-style theme-bg-color text-white" (click)="confirmButton()">Change Confirm</button>
    </div>
  </div>
</div>

