<div class="modal_wrapper">
  <mat-dialog-content>
    <div class="modal-inner-content">
      <p class="title">{{data.title}}</p>
      <!--    <p class="content">Are you sure you want to In-Active this vendor ?</p>-->
      <p class="content">{{data.message}}  ?</p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-button mat-dialog-close>No</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
  </mat-dialog-actions>
</div>
