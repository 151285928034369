<section class="login__wrapper">
  <div class="container">
    <div class="row login_box shadow">
      <div class="col-lg-7 col-md-12 bg-img">
        <div class="informeson">
          <img src="../../assets/img_2.png"/>
        </div>
      </div>
      <div class="col-lg-5 col-md-12 form-section">
        <div *ngIf="visible1">
          <form [formGroup]="loginform" (submit)="login()" class="form-horizontal form-material" id="loginform">
            <h4 class="heading_1 text-center fw-light">Welcome to,</h4>
            <h4 class="heading_2 text-center mb-5 fw-bold">Vendor Management Portal</h4>
<!--            <h5 class="heading_1 text-center fw-light">Login as Admin</h5>-->
            <div class="text-fields">
              <div class="form-group mb-4">
                <span>User name</span>
                <input formControlName="username" type="text" name="username" placeholder="username@mailid.com" required>
              </div>
              <div class="form-group mb-4">
                <span>Password</span>
                <div class="d-flex align-items-center pass_input">
                  <input #loginPasswordInput [(ngModel)]="password" formControlName="password" type="password" name="password" placeholder="********" required>
                  <i #faIconLoginPasswordInputButton (click)="togglePassword()" class="fas fa-eye"></i>
                </div>
              </div>
              <div class="justify-content-center d-flex text-danger">{{errorMessage}}</div>
              <div class="form-group text-center mb-1">
                <div class="col-xs-12 p-b-20">
                  <button *ngIf="!showloader" class="btn w-100 btn-theme" type="submit">Login</button>
                  <div *ngIf="showloader" disabled>
                    <button id="submitButton1" class="btn w-100 btn-theme" disabled>
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <a [routerLink]="getForgotPassword()">Forgot Password?</a>
              </div>
            </div>
          </form>
          <div class="d-flex justify-content-center">
<!--            <a [routerLink]="'../client/login'">Login As Client</a>-->
          </div>
        </div>
        <div class="bottom-text position-absolute">
          <span>Powered by <b>Linkage IT</b><sup>TM</sup> </span>
        </div>
      </div>
    </div>
  </div>
</section>
