import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {DataService} from "./data.service";

@Injectable({
  providedIn: 'root'
})
export class CustomInterceptorService implements HttpInterceptor {

  constructor(private router: Router,private data: DataService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (localStorage.getItem('access_token')) {
      if (request.url.match('token')) {
        return next.handle(request);
      }
    let orgId =  localStorage.getItem('orgId');
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          'org-id': orgId? orgId.toString() : ''
        }
      });
      return next.handle(request).pipe(tap(() => {
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }
            window.location.reload();
            localStorage.clear();
            this.router.navigate(['/login']);
          }
        }),
        finalize(
          () => {
            this.data.isLoading.next(false);
          }
        )
      );
    } else {
      return next.handle(request).pipe(
        finalize(
          () => {
            this.data.isLoading.next(false);
          }
        ));
    }
  }
}

