<div>
  <div class="d-flex align-items-center justify-content-between member_wrapper theme-bg-color" mat-dialog-title>
    <div class="fs-5">
      Customer Activity:
    </div>
    <div class="d-flex align-items-center justify-content-end">
      <div (click)="close()" class="btn close-btn">
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.5143 1L1.22705 17.2871" stroke="#fff" stroke-width="2" stroke-linecap="round"/>
          <path d="M1.22705 1L17.5143 17.2871" stroke="#fff" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </div>
    </div>
  </div>
  <div>
    <div>
      <div class="d-flex flex-row col-12">
        <div class="ms-2 me-2 mb-2 col">
          <div class="mb-2">Customer Contact</div>
          <div style="font-size: 14px;color: #5A5A5A;">
            {{ this.customerContact ?? '---' }}
          </div>
        </div>
        <div class="ms-2 me-2 mb-2 col">
          <div class="mb-2">Phone</div>
          <div style="font-size: 14px;color: #5A5A5A;">
            {{ this?.customerContactPhone ?? '---' }}
          </div>
        </div>
        <div class="ms-2 mb-2 col">
          <div class="mb-2">Email ID</div>
          <div style="font-size: 14px;color: #5A5A5A;">
            {{ this?.customerContactEmail ?? '---' }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr/>

  <div class="d-flex flex-row col-12" *ngIf="true">
    <div class="ms-2 me-2 mb-2 col border_right">
      <div class="mb-2">Search String Used</div>
      <div class="d-flex flex-row flex-wrap" *ngIf="searchStringsUsed.length > 0"
           style="font-size: 14px;color: #5A5A5A;">
        <div *ngFor="let searchString of searchStringsUsed; let i = index; last as isLast">
          <span>{{ searchString }}<span class="me-2" *ngIf="!isLast">, </span></span>
        </div>
      </div>
      <div class="d-flex flex-row flex-wrap" *ngIf="searchStringsUsed.length === 0">
        <div>
          <span>{{ '---' }}</span>
        </div>
      </div>
    </div>

    <div class="ms-2 me-2 mb-2 col">
      <div class="mb-2">Consultant ID Viewed:</div>
      <div class="d-flex flex-row flex-wrap" *ngIf="consultantIdViewed.length > 0"
           style="width:50%;font-size: 14px;color: #5A5A5A;">
        <div *ngFor="let consultantId of consultantIdViewed; let i = index; last as isLast">
          <span>{{ consultantId }}<span class="me-2" *ngIf="!isLast">, </span></span>
        </div>
      </div>
      <div class="d-flex flex-row flex-wrap" *ngIf="consultantIdViewed.length === 0">
        <div>
          <span>{{ '---' }}</span>
        </div>
      </div>
    </div>

    <!--    <div class="ms-2 mb-2 col">-->
    <!--      <div class="mb-2">Solutions ID accessed:</div>-->
    <!--      <div *ngFor="let solutionId of solutionsIdAccessed">-->
    <!--        {{solutionId?.solutionId ?? '-&#45;&#45;'}}-->
    <!--      </div>-->
    <!--    </div>-->

  </div>
  <div *ngIf="false" class="d-flex align-items-center justify-content-center mt-3">
    No Data Available
  </div>
</div>


