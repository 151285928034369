import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import * as moment from "moment/moment";
import {DeletePopupComponent} from "../../../delete-popup/delete-popup.component";
import {ApiService} from "../../../../services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ToastrService} from "ngx-toastr";
import {DataService} from "../../../../services/data.service";
import {ComponentName, FormUpdateEvent} from "../../../../shared/interfaces";
import {debounceTime, distinctUntilChanged, filter, map} from "rxjs/operators";
import {MatPaginator} from "@angular/material/paginator";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";

@Component({
  selector: 'app-vendor-communications',
  templateUrl: './vendor-communications.component.html',
  styleUrls: ['./vendor-communications.component.css']
})
export class VendorCommunicationsComponent implements OnInit {
  notesForm: FormGroup;
  showloader = false;
  notes: any[] = [];
  role: string | null;
  currentPage: number = 0;
  totalPages: number;
  pageSize = 5;
  notesError = '';
  length: any;
  editNotesForm: FormGroup;

  @Input() vendorData: any;
  @Input() openDialogType: string;
  totalNotes=0;

  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input',{static:true}) input:ElementRef | any;

  previousFormValue: any;
  initializingForm : boolean = true;
  @Output() formUpdated = new EventEmitter<FormUpdateEvent>();

  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    public dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar,
    private toastr: ToastrService,
    private dataService: DataService,
    private isVendorsDataUpdated: IsDataUpdatedService,
  ) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    this.getVendorNotes(this.vendorData?.id, this.currentPage,this.pageSize);

    this.notesForm = this.fb.group({
      description: ["", Validators.required]
    });

    this.editNotesForm = this.fb.group({
      noteRows: this.fb.array([])
    });

    this.previousFormValue = this.notesForm.value;
    this.initializingForm = false;

    this.notesForm.valueChanges.pipe(
      debounceTime(500),
      filter(() => !this.initializingForm),
      map(currentValue => JSON.stringify(currentValue) !== JSON.stringify(this.previousFormValue)),
      distinctUntilChanged()
    ).subscribe(isFormUpdated => {
      this.formUpdated.emit({ updated: isFormUpdated, componentName: ComponentName.VendorCommunications });
    });

  }
  handlePageNotes(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getVendorNotes(this.vendorData?.id, this.currentPage,this.pageSize);  }

  getVendorNotes(id: string, page: number,size:number): void {
    this.service.getVendorNotes(id, page,size).then(resp => {
      const control = <FormArray>this.editNotesForm.get('noteRows');
      control.clear();
      this.notes = resp.content;
      this.totalPages = resp.totalPages;
      this.length = resp.totalElements;
      this.currentPage = page;
      this.totalNotes=resp.totalElements
      this.paginator.pageIndex=resp.number;
      this.paginator.pageSize=resp.size;
      this.pageSize=resp.size;
      this.currentPage=resp.number;
      this.notes.forEach(res => {
        const form = this.fb.group({
          id: [res.id],
          description: [res.description],
          descriptionHtml: [res.descriptionHtml],
          createdAt: [res.createdAt],
          createdBy: [res.createdBy],
          isEditable: false,
        });
        control.push(form);
      })

    })
  }
  get getNotesFormControls() {
    return this.editNotesForm.get('noteRows') as FormArray;
  }

  addNotes(): void {
    this.notesError = '';
    this.showloader = true;

    // @ts-ignore
    const descriptionValue = this.notesForm.get('description').value;

    if (descriptionValue.invalid || !descriptionValue.trim()) {
      this.showloader = false;
      this.toastr.info('Description cannot be empty or contain only whitespace.', 'Error');

      return;
    }
    if (this.notesForm.invalid) {
      this.showloader = false;
      this.notesError = 'error in' + this.getFormValidationErrors() + 'see console fro details';
      return;
    } else {
      this.service.uploadNotes(this.notesForm.value, this.vendorData.id).then(res => {
        this.isVendorsDataUpdated.setUpdated(true);
        this.showloader = false;
        this.getVendorNotes(this.vendorData?.id, this.currentPage,this.pageSize);        this.notesForm.reset();
        this.formUpdated.emit({ updated: false, componentName: ComponentName.VendorCommunications });


        if (this.notes.length <= 0) {
          this.getVendorNotes(this.vendorData?.id, this.currentPage,this.pageSize);        } else {
          const control = <FormArray>this.editNotesForm.get('noteRows');
          const form = this.fb.group({
            id: [res.id],
            description: [res.description],
            descriptionHtml: [res.descriptionHtml],
            createdAt: [res.createdAt],
            createdBy: [res.createdBy],
            isEditable: false,
          });
          control.push(form);
        }
      }, (e) => {
        this.showloader = false;
        if (e.error.status == 400) {
          // this.dialogRef.disableClose;
          this.notesError = 'Select a file ';
        } else if (e.error.status == 500) {
          // this.dialogRef.disableClose;
          this.notesError = e.error.message;
        }else {
          this.notesError = 'An error occurred while uploading the notes.';
        }
      });
      // this.notesForm.reset();
    }
  }
  getFormValidationErrors(): string {
    let error = '';
    Object.keys(this.notesForm.controls).forEach(key => {
      const controlErrors = this.notesForm.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          // console.log(' Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          error = error + key + ', ';
        });
      }
    });
    return error;
  }


  updateNotes(group: any) {
    const noteId = group.get('id').value;
    const formData = {
      description: group.get('description').value
    }
    this.service.updateVendorNotes(formData, this.vendorData.id, noteId).then(res => {
      this.isVendorsDataUpdated.setUpdated(true);
      group.get('isEditable').setValue(false);

    }).catch((error: any) => {
      console.log('error', error)
      if (error.status == 400) {
        this.openSnackBar("Note can't be updated after 24 hours", "ok");
      }

    })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  checkForShowMore(id: string): boolean {
    let length: number | undefined = 0;
    length = document.getElementById(id)?.innerText.length;
    return !!(length && length > 450);

  }

  toggleShow(e: any) {
    if (e.target.parentElement.className === 'lessContent') {
      e.target.parentElement.className = 'moreContent';
      e.target.text = 'Show less';
    } else {
      e.target.parentElement.className = 'lessContent';
      e.target.text = 'Show more';
    }
  }

  hideWidget(creationTime: any) {
    let date = moment(creationTime).diff(new Date(), "days");
    return date === 0;
  }

  editNotes(group: any) {
    group.get('isEditable').setValue(true);
  }

  deleteNotes(noteId: any): void {
    console.log("Note id is" + noteId);
    this.dialog.open(DeletePopupComponent,{
      data: {
        message: 'Are you sure you want to delete this Note ?',
        type: 'Delete'
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.service.deleteVendorNotes(this.vendorData.id, noteId).then(() => {
          this.isVendorsDataUpdated.setUpdated(true);
          this.getVendorNotes(this.vendorData?.id, 0,this.pageSize);
          this.toastr.success('Note Deleted', 'Success', {
            positionClass: "toast-top-right"
          });
        }).catch((err)=>{
          this.toastr.error(err.error.reason, 'Error', {
            positionClass: "toast-top-right"
          });
        });
      } else {
      }
    })

  }
}
