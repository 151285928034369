import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsDataUpdatedService {
  private isDataUpdated = new BehaviorSubject<boolean>(false);
  isUpdated = this.isDataUpdated.asObservable();
  constructor() {}

  setUpdated(value: boolean) {
    this.isDataUpdated.next(value);
  }

  getBoolean(): boolean {
    return this.isDataUpdated.value;
  }

}
