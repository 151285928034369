<div (click)="showTextarea($event)">
  <mat-form-field *ngIf="!isTextareaVisible" appearance="outline" class="w-100">
    <mat-label>{{ label }}</mat-label>
    <input matInput [formControl]="formControl" [placeholder]="placeholder">
  </mat-form-field>
</div>

<div>
  <mat-form-field *ngIf="isTextareaVisible" appearance="outline" class="w-100">
    <textarea #textarea matInput [formControl]="formControl" (blur)="hideTextarea()" [placeholder]="placeholder"
              [rows]="6"></textarea>
  </mat-form-field>
</div>
