<div class="dropdown-wrapper shadow">
    <div class="dropdown-skills position-relative">
        <ul>
            <li (click)="chooseRecord(record)" *ngFor="let record of records">
                <span *ngIf="type==='place'">
                    {{record.name}}
                </span>
                <span *ngIf="type==='none'">
                    {{ record.title}}
                </span>
                <span *ngIf="type==='locations'">
                    {{record.name}}
                </span>
                <span *ngIf="type==='skills'">
                    {{record.title}}
                </span>
            </li>
        </ul>
    </div>
</div>
