import { Component, Inject, OnInit } from '@angular/core';
import { commentList } from "../../../../shared/interfaces";
import { ApiService } from "../../../../services/api.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";

@Component({
  selector: 'app-client-communication',
  templateUrl: './client-communication.component.html',
  styleUrls: ['./client-communication.component.css']
})

export class ClientCommunicationComponent implements OnInit {
  comment: string = '';
  getComment: string = '';
  commentsList: commentList[];
  editingCommentId: string | null = null;
  editableStatus: { [id: string]: boolean } = {};
  loginUserId: string ='';
  constructor(
    private service: ApiService,
    @Inject(MAT_DIALOG_DATA) public selectedMyTask: any,
    private toastr: ToastrService,
    private isMyTaskDataUpdated:IsDataUpdatedService
  ) {
  }

  ngOnInit(): void {
    this.loginUserId = localStorage.getItem('loginUserId') || '';
    this.getComments();
    setInterval(() => {
      this.updateEditableStatus();
    }, 60000);
  }

  getComments() {
    this.service.getCommentsForTasks(this.selectedMyTask.id, 'EXTERNAL').subscribe((res) => {
      this.commentsList = res.map((comment: commentList) => {
        return { ...comment, isEditable: true };
      });
      this.commentsList.forEach(comment => {
        this.editableStatus[comment.id] = this.isEditable(comment.createdDate);
      });
    });
  }

  addComment(type: String) {
    if (type == 'EXTERNAL') {
      if (this.comment.trim() != '') {
        this.service.addCommentInTask(this.comment, 'EXTERNAL', this.selectedMyTask.id).then((res) => {
          this.isMyTaskDataUpdated.setUpdated(true);
          this.comment = '';
          this.getComments();
        });
      } else {
        this.toastr.info('Please add Comment ', 'Info');
      }
    }
  }

  deleteComment(commentId: string) {
    this.service.deleteCommentForAdminClient(Number(commentId)).subscribe((res) => {
      this.isMyTaskDataUpdated.setUpdated(true);
      this.toastr.success('Comment Deleted Successfully', 'Delete');
      this.getComments();
    })
  }

  isEditMode(commentId: string): boolean {
    return this.editingCommentId === commentId;
  }

  editComment(commentId: string) {
    this.editingCommentId = commentId;
    this.service.getSingleComment(Number(commentId)).subscribe((res) => {
      this.getComment = res.comment
    })
  }

  saveEdit(comment: commentList) {
    const originalComment = this.commentsList.find(c => c.id === comment.id);
    if (originalComment && originalComment.comment !== this.getComment) {
      const payload = {
        comment: this.getComment,
        communicationType: "EXTERNAL"
      };
      this.service.editCommentForAdminClient(Number(comment.id), payload).subscribe((res) => {
        this.isMyTaskDataUpdated.setUpdated(true);
        this.editingCommentId = null;
        this.toastr.success('Comment Updated Successfully', 'Update')
        this.getComments();
      });
    } else {
      this.closeEdit(comment.id);
    }
  }

  closeEdit(commentId: string) {
    this.editingCommentId = null;
  }

  isEditable(createdDate: any): boolean {
    const commentDate = new Date(createdDate);
    const now = new Date();
    const elapsedTime = now.getTime() - commentDate.getTime();
    const editableWindow = 5 * 60 * 1000; // 5 minutes in milliseconds
    return elapsedTime < editableWindow;
  }

  updateEditableStatus() {
    const now = new Date();
    this.commentsList.forEach(comment => {
      const commentDate = new Date(comment.createdDate);
      const elapsedTime = now.getTime() - commentDate.getTime();
      const editableWindow = 5 * 60 * 1000; // 5 minutes in milliseconds
      this.editableStatus[comment.id] = elapsedTime < editableWindow;
    });
  }

  protected readonly Number = Number;
}
