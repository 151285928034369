import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../../../services/api.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {values} from "lodash";

@Component({
  selector: 'app-1',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  responseSent: false;
  changePasswordForm: FormGroup;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;

  @ViewChild('oldPasswordInput') oldPasswordInput: ElementRef<HTMLInputElement>;
  @ViewChild('newPasswordInput') newPasswordInput: ElementRef<HTMLInputElement>;
  @ViewChild('confirmNewPasswordInput') confirmNewPasswordInput: ElementRef<HTMLInputElement>;
  @ViewChild('faIconOldPasswordInputButton') faIconOldPasswordInputButton: ElementRef<HTMLElement>;
  @ViewChild('faIconNewPasswordInputButton') faIconNewPasswordInputButton: ElementRef<HTMLElement>;
  @ViewChild('faIconConfNewPasswordInputButton') faIconConfNewPasswordInputButton: ElementRef<HTMLElement>;

  constructor(private route: ActivatedRoute,
              private service: ApiService,
              private router: Router,
              private fb: FormBuilder,) { }

  showloader: boolean;
  errorMessage: string;

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      oldPassword: ["",[Validators.required]],
      newPassword: ["",[Validators.required, Validators.minLength(8),Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      confirmPassword: "",
    })
  }

  togglePassword(): void {
    if (this.oldPasswordInput.nativeElement.type == 'text'){
      this.oldPasswordInput.nativeElement.type = 'password';
      this.faIconOldPasswordInputButton.nativeElement.className ='fas fa-eye eye_icon';
    } else {
      this.faIconOldPasswordInputButton.nativeElement.className = 'fas fa-eye-slash eye_icon';
      this.oldPasswordInput.nativeElement.type = 'text';
    }
  }
  toggleNewPassword(): void {
    if(this.newPasswordInput.nativeElement.type == 'text') {
      this.newPasswordInput.nativeElement.type = 'password';
      this.faIconNewPasswordInputButton.nativeElement.className ='fas fa-eye eye_icon';
    }
    else {
      this.newPasswordInput.nativeElement.type = 'text';
      this.faIconNewPasswordInputButton.nativeElement.className ='fas fa-eye-slash eye_icon';
    }
  }

  toggleConfNewPassword(): void {
    if(this.confirmNewPasswordInput.nativeElement.type == 'text') {
      this.confirmNewPasswordInput.nativeElement.type = 'password';
      this.faIconConfNewPasswordInputButton.nativeElement.className ='fas fa-eye eye_icon';
    }
    else {
      this.confirmNewPasswordInput.nativeElement.type = 'text';
      this.faIconConfNewPasswordInputButton.nativeElement.className ='fas fa-eye-slash eye_icon';
    }
  }
  validatePassword (): void {
    this.showloader = true;
    this.oldPassword = this.changePasswordForm.get("oldPassword")?.value;
    this.newPassword = this.changePasswordForm.get("newPassword")?.value;
    this.confirmPassword = this.changePasswordForm.get("confirmPassword")?.value;
    if(this.oldPassword !== this.newPassword && this.newPassword == this.confirmPassword) {
      this.service.changePassword(this.changePasswordForm.value).then(() => {
        this.showloader = false;
        this.router.navigate(['/login']);
        this.changePasswordForm.reset(values)
      }, (e) => {
        this.showloader = false;
        if( e.error.code == 4000) {
            this.errorMessage = "Old Password Not Valid";
        }
      });

    } else if(this.newPassword !== this.confirmPassword) {
      this.showloader = false;
      this.errorMessage = "Confirm Password Does not Match";
    }else if(this.newPassword == this.oldPassword) {
      this.showloader = false;
      this.errorMessage = "Your Old Password cannot be your New Password ";
    }
  }

}
