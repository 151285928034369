import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TextEditorService {
  private activeEditorSubject = new BehaviorSubject<any>(null);
  activeEditor$ = this.activeEditorSubject.asObservable();

  setActiveEditor(editorInstance: any): void {
    if (this.activeEditorSubject.value && this.activeEditorSubject.value !== editorInstance) {
      this.activeEditorSubject.value.hideTextarea();
    }
    this.activeEditorSubject.next(editorInstance);
  }
}
