import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-consultant-view-video',
  templateUrl: './consultant-view-video.component.html',
  styleUrls: ['./consultant-view-video.component.css']
})
export class ConsultantViewVideoComponent implements OnInit {
  url: string = '';
  videoId: string = '';
  isYoutube: boolean = false;
  isGoogleDocs: boolean = false;
  apiLoaded:boolean= false;

  constructor(
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public videoUrl: any,
    public dialogRef: MatDialogRef<ConsultantViewVideoComponent>,
  ) {}

  ngOnInit(): void {
    this.url = this.videoUrl?.url?.url ? this.videoUrl?.url?.url :  this.videoUrl?.url;
    this.isYoutube = this.isYoutubeUrl(this.url);
    this.isGoogleDocs = this.isGoogleDocsUrl(this.url);

    if (this.isYoutube) {
      this.videoId = this.extractVideoId(this.url);
    }
    if(!this.apiLoaded) {
      console.log('apiLoaded',);
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }
  }

  close() {
    this.dialogRef.close();
  }

  getEmbedUrl() {
    if (this.isYoutube) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.videoId}`);
    } else if (this.isGoogleDocs) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/file/d/${this.extractGoogleDocsId(this.url)}/preview`);
    } else {
      return '';
    }
  }

  extractVideoId(url: string): string {
    const videoIdPattern = new RegExp('[\\?&]v=([^&#]*)');
    const match = videoIdPattern.exec(url);
    return match? match[1] : '';
  }

  extractGoogleDocsId(url: string): string {
    const googleDocsIdPattern = new RegExp('https?:\/\/drive\.google\.com\/file\/d\/([^\/]*)\/');
    const match = googleDocsIdPattern.exec(url);
    return match? match[1] : '';
  }

  isYoutubeUrl(url: string): boolean {
    return url.includes('youtube.com') || url.includes('youtube');
  }

  isGoogleDocsUrl(url: string): boolean {
    return url.includes('drive.google.com/file/d/');
  }
}
