import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {DataService} from "../../services/data.service";
import {ApiService} from "../../services/api.service";
import {RoleEnum} from "../../client/enums/dataType.enum";
import {SessionMap} from "../../shared/interfaces";
import {Common} from "../../shared/Common";
import {ThemeLoaderService} from "../../services/theme-loader.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  toggle = true;
  isLoading = false;
  initials = '';
  loginType = '';
  username = '';
  userNameLocal = '';
  userrole = '';
  loginUserId: string = '';
  userImage = '';
  @Output() sidebarClass = new EventEmitter<boolean>();

  constructor(private router: Router,
              private service: ApiService,
              public data: DataService,
              private themeLoaderService: ThemeLoaderService
  ) {
  }

  ngOnInit(): void {
    this.data.isLoading.next(true);
    this.data.isLoading.subscribe(res => {
      this.isLoading = res;
    });

    const rootVariable = document.documentElement;
    this.loginType = localStorage.getItem('loginType') ?? '';

    if (this.loginType === 'Vendor') {
      this.service.getLoginUser().subscribe({
        next: res => {
          this.username = res.firstName + " " + res.lastName;
          this.userNameLocal = res.firstName + res.lastName;
          this.loginUserId = res.id;
          this.userrole = res.role;
          this.userImage = res.picture;
          this.initials = this.getInitials(this.username);
          localStorage.setItem('role', res.role);
          localStorage.setItem('username', this.userNameLocal);
          localStorage.setItem('loginUserId', this.loginUserId);
          const primaryColor = res?.theme?.colour ?? '#76ba991a';
          const secondaryColor = Common.rgbaToHex(Common.hexToRGBA(primaryColor, 0.1));
          const ColorObj = {primaryColor, secondaryColor};
          localStorage.setItem('selectedTheme', JSON.stringify(ColorObj));
          rootVariable.style.setProperty('--primaryColor', primaryColor);
          rootVariable.style.setProperty('--secondaryColor', secondaryColor);
          this.themeLoaderService.setTheme(primaryColor, secondaryColor);
        },
        error: err => {
          // Handle error
        },
        complete: () => {
          this.data.isLoading.next(false);
        }
      });
    } else {
      this.service.getCustomerLoginUser().subscribe({
        next: res => {
          localStorage.setItem('name', res.role);
          localStorage.setItem('customerContactId', res.id);
          localStorage.setItem('customerId', res.customer.id);
          localStorage.setItem('role', res.designation);
          localStorage.setItem('role', res.designation);
          this.initials = this.getInitials(res.name);
          this.username = res.firstName;
          this.loginUserId = res.user.id;
          this.userNameLocal = res.name;
          this.userrole = res.designation;
          this.userImage = res.profilePicture;
          const primaryColor = res?.user.theme?.colour ?? '#76ba991a';
          const secondaryColor = Common.rgbaToHex(Common.hexToRGBA(primaryColor, 0.1));
          const ColorObj = {primaryColor, secondaryColor};
          localStorage.setItem('selectedTheme', JSON.stringify(ColorObj));
          rootVariable.style.setProperty('--primaryColor', primaryColor);
          rootVariable.style.setProperty('--secondaryColor', secondaryColor);
          localStorage.setItem('loginUserId', this.loginUserId);
        },
        error: err => {
          // Handle error
        },
        complete: () => {
          this.data.isLoading.next(false);
        }
      });
    }
  }


  getRole(role: string) {
    if (role == 'BSM') {
      return RoleEnum.BSM
    } else if (role == 'BSE') {
      return RoleEnum.BSE
    } else if (role == 'SALES_HEAD') {
      return RoleEnum.SALES_HEAD
    } else {
      return role;
    }
  }


  logout(): void {
    if (localStorage.getItem('loginType') == 'Vendor') {
      this.router.navigate(['admin/login'])
    } else if (localStorage.getItem('loginType') == 'Client') {
      this.logoutClient()
      this.router.navigate(['login'])
    }
    let session = null;
    if (localStorage.getItem('sessions')) {
      let str = localStorage.getItem('sessions');
      if (str) {
        let sessionMaps: [SessionMap] = JSON.parse(str);
        session = sessionMaps;
      }
    }
    localStorage.clear();
    if (session != null) {
      localStorage.setItem('sessions', JSON.stringify(session));
    }
  }

  toggleSidebar(): void {
    const aside = document.getElementById('side-bar');
    const mainContentid = document.getElementById('main-contentid');
    const header = document.getElementById('header');

    if (this.toggle) {
      aside ? aside.className = 'left-sidebar active' : null;
      mainContentid ? mainContentid.className = 'main-content-active' : null;
      header ? header.style.paddingLeft = '100px' : null;
      this.toggle = !this.toggle
      this.sidebarClass.emit(true);
    } else {
      aside ? aside.className = 'left-sidebar' : null;
      mainContentid ? mainContentid.className = 'main-content' : null;
      header ? header.style.paddingLeft = '300px' : null;
      this.toggle = !this.toggle;
      this.sidebarClass.emit(false);
    }
  }

  getInitials(name: string): string {
    let names = name.trim().replace(/_/g, '').split(' '),
      initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  logoutClient() {
    this.service.clientLogout().then(() => {
    }, error => {
      console.log('err', error)
    })
  }

  protected readonly localStorage = localStorage;
}
