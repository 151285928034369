import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {ToastrService} from "ngx-toastr";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-consultants-deploy-modal',
  templateUrl: './consultants-add-video-modal.component.html',
  styleUrls: ['./consultants-add-video-modal.component.css']
})
export class ConsultantAddVideoModalComponent implements OnInit {
  consultantId: string;
  isDisable = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: ApiService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<ConsultantAddVideoModalComponent>,
  ) {
    this.consultantId = data.id;
  }

  ngOnInit(): void {

  }

  close() {
    this.dialogRef.close();
  }

  Payload: any = {
    videoUrl: '',

  };

  saveUrl(url: string) {
    this.Payload.videoUrl = url
    this.service.addConsultantVideo(this.consultantId, this.Payload).subscribe(
      (res: any) => {
        this.dialogRef.close(res.video);
        this.toastr.success('URL Saved', 'Success');
      },
      (error: any) => {
        console.error('Error occurred:', error.error.text);
        this.dialogRef.close();
        this.toastr.error('Request Failed', 'error');
      }
    );
  }

}
