<div class="content-wrapper">
  <div class="row">
    <div class="col-md-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h5 class="page-title">Audit History</h5>
      </div>
    </div>
  </div>

  <div class="col-md-12">

    <mat-accordion class="search_component">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="filter_head">
            <h5 class="m-0">Search by Filter</h5>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="selectedFilters.length>0">
          <h5 class="filter_head mb-3">
            Applied filters
          </h5>
          <mat-chip-list #chipList>
            <mat-chip class="theme-bg-color"
                      *ngFor="let filter of selectedFilters, index as i">
              {{ filter.name }} : {{ filter.value }}
              <button matChipRemove class="mb-2" (click)="removeFilter(i,filter)">
                <mat-icon>
                  <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.18344 1L1.80908 7.37436" stroke="white" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path d="M1.80908 1L8.18344 7.37436" stroke="white" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"/>
                  </svg>
                </mat-icon>
              </button>
            </mat-chip>
          </mat-chip-list>
          <div class="border-bottom"></div>
        </div>
        <div class="row_grid">
          <div class="dateRange">
            <label class="d-flex">Date Range</label>
            <div class="d-flex">
              <mat-form-field appearance="fill" class="border-red">
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate placeholder="Start date" [(ngModel)]="startDate">
                  <input matEndDate placeholder="End date" [(ngModel)]="endDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>
            </div>
          </div>
          <div class="">
            <label class="d-flex">User</label>
            <mat-form-field appearance="outline" class="border-red">
              <mat-label>Choose User</mat-label>
              <mat-select #select (selectionChange)="optionClick($event)" multiple>
                <mat-option *ngFor="let member of members" [value]="member">
                  <a class="dropdown-item">
                    {{ member.firstName + ' ' + member.lastName + ' | ' + member.role }}
                  </a>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="">
            <label>Action</label>
            <div class="">
              <mat-form-field appearance="outline" class="border-red">
                <mat-label>Choose Action</mat-label>
                <mat-select #action (selectionChange)="optionAction($event)" multiple>
                  <mat-option *ngFor="let action of actionFilter" [value]="action">
                    <a class="dropdown-item">
                      {{ action }}
                    </a>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="mx-auto d-flex align-items-center mt-4 justify-content-end">
            <button class="btn btn-reset me-2" (click)="onResetFilters($event,currentPage)">
              Reset
            </button>
            <button class="btn btn-submit theme-btn-color" (click)="onLoadHistory($event ,currentPage)">
              Show Results
            </button>
          </div>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div *ngIf="!showLoader && audit.length == 0" class="no-data-view d-flex justify-content-center mt-8">
    <mat-card class="mat-card">No data is found</mat-card>
  </div>
  <div *ngIf="showLoader" class=" d-flex justify-content-center align-item-center mt-5 pt-5">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
  <div class="col-md-12">
    <div class="content-wrapper-main-inner-content">

      <table *ngIf="!showLoader && audit.length > 0" class="table table-Ui table-striped">
        <thead>
        <tr>
          <th class="border-0 p-0 pb-3">
            <div class="d-flex align-items-lg-center">
              <div class="col-8 p-0">
                <span style="color: #5A5A5A;font-weight: 500">Audit History</span>
              </div>
              <div class="col-4 d-flex justify-content-end p-0">
                <button class="btn btn-export theme-btn-color" (click)="downloadExcel()">
                  <i class="fas fa-cloud-download-alt"></i> Download Report
                </button>
              </div>
            </div>
          </th>
        </tr>
        <tr style="color: #fff" class="theme-bg-color">
          <th style="padding: 10px 28px;font-weight: 500"> Results showing: {{ audit.length }} / {{ allRecords }}</th>
        </tr>
        </thead>
        <tbody>

        <ng-container *ngFor="let audits of audit">
          <tr>
            <th>
              <div class="col d-flex">
                <div class="navbar-nav header-right">
                  <a class="nav-link" role="button" data-toggle="dropdown" id="navbarDropdown" data-bs-toggle="dropdown"
                     aria-expanded="false">
                    <div class="circle">
                      <span
                        class="initials"> {{ getInitials(audits.user?.firstName ?? '', audits?.user?.lastName ?? '-') }} </span>
                    </div>
                  </a>
                </div>
                <div class="col-md-9 d-flex mt-3 " style="font-weight: normal">
                  <p
                    *ngIf="audits.action !== 'Rename'"> {{ audits.user?.firstName ?? '' }} {{ audits?.user?.lastName ?? '' }}  {{ audits?.actionDetails }}
                    .</p>
                  <p *ngIf="audits.action == 'Rename'"> {{ audits.user?.firstName }} {{ audits?.user?.lastName }}
                    Renamed {{ audits?.previousValue }} to {{ audits?.nextValue }}.</p>
                </div>
                <div>
                  <div class="col mt-3 justify-content-end" style="font-weight: lighter; font-size: 14px">
                    <p style="font-size: 12px"> {{ audits.date | date: ' h:mm a, d MMM y ' }}</p>
                  </div>
                </div>
              </div>
            </th>
        </ng-container>
        </tbody>
      </table>

      <mat-paginator [length]="totalAudits"
                     [pageSize]="pageSize"
                     [pageSizeOptions]="[10,12,25,50,100]"
                     [hidden]="totalAudits==0"
                     aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
</div>
