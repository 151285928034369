<app-sidebar [sidebarFlag]="sidebarClass_1"></app-sidebar>
<div class="page-wrapper">
  <div *ngIf="this.isLoading" class="z-index_high progress_bar">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <app-header (sidebarClass)="getSidebarClass($event)"></app-header>
  <div [ngClass]="sidebarClass_1 ? 'main-content-active' : 'main-content'" id="main-contentid">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
