
<div class="container modal-popUp-content text-center position-relative">
  <div class="modal-inner-content">
    <div *ngIf="data.type==='consultant-summary'"  class="modal-header d-flex justify-content-between py-1">
      <p class="title">{{data.title}}</p>
      <div (click)="closeSummaryDialog()" style="cursor: pointer">
        <i class="fas fa-times"></i>
      </div>
    </div>
    <div *ngIf="data.type==='Skills'"  class="modal-header d-flex justify-content-between py-1">
      <p class="title">{{data.title}}</p>
      <div (click)="closeSummaryDialog()" style="cursor: pointer">
        <i class="fas fa-times"></i>
      </div>
    </div>
    <pre *ngIf="data.type==='consultant-summary'" class="content-summary pt-2" style="white-space: pre-line;">{{data.summary}} </pre>
    <div *ngIf="data.type!='consultant-summary'">
      <p class="title">{{data.title}}</p>
      <p class="content">{{data.message}}  </p>
    </div>
  </div>
  <div class="justify-content-center d-flex pb-4">
    <button mat-raised-button class="mx-2" *ngIf="data.type==='confirmation'" (click)="close(false)">No </button>
    <button mat-raised-button class="mx-2" *ngIf="data.type==='confirmation'" mat-dialog-close (click)="close(true)">Yes</button>
    <button mat-raised-button class="mx-2" *ngIf="data.type==='Delete'" (click)="close(false)">Cancel</button>
    <button mat-raised-button class="mx-2" *ngIf="data.type==='Delete'" (click)="close(true)">Delete</button>
    <button mat-raised-button class="mx-2" *ngIf="data.type==='Inactive'" (click)="close(false)">Cancel</button>
    <button mat-raised-button class="mx-2" *ngIf="data.type==='Inactive'" (click)="close(true)">Inactive</button>
    <button mat-raised-button class="mx-2" *ngIf="data.type==='Active'" (click)="close(false)">Cancel</button>
    <button mat-raised-button class="mx-2" *ngIf="data.type==='Active'" (click)="close(true)">Active</button>
    <button mat-raised-button class="mx-2" *ngIf="data.type==='information'" (click)="close('done')">OK</button>
  </div>
</div>
