<mat-form-field appearance="outline" class="w-100 border-red ms-0">
  <mat-label>Passing Year</mat-label>
  <input matInput [matDatepicker]="dp" [formControl]="date" placeholder="Choose a Month and Year" readonly
         [required]="true">
  <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
  <mat-datepicker #dp
                  startView="multi-year"
                  (monthSelected)="setMonthAndYear($event, dp)"
                  panelClass="example-month-picker">
  </mat-datepicker>
</mat-form-field>
