<div>
  <div class="d-flex align-items-center justify-content-between">
    <div class="ms-sm-4 fs-5">
      Introductory Video
    </div>
    <div class="d-flex align-items-center justify-content-end">
      <div (click)="close()" class="btn close-btn">
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.5143 1L1.22705 17.2871" stroke="#454545" stroke-width="2" stroke-linecap="round"/>
          <path d="M1.22705 1L17.5143 17.2871" stroke="#454545" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </div>
    </div>
  </div>
  <div class="iframe-container">
    <iframe class="iframe-element" [attr.src]="getEmbedUrl()" sandbox="allow-same-origin allow-scripts" allowfullscreen></iframe>
  </div>
</div>


